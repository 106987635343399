import { GeneralErrorPanel, MainContent } from '@components';
import { ProductFormModal, ProductFormModalProps } from '@components/molecules/ProductFormModal/ProductFormModal';
import { getIntegrationImageSrc } from '@containers/formatters/sourceIconFormatter';
import { MemberPageContainer } from '@containers/MemberPageContainer/MemberPageContainer';
import { ProductDetailsTabMenuContainer } from '@containers/ProductDetailsTabMenuContainer';
import {
  ActionBar,
  Alert,
  AlertDescription,
  AlertTitle,
  Badge,
  Box,
  Button,
  Dialog,
  DialogTypes,
  Flex,
  FormValidator,
  Icon,
  Panel,
  Spinner,
  Text,
  Tooltip,
  Widget,
  WidgetOneField
} from '@oplog/express';
import { urls } from '@routes/urls';
import {
  IntegrationType,
  PCSDimensionsDTO,
  ProductDetailOutputDTO,
  ProductFavoriteOutputDTO,
  ProductState,
  TenantDetailsDTO,
  UpdateProductCommand
} from '@services';
import { dateToString, integrationIdentifier } from '@utils';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { Props } from '../../atoms/Component/Component';

export interface ProductDetailsProps extends Props {
  onFavoriteChanged: (isFavorited: boolean) => void;
  productDetails: ProductDetailOutputDTO;
  isBusy: boolean;
  updateFavoriteError?: ErrorModel;
  updateError?: ErrorModel;
  updateFavoriteIsBusy: boolean;
  updateFavoriteSuccess: boolean;
  getFavoriteIsBusy: boolean;
  getFavoriteError?: ErrorModel;
  productFavorite: ProductFavoriteOutputDTO;
  onUpdateProductFavoriteSuccess: () => void;
  fileUrl: string;
  validator: FormValidator;
  onReset: () => void;
  onFileSelect: (file: File) => void;
  isUpdateSuccessFull: boolean;
  onProductUpdate: (command: UpdateProductCommand) => void;
  onRefresh: () => void;
  isUpdateBusy: boolean;
  isUploading: boolean;
  clearError: () => void;
  getTenantDetails: () => void;
  tenantDetails: TenantDetailsDTO;
}

export enum FavoriteState {
  Unchecked = 0,
  Checked = 1,
}

export const ProductDetails: React.FC<ProductDetailsProps> = ({
  onFavoriteChanged,
  productDetails,
  isBusy,
  updateFavoriteError,
  updateError,
  updateFavoriteIsBusy,
  updateFavoriteSuccess,
  getFavoriteIsBusy,
  getFavoriteError,
  productFavorite,
  onUpdateProductFavoriteSuccess,
  fileUrl,
  validator,
  onReset,
  onFileSelect,
  isUpdateSuccessFull,
  onProductUpdate,
  onRefresh,
  isUpdateBusy,
  isUploading,
  clearError,
  intl,
  error,
  onDidMount,
  onWillUnmount,
  getTenantDetails,
  tenantDetails,
}) => {
  const intlPrefix = 'ProductDetails';
  const imageSectionIntlPrefix = `${intlPrefix}.Section.Image`;
  const detailSectionIntlPrefix = `${intlPrefix}.Section.Detail`;
  const otherSectionIntlPrefix = `${intlPrefix}.Section.Other`;
  const dimensionSectionIntlPrefix = `${intlPrefix}.Section.Dimension`;

  const detailSectionLabelIntlPrefix = `${detailSectionIntlPrefix}.Label`;
  const otherSectionLabelIntlPrefix = `${otherSectionIntlPrefix}.Label`;
  const dimensionSectionLabelIntlPrefix = `${dimensionSectionIntlPrefix}.Label`;

  const [isSuccessful, setIsSuccessful] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [isPostErrorOpen, setIsPostErrorOpen] = useState(false);

  useEffect(() => {
    onDidMount && onDidMount();

    getTenantDetails();

    return () => {
      onWillUnmount && onWillUnmount();
    };
  }, []);

  useEffect(() => {
    if (isUpdateSuccessFull) {
      onRefresh();
      setIsSuccessful(true);
    }
  }, [isUpdateSuccessFull]);

  useEffect(() => {
    if (updateFavoriteError || getFavoriteError) {
      setIsSuccessful(false);
      setUpdateModal(false);
    }
  }, [updateFavoriteError, getFavoriteError]);

  useEffect(() => {
    onUpdateProductFavoriteSuccess();
  }, [updateFavoriteSuccess]);

  const getProductStatusLabel = (status: ProductState) => {
    let color = 'palette.grey';
    if (status === ProductState.Active) {
      color = 'palette.teal';
    } else if (status === ProductState.Disabled) {
      color = 'palette.orange';
    }
    return (
      <Badge width="71px" textAlign="center" bg={color} color="palette.white">
        {isBusy ? '' : intl.messages[`Enum.${status}`].toUpperCase()}
      </Badge>
    );
  };

  const summaryInfoFields = (): Array<WidgetOneField> => {
    return [
      {
        title: intl.messages[`${detailSectionLabelIntlPrefix}.Type`],
        value: isBusy || !productDetails ? '' : intl.messages[`Enum.${productDetails.type}`],
      },
      {
        title: intl.messages[`${detailSectionLabelIntlPrefix}.Status`],
        value: isBusy || !productDetails ? '' : getProductStatusLabel(productDetails.state),
      },
      {
        title: intl.messages[`${detailSectionLabelIntlPrefix}.SKU`],
        value: isBusy || !productDetails ? '' : productDetails.sku,
      },
      {
        title: intl.messages[`${detailSectionLabelIntlPrefix}.Barcode`],
        value: isBusy || !productDetails ? '' : productDetails.barcodes.join(', '),
      },
      {
        title: intl.messages[`${detailSectionLabelIntlPrefix}.Category`],
        value: isBusy || !productDetails ? '' : productDetails.category || 'N/A',
      },
      {
        title: intl.messages[`${otherSectionLabelIntlPrefix}.CreatedAt`],
        value: isBusy || !productDetails ? '' : dateToString(productDetails.createdAt),
      },
      {
        title: intl.messages[`${otherSectionLabelIntlPrefix}.LastUpdatedAt`],
        value: isBusy || !productDetails ? '' : dateToString(productDetails.updatedAt),
      },
    ];
  };

  const otherInfoFields = (): Array<WidgetOneField> => {
    return [
      {
        title: intl.messages[`${detailSectionLabelIntlPrefix}.StockAmount`],
        value:
          isBusy || !productDetails
            ? ''
            : productDetails.stockAmount === null || productDetails.stockAmount === undefined
            ? 'N/A'
            : intl.formatMessage(
                { id: 'ProductDetails.Section.Detail.Value.StockAmount' },
                { stock: productDetails.stockAmount }
              ),
      },
      {
        title: intl.messages[`${detailSectionLabelIntlPrefix}.ReservedAmount`],
        value:
          isBusy || !productDetails
            ? ''
            : productDetails.reservedAmount === null || productDetails.reservedAmount === undefined
            ? 'N/A'
            : intl.formatMessage(
                { id: 'ProductDetails.Section.Detail.Value.StockAmount' },
                { stock: productDetails.reservedAmount }
              ),
      },
      {
        title: intl.messages[`${otherSectionLabelIntlPrefix}.AvailableStockAmount`],
        value:
          isBusy || !productDetails
            ? ''
            : productDetails.availableStockAmount === null || productDetails.availableStockAmount === undefined
            ? 'N/A'
            : intl.formatMessage(
                { id: 'ProductDetails.Section.Other.Value.AvailableStockAmount' },
                { stock: productDetails.availableStockAmount }
              ),
      },
      {
        title: intl.messages[`${otherSectionLabelIntlPrefix}.ReceivedItemAmount`],
        value:
          isBusy || !productDetails
            ? ''
            : productDetails.receivedItemAmount === null || productDetails.receivedItemAmount === undefined
            ? 'N/A'
            : intl.formatMessage(
                { id: 'ProductDetails.Section.Other.Value.ReceivedItemAmount' },
                { stock: productDetails.receivedItemAmount }
              ),
      },
      {
        title: intl.messages[`${otherSectionLabelIntlPrefix}.DamagedItemAmount`],
        value:
          isBusy || !productDetails
            ? ''
            : productDetails.damagedItemAmount === null || productDetails.damagedItemAmount === undefined
            ? 'N/A'
            : intl.formatMessage(
                { id: 'ProductDetails.Section.Other.Value.DamagedItemAmount' },
                { stock: productDetails.damagedItemAmount }
              ),
      },
      ...(tenantDetails?.isLostItemAmountVisible
        ? [
            {
              title: intl.messages[`${otherSectionLabelIntlPrefix}.LostItemAmount`],
              value:
                isBusy || !productDetails
                  ? ''
                  : productDetails.lostItemAmount === null || productDetails.lostItemAmount === undefined
                  ? 'N/A'
                  : intl.formatMessage(
                      { id: 'ProductDetails.Section.Other.Value.LostItemAmount' },
                      { stock: productDetails.lostItemAmount }
                    ),
            },
          ]
        : []),
      {
        title: intl.messages[`${otherSectionLabelIntlPrefix}.ExpiredAmount`],
        value:
          isBusy || !productDetails
            ? ''
            : productDetails.expiredAmount === null || productDetails.expiredAmount === undefined
            ? 'N/A'
            : intl.formatMessage(
                { id: 'ProductDetails.Section.Other.Value.ExpiredAmount' },
                { stock: productDetails.expiredAmount }
              ),
      },
    ];
  };

  const dimensionsInfoFields = (): Array<WidgetOneField> => {
    return [
      {
        title: intl.messages[`${dimensionSectionLabelIntlPrefix}.Height`],
        value:
          isBusy || !productDetails
            ? ''
            : productDetails.height === null || productDetails.height === undefined
            ? 'N/A'
            : productDetails.height?.toString() + ' ' + productDetails.unitOfDimension,
      },
      {
        title: intl.messages[`${dimensionSectionLabelIntlPrefix}.Width`],
        value:
          isBusy || !productDetails
            ? ''
            : productDetails.width === null || productDetails.width === undefined
            ? 'N/A'
            : productDetails.width?.toString() + ' ' + productDetails.unitOfDimension,
      },
      {
        title: intl.messages[`${dimensionSectionLabelIntlPrefix}.Length`],
        value:
          isBusy || !productDetails
            ? ''
            : productDetails.length === null || productDetails.length === undefined
            ? 'N/A'
            : productDetails.length?.toString() + ' ' + productDetails.unitOfDimension,
      },
      {
        title: intl.messages[`${dimensionSectionLabelIntlPrefix}.Weight`],
        value:
          isBusy || !productDetails
            ? ''
            : productDetails.weight === null || productDetails.weight === undefined
            ? 'N/A'
            : productDetails.weight?.toString() + ' ' + productDetails.unitOfWeight,
      },
    ];
  };

  const toggleUpdateModal = (updateModal: boolean) => {
    return () => {
      setUpdateModal(updateModal);
    };
  };

  const onSubmit = (form: UpdateProductCommand) => {
    const dimensions: PCSDimensionsDTO = {
      height: form.height,
      width: form.width,
      weight: form.weight,
      length: form.length,
      unitOfWeight: form.unitOfWeight?.toLocaleLowerCase(),
      unitOfDimension: form.unitOfDimension?.toLocaleLowerCase(),
    };

    form.dimensions = dimensions;
    onProductUpdate(form);
  };

  const renderEditButton = () => {
    const isDisabled = productDetails && productDetails.integrationType !== IntegrationType.Oplog;
    const button = (
      <Button variant="dark" onClick={toggleUpdateModal(true)} id="edit-product" disabled={isBusy || isDisabled}>
        {intl.messages[`${intlPrefix}.Action.Edit`]}
      </Button>
    );
    if (isDisabled) {
      return (
        <Tooltip placement="bottom" content={intl.messages[`${intlPrefix}.EditProductWarning`]}>
          {button}
        </Tooltip>
      );
    }
    return button;
  };

  const toggleFavorite = () => {
    if (updateFavoriteIsBusy) {
      return;
    }

    onFavoriteChanged(!productFavorite.favorite);
  };

  const title = intl.messages[`${imageSectionIntlPrefix}.Title`];

  const subtitle = intl.messages['ProductDetails.Title'];
  const documentTitle = productDetails ? `${productDetails.name} - ${subtitle}` : '';

  const breadcrumb = [
    { title: `${intl.messages['ProductCatalog.Header.Title']}`, url: urls.products },
    { title: productDetails ? productDetails.name : '' },
  ];

  const modalProps: ProductFormModalProps = {
    onClose: toggleUpdateModal(false),
    onFileSelect: onFileSelect,
    onReset: onReset,
    isEdit: true,
    isBusy: isUpdateBusy,
    validator: validator,
    fileUrl: fileUrl,
    data: productDetails || undefined,
    intl,
    isUploading,
    onSubmit: onSubmit,
    error: updateError,
  };

  const integrationIconName = integrationIdentifier(productDetails?.integrationType, productDetails?.openApiIdentifier);

  return (
    <MemberPageContainer documentTitle={documentTitle}>
      <Alert variant="danger" isOpen={!!updateFavoriteError} onDismiss={clearError}>
        <AlertTitle>{intl.messages['PostErrorMessage.Title']}</AlertTitle>
        <AlertDescription>{intl.messages['PostErrorMessage.Description']}</AlertDescription>
      </Alert>

      <ActionBar
        top="66px"
        isLoading={isBusy}
        title={productDetails ? productDetails.name : subtitle}
        subtitle={productDetails ? subtitle : ''}
        integration={{
          icon: getIntegrationImageSrc(integrationIconName),
          name:
            productDetails && productDetails.integrationName
              ? productDetails.integrationName
              : intl.messages['Integration.NoIntegration'],
        }}
        breadcrumb={breadcrumb}
      >
        <Flex marginLeft="auto" alignItems="center">
          {updateFavoriteIsBusy || getFavoriteIsBusy ? (
            <Spinner mr="16" speed="1s" thickness="3px" emptyColor="palette.steel" />
          ) : (
            <Button kind="link" onClick={toggleFavorite}>
              <Flex alignItems="center" mr="16">
                <Text
                  fontFamily="heading"
                  color="palette.steel"
                  letterSpacing="negativeMedium"
                  fontSize="13"
                  mr="11"
                  fontWeight={800}
                >
                  {productFavorite && productFavorite.favorite
                    ? intl.messages[`ProductDetails.Unfavorite`]
                    : intl.messages[`ProductDetails.Favorite`]}
                </Text>
                <Icon
                  name={`${productFavorite && productFavorite.favorite ? 'fas' : 'far'} fa-star`}
                  fontSize="28"
                  color={productFavorite && productFavorite.favorite ? 'palette.yellow_dark' : 'palette.steel'}
                />
              </Flex>
            </Button>
          )}
          {!error && renderEditButton()}
        </Flex>
      </ActionBar>
      {isPostErrorOpen && (
        <Alert variant="danger" isOpen={isPostErrorOpen} onDismiss={() => setIsPostErrorOpen(false)}>
          <AlertTitle>{intl.messages['PostErrorMessage.Title']}</AlertTitle>
          <AlertDescription>{intl.messages['PostErrorMessage.Description']}</AlertDescription>
        </Alert>
      )}
      <MainContent>
        {error ? (
          <Box>
            <Panel title={intl.messages[`${detailSectionIntlPrefix}.Title`]}>
              <GeneralErrorPanel className="align-left" />
            </Panel>
          </Box>
        ) : (
          <Flex>
            <Flex flexDirection="column" width="50%">
              <Panel pb={['22', '22', '0']} title={title} height="full">
                {isBusy || !productDetails ? (
                  <Skeleton />
                ) : productDetails.imageUrl ? (
                  <Box
                    backgroundImage={`url("${productDetails.imageUrl}")`}
                    backgroundPosition="center"
                    backgroundRepeat="no-repeat"
                    backgroundSize="contain"
                    height={[250, 250, '100%']}
                  />
                ) : (
                  <Flex justifyContent="center" alignItems="center" height="full">
                    <Icon name="fas fa-images" fontSize={58} />
                  </Flex>
                )}
              </Panel>
            </Flex>
            <Box width="full" pl={['0', '0', '22']}>
              <Widget.One
                isLoading={isBusy}
                fields={summaryInfoFields()}
                column={2}
                panelProps={{ title: intl.messages[`${detailSectionIntlPrefix}.Title`] }}
              />
            </Box>
            <Box width="full" pl={['0', '22']} pt={['22', '0']}>
              <Widget.One
                isLoading={isBusy}
                fields={otherInfoFields()}
                column={3}
                panelProps={{ title: intl.messages[`${otherSectionIntlPrefix}.Title`] }}
              />
              <Widget.One
                isLoading={isBusy}
                fields={dimensionsInfoFields()}
                column={4}
                panelProps={{
                  title: (
                    <Box mb={-8} mt={8}>
                      {intl.messages[`${dimensionSectionIntlPrefix}.Title`]}
                    </Box>
                  ),
                }}
              />
            </Box>
          </Flex>
        )}
        <Box mt="30">
          <ProductDetailsTabMenuContainer intl={intl} />
        </Box>
        {updateModal && <ProductFormModal {...modalProps} />}
        {isSuccessful && (
          <Dialog
            type={DialogTypes.Success}
            message={<FormattedMessage id={`${intlPrefix}.SuccessDialog`} />}
            text={{ approve: intl.messages[`Modal.Success.Okay`] }}
            isOpen={isSuccessful}
            onApprove={() => {
              setIsSuccessful(false);
              setUpdateModal(false);
            }}
          />
        )}
      </MainContent>
    </MemberPageContainer>
  );
};
