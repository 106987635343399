import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormValidator,
  Input,
  InputCopy,
  Toggle,
} from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import {
  CreatedIntegrationOutputDTO,
  CreateIntegrationCommand,
  IntegrationOutputDTO,
  IntegrationState,
  IntegrationType,
  OpenApiIdentifier,
  UpdateIntegrationCommand,
} from '@services';
import * as React from 'react';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Props } from '../../atoms/Component/Component';

const COMPONENT_INTL_KEY = 'Integrations.IntegrationInfo';

export interface OpenApiIntegrationProps extends Props {
  integration?: IntegrationOutputDTO;
  type?: IntegrationType;
  validator: FormValidator;
  deleteIntegrationResource?: Resource<Response>;
  onSubmit: (
    integration: UpdateIntegrationCommand | CreateIntegrationCommand,
    initialIntegrationState: boolean | undefined
  ) => void;
  onClose: (isSuccess?: boolean) => void;
  onDelete?: () => void;
  isEdit: boolean;
  isNew: boolean;
  response?: CreatedIntegrationOutputDTO;
  isReferenceNumberRequired?: boolean;
}

export const OpenApiIntegrationForm: React.FC<OpenApiIntegrationProps> = ({
  response,
  validator,
  intl,
  integration,
  deleteIntegrationResource,
  isReferenceNumberRequired,
  isBusy,
  onClose,
  onDelete,
  isNew,
  onSubmit,
  isEdit,
  error,
}: OpenApiIntegrationProps) => {
  const [credentials, setCredentials] = useState<CreatedIntegrationOutputDTO>({
    id: '',
    integrationCredentials: {},
  });

  const [integrationState, setIntegrationState] = useState({
    id: integration ? integration.id : '',
    isActive: integration ? integration.state === IntegrationState.Active : true,
    integrationName: integration ? integration.name : '',
    referenceNumberPrefix: integration ? integration.referenceNumberPrefix : '',
    shouldSyncMissingData: false,
    type: IntegrationType.OpenApi,
    openApiIdentifier: integration ? integration.openApiIdentifier : OpenApiIdentifier.None,
  });

  useEffect(() => {
    validator.registerSchema(openApiIntegrationFormSchemaFields());
    return () => {
      validator.clearErrors();
    };
  }, []);

  useEffect(() => {
    if (response && response.integrationCredentials && Object.keys(response.integrationCredentials).length > 0) {
      const { clientId = '', clientSecret = '', tenantId = '', id = '' }: any = response.integrationCredentials;
      setCredentials({
        id: id,
        integrationCredentials: {
          clientId,
          clientSecret,
          tenantId,
        },
      });
    }
  }, [response]);

  const openApiIntegrationFormSchemaFields = () => {
    const referenceNumberPrefix = isReferenceNumberRequired
      ? Yup.string()
        .required(`${intl.messages[`${COMPONENT_INTL_KEY}.Form.ReferenceNumberPrefixInfo`]}`)
        .max(3, `${intl.messages[`${COMPONENT_INTL_KEY}.Error.ReferenceNumberPrefixMaxChar`]}`)
      : Yup.string().max(3, `${intl.messages[`${COMPONENT_INTL_KEY}.Error.ReferenceNumberPrefixMaxChar`]}`);

    return {
      id: Yup.string().required(),
      isActive: Yup.boolean().required(),
      integrationName: Yup.string().required(`${intl.messages[`${COMPONENT_INTL_KEY}.Error.IntegrationName`]}`),
      type: Yup.string().required(),
      shouldSyncMissingData: Yup.boolean().required(),
      referenceNumberPrefix,
    };
  };

  const handleClose = () => {
    if (deleteIntegrationResource && (isBusy || deleteIntegrationResource.isBusy)) {
      return;
    }
    onClose();
  };

  const handleDelete = () => {
    if (onDelete) {
      if (deleteIntegrationResource && (isBusy || deleteIntegrationResource.isBusy)) {
        return;
      }
      onDelete();
    }
  };

  const handleBlur = (key: string) => {
    validator.validate(key, integrationState[key]);
  };

  const handleIsActiveChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setIntegrationState({
      ...integrationState,
      isActive: !!e.currentTarget.checked,
    });
  };

  const handleInputChange = (key: string, value: any) => {
    setIntegrationState({
      ...integrationState,
      [key]: value,
    });
  };

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if ((deleteIntegrationResource && deleteIntegrationResource.isBusy) || isBusy) {
      return;
    }
    if (isNew) {
      const excludeNew = ['id', 'isActive', 'shouldSyncMissingData'];
      excludeNew.forEach(key => delete integrationState[key]);
    }

    for (const key in integration) {
      if (integration.hasOwnProperty(key)) {
        const element = integration[key];
        validator.validate(key, element);
      }
    }

    if (validator.hasErrors()) {
      return;
    }

    onSubmit(integrationState, integrationState.isActive);
  };

  const { clientId, clientSecret, tenantId }: any = credentials.integrationCredentials;
  const hasCredentials = clientId && clientSecret && tenantId;
  const errorKey = error && error.errors && error.errors.length > 0 ? error.errors[0].errorCode : error?.message;

  return (
    <form onSubmit={handleSubmit}>
      {isEdit && (
        <>
          <FormControl
            size="small"
            isInline
            mb="22"
            isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
          >
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.IsActive`]}</FormLabel>
            <Toggle isChecked={integrationState.isActive || false} onChange={handleIsActiveChange} />
          </FormControl>
          <FormControl size="small" isInline mb="11">
            <FormLabel>{'INTEGRATION ID'}</FormLabel>
            <Input placeholder={'INTEGRATION ID'} value={integrationState.id} isReadOnly />
          </FormControl>
        </>
      )}
      {!hasCredentials ? (
        <>
          <FormControl
            size="small"
            isInvalid={!!validator.getErrorIntent('integrationName')}
            isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
            isInline
            mb="11"
          >
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.IntegrationName`]}</FormLabel>
            <Input
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.IntegrationName`]}
              value={integrationState.integrationName}
              onChange={(e: any) => handleInputChange('integrationName', e.currentTarget.value)}
              onBlur={() => handleBlur('integrationName')}
            />
            <FormErrorMessage>{validator.getErrorIntent('integrationName')?.text}</FormErrorMessage>
            <FormErrorMessage>{error ? error.message : ''}</FormErrorMessage>
          </FormControl>
          <FormControl
            size="small"
            isInvalid={!!validator.getErrorIntent('referenceNumberPrefix')}
            isInline
            isDisabled={isEdit || isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
            mb="11"
          >
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.ReferenceNumberPrefix`]}</FormLabel>
            <Input
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.ReferenceNumberPrefix`]}
              value={integrationState.referenceNumberPrefix}
              onChange={(e: any) => handleInputChange('referenceNumberPrefix', e.currentTarget.value)}
              onBlur={handleBlur('referenceNumberPrefix')}
            />
            <FormErrorMessage>{validator.getErrorIntent('referenceNumberPrefix')?.text}</FormErrorMessage>
          </FormControl>
        </>
      ) : (
        <>
          <FormControl size="small" isInline mb="11">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.StaticToken`]}</FormLabel>
            <InputCopy
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.StaticToken`]}
              value={clientId}
              isReadOnly
            />
          </FormControl>
          <FormControl size="small" isInline mb="11">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.TenantId`]}</FormLabel>
            <InputCopy value={tenantId} isReadOnly />
          </FormControl>
          <FormErrorMessage independent>
            {intl.messages[`${COMPONENT_INTL_KEY}.Error.CredentialWarning`]}
          </FormErrorMessage>
        </>
      )}
      {!hasCredentials || isEdit ? (
        <>
          <FormErrorMessage independent>{intl.messages[`${COMPONENT_INTL_KEY}.Error.${errorKey}`]}</FormErrorMessage>
          <Flex mt="16">
            <Button
              size="small"
              type="button"
              kind="outline"
              variant="dark"
              disabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
              onClick={handleClose}
              mr="11"
              width="full"
              maxWidth="118px"
            >
              {intl.messages[`${COMPONENT_INTL_KEY}.Form.Cancel`]}
            </Button>
            {isEdit && (
              <Button
                size="small"
                type="button"
                variant="danger"
                disabled={isBusy}
                isLoading={deleteIntegrationResource && deleteIntegrationResource.isBusy}
                onClick={handleDelete}
                width="full"
              >
                {intl.messages[`${COMPONENT_INTL_KEY}.Form.Delete`]}
              </Button>
            )}
            <Button
              size="small"
              type="submit"
              disabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
              isLoading={isBusy && !(deleteIntegrationResource && deleteIntegrationResource.isBusy)}
              width="full"
              ml="11"
              variant="success"
            >
              {intl.messages[`${COMPONENT_INTL_KEY}.Form.Submit`]}
            </Button>
          </Flex>
        </>
      ) : (
        <Button
          mt="11"
          marginLeft="auto"
          size="small"
          type="submit"
          onClick={() => {
            onClose(true);
          }}
        >
          {intl.messages[`${COMPONENT_INTL_KEY}.Form.Okay`]}
        </Button>
      )}
    </form>
  );
};
