import {
  Box, Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormValidator,
  Input,
  InputGroup,
  InputRightAddon, ModalContent, ModalTitle,
  MultiStepModal,
  Select,
  SelectHTML,
  Text,
  Toggle
} from '@oplog/express';
import { FileType, FileUploader } from '@oplog/file-uploader';
import { CreateProductCommand, Currency, DimensionUnit, ProductDetailOutputDTO, ProductType, UpdateProductCommand, WeightUnit } from '@services';
import React, { useEffect, useState } from 'react';
import useForceUpdate from 'use-force-update';
import * as Yup from 'yup';
import { Props } from '../../atoms/Component/Component';

const COMPONENT_INTL_KEY = 'ProductForm';

export interface ProductFormModalProps extends Props {
  onSubmit: (form: CreateProductCommand | UpdateProductCommand) => void;
  onClose: () => void;
  onFileSelect: (file: File) => void;
  onReset: () => void;
  isEdit: boolean;
  validator: FormValidator;
  fileUrl: string;
  data?: ProductDetailOutputDTO;
  isUploading: boolean;
}

export interface ProductFormModalState {
  form: CreateProductCommand | UpdateProductCommand;
  imageStatus: boolean;
  fileName: string;
  renderPageTwo: boolean;
}

export const ProductFormModal: React.FC<ProductFormModalProps> = ({
  intl,
  validator,
  onClose,
  onFileSelect,
  onReset,
  onSubmit,
  isBusy,
  fileUrl,
  data,
  isUploading,
  isEdit,
  error,
}) => {
  const [imageStatus, setImageStatus] = useState(false);
  const [renderPageTwo, setRenderPageTwo] = useState(false);
  const [fileName, setFileName] = useState('');
  const [form, setForm] = useState<CreateProductCommand | UpdateProductCommand>({
    sku: data ? data.sku : '',
    barcodes: data ? data.barcodes : [],
    name: data ? data.name : '',
    type: data ? (data.type as any) : ProductType.Product,
    category: data ? data.category : '',
    imageUrl: data ? data.imageUrl : '',
    price: data && data.price ? data.price : undefined,
    salePrice: data && data.salePrice ? data.salePrice : undefined,
    currency: data && data.currency ? data.currency : undefined,
    taxRatio: data && data.taxRatio ? data.taxRatio : undefined,
    salesUrl: data && data.salesUrl ? data.salesUrl : undefined,
    sioc: data && data.sioc ? data.sioc : false,
    height: data && data.height ? data.height : undefined,
    weight: data && data.weight ? data.weight : undefined,
    width: data && data.width ? data.width : undefined,
    length: data && data.length ? data.length : undefined,
    unitOfWeight: data ? data.unitOfWeight : WeightUnit.Kg,
    unitOfDimension: data ? data.unitOfDimension : DimensionUnit.Cm
  });

  const COMPONENT_ID_KEY = 'productformmodal';

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    validator.registerSchema(productFormModalSchema());

    return () => {
      validator.clearErrors();
      onReset();
    };
  }, []);

  useEffect(() => {
    if (fileUrl) {
      setForm({
        ...form,
        imageUrl: fileUrl,
      });
    }
  }, [fileUrl]);

  const productTypeEnums = () => {
    return Object.keys(ProductType).map(e => ({
      value: e,
      label: intl.messages[`Enum.${e}`],
    }));
  };

  const DimensionUnitEnums = () => {
    return Object.keys(DimensionUnit).map(e => ({
      value: e,
      label: intl.messages[`Enum.${e}`],
    }));
  };

  const WeightUnitEnums = () => {
    return Object.keys(WeightUnit).map(e => ({
      value: e,
      label: intl.messages[`Enum.${e}`],
    }));
  };

  const productCurrencyEnums = () => {
    const currency = Object.keys(Currency).map(e => ({
      value: e,
      label: intl.messages[`${COMPONENT_INTL_KEY}.Form.ProductFormCurrencyOptions.${e}`],
    }));

    currency.push({
      value: '',
      label: intl.messages[`${COMPONENT_INTL_KEY}.Form.ProductFormCurrencyOptions.Default`],
    });
    return currency.filter(
      currencyOption => currencyOption.value !== Currency.UNSUPPORTED.toString() && currencyOption.label
    );
  };

  const productFormModalSchema = () => {
    return {
      sku: Yup.string().required(`${intl.messages[`${COMPONENT_INTL_KEY}.Form.Errors.SKU`]}`),
      name: Yup.string().required(`${intl.messages[`${COMPONENT_INTL_KEY}.Form.Errors.ProductName`]}`),
      type: Yup.string().required(),
      category: Yup.string().notRequired(),
      imageUrl: Yup.string().notRequired(),
      barcodes: Yup.array()
        .required(`${intl.messages[`${COMPONENT_INTL_KEY}.Form.Errors.Barcode`]}`)
        .min(1, `${intl.messages[`${COMPONENT_INTL_KEY}.Form.Errors.Barcode`]}`)
        .nullable(),
      price: Yup.number().notRequired(),
      salePrice: Yup.number().notRequired(),
      currency: Yup.string().notRequired(),
      taxRatio: Yup.number().notRequired(),
      salesUrl: Yup.string().notRequired(),
    };

    /* DO NOT remove .nullable(). It is added because if you add an barcode and then remove that barcode, it shows this error:
    
    this must be a `array` type, but the final value was: `null`. If "null" is intended as an empty value be sure to mark the schema as `.nullable()`

    More info: https://github.com/jquense/yup#mixednullableisnullable-boolean--true-schema
    
    */
  };

  const isFormInvalid = (): boolean => {
    const requiredFields = ['sku', 'name', 'type'];
    const hasEmptyFields = requiredFields.some((key: string) => !form[key]);
    return hasEmptyFields || validator.hasErrors() || form['barcodes']?.length === 0;
  };

  const isSecondFormInvalid = (): boolean => {
    let errors: string[] = [];

    Object.getOwnPropertyNames(form).forEach(propertyName => {
      const error = getSecondFormErrors(propertyName);
      if (error) {
        errors.push(error);
      }
    });

    return errors.length > 0 ? true : false;
  };

  const getSecondFormErrors = (propertyName: string): string | undefined => {
    if (propertyName === 'height') {
    if(form.sioc){
      if(!form.height){
        return `${intl.messages[`${COMPONENT_INTL_KEY}.Form.Errors.SiocPropertiesError`]}`;
      }
    }
  }
  if (propertyName === 'width') {
    if(form.sioc){
      if(!form.width){
        return `${intl.messages[`${COMPONENT_INTL_KEY}.Form.Errors.SiocPropertiesError`]}`;
      }
    }
  }
  if (propertyName === 'weight') {
    if(form.sioc){
      if(!form.weight){
        return `${intl.messages[`${COMPONENT_INTL_KEY}.Form.Errors.SiocPropertiesError`]}`;
      }
    }
  }
  if (propertyName === 'length') {
    if(form.sioc){
      if(!form.length ){
        return `${intl.messages[`${COMPONENT_INTL_KEY}.Form.Errors.SiocPropertiesError`]}`;
      }
    }
  }
    if (propertyName === 'price') {
      if (form.price && form.price < 0) {
        return `${intl.messages[`${COMPONENT_INTL_KEY}.Form.Errors.PriceLowerThanZero`]}`;
      }
      if (form.price && form.price.toString().length > 9) {
        return `${intl.messages[`${COMPONENT_INTL_KEY}.Form.Errors.MaxNineChars`]}`;
      }
    }

    if (propertyName === 'salePrice') {
      if (form.salePrice && form.salePrice < 0) {
        return `${intl.messages[`${COMPONENT_INTL_KEY}.Form.Errors.SalePriceLowerThanZero`]}`;
      }
      if (form.salePrice && form.salePrice.toString().length > 9) {
        return `${intl.messages[`${COMPONENT_INTL_KEY}.Form.Errors.MaxNineChars`]}`;
      }
    }

    if (propertyName === 'taxRatio') {
      if (form.taxRatio && form.taxRatio < 0) {
        return `${intl.messages[`${COMPONENT_INTL_KEY}.Form.Errors.TaxRatioLowerThanZero`]}`;
      }
      if (form.taxRatio && form.taxRatio.toString().length > 9) {
        return `${intl.messages[`${COMPONENT_INTL_KEY}.Form.Errors.MaxNineChars`]}`;
      }
    }

    if (
      propertyName === 'currency' &&
      (form.price || form.salePrice) &&
      (form.price! > 0 || form.salePrice! > 0) &&
      !form.currency
    ) {
      return `${intl.messages[`${COMPONENT_INTL_KEY}.Form.Errors.Currency`]}`;
    }

    if (error && error.errors) {
      for (const e of error.errors) {
        if (
          propertyName === 'salesUrl' &&
          e.property.toLowerCase() === 'salesurl' &&
          e.errorCode === 'SalesUrlIsNotValid'
        ) {
          return intl.messages[`${COMPONENT_INTL_KEY}.Form.Error.SalesUrlIsNotValid`];
        }
      }
    }

    return undefined;
  };

  const getError = (propertyName: string): string | undefined => {
    if (error && error.errors) {
      var hasBarcodeError = false;
      var barcodes = [];

      for (const e of error.errors) {
        if (propertyName === 'sku' && e.property.toLowerCase() === 'sku' && e.errorCode === 'ProductSKUAlreadyExists') {
          return intl.messages[`${COMPONENT_INTL_KEY}.Error.SKUExists`];
        }
        if (
          propertyName === 'barcode' &&
          e.property.toLowerCase() === 'barcodes' &&
          e.errorCode === 'ProductBarcodeAlreadyExists'
        ) {
          if (hasBarcodeError === false) hasBarcodeError = true;
          barcodes.push(e.message);
        }
      }
      if (hasBarcodeError) {
        return barcodes.toString() + ' ' + intl.messages[`${COMPONENT_INTL_KEY}.Error.BarcodeExists`];
      }
    }
    return undefined;
  };

  const handleOnFileSelect = (data: File) => {
    setFileName(data.name);
    onFileSelect(data);
  };

  const handleOnSubmit = () => {
    onSubmit(form as any);
  };

  const handleNextPage = () => {
    setRenderPageTwo(!renderPageTwo);
  };

  const handleBlur = (fieldname: keyof ProductDetailOutputDTO) => {
    return () => {
      validator.validate(fieldname, form[fieldname]);
      forceUpdate();
    };
  };

  const handeBarcodeChange = (e: any) => {
    setForm({
      ...form,
      barcodes: e.currentTarget.value,
    });

    validator.validate('barcodes', e.currentTarget.value);
    forceUpdate();
  };

  const handleInputChange = (fieldname: keyof ProductDetailOutputDTO, e: React.SyntheticEvent<HTMLInputElement>) => {
    if (fieldname === 'salePrice' && !e.currentTarget.value && !form.price) {
      form.currency = undefined;
      setForm({
        ...form,
      });
    }

    if (fieldname === 'price' && !e.currentTarget.value && !form.salePrice) {
      form.currency = undefined;
      setForm({
        ...form,
      });
    }

    setForm({
      ...form,
      [fieldname]: e.currentTarget.value,
    });

    validator.validate(fieldname, e.currentTarget.value);
    forceUpdate();
  };

  const uploadComplete = () => {
    setImageStatus(true);
  };

  const renderResourceAlreadyExistsErrors = (propertyName: string): boolean => {
    if (error && error.errors) {
      for (const e of error.errors) {
        if (propertyName === 'sku' && e.property.toLowerCase() === 'sku' && e.errorCode === 'ProductSKUAlreadyExists') {
          return true;
        }
        if (
          propertyName === 'barcodes' &&
          e.property.toLowerCase() === 'barcodes' &&
          e.errorCode === 'ProductBarcodeAlreadyExists'
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const renderDimesionValuesNotValidErrors = (): boolean => {
    if (error && error.errors) {
      for (const e of error.errors) {
        if ( e.errorCode === 'DimesionValuesNotValid') {
          return true;
        }
      }
    }
    return false;
  };

  const handleIsSiocActiveChange = () => (e: any) => {
    setForm({
      ...form,
      sioc: e.currentTarget.checked,
    });
  };

  const handleOnClose = () => {
    validator.clearErrors();
    onClose();
  };

  const formModalProps = {
    isOpen: true,
    onClose: handleOnClose,
    intl,
  };

  const customStyles = { multiValueRemove: (base: any) => ({ ...base, display: 'none' }) };

  const formModalFirstPage = () => {
    return (
      <ModalContent>
        <form onSubmit={(e: React.SyntheticEvent<HTMLFormElement>) => e.preventDefault()}>
          <FormControl
            isDisabled={isEdit || isUploading || isBusy}
            isInvalid={!!validator.getErrorIntent('sku')}
            size="small"
            mb="11"
          >
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Labels.SKU`]}*</FormLabel>
            <Input
              value={form.sku}
              placeholder={`${intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.SKU`]}`}
              onBlur={handleBlur('sku')}
              onChange={(e: any) => handleInputChange('sku', e)}
              data-cy={`${COMPONENT_ID_KEY}-input-sku`}
            />
            <FormErrorMessage>{validator.getErrorIntent('sku')?.text}</FormErrorMessage>
            {renderResourceAlreadyExistsErrors('sku') && (
              <FormErrorMessage data-cy={`${COMPONENT_ID_KEY}-errormessage-sku`} independent>
                {getError('sku')}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isDisabled={isBusy || isUploading}
            size="small"
            isInvalid={!!validator.getErrorIntent('barcodes')}
            mb="11"
          >
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Labels.Barcode`]}*</FormLabel>
            <Select
              styles={customStyles}
              isMulti
              creatable
              hideIndicators
              formatCreateLabel={(value: string) =>
                `${intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.AddBarcode`]} ${value}`
              }
              noOptionsMessage={() => intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.NoBarcode`]}
              options={form.barcodes && form.barcodes.map(e => ({ key: e, value: e, label: e }))}
              value={form.barcodes}
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.Barcode`]}
              onBlur={handleBlur('barcodes')}
              onChange={handeBarcodeChange}
              size={'large'}
              isInline
              data-cy={`${COMPONENT_ID_KEY}-select-barcodes`}
            />
            <FormErrorMessage>{validator.getErrorIntent('barcodes')?.text}</FormErrorMessage>
            {renderResourceAlreadyExistsErrors('barcodes') && (
              <FormErrorMessage data-cy={`${COMPONENT_ID_KEY}-errormessage-barcodes`} independent>
                {getError('barcode')}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isDisabled={isBusy || isUploading}
            isInvalid={!!validator.getErrorIntent('name')}
            size="small"
            mb="11"
          >
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Labels.ProductName`]}*</FormLabel>
            <Input
              value={form.name}
              placeholder={`${intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.ProductName`]}`}
              onBlur={handleBlur('name')}
              onChange={(e: any) => handleInputChange('name', e)}
              data-cy={`${COMPONENT_ID_KEY}-input-name`}
            />
            <FormErrorMessage>{validator.getErrorIntent('name')?.text}</FormErrorMessage>
          </FormControl>
          <Flex>
            <FormControl size="small" isDisabled={isBusy || isUploading} width={1 / 2} pr="16" mb="11">
              <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Labels.Type`]}*</FormLabel>
              <SelectHTML
                value={form.type}
                options={productTypeEnums()}
                onChange={(e: any) => handleInputChange('type', e)}
                placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.PlaceHolders.Type`]}
                data-cy={`${COMPONENT_ID_KEY}-select-type`}
              />
            </FormControl>
            <FormControl isDisabled={isBusy || isUploading} size="small" width={1 / 2} mb="11">
              <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Labels.Category`]}</FormLabel>
              <Input
                value={form.category}
                placeholder={`${intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.Category`]}`}
                onBlur={handleBlur('category')}
                onChange={(e: any) => handleInputChange('category', e)}
                data-cy={`${COMPONENT_ID_KEY}-input-category`}
              />
            </FormControl>
          </Flex>
          <FormControl size="small">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Labels.ProductImage`]}</FormLabel>
            <InputGroup>
              <InputRightAddon
                as="label"
                htmlFor="imageUploader"
                bg="palette.grey"
                data-cy={`${COMPONENT_ID_KEY}-fileuploader-imageUploader`}
              >
                <Text fontWeight={800} color="palette.white" fontFamily="heading" fontSize="13">
                  {intl.messages['FileUploader.SelectFile']}
                </Text>
              </InputRightAddon>
              <Input
                isReadOnly
                value={fileName}
                isDisabled={isBusy || isUploading}
                placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.ProductImage`]}
                data-cy={`${COMPONENT_ID_KEY}-input-productImagePlaceHolder`}
              />
            </InputGroup>
            <Box display="none">
              <FileUploader
                id="imageUploader"
                labelTextKey="SelectFile"
                resetTimeout={1500}
                intl={intl}
                isBusy={!!isUploading}
                onReset={onReset}
                url={fileUrl}
                onFileSelect={handleOnFileSelect}
                isCompleted={imageStatus}
                onComplete={uploadComplete}
                fileType={FileType.Image}
              />
            </Box>
            {/*
        <Input
          className={'pt-0'}
          readOnly
          fluid
          value={fileName}
          disabled={isBusy || isUploading}
          placeholder=}
        /> */}
          </FormControl>
        </form>
      </ModalContent>
    );
  };

  const formModalSecondPage = () => {
    return (
      <ModalContent>
        <form onSubmit={(e: React.SyntheticEvent<HTMLFormElement>) => e.preventDefault()}>
          <Flex>
            <FormControl
              isInvalid={!!getSecondFormErrors('price')}
              size="small"
              isDisabled={isBusy || isUploading}
              width={1 / 2}
              pr="16"
              mb="11"
            >
              <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Labels.Price`]}</FormLabel>
              <Input
                type="decimal"
                min="0"
                value={form.price ? form.price : ''}
                placeholder={`${intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.Price`]}`}
                onBlur={handleBlur('price')}
                onChange={(e: any) => handleInputChange('price', e)}
                data-cy={`${COMPONENT_ID_KEY}-input-price`}
              />
              <FormErrorMessage>{getSecondFormErrors('price')}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={!!getSecondFormErrors('salePrice')}
              size="small"
              isDisabled={isBusy || isUploading}
              width={1 / 2}
              pr="16"
              mb="11"
            >
              <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Labels.SalePrice`]}</FormLabel>
              <Input
                type="decimal"
                min="0"
                value={form.salePrice ? form.salePrice : ''}
                placeholder={`${intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.SalePrice`]}`}
                onBlur={handleBlur('salePrice')}
                onChange={(e: any) => handleInputChange('salePrice', e)}
                data-cy={`${COMPONENT_ID_KEY}-input-salePrice`}
              />
              <FormErrorMessage>{getSecondFormErrors('salePrice')}</FormErrorMessage>
            </FormControl>
          </Flex>

          {form.price || form.salePrice ? (
            <FormControl
              isInvalid={!!getSecondFormErrors('currency')}
              isDisabled={isBusy || isUploading}
              size="small"
              mb="11"
            >
              <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Labels.Currency`]}</FormLabel>
              <SelectHTML
                value={form.currency ? form.currency : ''}
                options={productCurrencyEnums()}
                onChange={(e: any) => handleInputChange('currency', e)}
                placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.PlaceHolders.Currency`]}
                data-cy={`${COMPONENT_ID_KEY}-select-Currency`}
              />
              <FormErrorMessage>{getSecondFormErrors('currency')}</FormErrorMessage>
            </FormControl>
          ) : (
            undefined
          )}

          <FormControl
            isInvalid={!!getSecondFormErrors('taxRatio')}
            isDisabled={isBusy || isUploading}
            size="small"
            mb="11"
          >
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Labels.TaxRatio`]}</FormLabel>
            <Input
              type="decimal"
              min="0"
              value={form.taxRatio ? form.taxRatio : ''}
              placeholder={`${intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.TaxRatio`]}`}
              onBlur={handleBlur('salePrice')}
              onChange={(e: any) => handleInputChange('taxRatio', e)}
              data-cy={`${COMPONENT_ID_KEY}-input-taxRatio`}
            />
            <FormErrorMessage>{getSecondFormErrors('taxRatio')}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={!!getSecondFormErrors('salesUrl')}
            isDisabled={isBusy || isUploading}
            size="small"
            mb="11"
          >
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Labels.SalesUrl`]}</FormLabel>
            <Input
              value={form.salesUrl ? form.salesUrl : ''}
              placeholder={`${intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.SalesUrl`]}`}
              onBlur={handleBlur('salesUrl')}
              onChange={(e: any) => handleInputChange('salesUrl', e)}
              data-cy={`${COMPONENT_ID_KEY}-input-salesUrl`}
            />
            <FormErrorMessage>{getSecondFormErrors('salesUrl')}</FormErrorMessage>
          </FormControl>

          <FormControl
            mt="22"
            mb="11"
            isInline
            isDisabled={isBusy}
      >
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Labels.Sioc`]}</FormLabel>
        <Toggle
          isChecked={form.sioc}
          onChange={handleIsSiocActiveChange()}
        />
      </FormControl>
      {true && (
        <>
        <Flex>
        <FormControl isInvalid={!!getSecondFormErrors('height')} size="small" isDisabled={isBusy || isUploading} width={1 / 2} pr="16" mb="11">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Labels.Height`]}*</FormLabel>
            <Input
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.Height`]}
              value={form.height}
              onChange={(e: any) => handleInputChange('height', e)}
              onBlur={handleBlur('height')}
              type = "number"
            />
             <FormErrorMessage>{getSecondFormErrors('height')}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!getSecondFormErrors('weight')} isDisabled={isBusy || isUploading} size="small" width={1 / 2} mb="11">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Labels.Weight`]}*</FormLabel>
            <Input
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.Weight`]}
              value={form.weight}
              onChange={(e: any) => handleInputChange('weight', e)}
              onBlur={handleBlur('weight')}
              type = "number"
            />
             <FormErrorMessage>{getSecondFormErrors('weight')}</FormErrorMessage>
          </FormControl>
          </Flex>

          <Flex>
          <FormControl isInvalid={!!getSecondFormErrors('width')} size="small" isDisabled={isBusy || isUploading} width={1 / 2} pr="16" mb="11">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Labels.Width`]}*</FormLabel>
            <Input
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.Width`]}
              value={form.width}
              onChange={(e: any) => handleInputChange('width', e)}
              onBlur={handleBlur('width')}
              type = "number"
            />
             <FormErrorMessage>{getSecondFormErrors('width')}</FormErrorMessage>
          </FormControl>
          <FormControl isDisabled={isBusy || isUploading} size="small" width={1 / 2} mb="11">
              <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Labels.WeightUnit`]}*</FormLabel>
              <SelectHTML
                value={form.unitOfWeight}
                options={WeightUnitEnums()}
                onChange={(e: any) => handleInputChange('unitOfWeight', e)}
                placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.PlaceHolders.WeightUnit`]}
                data-cy={`${COMPONENT_ID_KEY}-select-weightUnit`}
              />
            </FormControl>
          </Flex>

          <Flex>
          <FormControl isInvalid={!!getSecondFormErrors('length')} size="small" isDisabled={isBusy || isUploading} width={1 / 2} pr="16" mb="11">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Labels.Length`]}*</FormLabel>
            <Input
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.Length`]}
              value={form.length}
              onChange={(e: any) => handleInputChange('length', e)}
              onBlur={handleBlur('length')}
              type = "number"
            />
             <FormErrorMessage>{getSecondFormErrors('length')}</FormErrorMessage>
          </FormControl>
          </Flex>

          <Flex>
            <FormControl size="small" isDisabled={isBusy || isUploading} width={1 / 2} pr="16" mb="11">
              <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Labels.DimensionUnit`]}*</FormLabel>
              <SelectHTML
                value={form.unitOfDimension}
                options={DimensionUnitEnums()}
                onChange={(e: any) => handleInputChange('unitOfDimension', e)}
                placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.PlaceHolders.DimensionUnit`]}
                data-cy={`${COMPONENT_ID_KEY}-select-dimensionUnit`}
              />
            </FormControl>
          </Flex>
          {form.sioc && (
          <FormErrorMessage independent mb="11">
            {intl.messages[`${COMPONENT_INTL_KEY}.Form.SiocWarning`]}
          </FormErrorMessage>
          )}
          {renderDimesionValuesNotValidErrors() && !form.sioc && (
              <FormErrorMessage independent mb="11">
                {intl.messages[`${COMPONENT_INTL_KEY}.Form.DimesionValuesNotValid`]}
              </FormErrorMessage>
          )}
        </>
      )}
        </form>
      </ModalContent>
    );
  };

  const steps = [
    {
      component: formModalFirstPage(),
      isValid: !isFormInvalid() && (!isBusy || !isUploading),
    },
    {
      component: formModalSecondPage(),
      isValid: !isSecondFormInvalid(),
    },
  ];

  return (
    <MultiStepModal
      modalSize="2xl"
      data-cy={`${COMPONENT_ID_KEY}-modal-productformmodal`}
      header={<ModalTitle>{intl.messages[`${COMPONENT_INTL_KEY}.Title.${isEdit ? 'Edit' : 'Add'}`]}</ModalTitle>}
      footer={<></>}
      text={{
        previous: intl.messages['Form.Action.Prev'],
        next: intl.messages['Form.Action.Next'],
        cancel: intl.messages['Form.Action.Cancel'],
        submit: intl.messages['Form.Action.Save'],
      }}
      isOpen={formModalProps.isOpen}
      steps={steps}
      onClose={handleOnClose}
      onSubmit={() => handleOnSubmit()}
    ></MultiStepModal>
  );
};
