import { ResourceType } from '@models';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { Inventory, InventoryProps } from '@pages/Inventory';
import { StoreState } from '@store';
import { onTabChange } from '@utils';
import { RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

export function mapStateToProps({ resources }: StoreState): Partial<InventoryProps> {
  return {
    tenantDetails: resourceSelectors.getData(resources, ResourceType.TenantGetDetails),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | RouterAction>,
  routeProps: RouteComponentProps<any>
): Partial<InventoryProps> {
  return {
    goTab: tab => {
      onTabChange(tab, routeProps, dispatch);
    },
    getTenantDetails: () => {
      dispatch(resourceActions.resourceRequested(ResourceType.TenantGetDetails));
    },
  };
}

export const InventoryContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(Inventory));
