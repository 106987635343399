// tslint:disable
/**
 * OmniServices Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddAddressToCustomerCommand
 */
export interface AddAddressToCustomerCommand {
    /**
     * 
     * @type {number}
     * @memberof AddAddressToCustomerCommand
     */
    customerNo: number;
    /**
     * 
     * @type {string}
     * @memberof AddAddressToCustomerCommand
     */
    addressFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof AddAddressToCustomerCommand
     */
    addressLastName: string;
    /**
     * 
     * @type {string}
     * @memberof AddAddressToCustomerCommand
     */
    company: string;
    /**
     * 
     * @type {string}
     * @memberof AddAddressToCustomerCommand
     */
    addressOne: string;
    /**
     * 
     * @type {string}
     * @memberof AddAddressToCustomerCommand
     */
    addressTwo: string;
    /**
     * 
     * @type {string}
     * @memberof AddAddressToCustomerCommand
     */
    district: string;
    /**
     * 
     * @type {string}
     * @memberof AddAddressToCustomerCommand
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof AddAddressToCustomerCommand
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof AddAddressToCustomerCommand
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof AddAddressToCustomerCommand
     */
    addressPhone: string;
}
/**
 * 
 * @export
 * @interface AddTenantConfigurationCommand
 */
export interface AddTenantConfigurationCommand {
    /**
     * 
     * @type {string}
     * @memberof AddTenantConfigurationCommand
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof AddTenantConfigurationCommand
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof AddTenantConfigurationCommand
     */
    value: string;
}
/**
 * 
 * @export
 * @interface AddressDetailResponseDTO
 */
export interface AddressDetailResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof AddressDetailResponseDTO
     */
    addressNo: number;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailResponseDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailResponseDTO
     */
    addressFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailResponseDTO
     */
    addressLastName: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailResponseDTO
     */
    company: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailResponseDTO
     */
    addressOne: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailResponseDTO
     */
    addressTwo: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailResponseDTO
     */
    district: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailResponseDTO
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailResponseDTO
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailResponseDTO
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailResponseDTO
     */
    addressPhone: string;
}
/**
 * 
 * @export
 * @interface AddressOfCustomerOutputDTO
 */
export interface AddressOfCustomerOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof AddressOfCustomerOutputDTO
     */
    addressFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof AddressOfCustomerOutputDTO
     */
    addressLastName: string;
    /**
     * 
     * @type {string}
     * @memberof AddressOfCustomerOutputDTO
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressOfCustomerOutputDTO
     */
    addressOne: string;
    /**
     * 
     * @type {string}
     * @memberof AddressOfCustomerOutputDTO
     */
    addressTwo?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressOfCustomerOutputDTO
     */
    district: string;
    /**
     * 
     * @type {string}
     * @memberof AddressOfCustomerOutputDTO
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof AddressOfCustomerOutputDTO
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof AddressOfCustomerOutputDTO
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof AddressOfCustomerOutputDTO
     */
    addressPhone?: string;
    /**
     * 
     * @type {number}
     * @memberof AddressOfCustomerOutputDTO
     */
    addressNo: number;
    /**
     * 
     * @type {string}
     * @memberof AddressOfCustomerOutputDTO
     */
    details?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressOfCustomerOutputDTO
     */
    id: string;
}
/**
 * 
 * @export
 * @interface AddressOfCustomerQueryOutputDTO
 */
export interface AddressOfCustomerQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof AddressOfCustomerQueryOutputDTO
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof AddressOfCustomerQueryOutputDTO
     */
    addressFullName: string;
    /**
     * 
     * @type {Date}
     * @memberof AddressOfCustomerQueryOutputDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof AddressOfCustomerQueryOutputDTO
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof AddressOfCustomerQueryOutputDTO
     */
    company: string;
}
/**
 * 
 * @export
 * @interface AddressOfCustomerQueryOutputDTODynamicQueryOutputDTO
 */
export interface AddressOfCustomerQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<AddressOfCustomerQueryOutputDTO>}
     * @memberof AddressOfCustomerQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<AddressOfCustomerQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof AddressOfCustomerQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface AddressReportResponseDTO
 */
export interface AddressReportResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof AddressReportResponseDTO
     */
    referenceNumber: number;
    /**
     * 
     * @type {string}
     * @memberof AddressReportResponseDTO
     */
    addressFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof AddressReportResponseDTO
     */
    addressLastName: string;
    /**
     * 
     * @type {string}
     * @memberof AddressReportResponseDTO
     */
    company: string;
    /**
     * 
     * @type {string}
     * @memberof AddressReportResponseDTO
     */
    addressOne: string;
    /**
     * 
     * @type {string}
     * @memberof AddressReportResponseDTO
     */
    addressTwo: string;
    /**
     * 
     * @type {string}
     * @memberof AddressReportResponseDTO
     */
    district: string;
    /**
     * 
     * @type {string}
     * @memberof AddressReportResponseDTO
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof AddressReportResponseDTO
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof AddressReportResponseDTO
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof AddressReportResponseDTO
     */
    addressPhone: string;
}
/**
 * 
 * @export
 * @interface BaseAddress
 */
export interface BaseAddress {
    /**
     * 
     * @type {string}
     * @memberof BaseAddress
     */
    addressOne: string;
    /**
     * 
     * @type {string}
     * @memberof BaseAddress
     */
    addressTwo: string;
    /**
     * 
     * @type {string}
     * @memberof BaseAddress
     */
    district: string;
    /**
     * 
     * @type {string}
     * @memberof BaseAddress
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof BaseAddress
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof BaseAddress
     */
    country: string;
}
/**
 * 
 * @export
 * @interface BaseResponse
 */
export interface BaseResponse {
    /**
     * 
     * @type {boolean}
     * @memberof BaseResponse
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseResponse
     */
    errorCode: string;
    /**
     * 
     * @type {string}
     * @memberof BaseResponse
     */
    id?: string;
}
/**
 * 
 * @export
 * @interface BaseTransfer
 */
export interface BaseTransfer {
    /**
     * 
     * @type {string}
     * @memberof BaseTransfer
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof BaseTransfer
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseTransfer
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseTransfer
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof BaseTransfer
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof BaseTransfer
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof BaseTransfer
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @interface BulkCancelLineItemsInput
 */
export interface BulkCancelLineItemsInput {
    /**
     * 
     * @type {string}
     * @memberof BulkCancelLineItemsInput
     */
    referenceNumber: string;
    /**
     * 
     * @type {Array<CancelLineItems>}
     * @memberof BulkCancelLineItemsInput
     */
    lineItems?: Array<CancelLineItems>;
}
/**
 * 
 * @export
 * @interface CancelLineItems
 */
export interface CancelLineItems {
    /**
     * 
     * @type {number}
     * @memberof CancelLineItems
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof CancelLineItems
     */
    sku: string;
}
/**
 * 
 * @export
 * @interface BulkCancelLineItemsInputDTO
 */
export interface BulkCancelLineItemsInputDTO {
    /**
     * 
     * @type {Array<BulkCancelLineItemsInput>}
     * @memberof BulkCancelLineItemsInputDTO
     */
    cancelLineItems?: Array<BulkCancelLineItemsInput>;
}
/**
 * 
 * @export
 * @interface BulkCreatePackageForBlockedSalesOrdersCommand
 */
export interface BulkCreatePackageForBlockedSalesOrdersCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkCreatePackageForBlockedSalesOrdersCommand
     */
    referenceNumbers?: Array<string>;
}
/**
 * 
 * @export
 * @interface BulkForceCancelSalesOrdersCommand
 */
export interface BulkForceCancelSalesOrdersCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkForceCancelSalesOrdersCommand
     */
    referenceNumbers?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof BulkForceCancelSalesOrdersCommand
     */
    isCancelBlockedOnlyActive: boolean;
}
/**
 * 
 * @export
 * @interface BulkResetPackageFulfilmentSyncDetailsCommand
 */
export interface BulkResetPackageFulfilmentSyncDetailsCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkResetPackageFulfilmentSyncDetailsCommand
     */
    referenceNumbers?: Array<string>;
}
/**
 * 
 * @export
 * @interface BulkResetPackageWmsSyncDetailsCommand
 */
export interface BulkResetPackageWmsSyncDetailsCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkResetPackageWmsSyncDetailsCommand
     */
    referenceNumbers?: Array<string>;
}
/**
 * 
 * @export
 * @interface BulkSoftDeleteReceivingOrdersCommand
 */
export interface BulkSoftDeleteReceivingOrdersCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkSoftDeleteReceivingOrdersCommand
     */
    referenceNumbers?: Array<string>;
}
/**
 * 
 * @export
 * @interface BulkSoftDeleteSalesOrdersCommand
 */
export interface BulkSoftDeleteSalesOrdersCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkSoftDeleteSalesOrdersCommand
     */
    referenceNumbers?: Array<string>;
}
/**
 * 
 * @export
 * @interface BulkUndoCancelSalesOrdersCommand
 */
export interface BulkUndoCancelSalesOrdersCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkUndoCancelSalesOrdersCommand
     */
    referenceNumbers?: Array<string>;
}
/**
 * 
 * @export
 * @interface CancelLineItemFromMaestroCommand
 */
export interface CancelLineItemFromMaestroCommand {
    /**
     * 
     * @type {string}
     * @memberof CancelLineItemFromMaestroCommand
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof CancelLineItemFromMaestroCommand
     */
    tenantName: string;
    /**
     * 
     * @type {string}
     * @memberof CancelLineItemFromMaestroCommand
     */
    referenceNumber: string;
    /**
     * 
     * @type {Array<CancelLineItemInput>}
     * @memberof CancelLineItemFromMaestroCommand
     */
    lineItems?: Array<CancelLineItemInput>;
}
/**
 * 
 * @export
 * @interface CancelLineItemInput
 */
export interface CancelLineItemInput {
    /**
     * 
     * @type {number}
     * @memberof CancelLineItemInput
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof CancelLineItemInput
     */
    sku: string;
}
/**
 * 
 * @export
 * @interface CancelLineItemsInputDTO
 */
export interface CancelLineItemsInputDTO {
    /**
     * 
     * @type {string}
     * @memberof CancelLineItemsInputDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {number}
     * @memberof CancelLineItemsInputDTO
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof CancelLineItemsInputDTO
     */
    sku: string;
}
/**
 * 
 * @export
 * @interface CancelLineItemsRequestDTO
 */
export interface CancelLineItemsRequestDTO {
    /**
     * 
     * @type {number}
     * @memberof CancelLineItemsRequestDTO
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof CancelLineItemsRequestDTO
     */
    sku: string;
}
/**
 * 
 * @export
 * @interface CancelPackageCommand
 */
export interface CancelPackageCommand {
    /**
     * 
     * @type {string}
     * @memberof CancelPackageCommand
     */
    referenceNumber: string;
}
/**
 * 
 * @export
 * @interface CancelSalesOrderCommand
 */
export interface CancelSalesOrderCommand {
    /**
     * 
     * @type {string}
     * @memberof CancelSalesOrderCommand
     */
    referenceNumber: string;
}
/**
 * 
 * @export
 * @interface CargoCarriersApiDTO
 */
export interface CargoCarriersApiDTO {
    /**
     * 
     * @type {string}
     * @memberof CargoCarriersApiDTO
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof CargoCarriersApiDTO
     */
    value: string;
}
/**
 * 
 * @export
 * @interface CargoCarriersOpenApiDTO
 */
export interface CargoCarriersOpenApiDTO {
    /**
     * 
     * @type {string}
     * @memberof CargoCarriersOpenApiDTO
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof CargoCarriersOpenApiDTO
     */
    value: string;
}
/**
 * 
 * @export
 * @interface CargoTransferDTO
 */
export interface CargoTransferDTO {
    /**
     * 
     * @type {Date}
     * @memberof CargoTransferDTO
     */
    shipmentDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CargoTransferDTO
     */
    cargoCompanyName?: string;
    /**
     * 
     * @type {string}
     * @memberof CargoTransferDTO
     */
    trackingNumber?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CargoType {
    RegularDelivery = 'RegularDelivery',
    SameDayDelivery = 'SameDayDelivery',
    ScheduledDelivery = 'ScheduledDelivery',
    ManualDelivery = 'ManualDelivery'
}

/**
 * 
 * @export
 * @interface CarriageTransferDTO
 */
export interface CarriageTransferDTO {
    /**
     * 
     * @type {Date}
     * @memberof CarriageTransferDTO
     */
    shipmentDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CarriageTransferDTO
     */
    carriageCompanyName?: string;
    /**
     * 
     * @type {string}
     * @memberof CarriageTransferDTO
     */
    waybillNumber?: string;
}
/**
 * 
 * @export
 * @interface ChangeUserTypeCommand
 */
export interface ChangeUserTypeCommand {
    /**
     * 
     * @type {string}
     * @memberof ChangeUserTypeCommand
     */
    userId: string;
    /**
     * 
     * @type {UserType}
     * @memberof ChangeUserTypeCommand
     */
    type: UserType;
}
/**
 * 
 * @export
 * @interface CommonIntegrationOutputDTO
 */
export interface CommonIntegrationOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof CommonIntegrationOutputDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CommonIntegrationOutputDTO
     */
    name: string;
    /**
     * 
     * @type {IntegrationType}
     * @memberof CommonIntegrationOutputDTO
     */
    type: IntegrationType;
    /**
     * 
     * @type {OpenApiIdentifier}
     * @memberof CommonIntegrationOutputDTO
     */
    openApiIdentifier: OpenApiIdentifier;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    taxNo: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    taxAdministration: string;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    taxRatio: number;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    termDay: number;
}
/**
 * 
 * @export
 * @interface CompanyDTO
 */
export interface CompanyDTO {
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    taxNo: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    taxAdministration: string;
}
/**
 * 
 * @export
 * @interface CorrectInvalidSalesOrderCargo
 */
export interface CorrectInvalidSalesOrderCargo {
    /**
     * 
     * @type {string}
     * @memberof CorrectInvalidSalesOrderCargo
     */
    cargoCarrier: string;
    /**
     * 
     * @type {string}
     * @memberof CorrectInvalidSalesOrderCargo
     */
    cargoDocumentUrl: string;
    /**
     * 
     * @type {string}
     * @memberof CorrectInvalidSalesOrderCargo
     */
    cargoCode: string;
    /**
     * 
     * @type {CargoType}
     * @memberof CorrectInvalidSalesOrderCargo
     */
    cargoType: CargoType;
}
/**
 * 
 * @export
 * @interface CorrectInvalidSalesOrderCommand
 */
export interface CorrectInvalidSalesOrderCommand {
    /**
     * 
     * @type {string}
     * @memberof CorrectInvalidSalesOrderCommand
     */
    salesChannel: string;
    /**
     * 
     * @type {string}
     * @memberof CorrectInvalidSalesOrderCommand
     */
    orderId: string;
    /**
     * 
     * @type {Date}
     * @memberof CorrectInvalidSalesOrderCommand
     */
    orderCreatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof CorrectInvalidSalesOrderCommand
     */
    vasDocumentUrl: string;
    /**
     * 
     * @type {CorrectInvalidSalesOrderCustomer}
     * @memberof CorrectInvalidSalesOrderCommand
     */
    customer?: CorrectInvalidSalesOrderCustomer;
    /**
     * 
     * @type {CorrectInvalidSalesOrderShippingAddress}
     * @memberof CorrectInvalidSalesOrderCommand
     */
    shippingAddress?: CorrectInvalidSalesOrderShippingAddress;
    /**
     * 
     * @type {CorrectInvalidSalesOrderPayment}
     * @memberof CorrectInvalidSalesOrderCommand
     */
    payment?: CorrectInvalidSalesOrderPayment;
    /**
     * 
     * @type {CorrectInvalidSalesOrderCargo}
     * @memberof CorrectInvalidSalesOrderCommand
     */
    cargo?: CorrectInvalidSalesOrderCargo;
}
/**
 * 
 * @export
 * @interface CorrectInvalidSalesOrderCustomer
 */
export interface CorrectInvalidSalesOrderCustomer {
    /**
     * 
     * @type {string}
     * @memberof CorrectInvalidSalesOrderCustomer
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CorrectInvalidSalesOrderCustomer
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CorrectInvalidSalesOrderCustomer
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CorrectInvalidSalesOrderCustomer
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface CorrectInvalidSalesOrderPayment
 */
export interface CorrectInvalidSalesOrderPayment {
    /**
     * 
     * @type {number}
     * @memberof CorrectInvalidSalesOrderPayment
     */
    totalPaymentAmount: number;
    /**
     * 
     * @type {string}
     * @memberof CorrectInvalidSalesOrderPayment
     */
    paymentGatewayNames: string;
    /**
     * 
     * @type {boolean}
     * @memberof CorrectInvalidSalesOrderPayment
     */
    isPaid?: boolean;
    /**
     * 
     * @type {Currency}
     * @memberof CorrectInvalidSalesOrderPayment
     */
    currency: Currency;
}
/**
 * 
 * @export
 * @interface CorrectInvalidSalesOrderShippingAddress
 */
export interface CorrectInvalidSalesOrderShippingAddress {
    /**
     * 
     * @type {string}
     * @memberof CorrectInvalidSalesOrderShippingAddress
     */
    addressFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof CorrectInvalidSalesOrderShippingAddress
     */
    addressLastName: string;
    /**
     * 
     * @type {string}
     * @memberof CorrectInvalidSalesOrderShippingAddress
     */
    addressOne: string;
    /**
     * 
     * @type {string}
     * @memberof CorrectInvalidSalesOrderShippingAddress
     */
    addressTwo: string;
    /**
     * 
     * @type {string}
     * @memberof CorrectInvalidSalesOrderShippingAddress
     */
    district: string;
    /**
     * 
     * @type {string}
     * @memberof CorrectInvalidSalesOrderShippingAddress
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof CorrectInvalidSalesOrderShippingAddress
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof CorrectInvalidSalesOrderShippingAddress
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof CorrectInvalidSalesOrderShippingAddress
     */
    addressPhone: string;
}
/**
 * 
 * @export
 * @interface CountOutputDTO
 */
export interface CountOutputDTO {
    /**
     * 
     * @type {number}
     * @memberof CountOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface CreateAddressRequestDTO
 */
export interface CreateAddressRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateAddressRequestDTO
     */
    addressFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressRequestDTO
     */
    addressLastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressRequestDTO
     */
    company: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressRequestDTO
     */
    addressOne: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressRequestDTO
     */
    addressTwo: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressRequestDTO
     */
    district: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressRequestDTO
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressRequestDTO
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressRequestDTO
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressRequestDTO
     */
    addressPhone: string;
}
/**
 * 
 * @export
 * @interface CreateCustomerCommand
 */
export interface CreateCustomerCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerCommand
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerCommand
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerCommand
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerCommand
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerCommand
     */
    addressFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerCommand
     */
    addressLastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerCommand
     */
    company: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerCommand
     */
    addressOne: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerCommand
     */
    addressTwo: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerCommand
     */
    district: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerCommand
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerCommand
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerCommand
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerCommand
     */
    addressPhone: string;
}
/**
 * 
 * @export
 * @interface CreateCustomerRequestDTO
 */
export interface CreateCustomerRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequestDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequestDTO
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequestDTO
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequestDTO
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface CreateFtpIntegrationCommand
 */
export interface CreateFtpIntegrationCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateFtpIntegrationCommand
     */
    tenantName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFtpIntegrationCommand
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFtpIntegrationCommand
     */
    ftpAddress: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFtpIntegrationCommand
     */
    ftpUsername: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFtpIntegrationCommand
     */
    ftpPassword: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFtpIntegrationCommand
     */
    filePrefix: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFtpIntegrationCommand
     */
    fileFormat: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFtpIntegrationCommand
     */
    searchDestination: string;
    /**
     * 
     * @type {number}
     * @memberof CreateFtpIntegrationCommand
     */
    ftpPort: number;
    /**
     * 
     * @type {string}
     * @memberof CreateFtpIntegrationCommand
     */
    skuKey: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFtpIntegrationCommand
     */
    titleKey: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFtpIntegrationCommand
     */
    salePriceKey: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFtpIntegrationCommand
     */
    priceKey: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFtpIntegrationCommand
     */
    taxRatioKey: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFtpIntegrationCommand
     */
    barcodeKey: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFtpIntegrationCommand
     */
    subBarcodesKey: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFtpIntegrationCommand
     */
    categoryKey: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFtpIntegrationCommand
     */
    imageUrlKey: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFtpIntegrationCommand
     */
    createdAtKey: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFtpIntegrationCommand
     */
    updatedAtKey: string;
}
/**
 * 
 * @export
 * @interface CreateIntegrationCommand
 */
export interface CreateIntegrationCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationCommand
     */
    integrationName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationCommand
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationCommand
     */
    apiUserName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationCommand
     */
    apiPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationCommand
     */
    shopLink?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationCommand
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationCommand
     */
    clientSecret?: string;
    /**
     * 
     * @type {IntegrationType}
     * @memberof CreateIntegrationCommand
     */
    type: IntegrationType;
    /**
     * 
     * @type {boolean}
     * @memberof CreateIntegrationCommand
     */
    isCoDActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateIntegrationCommand
     */
    isSameAsBarcode?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateIntegrationCommand
     */
    skuWithBarcode?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateIntegrationCommand
     */
    isSubProductActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateIntegrationCommand
     */
    isTestEnvironment?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationCommand
     */
    partialFulfillmentServiceName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateIntegrationCommand
     */
    isPartialFulfillmentActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationOutputDTO
     */
    isSameDayDeliveryActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IntegrationOutputDTO
     */
    sameDayDeliveryKey?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationCommand
     */
    cashKey?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationCommand
     */
    creditCardKey?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateIntegrationCommand
     */
    locationId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationCommand
     */
    referenceNumberPrefix?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationCommand
     */
    tenantSecret?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateIntegrationCommand
     */
    isProductSyncActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateIntegrationCommand
     */
    isMarketplaceSyncActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateIntegrationCommand
     */
    isWebsiteSyncActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateIntegrationCommand
     */
    isSendOrdersByOplogActive?: boolean;
    /**
     * 
     * @type {OpenApiIdentifier}
     * @memberof IntegrationOutputDTO
     */
    openApiIdentifier: OpenApiIdentifier;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationCommand
     */
    productBarcodeKeyField?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateIntegrationCommand
     */
    isActiveOrderNumber?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationCommand
     */
    storeFrontCode?: string;
}
/**
 * 
 * @export
 * @interface CreateOmnitroReturnCommand
 */
export interface CreateOmnitroReturnCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateOmnitroReturnCommand
     */
    salesOrderId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOmnitroReturnCommand
     */
    courierRequested: boolean;
}
/**
 * 
 * @export
 * @interface CreatePackageCommand
 */
export interface CreatePackageCommand {
    /**
     * 
     * @type {string}
     * @memberof CreatePackageCommand
     */
    salesOrderReferenceNumber: string;
    /**
     * 
     * @type {Array<CreatePackageLineItem>}
     * @memberof CreatePackageCommand
     */
    lineItems?: Array<CreatePackageLineItem>;
    /**
     * 
     * @type {Date}
     * @memberof CreatePackageCommand
     */
    expectedShipmentDate?: Date;
}
/**
 * 
 * @export
 * @interface CreatePackageLineItem
 */
export interface CreatePackageLineItem {
    /**
     * 
     * @type {string}
     * @memberof CreatePackageLineItem
     */
    sku: string;
    /**
     * 
     * @type {number}
     * @memberof CreatePackageLineItem
     */
    amount: number;
}
/**
 * 
 * @export
 * @interface CreateProductCommand
 */
export interface CreateProductCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateProductCommand
     */
    sku: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductCommand
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductCommand
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProductCommand
     */
    barcodes?: Array<string>;
    /**
     * 
     * @type {ProductType}
     * @memberof CreateProductCommand
     */
    type: ProductType;
    /**
     * 
     * @type {string}
     * @memberof CreateProductCommand
     */
    category: string;
    /**
     * 
     * @type {number}
     * @memberof CreateProductCommand
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductCommand
     */
    salePrice?: number;
    /**
     * 
     * @type {Currency}
     * @memberof CreateProductCommand
     */
    currency?: Currency;
    /**
     * 
     * @type {number}
     * @memberof CreateProductCommand
     */
    taxRatio?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateProductCommand
     */
    salesUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProductCommand
     */
    sioc?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateProductCommand
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductCommand
     */
    weight?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductCommand
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductCommand
     */
    length?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateProductCommand
     */
    unitOfDimension?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductCommand
     */
    unitOfWeight?: string;
    /**
     * 
     * @type {PCSDimensionsDTO}
     * @memberof CreateProductCommand
     */
    dimensions?: PCSDimensionsDTO;
}
/**
 * 
 * @export
 * @interface DimensionsDTO
 */
export interface DimensionsDTO {
    /**
     * 
     * @type {number}
     * @memberof DimensionsDTO
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof DimensionsDTO
     */
    weight?: number;
    /**
     * 
     * @type {number}
     * @memberof DimensionsDTO
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof DimensionsDTO
     */
    length?: number;
    /**
     * 
     * @type {DimensionUnit}
     * @memberof DimensionsDTO
     */
    dimensionUnit?: DimensionUnit;
    /**
     * 
     * @type {WeightUnit}
     * @memberof DimensionsDTO
     */
    weightUnit?: WeightUnit;
}
/**
 * 
 * @export
 * @interface PCSDimensionsDTO
 */
export interface PCSDimensionsDTO {
    /**
     * 
     * @type {number}
     * @memberof DimensionsDTO
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof DimensionsDTO
     */
    weight?: number;
    /**
     * 
     * @type {number}
     * @memberof DimensionsDTO
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof DimensionsDTO
     */
    length?: number;
    /**
     * 
     * @type {string}
     * @memberof DimensionsDTO
     */
    unitOfDimension?: string;
    /**
     * 
     * @type {string}
     * @memberof DimensionsDTO
     */
    unitOfWeight?: string;
}
/**
 * 
 * @export
 * @interface CreateProductInputDTO
 */
export interface CreateProductInputDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateProductInputDTO
     */
    sku: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductInputDTO
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductInputDTO
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProductInputDTO
     */
    barcodes?: Array<string>;
    /**
     * 
     * @type {ProductType}
     * @memberof CreateProductInputDTO
     */
    type: ProductType;
    /**
     * 
     * @type {string}
     * @memberof CreateProductInputDTO
     */
    category: string;
    /**
     * 
     * @type {number}
     * @memberof CreateProductInputDTO
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductInputDTO
     */
    salePrice?: number;
    /**
     * 
     * @type {Currency}
     * @memberof CreateProductInputDTO
     */
    currency?: Currency;
    /**
     * 
     * @type {number}
     * @memberof CreateProductInputDTO
     */
    taxRatio?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateProductInputDTO
     */
    salesUrl: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductInputDTO
     */
    integrationInventoryItemId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductInputDTO
     */
    uniqueId: string;
}
/**
 * 
 * @export
 * @interface CreateProductRequestDTO
 */
export interface CreateProductRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequestDTO
     */
    sku: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequestDTO
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequestDTO
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProductRequestDTO
     */
    barcodes?: Array<string>;
    /**
     * 
     * @type {ProductType}
     * @memberof CreateProductRequestDTO
     */
    type: ProductType;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequestDTO
     */
    category: string;
    /**
     * 
     * @type {number}
     * @memberof CreateProductRequestDTO
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductRequestDTO
     */
    salePrice?: number;
    /**
     * 
     * @type {Currency}
     * @memberof CreateProductRequestDTO
     */
    currency?: Currency;
    /**
     * 
     * @type {number}
     * @memberof CreateProductRequestDTO
     */
    taxRatio?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequestDTO
     */
    salesUrl: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequestDTO
     */
    integrationId?: string;
}
/**
 * 
 * @export
 * @interface CreateProductsCommand
 */
export interface CreateProductsCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateProductsCommand
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductsCommand
     */
    integrationId: string;
    /**
     * 
     * @type {Array<CreateProductInputDTO>}
     * @memberof CreateProductsCommand
     */
    products?: Array<CreateProductInputDTO>;
}
/**
 * 
 * @export
 * @interface CreateReceivingOrderCommand
 */
export interface CreateReceivingOrderCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateReceivingOrderCommand
     */
    referenceNumber?: string;
    /**
     * 
     * @type {Array<CreateReceivingOrderLineItemInputDTO>}
     * @memberof CreateReceivingOrderCommand
     */
    lineItems: Array<CreateReceivingOrderLineItemInputDTO>;
    /**
     * 
     * @type {ReceivingOrderTransferType}
     * @memberof CreateReceivingOrderCommand
     */
    transferType: ReceivingOrderTransferType;
    /**
     * 
     * @type {CargoTransferDTO}
     * @memberof CreateReceivingOrderCommand
     */
    cargoTransfer?: CargoTransferDTO;
    /**
     * 
     * @type {PickupTransferDTO}
     * @memberof CreateReceivingOrderCommand
     */
    pickupTransfer?: PickupTransferDTO;
    /**
     * 
     * @type {CarriageTransferDTO}
     * @memberof CreateReceivingOrderCommand
     */
    carriageTransfer?: CarriageTransferDTO;
}
/**
 * 
 * @export
 * @interface CreateReceivingOrderLineItemInputDTO
 */
export interface CreateReceivingOrderLineItemInputDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateReceivingOrderLineItemInputDTO
     */
    sku: string;
    /**
     * 
     * @type {number}
     * @memberof CreateReceivingOrderLineItemInputDTO
     */
    expectedQuantity: number;
}
/**
 * 
 * @export
 * @interface CreateReceivingOrderRequestDTO
 */
export interface CreateReceivingOrderRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateReceivingOrderRequestDTO
     */
    integrationId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateReceivingOrderRequestDTO
     */
    referenceNumber?: string;
    /**
     * 
     * @type {Array<CreateReceivingOrderLineItemInputDTO>}
     * @memberof CreateReceivingOrderRequestDTO
     */
    lineItems: Array<CreateReceivingOrderLineItemInputDTO>;
    /**
     * 
     * @type {ReceivingOrderTransferType}
     * @memberof CreateReceivingOrderRequestDTO
     */
    transferType: ReceivingOrderTransferType;
    /**
     * 
     * @type {CargoTransferDTO}
     * @memberof CreateReceivingOrderRequestDTO
     */
    cargoTransfer?: CargoTransferDTO;
    /**
     * 
     * @type {PickupTransferDTO}
     * @memberof CreateReceivingOrderRequestDTO
     */
    pickupTransfer?: PickupTransferDTO;
    /**
     * 
     * @type {CarriageTransferDTO}
     * @memberof CreateReceivingOrderRequestDTO
     */
    carriageTransfer?: CarriageTransferDTO;
}
/**
 * 
 * @export
 * @interface CreateReturnRequestDTO
 */
export interface CreateReturnRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateReturnRequestDTO
     */
    salesOrderId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateReturnRequestDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CreateReturnRequestDTO
     */
    salesOrderReferenceNumber: string;
}
/**
 * 
 * @export
 * @interface CreateSalesOrderCargoRequestDTO
 */
export interface CreateSalesOrderCargoRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderCargoRequestDTO
     */
    cargoCarrier: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderCargoRequestDTO
     */
    cargoDocumentUrl: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderCargoRequestDTO
     */
    cargoCode: string;
    /**
     * 
     * @type {CargoType}
     * @memberof CreateSalesOrderCargoRequestDTO
     */
    cargoType: CargoType;
    /**
     * 
     * @type {Date}
     * @memberof CreateSalesOrderCargoRequestDTO
     */
    expectedShipmentDate?: Date;
}
/**
 * 
 * @export
 * @interface CreateSalesOrderDocumentVASRequestDTO
 */
export interface CreateSalesOrderDocumentVASRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderDocumentVASRequestDTO
     */
    documentUrl: string;
}
/**
 * 
 * @export
 * @interface CreateSalesOrderGiftVASRequestDTO
 */
export interface CreateSalesOrderGiftVASRequestDTO {
    /**
     * 
     * @type {boolean}
     * @memberof CreateSalesOrderGiftVASRequestDTO
     */
    giftWrap: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderGiftVASRequestDTO
     */
    giftNotes: string;
}
/**
 * 
 * @export
 * @interface CreateSalesOrderInputDTO
 */
export interface CreateSalesOrderInputDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderInputDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateSalesOrderInputDTO
     */
    orderCreatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderInputDTO
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderInputDTO
     */
    shippingAddressId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderInputDTO
     */
    billingAddressId: string;
    /**
     * 
     * @type {number}
     * @memberof CreateSalesOrderInputDTO
     */
    totalPaymentAmount?: number;
    /**
     * 
     * @type {Currency}
     * @memberof CreateSalesOrderInputDTO
     */
    currency?: Currency;
    /**
     * 
     * @type {CargoType}
     * @memberof CreateSalesOrderInputDTO
     */
    cargoType?: CargoType;
    /**
     * 
     * @type {PaymentOption}
     * @memberof CreateSalesOrderInputDTO
     */
    paymentOption?: PaymentOption;
    /**
     * 
     * @type {Array<CreateSalesOrderLineItemInputDTO>}
     * @memberof CreateSalesOrderInputDTO
     */
    salesOrderLineItems?: Array<CreateSalesOrderLineItemInputDTO>;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderInputDTO
     */
    cargoCarrier?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderInputDTO
     */
    cargoDocumentUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderInputDTO
     */
    vasDocumentUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderInputDTO
     */
    cargoCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderInputDTO
     */
    salesChannel?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateSalesOrderInputDTO
     */
    expectedShipmentDate?: Date;
}
/**
 * 
 * @export
 * @interface CreateSalesOrderLineItemInputDTO
 */
export interface CreateSalesOrderLineItemInputDTO {
    /**
     * 
     * @type {number}
     * @memberof CreateSalesOrderLineItemInputDTO
     */
    amountInOrder: number;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderLineItemInputDTO
     */
    sku: string;
}
/**
 * 
 * @export
 * @interface CreateSalesOrderLineItemRequestDTO
 */
export interface CreateSalesOrderLineItemRequestDTO {
    /**
     * 
     * @type {number}
     * @memberof CreateSalesOrderLineItemRequestDTO
     */
    amountInOrder: number;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderLineItemRequestDTO
     */
    sku: string;
    /**
     * 
     * @type {number}
     * @memberof CreateSalesOrderLineItemRequestDTO
     */
    productSalePrice?: number;
    /**
     * 
     * @type {Currency}
     * @memberof CreateSalesOrderLineItemRequestDTO
     */
    currency?: Currency;
}
/**
 * 
 * @export
 * @interface CreateSalesOrderPaymentRequestDTO
 */
export interface CreateSalesOrderPaymentRequestDTO {
    /**
     * 
     * @type {PaymentOption}
     * @memberof CreateSalesOrderPaymentRequestDTO
     */
    paymentOption: PaymentOption;
    /**
     * 
     * @type {number}
     * @memberof CreateSalesOrderPaymentRequestDTO
     */
    totalPaymentAmount?: number;
    /**
     * 
     * @type {Currency}
     * @memberof CreateSalesOrderPaymentRequestDTO
     */
    currency?: Currency;
}
/**
 * 
 * @export
 * @interface CreateSalesOrderRequestDTO
 */
export interface CreateSalesOrderRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderRequestDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderRequestDTO
     */
    salesChannel: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateSalesOrderRequestDTO
     */
    orderCreatedAt?: Date;
    /**
     * 
     * @type {CreateCustomerRequestDTO}
     * @memberof CreateSalesOrderRequestDTO
     */
    customer: CreateCustomerRequestDTO;
    /**
     * 
     * @type {CreateAddressRequestDTO}
     * @memberof CreateSalesOrderRequestDTO
     */
    shippingAddress: CreateAddressRequestDTO;
    /**
     * 
     * @type {CreateAddressRequestDTO}
     * @memberof CreateSalesOrderRequestDTO
     */
    billingAddress: CreateAddressRequestDTO;
    /**
     * 
     * @type {Array<CreateSalesOrderLineItemRequestDTO>}
     * @memberof CreateSalesOrderRequestDTO
     */
    lineItems: Array<CreateSalesOrderLineItemRequestDTO>;
    /**
     * 
     * @type {CreateSalesOrderPaymentRequestDTO}
     * @memberof CreateSalesOrderRequestDTO
     */
    payment?: CreateSalesOrderPaymentRequestDTO;
    /**
     * 
     * @type {CreateSalesOrderCargoRequestDTO}
     * @memberof CreateSalesOrderRequestDTO
     */
    cargo?: CreateSalesOrderCargoRequestDTO;
    /**
     * 
     * @type {SyncSalesOrderVASRequestDTO}
     * @memberof CreateSalesOrderRequestDTO
     */
    vas?: SyncSalesOrderVASRequestDTO;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderRequestDTO
     */
    integrationId?: string;
}
/**
 * 
 * @export
 * @interface CreateTenantCommand
 */
export interface CreateTenantCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateTenantCommand
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTenantCommand
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTenantCommand
     */
    warehouseCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTenantCommand
     */
    customerCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTenantCommand
     */
    wmsTenant: boolean;
    /**
     * 
     * @type {WarehouseType}
     * @memberof CreateTenantCommand
     */
    warehouseType: WarehouseType;
    /**
     * 
     * @type {string}
     * @memberof CreateTenantCommand
     */
    warehouseLocation: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTenantCommand
     */
    contractNo?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateTenantCommand
     */
    contractStartDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateTenantCommand
     */
    contractEndDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof CreateTenantCommand
     */
    taxRatio: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTenantCommand
     */
    termDay: number;
}
/**
 * 
 * @export
 * @interface CreateUserCommand
 */
export interface CreateUserCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateUserCommand
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserCommand
     */
    email: string;
}
/**
 * 
 * @export
 * @interface CreateWebHookCommand
 */
export interface CreateWebHookCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateWebHookCommand
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWebHookCommand
     */
    basicAuthUser: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWebHookCommand
     */
    basicAuthPass: string;
    /**
     * 
     * @type {WebHookType}
     * @memberof CreateWebHookCommand
     */
    hookType: WebHookType;
    /**
     * 
     * @type {string}
     * @memberof CreateWebHookCommand
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWebHookCommand
     */
    bodyTemplate: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWebHookCommand
     */
    headerTemplate: string;
}
/**
 * 
 * @export
 * @interface CreatedCustomerOutputDTO
 */
export interface CreatedCustomerOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof CreatedCustomerOutputDTO
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreatedCustomerOutputDTO
     */
    lastName: string;
    /**
     * 
     * @type {number}
     * @memberof CreatedCustomerOutputDTO
     */
    customerNo: number;
    /**
     * 
     * @type {string}
     * @memberof CreatedCustomerOutputDTO
     */
    id: string;
}
/**
 * 
 * @export
 * @interface CreatedIntegrationOutputDTO
 */
export interface CreatedIntegrationOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof CreatedIntegrationOutputDTO
     */
    id: string;
    /**
     * 
     * @type {object}
     * @memberof CreatedIntegrationOutputDTO
     */
    integrationCredentials?: object;
}
/**
 * 
 * @export
 * @interface CreatedOpenApiIntegrationCredentialsOutputDTO
 */
export interface CreatedOpenApiIntegrationCredentialsOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof CreatedOpenApiIntegrationCredentialsOutputDTO
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof CreatedOpenApiIntegrationCredentialsOutputDTO
     */
    clientSecret: string;
    /**
     * 
     * @type {string}
     * @memberof CreatedOpenApiIntegrationCredentialsOutputDTO
     */
    tenantId: string;
}
/**
 * 
 * @export
 * @interface CreatedOpenApiIntegrationOutputDTO
 */
export interface CreatedOpenApiIntegrationOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof CreatedOpenApiIntegrationOutputDTO
     */
    id: string;
    /**
     * 
     * @type {CreatedOpenApiIntegrationCredentialsOutputDTO}
     * @memberof CreatedOpenApiIntegrationOutputDTO
     */
    integrationCredentials?: CreatedOpenApiIntegrationCredentialsOutputDTO;
}
/**
 * 
 * @export
 * @interface CreatedReceivingOrderOutputDTO
 */
export interface CreatedReceivingOrderOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof CreatedReceivingOrderOutputDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {Array<CreateReceivingOrderLineItemInputDTO>}
     * @memberof CreatedReceivingOrderOutputDTO
     */
    lineItems?: Array<CreateReceivingOrderLineItemInputDTO>;
}
/**
 * 
 * @export
 * @interface CreatedReturnOutputDTO
 */
export interface CreatedReturnOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof CreatedReturnOutputDTO
     */
    referenceNumber: string;
}
/**
 * 
 * @export
 * @interface CreatedSalesOrderOutputDTO
 */
export interface CreatedSalesOrderOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof CreatedSalesOrderOutputDTO
     */
    referenceNumber: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DimensionUnit {
    Cm = 'Cm',
    In = 'In',
    Ft = 'Ft'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum WeightUnit {
    Kg = 'Kg',
    Lb = 'Lb',
    G = 'G',
    Oz = 'Oz'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Currency {
    TRY = 'TRY',
    USD = 'USD',
    EUR = 'EUR',
    GBP = 'GBP',
    RUB = 'RUB',
    UAH = 'UAH',
    UNSUPPORTED = 'UNSUPPORTED',
    AFN = 'AFN',
    ALL = 'ALL',
    AOA = 'AOA',
    ARS = 'ARS',
    AMD = 'AMD',
    AWG = 'AWG',
    AZN = 'AZN',
    BSD = 'BSD',
    BHD = 'BHD',
    BDT = 'BDT',
    BBD = 'BBD',
    BYN = 'BYN',
    BZD = 'BZD',
    BMD = 'BMD',
    BTN = 'BTN',
    BOB = 'BOB',
    BAM = 'BAM',
    BWP = 'BWP',
    BRL = 'BRL',
    BGN = 'BGN',
    BIF = 'BIF',
    KHR = 'KHR',
    CAD = 'CAD',
    CVE = 'CVE',
    KYD = 'KYD',
    CLP = 'CLP',
    CNY = 'CNY',
    COP = 'COP',
    KMF = 'KMF',
    CDF = 'CDF',
    CRC = 'CRC',
    HRK = 'HRK',
    CUP = 'CUP',
    CZK = 'CZK',
    DJF = 'DJF',
    DOP = 'DOP',
    EGP = 'EGP',
    ERN = 'ERN',
    SZL = 'SZL',
    ETB = 'ETB',
    FKP = 'FKP',
    FJD = 'FJD',
    XAF = 'XAF',
    GMD = 'GMD',
    GEL = 'GEL',
    GHS = 'GHS',
    GIP = 'GIP',
    DKK = 'DKK',
    GTQ = 'GTQ',
    GNF = 'GNF',
    GYD = 'GYD',
    HTG = 'HTG',
    HNL = 'HNL',
    HUF = 'HUF',
    ISK = 'ISK',
    INR = 'INR',
    IDR = 'IDR',
    IRR = 'IRR',
    IQD = 'IQD',
    JMD = 'JMD',
    JPY = 'JPY',
    KZT = 'KZT',
    KES = 'KES',
    KPW = 'KPW',
    KRW = 'KRW',
    KWD = 'KWD',
    KGS = 'KGS',
    LAK = 'LAK',
    LBP = 'LBP',
    LSL = 'LSL',
    LRD = 'LRD',
    LYD = 'LYD',
    MOP = 'MOP',
    HKD = 'HKD',
    MGA = 'MGA',
    MWK = 'MWK',
    MYR = 'MYR',
    MVR = 'MVR',
    MUR = 'MUR',
    MXN = 'MXN',
    MDL = 'MDL',
    MNT = 'MNT',
    MZN = 'MZN',
    MMK = 'MMK',
    NAD = 'NAD',
    NPR = 'NPR',
    NIO = 'NIO',
    NGN = 'NGN',
    MKD = 'MKD',
    NOK = 'NOK',
    OMR = 'OMR',
    PKR = 'PKR',
    ILS = 'ILS',
    JOD = 'JOD',
    PAB = 'PAB',
    PGK = 'PGK',
    PYG = 'PYG',
    PEN = 'PEN',
    PHP = 'PHP',
    NZD = 'NZD',
    PLN = 'PLN',
    QAR = 'QAR',
    RON = 'RON',
    RWF = 'RWF',
    DZD = 'DZD',
    MRU = 'MRU',
    MAD = 'MAD',
    SHP = 'SHP',
    XCD = 'XCD',
    WST = 'WST',
    STN = 'STN',
    SAR = 'SAR',
    RSD = 'RSD',
    SCR = 'SCR',
    SLL = 'SLL',
    SGD = 'SGD',
    BND = 'BND',
    ANG = 'ANG',
    SBD = 'SBD',
    SOS = 'SOS',
    ZAR = 'ZAR',
    SSP = 'SSP',
    LKR = 'LKR',
    SDG = 'SDG',
    SRD = 'SRD',
    SEK = 'SEK',
    CHF = 'CHF',
    SYP = 'SYP',
    TWD = 'TWD',
    TJS = 'TJS',
    TZS = 'TZS',
    THB = 'THB',
    XOF = 'XOF',
    TOP = 'TOP',
    TTD = 'TTD',
    TND = 'TND',
    TMT = 'TMT',
    AUD = 'AUD',
    UGX = 'UGX',
    AED = 'AED',
    UYU = 'UYU',
    UZS = 'UZS',
    VUV = 'VUV',
    VES = 'VES',
    VED = 'VED',
    VND = 'VND',
    XPF = 'XPF',
    YER = 'YER',
    ZMW = 'ZMW'
}

/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {Array<CustomerAddress>}
     * @memberof Customer
     */
    addresses?: Array<CustomerAddress>;
    /**
     * 
     * @type {Array<SalesOrder>}
     * @memberof Customer
     */
    salesOrders?: Array<SalesOrder>;
    /**
     * 
     * @type {Tenant}
     * @memberof Customer
     */
    tenant?: Tenant;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    fullName: string;
    /**
     * 
     * @type {Date}
     * @memberof Customer
     */
    fingerprintSyncTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    referenceNumber: number;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof Customer
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Customer
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Customer
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @interface CustomerAddress
 */
export interface CustomerAddress {
    /**
     * 
     * @type {Customer}
     * @memberof CustomerAddress
     */
    customer?: Customer;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    fingerprint: string;
    /**
     * 
     * @type {Date}
     * @memberof CustomerAddress
     */
    fingerprintSyncTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    lastName: string;
    /**
     * 
     * @type {BaseAddress}
     * @memberof CustomerAddress
     */
    baseAddress?: BaseAddress;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    company: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    fullAddress: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerAddress
     */
    referenceNumber: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof CustomerAddress
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof CustomerAddress
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CustomerAddress
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddress
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @interface CustomerDetailOutputDTO
 */
export interface CustomerDetailOutputDTO {
    /**
     * 
     * @type {number}
     * @memberof CustomerDetailOutputDTO
     */
    customerNo: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailOutputDTO
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailOutputDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailOutputDTO
     */
    phone: string;
    /**
     * 
     * @type {Date}
     * @memberof CustomerDetailOutputDTO
     */
    createdAt: Date;
}
/**
 * 
 * @export
 * @interface CustomerDetailResponseDTO
 */
export interface CustomerDetailResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailResponseDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailResponseDTO
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailResponseDTO
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailResponseDTO
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailResponseDTO
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerDetailResponseDTO
     */
    customerNo: number;
    /**
     * 
     * @type {Date}
     * @memberof CustomerDetailResponseDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {Array<AddressDetailResponseDTO>}
     * @memberof CustomerDetailResponseDTO
     */
    addresses?: Array<AddressDetailResponseDTO>;
}
/**
 * 
 * @export
 * @interface CustomerDetailResponseDTODynamicQueryPagedDTO
 */
export interface CustomerDetailResponseDTODynamicQueryPagedDTO {
    /**
     * 
     * @type {Array<CustomerDetailResponseDTO>}
     * @memberof CustomerDetailResponseDTODynamicQueryPagedDTO
     */
    items?: Array<CustomerDetailResponseDTO>;
    /**
     * 
     * @type {number}
     * @memberof CustomerDetailResponseDTODynamicQueryPagedDTO
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerDetailResponseDTODynamicQueryPagedDTO
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerDetailResponseDTODynamicQueryPagedDTO
     */
    dataSetCount: number;
}
/**
 * 
 * @export
 * @interface CustomerInfoOutputDTO
 */
export interface CustomerInfoOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof CustomerInfoOutputDTO
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerInfoOutputDTO
     */
    customerNo: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerInfoOutputDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInfoOutputDTO
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInfoOutputDTO
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInfoOutputDTO
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface CustomerOfProductQueryOutputDTO
 */
export interface CustomerOfProductQueryOutputDTO {
    /**
     * 
     * @type {number}
     * @memberof CustomerOfProductQueryOutputDTO
     */
    customerNo: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerOfProductQueryOutputDTO
     */
    customerFullName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOfProductQueryOutputDTO
     */
    customerEmail: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOfProductQueryOutputDTO
     */
    customerPhone: string;
    /**
     * 
     * @type {Date}
     * @memberof CustomerOfProductQueryOutputDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof CustomerOfProductQueryOutputDTO
     */
    salesOrderCount: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerOfProductQueryOutputDTO
     */
    salesOrderLineItemCount: number;
}
/**
 * 
 * @export
 * @interface CustomerOfProductQueryOutputDTODynamicQueryOutputDTO
 */
export interface CustomerOfProductQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<CustomerOfProductQueryOutputDTO>}
     * @memberof CustomerOfProductQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<CustomerOfProductQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof CustomerOfProductQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface CustomerQueryOutputDTO
 */
export interface CustomerQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof CustomerQueryOutputDTO
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerQueryOutputDTO
     */
    customerNo: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerQueryOutputDTO
     */
    fullName: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerQueryOutputDTO
     */
    addressCount: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerQueryOutputDTO
     */
    lastShippingAddress: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerQueryOutputDTO
     */
    salesOrderCount: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerQueryOutputDTO
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerQueryOutputDTO
     */
    email: string;
    /**
     * 
     * @type {Date}
     * @memberof CustomerQueryOutputDTO
     */
    createdAt: Date;
}
/**
 * 
 * @export
 * @interface CustomerQueryOutputDTODynamicQueryOutputDTO
 */
export interface CustomerQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<CustomerQueryOutputDTO>}
     * @memberof CustomerQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<CustomerQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof CustomerQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface CustomerSummaryResponseDTO
 */
export interface CustomerSummaryResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CustomerSummaryResponseDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSummaryResponseDTO
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSummaryResponseDTO
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSummaryResponseDTO
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSummaryResponseDTO
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerSummaryResponseDTO
     */
    customerNo: number;
    /**
     * 
     * @type {Date}
     * @memberof CustomerSummaryResponseDTO
     */
    createdAt: Date;
}
/**
 * 
 * @export
 * @interface DailyReportOutputDTO
 */
export interface DailyReportOutputDTO {
    /**
     * 
     * @type {number}
     * @memberof DailyReportOutputDTO
     */
    count: number;
    /**
     * 
     * @type {DayOfWeek}
     * @memberof DailyReportOutputDTO
     */
    dayOfWeek: DayOfWeek;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DamagedReturnLineItemState {
    WaitingInWarehouse = 'WaitingInWarehouse',
    SendToCustomer = 'SendToCustomer'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum DayOfWeek {
    Sunday = 'Sunday',
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday'
}

/**
 * 
 * @export
 * @interface DeleteIntegrationCommand
 */
export interface DeleteIntegrationCommand {
    /**
     * 
     * @type {string}
     * @memberof DeleteIntegrationCommand
     */
    id: string;
}
/**
 * 
 * @export
 * @interface DeleteUserCommand
 */
export interface DeleteUserCommand {
    /**
     * 
     * @type {string}
     * @memberof DeleteUserCommand
     */
    userId: string;
}
/**
 * 
 * @export
 * @interface ECommerceCargoCompany
 */
export interface ECommerceCargoCompany {
    /**
     * 
     * @type {string}
     * @memberof ECommerceCargoCompany
     */
    referenceCode: string;
    /**
     * 
     * @type {string}
     * @memberof ECommerceCargoCompany
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ECommerceSalesOrderStatuses
 */
export interface ECommerceSalesOrderStatuses {
    /**
     * 
     * @type {string}
     * @memberof ECommerceSalesOrderStatuses
     */
    referenceCode: string;
    /**
     * 
     * @type {string}
     * @memberof ECommerceSalesOrderStatuses
     */
    name: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ErrorCode {
    ResourceAlreadyExists = 'ResourceAlreadyExists',
    ResourceNotFound = 'ResourceNotFound',
    InvalidContentType = 'InvalidContentType',
    InvalidCredential = 'InvalidCredential',
    InvalidOperation = 'InvalidOperation',
    UnsupportedType = 'UnsupportedType',
    ResourceAlreadyFulfilled = 'ResourceAlreadyFulfilled',
    MaestroPackageCancelSyncFailed = 'MaestroPackageCancelSyncFailed',
    WmsPackageCancelSyncFailed = 'WmsPackageCancelSyncFailed',
    InvalidUrl = 'InvalidUrl'
}

/**
 * 
 * @export
 * @interface ExpirationDates
 */
export interface ExpirationDates {
    /**
     * 
     * @type {ItemType}
     * @memberof ExpirationDates
     */
    itemType: ItemType;
    /**
     * 
     * @type {Date}
     * @memberof ExpirationDates
     */
    expirationDate: Date;
    /**
     * 
     * @type {number}
     * @memberof ExpirationDates
     */
    amount: number;
}
/**
 * 
 * @export
 * @interface FetchFromIntegrationInputDTO
 */
export interface FetchFromIntegrationInputDTO {
    /**
     * 
     * @type {string}
     * @memberof FetchFromIntegrationInputDTO
     */
    integrationId: string;
    /**
     * 
     * @type {TimeRangeInputDTO}
     * @memberof FetchFromIntegrationInputDTO
     */
    fetchByTimeRangeMethod?: TimeRangeInputDTO;
}
/**
 * 
 * @export
 * @interface FetchPackagesByTimeRange
 */
export interface FetchPackagesByTimeRange {
    /**
     * 
     * @type {Date}
     * @memberof FetchPackagesByTimeRange
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof FetchPackagesByTimeRange
     */
    end: Date;
}
/**
 * 
 * @export
 * @interface FetchReturnsFromMaestroCommand
 */
export interface FetchReturnsFromMaestroCommand {
    /**
     * 
     * @type {string}
     * @memberof FetchReturnsFromMaestroCommand
     */
    tenantName: string;
    /**
     * 
     * @type {string}
     * @memberof FetchReturnsFromMaestroCommand
     */
    salesOrderReference: string;
    /**
     * 
     * @type {string}
     * @memberof FetchReturnsFromMaestroCommand
     */
    returnReference: string;
    /**
     * 
     * @type {string}
     * @memberof FetchReturnsFromMaestroCommand
     */
    cargoCarrier: string;
    /**
     * 
     * @type {string}
     * @memberof FetchReturnsFromMaestroCommand
     */
    trackingId: string;
    /**
     * 
     * @type {string}
     * @memberof FetchReturnsFromMaestroCommand
     */
    notes: string;
    /**
     * 
     * @type {Array<MaestroReturnLineItem>}
     * @memberof FetchReturnsFromMaestroCommand
     */
    lineItems?: Array<MaestroReturnLineItem>;
    /**
     * 
     * @type {ReturnCourierRequest}
     * @memberof FetchReturnsFromMaestroCommand
     */
    courierRequest: ReturnCourierRequest;
    /**
     * 
     * @type {ReturnState}
     * @memberof FetchReturnsFromMaestroCommand
     */
    state: ReturnState;
}
/**
 * 
 * @export
 * @interface Filter
 */
export interface Filter {
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    propertyName: string;
    /**
     * 
     * @type {object}
     * @memberof Filter
     */
    value?: object;
    /**
     * 
     * @type {FilterOperation}
     * @memberof Filter
     */
    operator: FilterOperation;
    /**
     * 
     * @type {boolean}
     * @memberof Filter
     */
    caseSensitive: boolean;
    /**
     * 
     * @type {LogicalOperator}
     * @memberof Filter
     */
    logicalOperator: LogicalOperator;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FilterOperation {
    In = 'In',
    Equals = 'Equals',
    LessThan = 'LessThan',
    Contains = 'Contains',
    NotEqual = 'NotEqual',
    EndsWith = 'EndsWith',
    StartsWith = 'StartsWith',
    GreaterThan = 'GreaterThan',
    LessThanOrEqual = 'LessThanOrEqual',
    GreaterThanOrEqual = 'GreaterThanOrEqual',
    Any = 'Any',
    All = 'All'
}

/**
 * 
 * @export
 * @interface GetStockQuantityDetailsUsedInBlockedSalesOrdersCommand
 */
export interface GetStockQuantityDetailsUsedInBlockedSalesOrdersCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetStockQuantityDetailsUsedInBlockedSalesOrdersCommand
     */
    referenceNumbers?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetStockQuantityDetailsUsedInBlockedSalesOrdersCommandResult
 */
export interface GetStockQuantityDetailsUsedInBlockedSalesOrdersCommandResult {
    /**
     * 
     * @type {string}
     * @memberof GetStockQuantityDetailsUsedInBlockedSalesOrdersCommandResult
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof GetStockQuantityDetailsUsedInBlockedSalesOrdersCommandResult
     */
    sku: string;
    /**
     * 
     * @type {number}
     * @memberof GetStockQuantityDetailsUsedInBlockedSalesOrdersCommandResult
     */
    amountInOrder: number;
    /**
     * 
     * @type {number}
     * @memberof GetStockQuantityDetailsUsedInBlockedSalesOrdersCommandResult
     */
    missingAmount: number;
    /**
     * 
     * @type {number}
     * @memberof GetStockQuantityDetailsUsedInBlockedSalesOrdersCommandResult
     */
    usingAmount: number;
}
/**
 * 
 * @export
 * @interface GetStockQuantityDetailsUsedInBlockedSalesOrdersCommandResultDynamicQueryOutputDTO
 */
export interface GetStockQuantityDetailsUsedInBlockedSalesOrdersCommandResultDynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<GetStockQuantityDetailsUsedInBlockedSalesOrdersCommandResult>}
     * @memberof GetStockQuantityDetailsUsedInBlockedSalesOrdersCommandResultDynamicQueryOutputDTO
     */
    data?: Array<GetStockQuantityDetailsUsedInBlockedSalesOrdersCommandResult>;
    /**
     * 
     * @type {number}
     * @memberof GetStockQuantityDetailsUsedInBlockedSalesOrdersCommandResultDynamicQueryOutputDTO
     */
    count: number;
}

/**
 * 
 * @export
 * @interface GetTenantLastSyncTimeQuery
 */
export interface GetTenantLastSyncTimeQuery {
    /**
     * 
     * @type {string}
     * @memberof GetTenantLastSyncTimeQuery
     */
    tenantId: string;
    /**
     * 
     * @type {TenantSyncType}
     * @memberof GetTenantLastSyncTimeQuery
     */
    syncType: TenantSyncType;
}
/**
 * 
 * @export
 * @interface Height
 */
export interface Height {
    /**
     * 
     * @type {string}
     * @memberof Height
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof Height
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {any}
     * @memberof InlineObject
     */
    file?: any;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject1
     */
    file?: any;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject2
     */
    file?: any;
}
/**
 * 
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject3
     */
    file?: any;
}
/**
 * 
 * @export
 * @interface Integration
 */
export interface Integration {
    /**
     * 
     * @type {Array<IntegrationConfiguration>}
     * @memberof Integration
     */
    configurations?: Array<IntegrationConfiguration>;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Integration
     */
    products?: Array<Product>;
    /**
     * 
     * @type {Array<SalesOrder>}
     * @memberof Integration
     */
    salesOrders?: Array<SalesOrder>;
    /**
     * 
     * @type {Array<InvalidSalesOrder>}
     * @memberof Integration
     */
    invalidSalesOrders?: Array<InvalidSalesOrder>;
    /**
     * 
     * @type {Array<ReceivingOrder>}
     * @memberof Integration
     */
    receivingOrders?: Array<ReceivingOrder>;
    /**
     * 
     * @type {Tenant}
     * @memberof Integration
     */
    tenant?: Tenant;
    /**
     * 
     * @type {IntegrationType}
     * @memberof Integration
     */
    type: IntegrationType;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof Integration
     */
    productCatalogLastSyncedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Integration
     */
    salesOrdersLastSyncedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Integration
     */
    cancelledSalesOrdersLastSyncedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Integration
     */
    deactivationTime?: Date;
    /**
     * 
     * @type {IntegrationState}
     * @memberof Integration
     */
    state: IntegrationState;
    /**
     * 
     * @type {boolean}
     * @memberof Integration
     */
    isCoDActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    cashKey: string;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    creditCardKey: string;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    referenceNumberPrefix: string;
    /**
     * 
     * @type {boolean}
     * @memberof Integration
     */
    salesOrdersSyncIsEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Integration
     */
    productCatalogSyncIsEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Integration
     */
    fulfillmentNotificationIsEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Integration
     */
    inventoryNotificationIsEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    payload: string;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof Integration
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Integration
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Integration
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @interface IntegrationConfiguration
 */
export interface IntegrationConfiguration {
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfiguration
     */
    integrationId: string;
    /**
     * 
     * @type {Integration}
     * @memberof IntegrationConfiguration
     */
    integration?: Integration;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfiguration
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfiguration
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfiguration
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfiguration
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof IntegrationConfiguration
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof IntegrationConfiguration
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IntegrationConfiguration
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfiguration
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfiguration
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfiguration
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @interface IntegrationDetailResponseDTO
 */
export interface IntegrationDetailResponseDTO {
    /**
     * 
     * @type {IntegrationType}
     * @memberof IntegrationDetailResponseDTO
     */
    integrationType?: IntegrationType;
    /**
     * 
     * @type {string}
     * @memberof IntegrationDetailResponseDTO
     */
    integrationName: string;
}
/**
 * 
 * @export
 * @interface IntegrationOutputDTO
 */
export interface IntegrationOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof IntegrationOutputDTO
     */
    id: string;
    /**
     * 
     * @type {IntegrationState}
     * @memberof IntegrationOutputDTO
     */
    state: IntegrationState;
    /**
     * 
     * @type {IntegrationType}
     * @memberof IntegrationOutputDTO
     */
    type: IntegrationType;
    /**
     * 
     * @type {string}
     * @memberof IntegrationOutputDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationOutputDTO
     */
    tenantSecret: string;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationOutputDTO
     */
    isProductSyncActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationOutputDTO
     */
    isMarketplaceSyncActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationOutputDTO
     */
    isWebsiteSyncActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationOutputDTO
     */
    isSendOrdersByOplogActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IntegrationOutputDTO
     */
    apiUserName?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationOutputDTO
     */
    apiPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationOutputDTO
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationOutputDTO
     */
    shopLink?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationOutputDTO
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationOutputDTO
     */
    clientSecret?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationOutputDTO
     */
    isCoDActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationOutputDTO
     */
    isSubProductActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationOutputDTO
     */
    isTestEnvironment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationOutputDTO
     */
    isPartialFulfillmentActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IntegrationOutputDTO
     */
    partialFulfillmentServiceName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationOutputDTO
     */
    isSameDayDeliveryActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IntegrationOutputDTO
     */
    sameDayDeliveryKey?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationOutputDTO
     */
    cashKey?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationOutputDTO
     */
    creditCardKey?: string;
    /**
     * 
     * @type {number}
     * @memberof IntegrationOutputDTO
     */
    locationId?: number;
    /**
     * 
     * @type {string}
     * @memberof IntegrationOutputDTO
     */
    referenceNumberPrefix?: string;
    /**
     * 
     * @type {TSoftIntegrationPayload}
     * @memberof IntegrationOutputDTO
     */
    tSoftPayload?: TSoftIntegrationPayload;
    /**
     * 
     * @type {TSoftIntegrationConfigurations}
     * @memberof IntegrationOutputDTO
     */
    tSoftConfigurations?: TSoftIntegrationConfigurations;
    /**
     * 
     * @type {TicimaxIntegrationPayload}
     * @memberof IntegrationOutputDTO
     */
    ticimaxPayload?: TicimaxIntegrationPayload;
    /**
     * 
     * @type {TicimaxIntegrationConfigurations}
     * @memberof IntegrationOutputDTO
     */
    ticimaxConfigurations?: TicimaxIntegrationConfigurations;
    /**
     * 
     * @type {WooCommerceIntegrationConfigurations}
     * @memberof IntegrationOutputDTO
     */
    wooCommerceConfigurations?: WooCommerceIntegrationConfigurations;
    /**
     * 
     * @type {number}
     * @memberof IntegrationOutputDTO
     */
    salesOrderCount: number;
    /**
     * 
     * @type {number}
     * @memberof IntegrationOutputDTO
     */
    productCount: number;
    /**
     * 
     * @type {Date}
     * @memberof IntegrationOutputDTO
     */
    lastSyncTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IntegrationOutputDTO
     */
    deactivationTime?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationOutputDTO
     */
    editable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationOutputDTO
     */
    syncable: boolean;
    /**
     * 
     * @type {OpenApiIdentifier}
     * @memberof IntegrationOutputDTO
     */
    openApiIdentifier: OpenApiIdentifier;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationOutputDTO
     */
    isSameAsBarcode: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationOutputDTO
     */
    skuWithBarcode: boolean;
    /**
     * 
     * @type {string}
     * @memberof IntegrationOutputDTO
     */
    productBarcodeKeyField?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationOutputDTO
     */
    isActiveOrderNumber?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IntegrationOutputDTO
     */
    storeFrontCode?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum IntegrationState {
    Passive = 'Passive',
    Active = 'Active'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum IntegrationSyncType {
    ProductCatalogFromIntegration = 'ProductCatalogFromIntegration',
    SalesOrdersFromIntegration = 'SalesOrdersFromIntegration',
    CancelledSalesOrdersFromIntegration = 'CancelledSalesOrdersFromIntegration',
    All = 'All'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum IntegrationType {
    CudExcludedIntegrations = 'CudExcludedIntegrations',
    SyncExcludedIntegrations = 'SyncExcludedIntegrations',
    InventoryNotificationExcludedIntegrations = 'InventoryNotificationExcludedIntegrations',
    FulfillmentNotificationExcludedIntegrations = 'FulfillmentNotificationExcludedIntegrations',
    FulfillmentNotificationViaPackage = 'FulfillmentNotificationViaPackage',
    CreatePackageByQuantity = 'CreatePackageByQuantity',
    Oplog = 'Oplog',
    Shopify = 'Shopify',
    OpenApi = 'OpenApi',
    Custom = 'Custom',
    WooCommerce = 'WooCommerce',
    Ticimax = 'Ticimax',
    TSoft = 'TSoft',
    Default = 'Default',
    Entegra = 'Entegra',
    Trendyol = 'Trendyol',
    TrendyolDE = 'TrendyolDE',
    OrderDesk = 'OrderDesk'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum OpenApiIdentifier {
    None = 'None',
    Ikas = 'Ikas',
    Pixa = 'Pixa',
    Shopiverse = 'Shopiverse',
    Logo = 'Logo',
    MNM = 'MNM',
    Dopigo = 'Dopigo'
}

/**
 * 
 * @export
 * @interface IntegrationsOfTenantOutputDTO
 */
export interface IntegrationsOfTenantOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsOfTenantOutputDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationsOfTenantOutputDTO
     */
    name: string;
}
/**
 * 
 * @export
 * @interface IntegrationsOfTenantQueryOutputDTO
 */
export interface IntegrationsOfTenantQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsOfTenantQueryOutputDTO
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof IntegrationsOfTenantQueryOutputDTO
     */
    productCount: number;
    /**
     * 
     * @type {number}
     * @memberof IntegrationsOfTenantQueryOutputDTO
     */
    salesOrderCount: number;
    /**
     * 
     * @type {number}
     * @memberof IntegrationsOfTenantQueryOutputDTO
     */
    invalidSalesOrderCount: number;
    /**
     * 
     * @type {number}
     * @memberof IntegrationsOfTenantQueryOutputDTO
     */
    receivingOrderCount: number;
    /**
     * 
     * @type {IntegrationType}
     * @memberof IntegrationsOfTenantQueryOutputDTO
     */
    type: IntegrationType;
    /**
     * 
     * @type {string}
     * @memberof IntegrationsOfTenantQueryOutputDTO
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof IntegrationsOfTenantQueryOutputDTO
     */
    productCatalogLastSyncedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IntegrationsOfTenantQueryOutputDTO
     */
    salesOrdersLastSyncedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IntegrationsOfTenantQueryOutputDTO
     */
    cancelledSalesOrdersLastSyncedAt?: Date;
    /**
     * 
     * @type {IntegrationState}
     * @memberof IntegrationsOfTenantQueryOutputDTO
     */
    state: IntegrationState;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationsOfTenantQueryOutputDTO
     */
    isCoDActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof IntegrationsOfTenantQueryOutputDTO
     */
    cashKey: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationsOfTenantQueryOutputDTO
     */
    creditCardKey: string;
}
/**
 * 
 * @export
 * @interface InvalidProductQueryOutputDTO
 */
export interface InvalidProductQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof InvalidProductQueryOutputDTO
     */
    id: string;
    /**
     * 
     * @type {IntegrationType}
     * @memberof InvalidProductQueryOutputDTO
     */
    integrationType?: IntegrationType;
    /**
     * 
     * @type {string}
     * @memberof InvalidProductQueryOutputDTO
     */
    integrationName: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidProductQueryOutputDTO
     */
    sku: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvalidProductQueryOutputDTO
     */
    barcodes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InvalidProductQueryOutputDTO
     */
    name: string;
    /**
     * 
     * @type {ValidationErrorCodesForProduct}
     * @memberof InvalidProductQueryOutputDTO
     */
    error: ValidationErrorCodesForProduct;
    /**
     * 
     * @type {string}
     * @memberof InvalidProductQueryOutputDTO
     */
    errorMessage: string;
    /**
     * 
     * @type {Date}
     * @memberof InvalidProductQueryOutputDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {OpenApiIdentifier}
     * @memberof InvalidProductQueryOutputDTO
     */
    openApiIdentifier: OpenApiIdentifier;
}
/**
 * 
 * @export
 * @interface InvalidProductQueryOutputDTODynamicQueryOutputDTO
 */
export interface InvalidProductQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<InvalidProductQueryOutputDTO>}
     * @memberof InvalidProductQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<InvalidProductQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof InvalidProductQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface InvalidSalesOrder
 */
export interface InvalidSalesOrder {
    /**
     * 
     * @type {Tenant}
     * @memberof InvalidSalesOrder
     */
    tenant?: Tenant;
    /**
     * 
     * @type {Integration}
     * @memberof InvalidSalesOrder
     */
    integration?: Integration;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrder
     */
    integrationId: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrder
     */
    salesChannel: string;
    /**
     * 
     * @type {InvalidSalesOrderCustomer}
     * @memberof InvalidSalesOrder
     */
    invalidSalesOrderCustomer?: InvalidSalesOrderCustomer;
    /**
     * 
     * @type {InvalidSalesOrderShippingAddress}
     * @memberof InvalidSalesOrder
     */
    invalidSalesOrderShippingAddress?: InvalidSalesOrderShippingAddress;
    /**
     * 
     * @type {Array<InvalidSalesOrderLineItem>}
     * @memberof InvalidSalesOrder
     */
    invalidSalesOrderLineItems?: Array<InvalidSalesOrderLineItem>;
    /**
     * 
     * @type {InvalidSalesOrderPayment}
     * @memberof InvalidSalesOrder
     */
    invalidSalesOrderPayment?: InvalidSalesOrderPayment;
    /**
     * 
     * @type {InvalidSalesOrderCargo}
     * @memberof InvalidSalesOrder
     */
    invalidSalesOrderCargo?: InvalidSalesOrderCargo;
    /**
     * 
     * @type {InvalidSalesOrderVAS}
     * @memberof InvalidSalesOrder
     */
    invalidSalesOrderVAS?: InvalidSalesOrderVAS;
    /**
     * 
     * @type {OmnitroErrorCode}
     * @memberof InvalidSalesOrder
     */
    error: OmnitroErrorCode;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrder
     */
    shippingAddressFullName: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrder
     */
    orderId: string;
    /**
     * 
     * @type {Date}
     * @memberof InvalidSalesOrder
     */
    orderCreatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrder
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrder
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrder
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof InvalidSalesOrder
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvalidSalesOrder
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvalidSalesOrder
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrder
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrder
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrder
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @interface InvalidSalesOrderCargo
 */
export interface InvalidSalesOrderCargo {
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderCargo
     */
    requestedCargoCarrier: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderCargo
     */
    cargoDocumentUrl: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderCargo
     */
    cargoCode: string;
    /**
     * 
     * @type {CargoType}
     * @memberof InvalidSalesOrderCargo
     */
    cargoType: CargoType;
}
/**
 * 
 * @export
 * @interface InvalidSalesOrderCustomer
 */
export interface InvalidSalesOrderCustomer {
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderCustomer
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderCustomer
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderCustomer
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderCustomer
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface InvalidSalesOrderDetailCargoOutputDTO
 */
export interface InvalidSalesOrderDetailCargoOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderDetailCargoOutputDTO
     */
    requestedCargoCarrier: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderDetailCargoOutputDTO
     */
    cargoCarrier: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderDetailCargoOutputDTO
     */
    cargoDocumentUrl: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderDetailCargoOutputDTO
     */
    cargoCode: string;
    /**
     * 
     * @type {CargoType}
     * @memberof InvalidSalesOrderDetailCargoOutputDTO
     */
    cargoType: CargoType;
}
/**
 * 
 * @export
 * @interface InvalidSalesOrderDetailCustomerOutputDTO
 */
export interface InvalidSalesOrderDetailCustomerOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderDetailCustomerOutputDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderDetailCustomerOutputDTO
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderDetailCustomerOutputDTO
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderDetailCustomerOutputDTO
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface InvalidSalesOrderDetailOutputDTO
 */
export interface InvalidSalesOrderDetailOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderDetailOutputDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderDetailOutputDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderDetailOutputDTO
     */
    salesChannel: string;
    /**
     * 
     * @type {OmnitroErrorCode}
     * @memberof InvalidSalesOrderDetailOutputDTO
     */
    error: OmnitroErrorCode;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderDetailOutputDTO
     */
    orderId: string;
    /**
     * 
     * @type {Date}
     * @memberof InvalidSalesOrderDetailOutputDTO
     */
    orderCreatedAt: Date;
    /**
     * 
     * @type {InvalidSalesOrderDetailCustomerOutputDTO}
     * @memberof InvalidSalesOrderDetailOutputDTO
     */
    customer?: InvalidSalesOrderDetailCustomerOutputDTO;
    /**
     * 
     * @type {InvalidSalesOrderDetailShippingAddressOutputDTO}
     * @memberof InvalidSalesOrderDetailOutputDTO
     */
    shippingAddress?: InvalidSalesOrderDetailShippingAddressOutputDTO;
    /**
     * 
     * @type {CommonIntegrationOutputDTO}
     * @memberof InvalidSalesOrderDetailOutputDTO
     */
    integration?: CommonIntegrationOutputDTO;
    /**
     * 
     * @type {SalesOrderPaymentOutputDTO}
     * @memberof InvalidSalesOrderDetailOutputDTO
     */
    payment?: SalesOrderPaymentOutputDTO;
    /**
     * 
     * @type {InvalidSalesOrderDetailCargoOutputDTO}
     * @memberof InvalidSalesOrderDetailOutputDTO
     */
    cargo?: InvalidSalesOrderDetailCargoOutputDTO;
    /**
     * 
     * @type {Date}
     * @memberof InvalidSalesOrderDetailOutputDTO
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvalidSalesOrderDetailOutputDTO
     */
    createdAt: Date;
}
/**
 * 
 * @export
 * @interface InvalidSalesOrderDetailShippingAddressOutputDTO
 */
export interface InvalidSalesOrderDetailShippingAddressOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderDetailShippingAddressOutputDTO
     */
    addressFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderDetailShippingAddressOutputDTO
     */
    addressLastName: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderDetailShippingAddressOutputDTO
     */
    addressOne: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderDetailShippingAddressOutputDTO
     */
    addressTwo: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderDetailShippingAddressOutputDTO
     */
    district: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderDetailShippingAddressOutputDTO
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderDetailShippingAddressOutputDTO
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderDetailShippingAddressOutputDTO
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderDetailShippingAddressOutputDTO
     */
    addressPhone: string;
}
/**
 * 
 * @export
 * @interface InvalidSalesOrderDocumentVAS
 */
export interface InvalidSalesOrderDocumentVAS {
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderDocumentVAS
     */
    documentUrl: string;
}
/**
 * 
 * @export
 * @interface InvalidSalesOrderGiftVAS
 */
export interface InvalidSalesOrderGiftVAS {
    /**
     * 
     * @type {boolean}
     * @memberof InvalidSalesOrderGiftVAS
     */
    giftWrap: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderGiftVAS
     */
    giftNotes: string;
}
/**
 * 
 * @export
 * @interface InvalidSalesOrderLineItem
 */
export interface InvalidSalesOrderLineItem {
    /**
     * 
     * @type {InvalidSalesOrder}
     * @memberof InvalidSalesOrderLineItem
     */
    invalidSalesOrder?: InvalidSalesOrder;
    /**
     * 
     * @type {number}
     * @memberof InvalidSalesOrderLineItem
     */
    amountInOrder: number;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderLineItem
     */
    sku: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderLineItem
     */
    barcode: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderLineItem
     */
    invalidSalesOrderId: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderLineItem
     */
    productId?: string;
    /**
     * 
     * @type {Product}
     * @memberof InvalidSalesOrderLineItem
     */
    product?: Product;
    /**
     * 
     * @type {number}
     * @memberof InvalidSalesOrderLineItem
     */
    productSalePrice?: number;
    /**
     * 
     * @type {Currency}
     * @memberof InvalidSalesOrderLineItem
     */
    currency?: Currency;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderLineItem
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof InvalidSalesOrderLineItem
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvalidSalesOrderLineItem
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvalidSalesOrderLineItem
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderLineItem
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderLineItem
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderLineItem
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @interface InvalidSalesOrderLineItemQueryOutputDTO
 */
export interface InvalidSalesOrderLineItemQueryOutputDTO {
    /**
     * 
     * @type {number}
     * @memberof InvalidSalesOrderLineItemQueryOutputDTO
     */
    amountInOrder: number;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderLineItemQueryOutputDTO
     */
    sku: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderLineItemQueryOutputDTO
     */
    productName: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderLineItemQueryOutputDTO
     */
    productImageUrl: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvalidSalesOrderLineItemQueryOutputDTO
     */
    barcodes?: Array<string>;
}
/**
 * 
 * @export
 * @interface InvalidSalesOrderLineItemQueryOutputDTODynamicQueryOutputDTO
 */
export interface InvalidSalesOrderLineItemQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<InvalidSalesOrderLineItemQueryOutputDTO>}
     * @memberof InvalidSalesOrderLineItemQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<InvalidSalesOrderLineItemQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof InvalidSalesOrderLineItemQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface InvalidSalesOrderPayment
 */
export interface InvalidSalesOrderPayment {
    /**
     * 
     * @type {number}
     * @memberof InvalidSalesOrderPayment
     */
    totalPaymentAmount?: number;
    /**
     * 
     * @type {Currency}
     * @memberof InvalidSalesOrderPayment
     */
    currency?: Currency;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderPayment
     */
    paymentGatewayNames: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvalidSalesOrderPayment
     */
    isPaid?: boolean;
    /**
     * 
     * @type {PaymentOption}
     * @memberof InvalidSalesOrderPayment
     */
    paymentOption?: PaymentOption;
}
/**
 * 
 * @export
 * @interface InvalidSalesOrderQueryOutputDTO
 */
export interface InvalidSalesOrderQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderQueryOutputDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderQueryOutputDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {IntegrationType}
     * @memberof InvalidSalesOrderQueryOutputDTO
     */
    integrationType?: IntegrationType;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderQueryOutputDTO
     */
    integrationName: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderQueryOutputDTO
     */
    salesChannel: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderQueryOutputDTO
     */
    customerFullName: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderQueryOutputDTO
     */
    paymentGatewayNames: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvalidSalesOrderQueryOutputDTO
     */
    isPaid: boolean;
    /**
     * 
     * @type {OmnitroErrorCode}
     * @memberof InvalidSalesOrderQueryOutputDTO
     */
    error: OmnitroErrorCode;
    /**
     * 
     * @type {Date}
     * @memberof InvalidSalesOrderQueryOutputDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {OpenApiIdentifier}
     * @memberof InvalidSalesOrderQueryOutputDTO
     */
    openApiIdentifier: OpenApiIdentifier;
}
/**
 * 
 * @export
 * @interface InvalidSalesOrderQueryOutputDTODynamicQueryOutputDTO
 */
export interface InvalidSalesOrderQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<InvalidSalesOrderQueryOutputDTO>}
     * @memberof InvalidSalesOrderQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<InvalidSalesOrderQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof InvalidSalesOrderQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface InvalidSalesOrderShippingAddress
 */
export interface InvalidSalesOrderShippingAddress {
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderShippingAddress
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderShippingAddress
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderShippingAddress
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderShippingAddress
     */
    addressOne: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderShippingAddress
     */
    addressTwo: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderShippingAddress
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderShippingAddress
     */
    district: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderShippingAddress
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderShippingAddress
     */
    country: string;
}
/**
 * 
 * @export
 * @interface InvalidSalesOrderVAS
 */
export interface InvalidSalesOrderVAS {
    /**
     * 
     * @type {string}
     * @memberof InvalidSalesOrderVAS
     */
    packageNote: string;
    /**
     * 
     * @type {InvalidSalesOrderGiftVAS}
     * @memberof InvalidSalesOrderVAS
     */
    giftVAS?: InvalidSalesOrderGiftVAS;
    /**
     * 
     * @type {InvalidSalesOrderDocumentVAS}
     * @memberof InvalidSalesOrderVAS
     */
    documentVAS?: InvalidSalesOrderDocumentVAS;
}
/**
 * 
 * @export
 * @interface InventoryExpirationDatesQueryOutputDTO
 */
export interface InventoryExpirationDatesQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof InventoryExpirationDatesQueryOutputDTO
     */
    sku: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InventoryExpirationDatesQueryOutputDTO
     */
    barcodes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InventoryExpirationDatesQueryOutputDTO
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryExpirationDatesQueryOutputDTO
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof InventoryExpirationDatesQueryOutputDTO
     */
    expirationDate: Date;
    /**
     * 
     * @type {number}
     * @memberof InventoryExpirationDatesQueryOutputDTO
     */
    amount: number;
    /**
     * 
     * @type {InventoryItemType}
     * @memberof InventoryExpirationDatesQueryOutputDTO
     */
    inventoryItemType: InventoryItemType;
}
/**
 * 
 * @export
 * @interface InventoryExpirationDatesQueryOutputDTODynamicQueryOutputDTO
 */
export interface InventoryExpirationDatesQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<InventoryExpirationDatesQueryOutputDTO>}
     * @memberof InventoryExpirationDatesQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<InventoryExpirationDatesQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof InventoryExpirationDatesQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface InventoryItem
 */
export interface InventoryItem {
    /**
     * 
     * @type {Product}
     * @memberof InventoryItem
     */
    product?: Product;
    /**
     * 
     * @type {Array<InventoryItemExpirationDate>}
     * @memberof InventoryItem
     */
    inventoryItemExpirationDates?: Array<InventoryItemExpirationDate>;
    /**
     * 
     * @type {string}
     * @memberof InventoryItem
     */
    productId: string;
    /**
     * 
     * @type {Tenant}
     * @memberof InventoryItem
     */
    tenant?: Tenant;
    /**
     * 
     * @type {number}
     * @memberof InventoryItem
     */
    stockAmount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryItem
     */
    stockAmountChanged: boolean;
    /**
     * 
     * @type {WarehouseInventoryItem}
     * @memberof InventoryItem
     */
    warehouse?: WarehouseInventoryItem;
    /**
     * 
     * @type {Width}
     * @memberof InventoryItem
     */
    pieceWidth?: Width;
    /**
     * 
     * @type {Length}
     * @memberof InventoryItem
     */
    pieceLength?: Length;
    /**
     * 
     * @type {Height}
     * @memberof InventoryItem
     */
    pieceHeight?: Height;
    /**
     * 
     * @type {Weight}
     * @memberof InventoryItem
     */
    pieceWeight?: Weight;
    /**
     * 
     * @type {string}
     * @memberof InventoryItem
     */
    sku: string;
    /**
     * 
     * @type {Date}
     * @memberof InventoryItem
     */
    wmsLastSyncTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InventoryItem
     */
    webHookLastSyncTime?: Date;
    /**
     * 
     * @type {InventoryWebHookSyncState}
     * @memberof InventoryItem
     */
    webHookSyncState: InventoryWebHookSyncState;
    /**
     * 
     * @type {number}
     * @memberof InventoryItem
     */
    webHookSyncRetryCount: number;
    /**
     * 
     * @type {Date}
     * @memberof InventoryItem
     */
    etag?: Date;
    /**
     * 
     * @type {string}
     * @memberof InventoryItem
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryItem
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof InventoryItem
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof InventoryItem
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InventoryItem
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof InventoryItem
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryItem
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryItem
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @interface InventoryItemExpirationDate
 */
export interface InventoryItemExpirationDate {
    /**
     * 
     * @type {InventoryItem}
     * @memberof InventoryItemExpirationDate
     */
    inventoryItem?: InventoryItem;
    /**
     * 
     * @type {string}
     * @memberof InventoryItemExpirationDate
     */
    inventoryItemId: string;
    /**
     * 
     * @type {Product}
     * @memberof InventoryItemExpirationDate
     */
    product?: Product;
    /**
     * 
     * @type {string}
     * @memberof InventoryItemExpirationDate
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryItemExpirationDate
     */
    sku: string;
    /**
     * 
     * @type {number}
     * @memberof InventoryItemExpirationDate
     */
    quantity: number;
    /**
     * 
     * @type {InventoryItemType}
     * @memberof InventoryItemExpirationDate
     */
    inventoryItemType: InventoryItemType;
    /**
     * 
     * @type {Date}
     * @memberof InventoryItemExpirationDate
     */
    expirationDate: Date;
    /**
     * 
     * @type {string}
     * @memberof InventoryItemExpirationDate
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryItemExpirationDate
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof InventoryItemExpirationDate
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof InventoryItemExpirationDate
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InventoryItemExpirationDate
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof InventoryItemExpirationDate
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryItemExpirationDate
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryItemExpirationDate
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @interface InventoryItemQueryOutputDTO
 */
export interface InventoryItemQueryOutputDTO {
    /**
     * 
     * @type {ProductState}
     * @memberof InventoryItemQueryOutputDTO
     */
    state?: ProductState;
    /**
     * 
     * @type {IntegrationType}
     * @memberof InventoryItemQueryOutputDTO
     */
    integrationType?: IntegrationType;
    /**
     * 
     * @type {string}
     * @memberof InventoryItemQueryOutputDTO
     */
    integrationName?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryItemQueryOutputDTO
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryItemQueryOutputDTO
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InventoryItemQueryOutputDTO
     */
    barcodes?: Array<string>;
    /**
     * 
     * @type {ProductType}
     * @memberof InventoryItemQueryOutputDTO
     */
    type: ProductType;
    /**
     * 
     * @type {string}
     * @memberof InventoryItemQueryOutputDTO
     */
    category: string;
    /**
     * 
     * @type {number}
     * @memberof InventoryItemQueryOutputDTO
     */
    stockAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryItemQueryOutputDTO
     */
    availableAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryItemQueryOutputDTO
     */
    reservedAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryItemQueryOutputDTO
     */
    sku: string;
    /**
     * 
     * @type {number}
     * @memberof InventoryItemQueryOutputDTO
     */
    receivedItemAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryItemQueryOutputDTO
     */
    damagedItemAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryItemQueryOutputDTO
     */
    lostItemAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryItemQueryOutputDTO
     */
    expiredAmount?: number;
    /**
     * 
     * @type {OpenApiIdentifier}
     * @memberof InventoryItemQueryOutputDTO
     */
    openApiIdentifier: OpenApiIdentifier;
}
/**
 * 
 * @export
 * @interface InventoryItemQueryOutputDTODynamicQueryOutputDTO
 */
export interface InventoryItemQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<InventoryItemQueryOutputDTO>}
     * @memberof InventoryItemQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<InventoryItemQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof InventoryItemQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum InventoryItemType {
    StockItem = 'StockItem',
    DamagedItem = 'DamagedItem'
}

/**
 * 
 * @export
 * @interface InventoryMissingQueryOutputDTO
 */
export interface InventoryMissingQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof InventoryMissingQueryOutputDTO
     */
    sku: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryMissingQueryOutputDTO
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InventoryMissingQueryOutputDTO
     */
    barcodes?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof InventoryMissingQueryOutputDTO
     */
    missingAmount: number;
}
/**
 * 
 * @export
 * @interface InventoryMissingQueryOutputDTODynamicQueryOutputDTO
 */
export interface InventoryMissingQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<InventoryMissingQueryOutputDTO>}
     * @memberof InventoryMissingQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<InventoryMissingQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof InventoryMissingQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface InventoryReservation
 */
export interface InventoryReservation {
    /**
     * 
     * @type {Product}
     * @memberof InventoryReservation
     */
    product?: Product;
    /**
     * 
     * @type {string}
     * @memberof InventoryReservation
     */
    productId: string;
    /**
     * 
     * @type {Tenant}
     * @memberof InventoryReservation
     */
    tenant?: Tenant;
    /**
     * 
     * @type {SalesOrder}
     * @memberof InventoryReservation
     */
    salesOrder?: SalesOrder;
    /**
     * 
     * @type {string}
     * @memberof InventoryReservation
     */
    sku: string;
    /**
     * 
     * @type {number}
     * @memberof InventoryReservation
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryReservation
     */
    salesOrderId: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryReservation
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryReservation
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof InventoryReservation
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof InventoryReservation
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InventoryReservation
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof InventoryReservation
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryReservation
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryReservation
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @interface InventorySerialNumbersQueryOutputDTO
 */
export interface InventorySerialNumbersQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof InventorySerialNumbersQueryOutputDTO
     */
    sku: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InventorySerialNumbersQueryOutputDTO
     */
    barcodes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InventorySerialNumbersQueryOutputDTO
     */
    serialNumber: string;
    /**
     * 
     * @type {string}
     * @memberof InventorySerialNumbersQueryOutputDTO
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof InventorySerialNumbersQueryOutputDTO
     */
    name: string;
    /**
     * 
     * @type {InventoryItemType}
     * @memberof InventorySerialNumbersQueryOutputDTO
     */
    inventoryItemType: InventoryItemType;
}
/**
 * 
 * @export
 * @interface InventorySerialNumbersQueryOutputDTODynamicQueryOutputDTO
 */
export interface InventorySerialNumbersQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<InventorySerialNumbersQueryOutputDTO>}
     * @memberof InventorySerialNumbersQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<InventorySerialNumbersQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof InventorySerialNumbersQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum InventoryWebHookSyncState {
    None = 'None',
    WebHookFailed = 'WebHookFailed',
    WebHookSucceeded = 'WebHookSucceeded'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ItemType {
    StockType = 'StockType',
    DamagedType = 'DamagedType',
    None = 'None',
    StockItem = 'StockItem',
    RestowItem = 'RestowItem',
    ReservedItem = 'ReservedItem',
    DamagedItem = 'DamagedItem'
}

/**
 * 
 * @export
 * @interface Length
 */
export interface Length {
    /**
     * 
     * @type {string}
     * @memberof Length
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof Length
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface LineItemExpirationDateOfReceivingOrderQueryOutputDTO
 */
export interface LineItemExpirationDateOfReceivingOrderQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof LineItemExpirationDateOfReceivingOrderQueryOutputDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemExpirationDateOfReceivingOrderQueryOutputDTO
     */
    productName: string;
    /**
     * 
     * @type {Date}
     * @memberof LineItemExpirationDateOfReceivingOrderQueryOutputDTO
     */
    expirationDate: Date;
    /**
     * 
     * @type {string}
     * @memberof LineItemExpirationDateOfReceivingOrderQueryOutputDTO
     */
    sku: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LineItemExpirationDateOfReceivingOrderQueryOutputDTO
     */
    barcodes?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof LineItemExpirationDateOfReceivingOrderQueryOutputDTO
     */
    quantity: number;
}
/**
 * 
 * @export
 * @interface LineItemExpirationDateOfReceivingOrderQueryOutputDTODynamicQueryOutputDTO
 */
export interface LineItemExpirationDateOfReceivingOrderQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<LineItemExpirationDateOfReceivingOrderQueryOutputDTO>}
     * @memberof LineItemExpirationDateOfReceivingOrderQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<LineItemExpirationDateOfReceivingOrderQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof LineItemExpirationDateOfReceivingOrderQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface LineItemOfPackageQueryOutputDTO
 */
export interface LineItemOfPackageQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof LineItemOfPackageQueryOutputDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemOfPackageQueryOutputDTO
     */
    sku: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LineItemOfPackageQueryOutputDTO
     */
    barcodes: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LineItemOfPackageQueryOutputDTO
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemOfPackageQueryOutputDTO
     */
    productName: string;
    /**
     * 
     * @type {ProductType}
     * @memberof LineItemOfPackageQueryOutputDTO
     */
    productType: ProductType;
    /**
     * 
     * @type {string}
     * @memberof LineItemOfPackageQueryOutputDTO
     */
    productCategory: string;
    /**
     * 
     * @type {number}
     * @memberof LineItemOfPackageQueryOutputDTO
     */
    amountInOrder: number;
}
/**
 * 
 * @export
 * @interface LineItemOfPackageQueryOutputDTODynamicQueryOutputDTO
 */
export interface LineItemOfPackageQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<LineItemOfPackageQueryOutputDTO>}
     * @memberof LineItemOfPackageQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<LineItemOfPackageQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof LineItemOfPackageQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface LineItemOfPackageReportResponseDTO
 */
export interface LineItemOfPackageReportResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof LineItemOfPackageReportResponseDTO
     */
    sku: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LineItemOfPackageReportResponseDTO
     */
    barcodes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LineItemOfPackageReportResponseDTO
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemOfPackageReportResponseDTO
     */
    productName: string;
    /**
     * 
     * @type {ProductType}
     * @memberof LineItemOfPackageReportResponseDTO
     */
    productType: ProductType;
    /**
     * 
     * @type {string}
     * @memberof LineItemOfPackageReportResponseDTO
     */
    productCategory: string;
    /**
     * 
     * @type {number}
     * @memberof LineItemOfPackageReportResponseDTO
     */
    amountInOrder: number;
    /**
     * 
     * @type {number}
     * @memberof LineItemOfPackageReportResponseDTO
     */
    cancelledAmount?: number;
}
/**
 * 
 * @export
 * @interface LineItemOfPackageResponseDTO
 */
export interface LineItemOfPackageResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof LineItemOfPackageResponseDTO
     */
    sku: string;
    /**
     * 
     * @type {number}
     * @memberof LineItemOfPackageResponseDTO
     */
    amountInPackage: number;
}
/**
 * 
 * @export
 * @interface LineItemOfReceivingOrderQueryOutputDTO
 */
export interface LineItemOfReceivingOrderQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof LineItemOfReceivingOrderQueryOutputDTO
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof LineItemOfReceivingOrderQueryOutputDTO
     */
    acceptedQuantityDifference: number;
    /**
     * 
     * @type {boolean}
     * @memberof LineItemOfReceivingOrderQueryOutputDTO
     */
    isArrivedToWarehouse: boolean;
    /**
     * 
     * @type {string}
     * @memberof LineItemOfReceivingOrderQueryOutputDTO
     */
    productName: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemOfReceivingOrderQueryOutputDTO
     */
    imageUrl: string;
    /**
     * 
     * @type {number}
     * @memberof LineItemOfReceivingOrderQueryOutputDTO
     */
    acceptedQuantity?: number;
    /**
     * 
     * @type {Date}
     * @memberof LineItemOfReceivingOrderQueryOutputDTO
     */
    processedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof LineItemOfReceivingOrderQueryOutputDTO
     */
    sku: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LineItemOfReceivingOrderQueryOutputDTO
     */
    barcodes?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof LineItemOfReceivingOrderQueryOutputDTO
     */
    expectedQuantity: number;
    /**
     * 
     * @type {boolean}
     * @memberof LineItemOfReceivingOrderQueryOutputDTO
     */
    isUnexpected: boolean;
}
/**
 * 
 * @export
 * @interface LineItemOfReceivingOrderQueryOutputDTODynamicQueryOutputDTO
 */
export interface LineItemOfReceivingOrderQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<LineItemOfReceivingOrderQueryOutputDTO>}
     * @memberof LineItemOfReceivingOrderQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<LineItemOfReceivingOrderQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof LineItemOfReceivingOrderQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface LineItemOfReceivingOrderResponseDTO
 */
export interface LineItemOfReceivingOrderResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof LineItemOfReceivingOrderResponseDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemOfReceivingOrderResponseDTO
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemOfReceivingOrderResponseDTO
     */
    productName: string;
    /**
     * 
     * @type {number}
     * @memberof LineItemOfReceivingOrderResponseDTO
     */
    acceptedQuantity?: number;
    /**
     * 
     * @type {Date}
     * @memberof LineItemOfReceivingOrderResponseDTO
     */
    processedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof LineItemOfReceivingOrderResponseDTO
     */
    sku: string;
    /**
     * 
     * @type {number}
     * @memberof LineItemOfReceivingOrderResponseDTO
     */
    expectedQuantity: number;
}
/**
 * 
 * @export
 * @interface LineItemOfReturnQueryOutputDTO
 */
export interface LineItemOfReturnQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof LineItemOfReturnQueryOutputDTO
     */
    sku: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LineItemOfReturnQueryOutputDTO
     */
    barcodes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LineItemOfReturnQueryOutputDTO
     */
    name: string;
    /**
     * 
     * @type {ReturnLineItemState}
     * @memberof LineItemOfReturnQueryOutputDTO
     */
    state: ReturnLineItemState;
    /**
     * 
     * @type {Date}
     * @memberof LineItemOfReturnQueryOutputDTO
     */
    processedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof LineItemOfReturnQueryOutputDTO
     */
    serialNumber: string;
}
/**
 * 
 * @export
 * @interface LineItemOfReturnQueryOutputDTODynamicQueryOutputDTO
 */
export interface LineItemOfReturnQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<LineItemOfReturnQueryOutputDTO>}
     * @memberof LineItemOfReturnQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<LineItemOfReturnQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof LineItemOfReturnQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface LineItemOfSalesOrderQueryOutputDTO
 */
export interface LineItemOfSalesOrderQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof LineItemOfSalesOrderQueryOutputDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemOfSalesOrderQueryOutputDTO
     */
    sku: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LineItemOfSalesOrderQueryOutputDTO
     */
    barcodes: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LineItemOfSalesOrderQueryOutputDTO
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemOfSalesOrderQueryOutputDTO
     */
    productName: string;
    /**
     * 
     * @type {ProductType}
     * @memberof LineItemOfSalesOrderQueryOutputDTO
     */
    productType: ProductType;
    /**
     * 
     * @type {string}
     * @memberof LineItemOfSalesOrderQueryOutputDTO
     */
    productCategory: string;
    /**
     * 
     * @type {number}
     * @memberof LineItemOfSalesOrderQueryOutputDTO
     */
    amountInOrder: number;
    /**
     * 
     * @type {number}
     * @memberof LineItemOfSalesOrderQueryOutputDTO
     */
    missingQuantity: number;
    /**
     * 
     * @type {number}
     * @memberof LineItemOfSalesOrderQueryOutputDTO
     */
    availableStock: number;
    /**
     * 
     * @type {number}
     * @memberof LineItemOfSalesOrderQueryOutputDTO
     */
    cancelledAmount: number;
    /**
     * 
     * @type {boolean}
     * @memberof LineItemOfSalesOrderQueryOutputDTO
     */
    isCancellable: boolean;
    /**
     * 
     * @type {SalesOrderLineItemState}
     * @memberof LineItemOfSalesOrderQueryOutputDTO
     */
    state: SalesOrderLineItemState;
    /**
     * 
     * @type {number}
     * @memberof LineItemOfSalesOrderQueryOutputDTO
     */
    cancellableAmount: number;
    /**
     * 
     * @type {number}
     * @memberof LineItemOfSalesOrderQueryOutputDTO
     */
    packableAmount: number;
    /**
     * 
     * @type {boolean}
     * @memberof LineItemOfSalesOrderQueryOutputDTO
     */
    isPendingReason: boolean;
}
/**
 * 
 * @export
 * @interface LineItemOfSalesOrderQueryOutputDTODynamicQueryOutputDTO
 */
export interface LineItemOfSalesOrderQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<LineItemOfSalesOrderQueryOutputDTO>}
     * @memberof LineItemOfSalesOrderQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<LineItemOfSalesOrderQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof LineItemOfSalesOrderQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface LineItemOfSalesOrderResponseDTO
 */
export interface LineItemOfSalesOrderResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof LineItemOfSalesOrderResponseDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemOfSalesOrderResponseDTO
     */
    sku: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LineItemOfSalesOrderResponseDTO
     */
    barcodes?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof LineItemOfSalesOrderResponseDTO
     */
    salePrice?: number;
    /**
     * 
     * @type {Currency}
     * @memberof LineItemOfSalesOrderResponseDTO
     */
    currency?: Currency;
    /**
     * 
     * @type {string}
     * @memberof LineItemOfSalesOrderResponseDTO
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemOfSalesOrderResponseDTO
     */
    productName: string;
    /**
     * 
     * @type {ProductType}
     * @memberof LineItemOfSalesOrderResponseDTO
     */
    productType: ProductType;
    /**
     * 
     * @type {string}
     * @memberof LineItemOfSalesOrderResponseDTO
     */
    productCategory: string;
    /**
     * 
     * @type {number}
     * @memberof LineItemOfSalesOrderResponseDTO
     */
    amountInOrder: number;
    /**
     * 
     * @type {number}
     * @memberof LineItemOfSalesOrderResponseDTO
     */
    missingQuantity: number;
    /**
     * 
     * @type {number}
     * @memberof LineItemOfSalesOrderResponseDTO
     */
    cancelledAmount?: number;
}
/**
 * 
 * @export
 * @interface LineItemsPurchasedByCustomerQueryOutputDTO
 */
export interface LineItemsPurchasedByCustomerQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof LineItemsPurchasedByCustomerQueryOutputDTO
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof LineItemsPurchasedByCustomerQueryOutputDTO
     */
    favorite: boolean;
    /**
     * 
     * @type {number}
     * @memberof LineItemsPurchasedByCustomerQueryOutputDTO
     */
    totalSalesCount: number;
    /**
     * 
     * @type {string}
     * @memberof LineItemsPurchasedByCustomerQueryOutputDTO
     */
    sku: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LineItemsPurchasedByCustomerQueryOutputDTO
     */
    barcodes: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LineItemsPurchasedByCustomerQueryOutputDTO
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof LineItemsPurchasedByCustomerQueryOutputDTO
     */
    productName: string;
    /**
     * 
     * @type {ProductType}
     * @memberof LineItemsPurchasedByCustomerQueryOutputDTO
     */
    productType: ProductType;
    /**
     * 
     * @type {string}
     * @memberof LineItemsPurchasedByCustomerQueryOutputDTO
     */
    productCategory: string;
    /**
     * 
     * @type {number}
     * @memberof LineItemsPurchasedByCustomerQueryOutputDTO
     */
    amountInOrder: number;
}
/**
 * 
 * @export
 * @interface LineItemsPurchasedByCustomerQueryOutputDTODynamicQueryOutputDTO
 */
export interface LineItemsPurchasedByCustomerQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<LineItemsPurchasedByCustomerQueryOutputDTO>}
     * @memberof LineItemsPurchasedByCustomerQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<LineItemsPurchasedByCustomerQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof LineItemsPurchasedByCustomerQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum LogicalOperator {
    None = 'None',
    AndAlso = 'AndAlso',
    OrElse = 'OrElse',
    And = 'And',
    Or = 'Or',
    Xor = 'Xor'
}

/**
 * 
 * @export
 * @interface MaestroReceivingOrderAcceptedExpirationDateDTO
 */
export interface MaestroReceivingOrderAcceptedExpirationDateDTO {
    /**
     * 
     * @type {Date}
     * @memberof MaestroReceivingOrderAcceptedExpirationDateDTO
     */
    expirationDate: Date;
    /**
     * 
     * @type {number}
     * @memberof MaestroReceivingOrderAcceptedExpirationDateDTO
     */
    amount: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MaestroReceivingOrderExtendedState {
    None = 'None',
    Created = 'Created',
    ArrivedToWarehouse = 'ArrivedToWarehouse',
    InProgress = 'InProgress',
    Completed = 'Completed'
}

/**
 * 
 * @export
 * @interface MaestroReceivingOrderLineItemSyncDTO
 */
export interface MaestroReceivingOrderLineItemSyncDTO {
    /**
     * 
     * @type {string}
     * @memberof MaestroReceivingOrderLineItemSyncDTO
     */
    sku: string;
    /**
     * 
     * @type {number}
     * @memberof MaestroReceivingOrderLineItemSyncDTO
     */
    acceptedAmount: number;
    /**
     * 
     * @type {number}
     * @memberof MaestroReceivingOrderLineItemSyncDTO
     */
    damagedAmount: number;
    /**
     * 
     * @type {number}
     * @memberof MaestroReceivingOrderLineItemSyncDTO
     */
    delta: number;
    /**
     * 
     * @type {Array<MaestroReceivingOrderAcceptedExpirationDateDTO>}
     * @memberof MaestroReceivingOrderLineItemSyncDTO
     */
    acceptedExpirationDates?: Array<MaestroReceivingOrderAcceptedExpirationDateDTO>;
    /**
     * 
     * @type {string}
     * @memberof MaestroReceivingOrderLineItemSyncDTO
     */
    serialNumbers: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MaestroReceivingOrderState {
    None = 'None',
    Created = 'Created',
    InProgress = 'InProgress',
    Completed = 'Completed'
}

/**
 * 
 * @export
 * @interface MaestroReturnLineItem
 */
export interface MaestroReturnLineItem {
    /**
     * 
     * @type {string}
     * @memberof MaestroReturnLineItem
     */
    returnLineReference: string;
    /**
     * 
     * @type {string}
     * @memberof MaestroReturnLineItem
     */
    sku: string;
    /**
     * 
     * @type {Date}
     * @memberof MaestroReturnLineItem
     */
    processedAt?: Date;
    /**
     * 
     * @type {ReturnLineItemState}
     * @memberof MaestroReturnLineItem
     */
    state?: ReturnLineItemState;
}
/**
 * 
 * @export
 * @interface MeasurementDTO
 */
export interface MeasurementDTO {
    /**
     * 
     * @type {number}
     * @memberof MeasurementDTO
     */
    value?: number;
    /**
     * 
     * @type {string}
     * @memberof MeasurementDTO
     */
    type: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum NotificationChannels {
    Browser = 'Browser',
    MobileApp = 'MobileApp'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum NotificationProviders {
    Firebase = 'Firebase'
}

/**
 * 
 * @export
 * @interface NotifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO
 */
export interface NotifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO {
    /**
     * 
     * @type {string}
     * @memberof NotifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO
     */
    tenantName: string;
    /**
     * 
     * @type {string}
     * @memberof NotifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO
     */
    integrationId?: string;
    /**
     * 
     * @type {TimeRangeInputDTO}
     * @memberof NotifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO
     */
    notifyByTimeRange?: TimeRangeInputDTO;
    /**
     * 
     * @type {ReferenceNumbersInputDTO}
     * @memberof NotifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO
     */
    notifyByReferenceNumbers?: ReferenceNumbersInputDTO;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OmnitroErrorCode {
    ReferenceNumberAlreadyExists = 'ReferenceNumberAlreadyExists',
    EmptyAddressFirstName = 'EmptyAddressFirstName',
    EmptyAddressLastName = 'EmptyAddressLastName',
    EmptyAddressPhone = 'EmptyAddressPhone',
    EmptyAddressOne = 'EmptyAddressOne',
    EmptyAddressCity = 'EmptyAddressCity',
    EmptyAddressPostalCode = 'EmptyAddressPostalCode',
    EmptyAddressCountry = 'EmptyAddressCountry',
    EmptyCustomerFirstName = 'EmptyCustomerFirstName',
    EmptyCustomerLastName = 'EmptyCustomerLastName',
    EmptyCustomerPhone = 'EmptyCustomerPhone',
    CustomerEmailWrongFormat = 'CustomerEmailWrongFormat',
    EmptyLineItemSku = 'EmptyLineItemSku',
    LineItemQuantityError = 'LineItemQuantityError',
    LineItemNotFound = 'LineItemNotFound',
    EmptySalesOrderPaymentCurrency = 'EmptySalesOrderPaymentCurrency',
    EmptySalesOrderPaymentTotalPaymentAmount = 'EmptySalesOrderPaymentTotalPaymentAmount',
    EmptyCoDKey = 'EmptyCoDKey',
    EmptyOrMultipleCodKey = 'EmptyOrMultipleCodKey',
    CoDKeysNotEqualToPaymentGateway = 'CoDKeysNotEqualToPaymentGateway',
    SalesOrderPaidWhenCoDKeysExist = 'SalesOrderPaidWhenCoDKeysExist',
    SalesOrderNotPaidWhenCoDPassive = 'SalesOrderNotPaidWhenCoDPassive',
    UnsupportedCurrency = 'UnsupportedCurrency',
    CargoCarrierShouldBeUpsWhenCargoCodeNull = 'CargoCarrierShouldBeUpsWhenCargoCodeNull',
    CargoCarrierShouldNotBeEmptyWhenCargoCodeNotNull = 'CargoCarrierShouldNotBeEmptyWhenCargoCodeNotNull',
    UnhandledException = 'UnhandledException',
    UpdateFromDifferentIntegration = 'UpdateFromDifferentIntegration',
    ReferenceNumberTooLong = 'ReferenceNumberTooLong',
    SalesOrderHasLineItemsWithSameSku = 'SalesOrderHasLineItemsWithSameSku',
    NullReferenceNumberPrefix = 'NullReferenceNumberPrefix',
    ReferenceNumberPrefixLength = 'ReferenceNumberPrefixLength',
    ReferenceNumberPrefixExist = 'ReferenceNumberPrefixExist',
    CargoCarrierNotFound = 'CargoCarrierNotFound',
    InvalidSalesOrderReferenceNumber = 'InvalidSalesOrderReferenceNumber',
    InvalidReceivingOrderReferenceNumber = 'InvalidReceivingOrderReferenceNumber',
    MissingInformation = 'MissingInformation',
    InternationalCustomsProductPriceError = 'InternationalCustomsProductPriceError',
    ProductSkuAlreadyExists = 'ProductSkuAlreadyExists',
    EmptyReceivingOrderShipmentDate = 'EmptyReceivingOrderShipmentDate',
    InvalidReceivingOrderShipmentDate = 'InvalidReceivingOrderShipmentDate',
    TenantNameEmpty = 'TenantNameEmpty',
    ReceivingOrderReferenceNumberEmpty = 'ReceivingOrderReferenceNumberEmpty',
    ReceivingOrderExpectedDeliveryDateEmpty = 'ReceivingOrderExpectedDeliveryDateEmpty',
    ReceivingOrderArrivalStateEmpty = 'ReceivingOrderArrivalStateEmpty',
    CargoDocumentURLAndCargoCodeShouldBeEmptyWhenCargoTypeIsSameDayDelivery = 'CargoDocumentURLAndCargoCodeShouldBeEmptyWhenCargoTypeIsSameDayDelivery',
    InvalidCountry = 'InvalidCountry',
    InvalidCity = 'InvalidCity',
    InvalidDistrict = 'InvalidDistrict',
    InvalidUrl = 'InvalidUrl',
    InvalidAddress = 'InvalidAddress',
    IntegrationDoesNotSupportPayload = 'IntegrationDoesNotSupportPayload',
    GiftWrapType = 'GiftWrapType',
    GiftNotesTooLong = 'GiftNotesTooLong',
    InvalidExpectedShipmentDate = 'InvalidExpectedShipmentDate',
    EmptyExpectedShipmentDate = 'EmptyExpectedShipmentDate',
    EmptyIntegrationId = 'EmptyIntegrationId',
    InvalidReferenceNumber = 'InvalidReferenceNumber',
    SameDayDeliveryNotEnabled = 'SameDayDeliveryNotEnabled',
    OutOfCoverageSameDayDelivery = 'OutOfCoverageSameDayDelivery',
    ReceivingOrderTransferTypeEmpty = 'ReceivingOrderTransferTypeEmpty',
    CargoCodeAndCargoDocumentUrlCannotBeNullOrEmpty = 'CargoCodeAndCargoDocumentUrlCannotBeNullOrEmpty',
    CancelledSalesOrder = 'CancelledSalesOrder',
    SalesOrderDoesNotSupportCargoInfoUpdate = 'SalesOrderDoesNotSupportCargoInfoUpdate',
    DeliveredSalesOrder = 'DeliveredSalesOrder',
    AddressFirstNameMaxLenght = 'AddressFirstNameMaxLenght',
    AddressLastNameMaxLenght = 'AddressLastNameMaxLenght',
    AddressPhoneMaxLenght = 'AddressPhoneMaxLenght',
    AddressEmailMaxLenght = 'AddressEmailMaxLenght',
    AddressOneMaxLenght = 'AddressOneMaxLenght',
    AddressTwoMaxLenght = 'AddressTwoMaxLenght',
    AddressDistrictMaxLenght = 'AddressDistrictMaxLenght',
    AddressPostalCodeMaxLenght = 'AddressPostalCodeMaxLenght',
    AddressCityMaxLenght = 'AddressCityMaxLenght',
    AddressCountryMaxLenght = 'AddressCountryMaxLenght',
    InvalidCodReceiptTotalAmount = 'InvalidCodReceiptTotalAmount',
    MarketplaceNoCoD = 'MarketplaceNoCoD',
    InvalidCargoDocumentUrlForExcel = 'InvalidCargoDocumentUrlForExcel',
    InvalidCargoDocumentUrlForOpenApiIntegration = 'InvalidCargoDocumentUrlForOpenApiIntegration',
    InvalidVasDocumentUrlForExcel = 'InvalidVasDocumentUrlForExcel',
    InvalidVasDocumentUrlForOpenApiIntegration = 'InvalidVasDocumentUrlForOpenApiIntegration'
}

/**
 * 
 * @export
 * @interface OplogError
 */
export interface OplogError {
    /**
     * 
     * @type {ErrorCode}
     * @memberof OplogError
     */
    errorCode: ErrorCode;
}
/**
 * 
 * @export
 * @interface Package
 */
export interface Package {
    /**
     * 
     * @type {PackageState}
     * @memberof Package
     */
    state: PackageState;
    /**
     * 
     * @type {Date}
     * @memberof Package
     */
    pickedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Package
     */
    packedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Package
     */
    cancelledAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Package
     */
    readyToBeShippedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Package
     */
    shippedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Package
     */
    deliveredAt?: Date;
    /**
     * 
     * @type {PackageFulfillmentSyncState}
     * @memberof Package
     */
    packageFulfillmentSyncState: PackageFulfillmentSyncState;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    externalCode: string;
    /**
     * 
     * @type {number}
     * @memberof Package
     */
    packageFulfillmentSyncRetryCount: number;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    salesOrderId: string;
    /**
     * 
     * @type {WmsSyncState}
     * @memberof Package
     */
    wmsSyncState: WmsSyncState;
    /**
     * 
     * @type {number}
     * @memberof Package
     */
    wmsSyncRetryCount: number;
    /**
     * 
     * @type {SalesOrder}
     * @memberof Package
     */
    salesOrder?: SalesOrder;
    /**
     * 
     * @type {PackageAddress}
     * @memberof Package
     */
    shippingAddress?: PackageAddress;
    /**
     * 
     * @type {PackagePayment}
     * @memberof Package
     */
    payment?: PackagePayment;
    /**
     * 
     * @type {PackageCargo}
     * @memberof Package
     */
    cargo?: PackageCargo;
    /**
     * 
     * @type {PackageVAS}
     * @memberof Package
     */
    vas?: PackageVAS;
    /**
     * 
     * @type {Array<SalesOrderLineItem>}
     * @memberof Package
     */
    lineItems?: Array<SalesOrderLineItem>;
    /**
     * 
     * @type {Array<PackageSerialNumber>}
     * @memberof Package
     */
    packageSerialNumbers?: Array<PackageSerialNumber>;
    /**
     * 
     * @type {Date}
     * @memberof Package
     */
    etag?: Date;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof Package
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Package
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Package
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @interface PackageAddress
 */
export interface PackageAddress {
    /**
     * 
     * @type {string}
     * @memberof PackageAddress
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PackageAddress
     */
    lastName: string;
    /**
     * 
     * @type {BaseAddress}
     * @memberof PackageAddress
     */
    baseAddress?: BaseAddress;
    /**
     * 
     * @type {string}
     * @memberof PackageAddress
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof PackageAddress
     */
    company: string;
    /**
     * 
     * @type {string}
     * @memberof PackageAddress
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof PackageAddress
     */
    fullAddress: string;
    /**
     * 
     * @type {number}
     * @memberof PackageAddress
     */
    referenceNumber: number;
    /**
     * 
     * @type {string}
     * @memberof PackageAddress
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof PackageAddress
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof PackageAddress
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PackageAddress
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PackageAddress
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof PackageAddress
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof PackageAddress
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @interface PackageCargo
 */
export interface PackageCargo {
    /**
     * 
     * @type {string}
     * @memberof PackageCargo
     */
    shippingCompany: string;
    /**
     * 
     * @type {string}
     * @memberof PackageCargo
     */
    shippingTrackingId: string;
    /**
     * 
     * @type {string}
     * @memberof PackageCargo
     */
    shippingState: string;
    /**
     * 
     * @type {number}
     * @memberof PackageCargo
     */
    requestedCargoCarrier?: number;
    /**
     * 
     * @type {string}
     * @memberof PackageCargo
     */
    cargoDocumentUrl: string;
    /**
     * 
     * @type {string}
     * @memberof PackageCargo
     */
    cargoCode: string;
    /**
     * 
     * @type {CargoType}
     * @memberof PackageCargo
     */
    cargoType: CargoType;
    /**
     * 
     * @type {Date}
     * @memberof PackageCargo
     */
    expectedShipmentDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PackageCargo
     */
    shipmentTrackingLinkUrl: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PackageCreationType {
    ByQuantity = 'ByQuantity',
    BySKU = 'BySKU',
    ByWholeLineItems = 'ByWholeLineItems'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum PackageFulfillmentSyncState {
    None = 'None',
    FulfillmentFailed = 'FulfillmentFailed',
    FulfillmentSucceeded = 'FulfillmentSucceeded'
}

/**
 * 
 * @export
 * @interface PackageOutputDTO
 */
export interface PackageOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof PackageOutputDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PackageOutputDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {PackageState}
     * @memberof PackageOutputDTO
     */
    state: PackageState;
    /**
     * 
     * @type {string}
     * @memberof PackageOutputDTO
     */
    shippingCompany: string;
    /**
     * 
     * @type {string}
     * @memberof PackageOutputDTO
     */
    shippingTrackingId: string;
    /**
     * 
     * @type {string}
     * @memberof PackageOutputDTO
     */
    cargoDocumentUrl: string;
    /**
     * 
     * @type {boolean}
     * @memberof PackageOutputDTO
     */
    isCancellable: boolean;
    /**
     * 
     * @type {Date}
     * @memberof PackageOutputDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof PackageOutputDTO
     */
    trackingUrl: string;
    /**
     * 
     * @type {Date}
     * @memberof PackageOutputDTO
     */
    expectedShipmentDate: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PackageOutputDTO
     */
    isExternalContract: boolean;
     /**
     * 
     * @type {string}
     * @memberof PackageOutputDTO
     */
     totalPackageCount: string;
     /**
     * 
     * @type {string}
     * @memberof PackageOutputDTO
     */
     invoiceURL: string;
}
/**
 * 
 * @export
 * @interface PackageOutputDTODynamicQueryOutputDTO
 */
export interface PackageOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<PackageOutputDTO>}
     * @memberof PackageOutputDTODynamicQueryOutputDTO
     */
    data?: Array<PackageOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof PackageOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface PackagePayment
 */
export interface PackagePayment {
    /**
     * 
     * @type {PaymentOption}
     * @memberof PackagePayment
     */
    paymentOption: PaymentOption;
    /**
     * 
     * @type {number}
     * @memberof PackagePayment
     */
    totalPaymentAmount?: number;
    /**
     * 
     * @type {Currency}
     * @memberof PackagePayment
     */
    currency?: Currency;
}
/**
 * 
 * @export
 * @interface PackageReportResponseDTO
 */
export interface PackageReportResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof PackageReportResponseDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PackageReportResponseDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof PackageReportResponseDTO
     */
    salesChannel: string;
    /**
     * 
     * @type {PackageState}
     * @memberof PackageReportResponseDTO
     */
    state: PackageState;
    /**
     * 
     * @type {Date}
     * @memberof PackageReportResponseDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof PackageReportResponseDTO
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PackageReportResponseDTO
     */
    orderCreatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof PackageReportResponseDTO
     */
    pickedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PackageReportResponseDTO
     */
    packedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PackageReportResponseDTO
     */
    cancelledAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PackageReportResponseDTO
     */
    shippedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PackageReportResponseDTO
     */
    deliveredAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PackageReportResponseDTO
     */
    shippingCompany: string;
    /**
     * 
     * @type {string}
     * @memberof PackageReportResponseDTO
     */
    shippingTrackingId: string;
    /**
     * 
     * @type {string}
     * @memberof PackageReportResponseDTO
     */
    shippingState: string;
    /**
     * 
     * @type {string}
     * @memberof PackageReportResponseDTO
     */
    requestedCargoCarrier: string;
    /**
     * 
     * @type {string}
     * @memberof PackageReportResponseDTO
     */
    cargoDocumentUrl: string;
    /**
     * 
     * @type {string}
     * @memberof PackageReportResponseDTO
     */
    cargoCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof PackageReportResponseDTO
     */
    gifftWrap: boolean;
    /**
     * 
     * @type {string}
     * @memberof PackageReportResponseDTO
     */
    giftNotes: string;
    /**
     * 
     * @type {AddressReportResponseDTO}
     * @memberof PackageReportResponseDTO
     */
    shippingAddress?: AddressReportResponseDTO;
    /**
     * 
     * @type {Array<LineItemOfPackageReportResponseDTO>}
     * @memberof PackageReportResponseDTO
     */
    lineItems?: Array<LineItemOfPackageReportResponseDTO>;
}
/**
 * 
 * @export
 * @interface PackageSerialNumber
 */
export interface PackageSerialNumber {
    /**
     * 
     * @type {Package}
     * @memberof PackageSerialNumber
     */
    _package?: Package;
    /**
     * 
     * @type {string}
     * @memberof PackageSerialNumber
     */
    packageId: string;
    /**
     * 
     * @type {Product}
     * @memberof PackageSerialNumber
     */
    product?: Product;
    /**
     * 
     * @type {string}
     * @memberof PackageSerialNumber
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof PackageSerialNumber
     */
    sku: string;
    /**
     * 
     * @type {string}
     * @memberof PackageSerialNumber
     */
    serialNumbers: string;
    /**
     * 
     * @type {string}
     * @memberof PackageSerialNumber
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof PackageSerialNumber
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof PackageSerialNumber
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof PackageSerialNumber
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PackageSerialNumber
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PackageSerialNumber
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof PackageSerialNumber
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof PackageSerialNumber
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PackageState {
    Created = 'Created',
    Picked = 'Picked',
    Packed = 'Packed',
    ReadyToBeShipped = 'ReadyToBeShipped',
    Shipped = 'Shipped',
    Delivered = 'Delivered',
    Cancelled = 'Cancelled',
}

/**
 * 
 * @export
 * @interface PackageVAS
 */
export interface PackageVAS {
    /**
     * 
     * @type {string}
     * @memberof PackageVAS
     */
    packageNote: string;
    /**
     * 
     * @type {SalesOrderGiftVAS}
     * @memberof PackageVAS
     */
    giftVAS?: SalesOrderGiftVAS;
    /**
     * 
     * @type {SalesOrderDocumentVAS}
     * @memberof PackageVAS
     */
    documentVAS?: SalesOrderDocumentVAS;
}
/**
 * 
 * @export
 * @interface PackagesOfTenantQueryOutputDTO
 */
export interface PackagesOfTenantQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof PackagesOfTenantQueryOutputDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PackagesOfTenantQueryOutputDTO
     */
    salesOrderId: string;
    /**
     * 
     * @type {string}
     * @memberof PackagesOfTenantQueryOutputDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {PackageState}
     * @memberof PackagesOfTenantQueryOutputDTO
     */
    state: PackageState;
    /**
     * 
     * @type {Date}
     * @memberof PackagesOfTenantQueryOutputDTO
     */
    pickedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PackagesOfTenantQueryOutputDTO
     */
    packedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PackagesOfTenantQueryOutputDTO
     */
    shippedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PackagesOfTenantQueryOutputDTO
     */
    deliveredAt?: Date;
    /**
     * 
     * @type {WmsSyncState}
     * @memberof PackagesOfTenantQueryOutputDTO
     */
    wmsSyncState: WmsSyncState;
    /**
     * 
     * @type {number}
     * @memberof PackagesOfTenantQueryOutputDTO
     */
    wmsSyncRetryCount: number;
    /**
     * 
     * @type {PackageFulfillmentSyncState}
     * @memberof PackagesOfTenantQueryOutputDTO
     */
    packageFulfillmentSyncState: PackageFulfillmentSyncState;
    /**
     * 
     * @type {number}
     * @memberof PackagesOfTenantQueryOutputDTO
     */
    packageFulfillmentSyncRetryCount: number;
    /**
     * 
     * @type {Date}
     * @memberof PackagesOfTenantQueryOutputDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof PackagesOfTenantQueryOutputDTO
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PackagesOfTenantQueryOutputDTO
     */
    cancelledAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PackagesOfTenantQueryOutputDTO
     */
    deletedAt?: Date;
    /**
     * 
     * @type {PackageState}
     * @memberof PackagesOfTenantQueryOutputDTO
     */
    lastCompletedState: PackageState;
}
/**
 * 
 * @export
 * @interface PackagesSyncFromWmsInputDTO
 */
export interface PackagesSyncFromWmsInputDTO {
    /**
     * 
     * @type {FetchPackagesByTimeRange}
     * @memberof PackagesSyncFromWmsInputDTO
     */
    fetchByTimeRangeMethod?: FetchPackagesByTimeRange;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentMethod {
    Transfer = 'Transfer'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentOption {
    Paid = 'Paid',
    Cash = 'Cash',
    CreditCard = 'CreditCard'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentPeriod {
    Weekly = 'Weekly',
    Monthly = 'Monthly'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentState {
    Paid = 'Paid',
    Overdue = 'Overdue',
    Future = 'Future'
}

/**
 * 
 * @export
 * @interface PickupTransferDTO
 */
export interface PickupTransferDTO {
    /**
     * 
     * @type {Date}
     * @memberof PickupTransferDTO
     */
    pickupDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PickupTransferDTO
     */
    pickupAddress?: string;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    title: string;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    status?: number;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    detail: string;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    instance: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProblemDetails
     */
    extensions?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {InventoryItem}
     * @memberof Product
     */
    inventoryItem?: InventoryItem;
    /**
     * 
     * @type {Array<InventoryReservation>}
     * @memberof Product
     */
    inventoryReservations?: Array<InventoryReservation>;
    /**
     * 
     * @type {Array<SalesOrderLineItem>}
     * @memberof Product
     */
    salesOrderLineItems?: Array<SalesOrderLineItem>;
    /**
     * 
     * @type {Array<ReturnLineItem>}
     * @memberof Product
     */
    returnLineItems?: Array<ReturnLineItem>;
    /**
     * 
     * @type {Array<ReceivingOrderLineItem>}
     * @memberof Product
     */
    receivingOrderLineItems?: Array<ReceivingOrderLineItem>;
    /**
     * 
     * @type {Array<ReceivingOrderLineItemExpirationDate>}
     * @memberof Product
     */
    receivingOrderLineItemExpirationDates?: Array<ReceivingOrderLineItemExpirationDate>;
    /**
     * 
     * @type {Array<InventoryItemExpirationDate>}
     * @memberof Product
     */
    inventoryItemExpirationDates?: Array<InventoryItemExpirationDate>;
    /**
     * 
     * @type {Tenant}
     * @memberof Product
     */
    tenant?: Tenant;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    sku: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    externalCode: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    salePrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    taxRatio?: number;
    /**
     * 
     * @type {Currency}
     * @memberof Product
     */
    currency?: Currency;
    /**
     * 
     * @type {Array<ProductBarcode>}
     * @memberof Product
     */
    barcodes?: Array<ProductBarcode>;
    /**
     * 
     * @type {Array<ProductSerialNumber>}
     * @memberof Product
     */
    serialNumbers?: Array<ProductSerialNumber>;
    /**
     * 
     * @type {Array<PackageSerialNumber>}
     * @memberof Product
     */
    packageSerialNumbers?: Array<PackageSerialNumber>;
    /**
     * 
     * @type {ProductType}
     * @memberof Product
     */
    type: ProductType;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    category: string;
    /**
     * 
     * @type {ProductState}
     * @memberof Product
     */
    state: ProductState;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    integrationInventoryItemId: string;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    favorite: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    lastEventTime?: Date;
    /**
     * 
     * @type {Integration}
     * @memberof Product
     */
    integration?: Integration;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    integrationId?: string;
    /**
     * 
     * @type {WmsSyncState}
     * @memberof Product
     */
    wmsSyncState: WmsSyncState;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    wmsSyncDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    wmsSyncRetryCount: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    referenceNumber: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @interface ProductBarcode
 */
export interface ProductBarcode {
    /**
     * 
     * @type {Product}
     * @memberof ProductBarcode
     */
    product?: Product;
    /**
     * 
     * @type {string}
     * @memberof ProductBarcode
     */
    sku: string;
    /**
     * 
     * @type {string}
     * @memberof ProductBarcode
     */
    barcode: string;
    /**
     * 
     * @type {string}
     * @memberof ProductBarcode
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof ProductBarcode
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof ProductBarcode
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductBarcode
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductBarcode
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProductBarcode
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof ProductBarcode
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof ProductBarcode
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @interface ProductDetailOutputDTO
 */
export interface ProductDetailOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof ProductDetailOutputDTO
     */
    id: string;
    /**
     * 
     * @type {ProductState}
     * @memberof ProductDetailOutputDTO
     */
    state: ProductState;
    /**
     * 
     * @type {Date}
     * @memberof ProductDetailOutputDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductDetailOutputDTO
     */
    updatedAt?: Date;
    /**
     * 
     * @type {IntegrationType}
     * @memberof ProductDetailOutputDTO
     */
    integrationType?: IntegrationType;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailOutputDTO
     */
    integrationName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDetailOutputDTO
     */
    sioc?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailOutputDTO
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailOutputDTO
     */
    length?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailOutputDTO
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailOutputDTO
     */
    weight?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailOutputDTO
     */
    unitOfWeight?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailOutputDTO
     */
    unitOfDimension?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailOutputDTO
     */
    stockAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailOutputDTO
     */
    sku: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailOutputDTO
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailOutputDTO
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductDetailOutputDTO
     */
    barcodes: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailOutputDTO
     */
    reservedAmount?: number;
    /**
     * 
     * @type {ProductType}
     * @memberof ProductDetailOutputDTO
     */
    type: ProductType;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailOutputDTO
     */
    category: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailOutputDTO
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailOutputDTO
     */
    salePrice?: number;
    /**
     * 
     * @type {Currency}
     * @memberof ProductDetailOutputDTO
     */
    currency?: Currency;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailOutputDTO
     */
    taxRatio?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailOutputDTO
     */
    salesUrl: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailOutputDTO
     */
    availableStockAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailOutputDTO
     */
    receivedItemAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailOutputDTO
     */
    damagedItemAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailOutputDTO
     */
    lostItemAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailOutputDTO
     */
    expiredAmount?: number;
    /**
     * 
     * @type {OpenApiIdentifier}
     * @memberof ProductDetailOutputDTO
     */
    openApiIdentifier: OpenApiIdentifier;
}
/**
 * 
 * @export
 * @interface ProductDetailResponseDTO
 */
export interface ProductDetailResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof ProductDetailResponseDTO
     */
    id: string;
    /**
     * 
     * @type {ProductState}
     * @memberof ProductDetailResponseDTO
     */
    state: ProductState;
    /**
     * 
     * @type {Date}
     * @memberof ProductDetailResponseDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductDetailResponseDTO
     */
    updatedAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDetailResponseDTO
     */
    favorite: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailResponseDTO
     */
    sku: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailResponseDTO
     */
    stockAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailResponseDTO
     */
    availableStock?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailResponseDTO
     */
    receivedItemAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailResponseDTO
     */
    damagedItemAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailResponseDTO
     */
    expiredAmount?: number;
    /**
     * 
     * @type {IntegrationDetailResponseDTO}
     * @memberof ProductDetailResponseDTO
     */
    integration?: IntegrationDetailResponseDTO;
    /**
     * 
     * @type {MeasurementDTO}
     * @memberof ProductDetailResponseDTO
     */
    width?: MeasurementDTO;
    /**
     * 
     * @type {MeasurementDTO}
     * @memberof ProductDetailResponseDTO
     */
    length?: MeasurementDTO;
    /**
     * 
     * @type {MeasurementDTO}
     * @memberof ProductDetailResponseDTO
     */
    height?: MeasurementDTO;
    /**
     * 
     * @type {MeasurementDTO}
     * @memberof ProductDetailResponseDTO
     */
    weight?: MeasurementDTO;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailResponseDTO
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailResponseDTO
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductDetailResponseDTO
     */
    barcodes?: Array<string>;
    /**
     * 
     * @type {ProductType}
     * @memberof ProductDetailResponseDTO
     */
    type: ProductType;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailResponseDTO
     */
    category: string;
}
/**
 * 
 * @export
 * @interface ProductDetailResponseDTODynamicQueryPagedDTO
 */
export interface ProductDetailResponseDTODynamicQueryPagedDTO {
    /**
     * 
     * @type {Array<ProductDetailResponseDTO>}
     * @memberof ProductDetailResponseDTODynamicQueryPagedDTO
     */
    items?: Array<ProductDetailResponseDTO>;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailResponseDTODynamicQueryPagedDTO
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailResponseDTODynamicQueryPagedDTO
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailResponseDTODynamicQueryPagedDTO
     */
    dataSetCount: number;
}
/**
 * 
 * @export
 * @interface ProductFavoriteOutputDTO
 */
export interface ProductFavoriteOutputDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ProductFavoriteOutputDTO
     */
    favorite: boolean;
}
/**
 * 
 * @export
 * @interface ProductQueryOutputDTO
 */
export interface ProductQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof ProductQueryOutputDTO
     */
    id: string;
    /**
     * 
     * @type {ProductState}
     * @memberof ProductQueryOutputDTO
     */
    state: ProductState;
    /**
     * 
     * @type {Date}
     * @memberof ProductQueryOutputDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ProductQueryOutputDTO
     */
    favorite: boolean;
    /**
     * 
     * @type {IntegrationType}
     * @memberof ProductQueryOutputDTO
     */
    integrationType?: IntegrationType;
    /**
     * 
     * @type {string}
     * @memberof ProductQueryOutputDTO
     */
    integrationName?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductQueryOutputDTO
     */
    stockAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductQueryOutputDTO
     */
    availableAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductQueryOutputDTO
     */
    receivedItemAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductQueryOutputDTO
     */
    damagedItemAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductQueryOutputDTO
     */
    expiredAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductQueryOutputDTO
     */
    sku: string;
    /**
     * 
     * @type {string}
     * @memberof ProductQueryOutputDTO
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof ProductQueryOutputDTO
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductQueryOutputDTO
     */
    barcodes?: Array<string>;
    /**
     * 
     * @type {ProductType}
     * @memberof ProductQueryOutputDTO
     */
    type: ProductType;
    /**
     * 
     * @type {string}
     * @memberof ProductQueryOutputDTO
     */
    category: string;
    /**
     * 
     * @type {OpenApiIdentifier}
     * @memberof ProductQueryOutputDTO
     */
    openApiIdentifier: OpenApiIdentifier;
}
/**
 * 
 * @export
 * @interface ProductQueryOutputDTODynamicQueryOutputDTO
 */
export interface ProductQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<ProductQueryOutputDTO>}
     * @memberof ProductQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<ProductQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof ProductQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface ProductSerialNumber
 */
export interface ProductSerialNumber {
    /**
     * 
     * @type {Product}
     * @memberof ProductSerialNumber
     */
    product?: Product;
    /**
     * 
     * @type {string}
     * @memberof ProductSerialNumber
     */
    sku: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSerialNumber
     */
    serialNumbers: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSerialNumber
     */
    productId: string;
    /**
     * 
     * @type {InventoryItemType}
     * @memberof ProductSerialNumber
     */
    inventoryItemType: InventoryItemType;
    /**
     * 
     * @type {string}
     * @memberof ProductSerialNumber
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSerialNumber
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof ProductSerialNumber
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductSerialNumber
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductSerialNumber
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProductSerialNumber
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSerialNumber
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSerialNumber
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ProductState {
    Active = 'Active',
    Disabled = 'Disabled',
    Deleted = 'Deleted'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ProductType {
    Product = 'Product',
    Bundle = 'Bundle'
}

/**
 * 
 * @export
 * @interface ProductsOfTenantQueryOutputDTO
 */
export interface ProductsOfTenantQueryOutputDTO {
    /**
     * 
     * @type {ProductState}
     * @memberof ProductsOfTenantQueryOutputDTO
     */
    state: ProductState;
    /**
     * 
     * @type {Date}
     * @memberof ProductsOfTenantQueryOutputDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {IntegrationType}
     * @memberof ProductsOfTenantQueryOutputDTO
     */
    integrationType?: IntegrationType;
    /**
     * 
     * @type {string}
     * @memberof ProductsOfTenantQueryOutputDTO
     */
    integrationName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductsOfTenantQueryOutputDTO
     */
    sku: string;
    /**
     * 
     * @type {string}
     * @memberof ProductsOfTenantQueryOutputDTO
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductsOfTenantQueryOutputDTO
     */
    barcodes: Array<string>;
    /**
     * 
     * @type {WmsSyncState}
     * @memberof ProductsOfTenantQueryOutputDTO
     */
    wmsSyncState: WmsSyncState;
    /**
     * 
     * @type {number}
     * @memberof ProductsOfTenantQueryOutputDTO
     */
    wmsSyncRetryCount: number;
}
/**
 * 
 * @export
 * @interface ReceivingOrder
 */
export interface ReceivingOrder {
    /**
     * 
     * @type {Array<ReceivingOrderLineItem>}
     * @memberof ReceivingOrder
     */
    lineItems?: Array<ReceivingOrderLineItem>;
    /**
     * 
     * @type {Array<StringInt32Tuple>}
     * @memberof ReceivingOrder
     */
    lineItemCustomHashes?: Array<StringInt32Tuple>;
    /**
     * 
     * @type {Tenant}
     * @memberof ReceivingOrder
     */
    tenant?: Tenant;
    /**
     * 
     * @type {BaseTransfer}
     * @memberof ReceivingOrder
     */
    transferDetails?: BaseTransfer;
    /**
     * 
     * @type {ReceivingOrderState}
     * @memberof ReceivingOrder
     */
    state: ReceivingOrderState;
    /**
     * 
     * @type {Date}
     * @memberof ReceivingOrder
     */
    arrivedToWarehouseAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReceivingOrder
     */
    completedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReceivingOrder
     */
    processedAt: Date;
    /**
     * 
     * @type {ReceivingOrderTransferType}
     * @memberof ReceivingOrder
     */
    transferType: ReceivingOrderTransferType;
    /**
     * 
     * @type {Date}
     * @memberof ReceivingOrder
     */
    cancelledAt?: Date;
    /**
     * 
     * @type {ReceivingOrderArrivalState}
     * @memberof ReceivingOrder
     */
    arrivalState: ReceivingOrderArrivalState;
    /**
     * 
     * @type {Array<WayBill>}
     * @memberof ReceivingOrder
     */
    wayBills?: Array<WayBill>;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrder
     */
    externalCode: string;
    /**
     * 
     * @type {Integration}
     * @memberof ReceivingOrder
     */
    integration?: Integration;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrder
     */
    integrationId?: string;
    /**
     * 
     * @type {WmsSyncState}
     * @memberof ReceivingOrder
     */
    wmsSyncState: WmsSyncState;
    /**
     * 
     * @type {Date}
     * @memberof ReceivingOrder
     */
    wmsSyncDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof ReceivingOrder
     */
    wmsSyncRetryCount: number;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrder
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrder
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrder
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof ReceivingOrder
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReceivingOrder
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReceivingOrder
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrder
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrder
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrder
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ReceivingOrderArrivalState {
    None = 'None',
    Planned = 'Planned',
    ArrivedBeforeExpectedTime = 'ArrivedBeforeExpectedTime',
    ArrivedAfterExpectedTime = 'ArrivedAfterExpectedTime',
    Unexpected = 'Unexpected'
}

/**
 * 
 * @export
 * @interface ReceivingOrderDetailOutputDTO
 */
export interface ReceivingOrderDetailOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderDetailOutputDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderDetailOutputDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {ReceivingOrderState}
     * @memberof ReceivingOrderDetailOutputDTO
     */
    state: ReceivingOrderState;
    /**
     * 
     * @type {ReceivingOrderTransferType}
     * @memberof ReceivingOrderDetailOutputDTO
     */
    transferType: ReceivingOrderTransferType;
    /**
     * 
     * @type {ReceivingOrderTransferDTO}
     * @memberof ReceivingOrderDetailOutputDTO
     */
    transferDetails?: ReceivingOrderTransferDTO;
    /**
     * 
     * @type {IntegrationType}
     * @memberof ReceivingOrderDetailOutputDTO
     */
    integrationType?: IntegrationType;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderDetailOutputDTO
     */
    integrationName?: string;
    /**
     * 
     * @type {OpenApiIdentifier}
     * @memberof ReceivingOrderDetailOutputDTO
     */
    openApiIdentifier: OpenApiIdentifier;
}
/**
 * 
 * @export
 * @interface ReceivingOrderDetailResponseDTO
 */
export interface ReceivingOrderDetailResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderDetailResponseDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderDetailResponseDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {ReceivingOrderState}
     * @memberof ReceivingOrderDetailResponseDTO
     */
    state: ReceivingOrderState;
    /**
     * 
     * @type {ReceivingOrderTransferType}
     * @memberof ReceivingOrderDetailResponseDTO
     */
    transferType: ReceivingOrderTransferType;
    /**
     * 
     * @type {IntegrationDetailResponseDTO}
     * @memberof ReceivingOrderDetailResponseDTO
     */
    integration?: IntegrationDetailResponseDTO;
    /**
     * 
     * @type {ReceivingOrderTransferDTO}
     * @memberof ReceivingOrderDetailResponseDTO
     */
    transferDetails?: ReceivingOrderTransferDTO;
    /**
     * 
     * @type {Array<LineItemOfReceivingOrderResponseDTO>}
     * @memberof ReceivingOrderDetailResponseDTO
     */
    lineItems?: Array<LineItemOfReceivingOrderResponseDTO>;
    /**
     * 
     * @type {Array<WayBillOfReceivingOrderResponseDTO>}
     * @memberof ReceivingOrderDetailResponseDTO
     */
    wayBills?: Array<WayBillOfReceivingOrderResponseDTO>;
}
/**
 * 
 * @export
 * @interface ReceivingOrderLineItem
 */
export interface ReceivingOrderLineItem {
    /**
     * 
     * @type {ReceivingOrder}
     * @memberof ReceivingOrderLineItem
     */
    receivingOrder?: ReceivingOrder;
    /**
     * 
     * @type {Product}
     * @memberof ReceivingOrderLineItem
     */
    product?: Product;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderLineItem
     */
    receivingOrderId: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderLineItem
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderLineItem
     */
    sku: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderLineItem
     */
    serialNumbers: string;
    /**
     * 
     * @type {number}
     * @memberof ReceivingOrderLineItem
     */
    expectedQuantity: number;
    /**
     * 
     * @type {number}
     * @memberof ReceivingOrderLineItem
     */
    acceptedQuantity?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ReceivingOrderLineItem
     */
    isUnexpected: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ReceivingOrderLineItem
     */
    processedAt: Date;
    /**
     * 
     * @type {Array<ReceivingOrderLineItemExpirationDate>}
     * @memberof ReceivingOrderLineItem
     */
    lineItemExpirationDates?: Array<ReceivingOrderLineItemExpirationDate>;
    /**
     * 
     * @type {Date}
     * @memberof ReceivingOrderLineItem
     */
    etag?: Date;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderLineItem
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderLineItem
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof ReceivingOrderLineItem
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReceivingOrderLineItem
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReceivingOrderLineItem
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderLineItem
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderLineItem
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderLineItem
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @interface ReceivingOrderLineItemExpirationDate
 */
export interface ReceivingOrderLineItemExpirationDate {
    /**
     * 
     * @type {ReceivingOrderLineItem}
     * @memberof ReceivingOrderLineItemExpirationDate
     */
    receivingOrderLineItem?: ReceivingOrderLineItem;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderLineItemExpirationDate
     */
    receivingOrderLineItemId: string;
    /**
     * 
     * @type {Product}
     * @memberof ReceivingOrderLineItemExpirationDate
     */
    product?: Product;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderLineItemExpirationDate
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderLineItemExpirationDate
     */
    sku: string;
    /**
     * 
     * @type {number}
     * @memberof ReceivingOrderLineItemExpirationDate
     */
    quantity: number;
    /**
     * 
     * @type {Date}
     * @memberof ReceivingOrderLineItemExpirationDate
     */
    expirationDate: Date;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderLineItemExpirationDate
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderLineItemExpirationDate
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof ReceivingOrderLineItemExpirationDate
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReceivingOrderLineItemExpirationDate
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReceivingOrderLineItemExpirationDate
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderLineItemExpirationDate
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderLineItemExpirationDate
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderLineItemExpirationDate
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @interface ReceivingOrderLineItemSerialNumbersQueryOutputDTO
 */
export interface ReceivingOrderLineItemSerialNumbersQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderLineItemSerialNumbersQueryOutputDTO
     */
    sku: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReceivingOrderLineItemSerialNumbersQueryOutputDTO
     */
    barcodes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderLineItemSerialNumbersQueryOutputDTO
     */
    serialNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderLineItemSerialNumbersQueryOutputDTO
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ReceivingOrderLineItemSerialNumbersQueryOutputDTODynamicQueryOutputDTO
 */
export interface ReceivingOrderLineItemSerialNumbersQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<ReceivingOrderLineItemSerialNumbersQueryOutputDTO>}
     * @memberof ReceivingOrderLineItemSerialNumbersQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<ReceivingOrderLineItemSerialNumbersQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof ReceivingOrderLineItemSerialNumbersQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface ReceivingOrderOfProductQueryOutputDTO
 */
export interface ReceivingOrderOfProductQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderOfProductQueryOutputDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderOfProductQueryOutputDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {ReceivingOrderState}
     * @memberof ReceivingOrderOfProductQueryOutputDTO
     */
    state: ReceivingOrderState;
    /**
     * 
     * @type {ReceivingOrderState}
     * @memberof ReceivingOrderOfProductQueryOutputDTO
     */
    lastCompletedState: ReceivingOrderState;
    /**
     * 
     * @type {ReceivingOrderTransferType}
     * @memberof ReceivingOrderOfProductQueryOutputDTO
     */
    transferType: ReceivingOrderTransferType;
    /**
     * 
     * @type {number}
     * @memberof ReceivingOrderOfProductQueryOutputDTO
     */
    acceptedQuantity: number;
    /**
     * 
     * @type {Date}
     * @memberof ReceivingOrderOfProductQueryOutputDTO
     */
    createdAt: Date;
}
/**
 * 
 * @export
 * @interface ReceivingOrderOfProductQueryOutputDTODynamicQueryOutputDTO
 */
export interface ReceivingOrderOfProductQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<ReceivingOrderOfProductQueryOutputDTO>}
     * @memberof ReceivingOrderOfProductQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<ReceivingOrderOfProductQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof ReceivingOrderOfProductQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface ReceivingOrderQueryOutputDTO
 */
export interface ReceivingOrderQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderQueryOutputDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderQueryOutputDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {ReceivingOrderState}
     * @memberof ReceivingOrderQueryOutputDTO
     */
    state: ReceivingOrderState;
    /**
     * 
     * @type {ReceivingOrderTransferType}
     * @memberof ReceivingOrderQueryOutputDTO
     */
    transferType: ReceivingOrderTransferType;
    /**
     * 
     * @type {number}
     * @memberof ReceivingOrderQueryOutputDTO
     */
    productTypeCount: number;
    /**
     * 
     * @type {number}
     * @memberof ReceivingOrderQueryOutputDTO
     */
    productCount: number;
    /**
     * 
     * @type {Date}
     * @memberof ReceivingOrderQueryOutputDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {IntegrationType}
     * @memberof ReceivingOrderQueryOutputDTO
     */
    integrationType?: IntegrationType;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderQueryOutputDTO
     */
    integrationName?: string;
    /**
     * 
     * @type {ReceivingOrderState}
     * @memberof ReceivingOrderQueryOutputDTO
     */
    lastCompletedState: ReceivingOrderState;
    /**
     * 
     * @type {number}
     * @memberof ReceivingOrderQueryOutputDTO
     */
    acceptedQuantity: number;
    /**
     * 
     * @type {number}
     * @memberof ReceivingOrderQueryOutputDTO
     */
    acceptedQuantityDifference: number;
    /**
     * 
     * @type {boolean}
     * @memberof ReceivingOrderQueryOutputDTO
     */
    isArrivedToWarehouse: boolean;
    /**
     * 
     * @type {ReceivingOrderArrivalState}
     * @memberof ReceivingOrderQueryOutputDTO
     */
    arrivalState: ReceivingOrderArrivalState;
    /**
     * 
     * @type {OpenApiIdentifier}
     * @memberof ReceivingOrderQueryOutputDTO
     */
    openApiIdentifier: OpenApiIdentifier;
}
/**
 * 
 * @export
 * @interface ReceivingOrderQueryOutputDTODynamicQueryOutputDTO
 */
export interface ReceivingOrderQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<ReceivingOrderQueryOutputDTO>}
     * @memberof ReceivingOrderQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<ReceivingOrderQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof ReceivingOrderQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface ReceivingOrderQueryResponseDTO
 */
export interface ReceivingOrderQueryResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderQueryResponseDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderQueryResponseDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {ReceivingOrderState}
     * @memberof ReceivingOrderQueryResponseDTO
     */
    state: ReceivingOrderState;
    /**
     * 
     * @type {ReceivingOrderTransferType}
     * @memberof ReceivingOrderQueryResponseDTO
     */
    transferType: ReceivingOrderTransferType;
    /**
     * 
     * @type {Array<LineItemOfReceivingOrderResponseDTO>}
     * @memberof ReceivingOrderQueryResponseDTO
     */
    lineItems?: Array<LineItemOfReceivingOrderResponseDTO>;
    /**
     * 
     * @type {Array<WayBillOfReceivingOrderResponseDTO>}
     * @memberof ReceivingOrderQueryResponseDTO
     */
    wayBills?: Array<WayBillOfReceivingOrderResponseDTO>;
    /**
     * 
     * @type {IntegrationDetailResponseDTO}
     * @memberof ReceivingOrderQueryResponseDTO
     */
    integration?: IntegrationDetailResponseDTO;
}
/**
 * 
 * @export
 * @interface ReceivingOrderQueryResponseDTODynamicQueryPagedDTO
 */
export interface ReceivingOrderQueryResponseDTODynamicQueryPagedDTO {
    /**
     * 
     * @type {Array<ReceivingOrderQueryResponseDTO>}
     * @memberof ReceivingOrderQueryResponseDTODynamicQueryPagedDTO
     */
    items?: Array<ReceivingOrderQueryResponseDTO>;
    /**
     * 
     * @type {number}
     * @memberof ReceivingOrderQueryResponseDTODynamicQueryPagedDTO
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof ReceivingOrderQueryResponseDTODynamicQueryPagedDTO
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof ReceivingOrderQueryResponseDTODynamicQueryPagedDTO
     */
    dataSetCount: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ReceivingOrderState {
    ReceivingOrderCreated = 'ReceivingOrderCreated',
    ArrivedToWarehouse = 'ArrivedToWarehouse',
    Completed = 'Completed',
    Cancelled = 'Cancelled'
}

/**
 * 
 * @export
 * @interface ReceivingOrderTransferDTO
 */
export interface ReceivingOrderTransferDTO {
    /**
     * 
     * @type {Date}
     * @memberof ReceivingOrderTransferDTO
     */
    shipmentDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderTransferDTO
     */
    cargoCompanyName?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderTransferDTO
     */
    trackingNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof ReceivingOrderTransferDTO
     */
    pickupDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderTransferDTO
     */
    pickupAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderTransferDTO
     */
    carriageCompanyName?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrderTransferDTO
     */
    waybillNumber?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ReceivingOrderTransferType {
    CargoTransfer = 'CargoTransfer',
    PickupTransfer = 'PickupTransfer',
    CarriageTransfer = 'CarriageTransfer'
}

/**
 * 
 * @export
 * @interface ReceivingOrdersOfTenantQueryOutputDTO
 */
export interface ReceivingOrdersOfTenantQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrdersOfTenantQueryOutputDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ReceivingOrdersOfTenantQueryOutputDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof ReceivingOrdersOfTenantQueryOutputDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReceivingOrdersOfTenantQueryOutputDTO
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReceivingOrdersOfTenantQueryOutputDTO
     */
    arrivedToWarehouseAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReceivingOrdersOfTenantQueryOutputDTO
     */
    completedAt?: Date;
    /**
     * 
     * @type {WmsSyncState}
     * @memberof ReceivingOrdersOfTenantQueryOutputDTO
     */
    wmsSyncState: WmsSyncState;
    /**
     * 
     * @type {number}
     * @memberof ReceivingOrdersOfTenantQueryOutputDTO
     */
    wmsSyncRetryCount: number;
    /**
     * 
     * @type {ReceivingOrderState}
     * @memberof ReceivingOrdersOfTenantQueryOutputDTO
     */
    state: ReceivingOrderState;
    /**
     * 
     * @type {ReceivingOrderState}
     * @memberof ReceivingOrdersOfTenantQueryOutputDTO
     */
    lastCompletedState: ReceivingOrderState;
    /**
     * 
     * @type {ReceivingOrderTransferType}
     * @memberof ReceivingOrdersOfTenantQueryOutputDTO
     */
    transferType: ReceivingOrderTransferType;
}
/**
 * 
 * @export
 * @interface ReferenceNumberOutputDTO
 */
export interface ReferenceNumberOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof ReferenceNumberOutputDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceNumberOutputDTO
     */
    errorCode: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceNumberOutputDTO
     */
    exceptionMessage: string;
}
/**
 * 
 * @export
 * @interface ReferenceNumberOutputDTOBulkOperationResultDTO
 */
export interface ReferenceNumberOutputDTOBulkOperationResultDTO {
    /**
     * 
     * @type {Array<ReferenceNumberOutputDTO>}
     * @memberof ReferenceNumberOutputDTOBulkOperationResultDTO
     */
    successfulItems?: Array<ReferenceNumberOutputDTO>;
    /**
     * 
     * @type {Array<ReferenceNumberOutputDTO>}
     * @memberof ReferenceNumberOutputDTOBulkOperationResultDTO
     */
    faultyItems?: Array<ReferenceNumberOutputDTO>;
}
/**
 * 
 * @export
 * @interface ReferenceNumbersInputDTO
 */
export interface ReferenceNumbersInputDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof ReferenceNumbersInputDTO
     */
    referenceNumbers?: Array<string>;
}
/**
 * 
 * @export
 * @interface ResetReceivingOrdersWmsSyncCommand
 */
export interface ResetReceivingOrdersWmsSyncCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof ResetReceivingOrdersWmsSyncCommand
     */
    referenceNumbers?: Array<string>;
}
/**
 * 
 * @export
 * @interface Return
 */
export interface Return {
    /**
     * 
     * @type {SalesOrder}
     * @memberof Return
     */
    salesOrder?: SalesOrder;
    /**
     * 
     * @type {Tenant}
     * @memberof Return
     */
    tenant?: Tenant;
    /**
     * 
     * @type {Array<ReturnLineItem>}
     * @memberof Return
     */
    lineItems?: Array<ReturnLineItem>;
    /**
     * 
     * @type {ReturnSource}
     * @memberof Return
     */
    source: ReturnSource;
    /**
     * 
     * @type {ReturnState}
     * @memberof Return
     */
    state: ReturnState;
    /**
     * 
     * @type {Date}
     * @memberof Return
     */
    arrivedToWarehouseAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Return
     */
    completedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Return
     */
    salesOrderId?: string;
    /**
     * 
     * @type {string}
     * @memberof Return
     */
    cargoCarrier: string;
    /**
     * 
     * @type {string}
     * @memberof Return
     */
    trackingId: string;
    /**
     * 
     * @type {string}
     * @memberof Return
     */
    notes: string;
    /**
     * 
     * @type {ReturnCourierRequest}
     * @memberof Return
     */
    courierRequest: ReturnCourierRequest;
    /**
     * 
     * @type {ReturnIntegrationSyncState}
     * @memberof Return
     */
    returnIntegrationSyncState: ReturnIntegrationSyncState;
    /**
     * 
     * @type {number}
     * @memberof Return
     */
    returnIntegrationSyncRetryCount: number;
    /**
     * 
     * @type {WmsSyncState}
     * @memberof Return
     */
    wmsSyncState: WmsSyncState;
    /**
     * 
     * @type {Date}
     * @memberof Return
     */
    wmsSyncDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Return
     */
    wmsSyncRetryCount: number;
    /**
     * 
     * @type {string}
     * @memberof Return
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof Return
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof Return
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof Return
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Return
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Return
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Return
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof Return
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof Return
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ReturnCourierRequest {
    No = 'No',
    Requested = 'Requested',
    Success = 'Success',
    Fail = 'Fail'
}

/**
 * 
 * @export
 * @interface ReturnDamagedProductsQueryOutputDTO
 */
export interface ReturnDamagedProductsQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof ReturnDamagedProductsQueryOutputDTO
     */
    sku: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnDamagedProductsQueryOutputDTO
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ReturnDamagedProductsQueryOutputDTO
     */
    waitingInWarehouseCount: number;
}
/**
 * 
 * @export
 * @interface ReturnDamagedProductsQueryOutputDTODynamicQueryOutputDTO
 */
export interface ReturnDamagedProductsQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<ReturnDamagedProductsQueryOutputDTO>}
     * @memberof ReturnDamagedProductsQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<ReturnDamagedProductsQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof ReturnDamagedProductsQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface ReturnDetailOutputDTO
 */
export interface ReturnDetailOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof ReturnDetailOutputDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {ReturnSource}
     * @memberof ReturnDetailOutputDTO
     */
    source: ReturnSource;
    /**
     * 
     * @type {ReturnState}
     * @memberof ReturnDetailOutputDTO
     */
    state: ReturnState;
    /**
     * 
     * @type {string}
     * @memberof ReturnDetailOutputDTO
     */
    notes: string;
    /**
     * 
     * @type {ReturnSalesOrderOutputDTO}
     * @memberof ReturnDetailOutputDTO
     */
    returnSalesOrder?: ReturnSalesOrderOutputDTO;
}
/**
 * 
 * @export
 * @interface ReturnDetailResponseDTO
 */
export interface ReturnDetailResponseDTO {
    /**
     * 
     * @type {Array<ReturnLineItemResponseDTO>}
     * @memberof ReturnDetailResponseDTO
     */
    lineItems?: Array<ReturnLineItemResponseDTO>;
    /**
     * 
     * @type {ReturnSource}
     * @memberof ReturnDetailResponseDTO
     */
    source: ReturnSource;
    /**
     * 
     * @type {string}
     * @memberof ReturnDetailResponseDTO
     */
    returnReferenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnDetailResponseDTO
     */
    salesOrderChannel: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnDetailResponseDTO
     */
    salesOrderReferenceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnDetailResponseDTO
     */
    notes?: string;
    /**
     * 
     * @type {ReturnState}
     * @memberof ReturnDetailResponseDTO
     */
    state: ReturnState;
    /**
     * 
     * @type {string}
     * @memberof ReturnDetailResponseDTO
     */
    trackingId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnDetailResponseDTO
     */
    cargoCarrier?: string;
    /**
     * 
     * @type {Date}
     * @memberof ReturnDetailResponseDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReturnDetailResponseDTO
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReturnDetailResponseDTO
     */
    arrivedToWarehouseAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReturnDetailResponseDTO
     */
    completedAt?: Date;
    /**
     * 
     * @type {ReturnCourierRequest}
     * @memberof ReturnDetailResponseDTO
     */
    courierRequest: ReturnCourierRequest;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ReturnIntegrationSyncState {
    None = 'None',
    SyncFailed = 'SyncFailed',
    SyncSucceeded = 'SyncSucceeded'
}

/**
 * 
 * @export
 * @interface ReturnLineItem
 */
export interface ReturnLineItem {
    /**
     * 
     * @type {Return}
     * @memberof ReturnLineItem
     */
    _return?: Return;
    /**
     * 
     * @type {Product}
     * @memberof ReturnLineItem
     */
    product?: Product;
    /**
     * 
     * @type {string}
     * @memberof ReturnLineItem
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnLineItem
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnLineItem
     */
    sku: string;
    /**
     * 
     * @type {Date}
     * @memberof ReturnLineItem
     */
    processedAt?: Date;
    /**
     * 
     * @type {ReturnLineItemState}
     * @memberof ReturnLineItem
     */
    state: ReturnLineItemState;
    /**
     * 
     * @type {DamagedReturnLineItemState}
     * @memberof ReturnLineItem
     */
    damagedState?: DamagedReturnLineItemState;
    /**
     * 
     * @type {string}
     * @memberof ReturnLineItem
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnLineItem
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof ReturnLineItem
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReturnLineItem
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReturnLineItem
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ReturnLineItem
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnLineItem
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnLineItem
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @interface ReturnLineItemResponseDTO
 */
export interface ReturnLineItemResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof ReturnLineItemResponseDTO
     */
    lineItemReferenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnLineItemResponseDTO
     */
    sku: string;
    /**
     * 
     * @type {Date}
     * @memberof ReturnLineItemResponseDTO
     */
    processedAt?: Date;
    /**
     * 
     * @type {ReturnLineItemState}
     * @memberof ReturnLineItemResponseDTO
     */
    state: ReturnLineItemState;
    /**
     * 
     * @type {DamagedReturnLineItemState}
     * @memberof ReturnLineItemResponseDTO
     */
    damagedState?: DamagedReturnLineItemState;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ReturnLineItemState {
    WaitingToBeProcessed = 'WaitingToBeProcessed',
    Missing = 'Missing',
    Damaged = 'Damaged',
    Undamaged = 'Undamaged'
}

/**
 * 
 * @export
 * @interface ReturnLongTermReportOutputDTO
 */
export interface ReturnLongTermReportOutputDTO {
    /**
     * 
     * @type {number}
     * @memberof ReturnLongTermReportOutputDTO
     */
    todaysReturnCount: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLongTermReportOutputDTO
     */
    lastWeeksReturnCount: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLongTermReportOutputDTO
     */
    lastThirtyDays: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnLongTermReportOutputDTO
     */
    waitingInWarehouseReturnCount: number;
}
/**
 * 
 * @export
 * @interface ReturnQueryOutputDTO
 */
export interface ReturnQueryOutputDTO {
    /**
     * 
     * @type {ReturnSource}
     * @memberof ReturnQueryOutputDTO
     */
    source: ReturnSource;
    /**
     * 
     * @type {string}
     * @memberof ReturnQueryOutputDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnQueryOutputDTO
     */
    salesOrderReferenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnQueryOutputDTO
     */
    salesOrderChannel: string;
    /**
     * 
     * @type {ReturnState}
     * @memberof ReturnQueryOutputDTO
     */
    state?: ReturnState;
    /**
     * 
     * @type {string}
     * @memberof ReturnQueryOutputDTO
     */
    customerFullName?: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnQueryOutputDTO
     */
    trackingId?: string;
    /**
     * 
     * @type {Date}
     * @memberof ReturnQueryOutputDTO
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReturnQueryOutputDTO
     */
    arrivedToWarehouseAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ReturnQueryOutputDTO
     */
    city: string;
    /**
     * 
     * @type {ReturnCourierRequest}
     * @memberof ReturnQueryOutputDTO
     */
    courierRequest: ReturnCourierRequest;
    /**
     * 
     * @type {OpenApiIdentifier}
     * @memberof ReturnQueryOutputDTO
     */
    openApiIdentifier: OpenApiIdentifier;
}
/**
 * 
 * @export
 * @interface ReturnQueryOutputDTODynamicQueryOutputDTO
 */
export interface ReturnQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<ReturnQueryOutputDTO>}
     * @memberof ReturnQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<ReturnQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof ReturnQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface ReturnSalesOrderOutputDTO
 */
export interface ReturnSalesOrderOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof ReturnSalesOrderOutputDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnSalesOrderOutputDTO
     */
    customerFullName: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnSalesOrderOutputDTO
     */
    fullAddress: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnSalesOrderOutputDTO
     */
    shippingAddressFullName: string;
    /**
     * 
     * @type {Date}
     * @memberof ReturnSalesOrderOutputDTO
     */
    createdAt?: Date;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ReturnSource {
    Oplog = 'Oplog',
    WMS = 'WMS'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ReturnState {
    ReturnCreated = 'ReturnCreated',
    ArrivedToWarehouse = 'ArrivedToWarehouse',
    Completed = 'Completed'
}

/**
 * 
 * @export
 * @interface ReturnSummaryResponseDTO
 */
export interface ReturnSummaryResponseDTO {
    /**
     * 
     * @type {ReturnSource}
     * @memberof ReturnSummaryResponseDTO
     */
    source: ReturnSource;
    /**
     * 
     * @type {string}
     * @memberof ReturnSummaryResponseDTO
     */
    returnReferenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnSummaryResponseDTO
     */
    salesOrderChannel: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnSummaryResponseDTO
     */
    salesOrderReferenceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnSummaryResponseDTO
     */
    notes?: string;
    /**
     * 
     * @type {ReturnState}
     * @memberof ReturnSummaryResponseDTO
     */
    state: ReturnState;
    /**
     * 
     * @type {string}
     * @memberof ReturnSummaryResponseDTO
     */
    trackingId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnSummaryResponseDTO
     */
    cargoCarrier?: string;
    /**
     * 
     * @type {Date}
     * @memberof ReturnSummaryResponseDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReturnSummaryResponseDTO
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReturnSummaryResponseDTO
     */
    arrivedToWarehouseAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReturnSummaryResponseDTO
     */
    completedAt?: Date;
    /**
     * 
     * @type {ReturnCourierRequest}
     * @memberof ReturnSummaryResponseDTO
     */
    courierRequest: ReturnCourierRequest;
}
/**
 * 
 * @export
 * @interface ReturnSummaryResponseDTODynamicQueryPagedDTO
 */
export interface ReturnSummaryResponseDTODynamicQueryPagedDTO {
    /**
     * 
     * @type {Array<ReturnSummaryResponseDTO>}
     * @memberof ReturnSummaryResponseDTODynamicQueryPagedDTO
     */
    items?: Array<ReturnSummaryResponseDTO>;
    /**
     * 
     * @type {number}
     * @memberof ReturnSummaryResponseDTODynamicQueryPagedDTO
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnSummaryResponseDTODynamicQueryPagedDTO
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnSummaryResponseDTODynamicQueryPagedDTO
     */
    dataSetCount: number;
}
/**
 * 
 * @export
 * @interface SalesChannelsDTO
 */
export interface SalesChannelsDTO {
    /**
     * 
     * @type {string}
     * @memberof SalesChannelsDTO
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof SalesChannelsDTO
     */
    value: string;
}
/**
 * 
 * @export
 * @interface SalesOrder
 */
export interface SalesOrder {
    /**
     * 
     * @type {Tenant}
     * @memberof SalesOrder
     */
    tenant?: Tenant;
    /**
     * 
     * @type {Customer}
     * @memberof SalesOrder
     */
    customer?: Customer;
    /**
     * 
     * @type {SalesOrderAddress}
     * @memberof SalesOrder
     */
    shippingAddress?: SalesOrderAddress;
    /**
     * 
     * @type {SalesOrderAddress}
     * @memberof SalesOrder
     */
    billingAddress?: SalesOrderAddress;
    /**
     * 
     * @type {Array<SalesOrderLineItem>}
     * @memberof SalesOrder
     */
    lineItems?: Array<SalesOrderLineItem>;
    /**
     * 
     * @type {Array<Return>}
     * @memberof SalesOrder
     */
    returns?: Array<Return>;
    /**
     * 
     * @type {SalesOrderPayment}
     * @memberof SalesOrder
     */
    payment?: SalesOrderPayment;
    /**
     * 
     * @type {SalesOrderVAS}
     * @memberof SalesOrder
     */
    vas?: SalesOrderVAS;
    /**
     * 
     * @type {Array<SalesOrderVasItem>}
     * @memberof SalesOrder
     */
    vasItems?: Array<SalesOrderVasItem>;
    /**
     * 
     * @type {SalesOrderCargo}
     * @memberof SalesOrder
     */
    cargo?: SalesOrderCargo;
    /**
     * 
     * @type {Array<InventoryReservation>}
     * @memberof SalesOrder
     */
    inventoryReservations?: Array<InventoryReservation>;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    orderId: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    salesChannel: string;
    /**
     * 
     * @type {SalesOrderState}
     * @memberof SalesOrder
     */
    state: SalesOrderState;
    /**
     * 
     * @type {SalesOrderFulfillmentSyncState}
     * @memberof SalesOrder
     */
    salesOrderFulfillmentSyncState: SalesOrderFulfillmentSyncState;
    /**
     * 
     * @type {number}
     * @memberof SalesOrder
     */
    salesOrderFulfillmentSyncRetryCount: number;
    /**
     * 
     * @type {SalesOrderState}
     * @memberof SalesOrder
     */
    lastCompletedState: SalesOrderState;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrder
     */
    orderCreatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrder
     */
    pickedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrder
     */
    packedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrder
     */
    cancelledAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrder
     */
    readyToBeShippedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrder
     */
    shippedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrder
     */
    deliveredAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    customerId: string;
    /**
     * 
     * @type {WarehouseType}
     * @memberof SalesOrder
     */
    warehouseType: WarehouseType;
    /**
     * 
     * @type {boolean}
     * @memberof SalesOrder
     */
    pending: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SalesOrder
     */
    approved: boolean;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    externalCode: string;
    /**
     * 
     * @type {Array<Package>}
     * @memberof SalesOrder
     */
    packages?: Array<Package>;
    /**
     * 
     * @type {Integration}
     * @memberof SalesOrder
     */
    integration?: Integration;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    integrationId?: string;
    /**
     * 
     * @type {WmsSyncState}
     * @memberof SalesOrder
     */
    wmsSyncState: WmsSyncState;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrder
     */
    wmsSyncDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof SalesOrder
     */
    wmsSyncRetryCount: number;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrder
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrder
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrder
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @interface SalesOrderAddress
 */
export interface SalesOrderAddress {
    /**
     * 
     * @type {string}
     * @memberof SalesOrderAddress
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderAddress
     */
    lastName: string;
    /**
     * 
     * @type {BaseAddress}
     * @memberof SalesOrderAddress
     */
    baseAddress?: BaseAddress;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderAddress
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderAddress
     */
    company: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderAddress
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderAddress
     */
    fullAddress: string;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderAddress
     */
    referenceNumber: number;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderAddress
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderAddress
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderAddress
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderAddress
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderAddress
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderAddress
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderAddress
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @interface SalesOrderCargo
 */
export interface SalesOrderCargo {
    /**
     * 
     * @type {string}
     * @memberof SalesOrderCargo
     */
    shippingCompany: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderCargo
     */
    shippingTrackingId: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderCargo
     */
    shippingState: string;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderCargo
     */
    requestedCargoCarrier?: number;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderCargo
     */
    cargoDocumentUrl: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderCargo
     */
    cargoCode: string;
    /**
     * 
     * @type {CargoType}
     * @memberof SalesOrderCargo
     */
    cargoType: CargoType;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderCargo
     */
    expectedShipmentDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderCargo
     */
    shipmentTrackingLinkUrl: string;
}
/**
 * 
 * @export
 * @interface SalesOrderCargoResponseDTO
 */
export interface SalesOrderCargoResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof SalesOrderCargoResponseDTO
     */
    requestedCargoCarrier: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderCargoResponseDTO
     */
    cargoDocumentUrl: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderCargoResponseDTO
     */
    cargoCode: string;
    /**
     * 
     * @type {CargoType}
     * @memberof SalesOrderCargoResponseDTO
     */
    cargoType: CargoType;
}
/**
 * 
 * @export
 * @interface SalesOrderDetailOfReturnOutputDTO
 */
export interface SalesOrderDetailOfReturnOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof SalesOrderDetailOfReturnOutputDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderDetailOfReturnOutputDTO
     */
    customerFullName: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderDetailOfReturnOutputDTO
     */
    shippingAddressFullName: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderDetailOfReturnOutputDTO
     */
    shippingAddress: string;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderDetailOfReturnOutputDTO
     */
    orderCreatedAt: Date;
}
/**
 * 
 * @export
 * @interface SalesOrderDetailOutputDTO
 */
export interface SalesOrderDetailOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof SalesOrderDetailOutputDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderDetailOutputDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderDetailOutputDTO
     */
    customerFullName: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderDetailOutputDTO
     */
    shippingAddressFullName: string;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderDetailOutputDTO
     */
    customerNo: number;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderDetailOutputDTO
     */
    shippingAddress: string;
    /**
     * 
     * @type {IntegrationType}
     * @memberof SalesOrderDetailOutputDTO
     */
    integrationType?: IntegrationType;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderDetailOutputDTO
     */
    integrationName?: string;
    /**
     * 
     * @type {SalesOrderState}
     * @memberof SalesOrderDetailOutputDTO
     */
    state: SalesOrderState;
    /**
     * 
     * @type {PaymentOption}
     * @memberof SalesOrderDetailOutputDTO
     */
    paymentOption: PaymentOption;
    /**
     * 
     * @type {Currency}
     * @memberof SalesOrderDetailOutputDTO
     */
    currency?: Currency;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderDetailOutputDTO
     */
    totalPaymentAmount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SalesOrderDetailOutputDTO
     */
    pending: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SalesOrderDetailOutputDTO
     */
    hasPackage: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SalesOrderDetailOutputDTO
     */
    hasPackagelessLineItems: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SalesOrderDetailOutputDTO
     */
    hasPackableLineItems: boolean;
    /**
     * 
     * @type {PackageCreationType}
     * @memberof SalesOrderDetailOutputDTO
     */
    packageCreationType: PackageCreationType;
    /**
     * 
     * @type {boolean}
     * @memberof SalesOrderDetailOutputDTO
     */
    isCancellable: boolean;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderDetailOutputDTO
     */
    trackingUrl: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderDetailOutputDTO
     */
    shipmentTrackingLinkUrl: string;
    /**
     * 
     * @type {CargoType}
     * @memberof SalesOrderDetailOutputDTO
     */
    cargoType: CargoType;
    /**
     * 
     * @type {OpenApiIdentifier}
     * @memberof SalesOrderDetailOutputDTO
     */
    openApiIdentifier: OpenApiIdentifier;
}
/**
 * 
 * @export
 * @interface SalesOrderDetailResponseDTO
 */
export interface SalesOrderDetailResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof SalesOrderDetailResponseDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderDetailResponseDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderDetailResponseDTO
     */
    salesChannel: string;
    /**
     * 
     * @type {SalesOrderState}
     * @memberof SalesOrderDetailResponseDTO
     */
    state: SalesOrderState;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderDetailResponseDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderDetailResponseDTO
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderDetailResponseDTO
     */
    orderCreatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderDetailResponseDTO
     */
    pickedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderDetailResponseDTO
     */
    packedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderDetailResponseDTO
     */
    cancelledAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderDetailResponseDTO
     */
    shippedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderDetailResponseDTO
     */
    deliveredAt?: Date;
    /**
     * 
     * @type {CustomerSummaryResponseDTO}
     * @memberof SalesOrderDetailResponseDTO
     */
    customer?: CustomerSummaryResponseDTO;
    /**
     * 
     * @type {AddressDetailResponseDTO}
     * @memberof SalesOrderDetailResponseDTO
     */
    shippingAddress?: AddressDetailResponseDTO;
    /**
     * 
     * @type {AddressDetailResponseDTO}
     * @memberof SalesOrderDetailResponseDTO
     */
    billingAddress?: AddressDetailResponseDTO;
    /**
     * 
     * @type {ShippingInfoOfSalesOrderResponseDTO}
     * @memberof SalesOrderDetailResponseDTO
     */
    shippingInfo?: ShippingInfoOfSalesOrderResponseDTO;
    /**
     * 
     * @type {Array<LineItemOfSalesOrderResponseDTO>}
     * @memberof SalesOrderDetailResponseDTO
     */
    lineItems?: Array<LineItemOfSalesOrderResponseDTO>;
    /**
     * 
     * @type {IntegrationDetailResponseDTO}
     * @memberof SalesOrderDetailResponseDTO
     */
    integration?: IntegrationDetailResponseDTO;
    /**
     * 
     * @type {SalesOrderPaymentResponseDTO}
     * @memberof SalesOrderDetailResponseDTO
     */
    payment?: SalesOrderPaymentResponseDTO;
    /**
     * 
     * @type {SalesOrderCargoResponseDTO}
     * @memberof SalesOrderDetailResponseDTO
     */
    cargo?: SalesOrderCargoResponseDTO;
    /**
     * 
     * @type {SalesOrderVASResponseDTO}
     * @memberof SalesOrderDetailResponseDTO
     */
    vas?: SalesOrderVASResponseDTO;
    /**
     * 
     * @type {Array<SalesOrderPackageResponseDTO>}
     * @memberof SalesOrderDetailResponseDTO
     */
    packages?: Array<SalesOrderPackageResponseDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof SalesOrderDetailResponseDTO
     */
    approved: boolean;
}
/**
 * 
 * @export
 * @interface SalesOrderDetailResponseDTODynamicQueryPagedDTO
 */
export interface SalesOrderDetailResponseDTODynamicQueryPagedDTO {
    /**
     * 
     * @type {Array<SalesOrderDetailResponseDTO>}
     * @memberof SalesOrderDetailResponseDTODynamicQueryPagedDTO
     */
    items?: Array<SalesOrderDetailResponseDTO>;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderDetailResponseDTODynamicQueryPagedDTO
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderDetailResponseDTODynamicQueryPagedDTO
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderDetailResponseDTODynamicQueryPagedDTO
     */
    dataSetCount: number;
}
/**
 * 
 * @export
 * @interface SalesOrderDocumentVAS
 */
export interface SalesOrderDocumentVAS {
    /**
     * 
     * @type {string}
     * @memberof SalesOrderDocumentVAS
     */
    documentUrl: string;
}
/**
 * 
 * @export
 * @interface SalesOrderDocumentVASResponseDTO
 */
export interface SalesOrderDocumentVASResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof SalesOrderDocumentVASResponseDTO
     */
    documentUrl: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SalesOrderFulfillmentSyncState {
    None = 'None',
    FulfillmentFailed = 'FulfillmentFailed',
    FulfillmentSucceeded = 'FulfillmentSucceeded'
}

/**
 * 
 * @export
 * @interface SalesOrderGiftVAS
 */
export interface SalesOrderGiftVAS {
    /**
     * 
     * @type {boolean}
     * @memberof SalesOrderGiftVAS
     */
    giftWrap: boolean;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderGiftVAS
     */
    giftNotes: string;
}
/**
 * 
 * @export
 * @interface SalesOrderGiftVASResponseDTO
 */
export interface SalesOrderGiftVASResponseDTO {
    /**
     * 
     * @type {boolean}
     * @memberof SalesOrderGiftVASResponseDTO
     */
    wrap: boolean;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderGiftVASResponseDTO
     */
    notes: string;
}
/**
 * 
 * @export
 * @interface SalesOrderLineItem
 */
export interface SalesOrderLineItem {
    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineItem
     */
    salesOrderId: string;
    /**
     * 
     * @type {SalesOrder}
     * @memberof SalesOrderLineItem
     */
    salesOrder?: SalesOrder;
    /**
     * 
     * @type {Product}
     * @memberof SalesOrderLineItem
     */
    product?: Product;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineItem
     */
    productId: string;
    /**
     * 
     * @type {Package}
     * @memberof SalesOrderLineItem
     */
    _package?: Package;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineItem
     */
    packageId?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineItem
     */
    sku: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineItem
     */
    externalCode: string;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderLineItem
     */
    productSalePrice?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderLineItem
     */
    productPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderLineItem
     */
    productTaxRatio?: number;
    /**
     * 
     * @type {Currency}
     * @memberof SalesOrderLineItem
     */
    currency?: Currency;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderLineItem
     */
    pickedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderLineItem
     */
    packedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderLineItem
     */
    cancelledAt?: Date;
    /**
     * 
     * @type {SalesOrderLineItemState}
     * @memberof SalesOrderLineItem
     */
    state: SalesOrderLineItemState;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineItem
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineItem
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderLineItem
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderLineItem
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderLineItem
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineItem
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineItem
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineItem
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SalesOrderLineItemState {
    Created = 'Created',
    Picked = 'Picked',
    Packed = 'Packed',
    Cancelled = 'Cancelled'
}

/**
 * 
 * @export
 * @interface SalesOrderLongTermReportOutputDTO
 */
export interface SalesOrderLongTermReportOutputDTO {
    /**
     * 
     * @type {SalesOrderReportDetailsOutputDTO}
     * @memberof SalesOrderLongTermReportOutputDTO
     */
    today?: SalesOrderReportDetailsOutputDTO;
    /**
     * 
     * @type {SalesOrderReportDetailsOutputDTO}
     * @memberof SalesOrderLongTermReportOutputDTO
     */
    lastSevenDays?: SalesOrderReportDetailsOutputDTO;
    /**
     * 
     * @type {SalesOrderReportDetailsOutputDTO}
     * @memberof SalesOrderLongTermReportOutputDTO
     */
    lastThirtyDays?: SalesOrderReportDetailsOutputDTO;
    /**
     * 
     * @type {SalesOrderReportDetailsOutputDTO}
     * @memberof SalesOrderLongTermReportOutputDTO
     */
    lastNinetyDays?: SalesOrderReportDetailsOutputDTO;
}
/**
 * 
 * @export
 * @interface SalesOrderOfCustomerQueryOutputDTO
 */
export interface SalesOrderOfCustomerQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof SalesOrderOfCustomerQueryOutputDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderOfCustomerQueryOutputDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {SalesOrderState}
     * @memberof SalesOrderOfCustomerQueryOutputDTO
     */
    state: SalesOrderState;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderOfCustomerQueryOutputDTO
     */
    shippingAddress: string;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderOfCustomerQueryOutputDTO
     */
    orderCreatedAt: Date;
    /**
     * 
     * @type {SalesOrderState}
     * @memberof SalesOrderOfCustomerQueryOutputDTO
     */
    lastCompletedState: SalesOrderState;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderOfCustomerQueryOutputDTO
     */
    uniqueProductCount: number;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderOfCustomerQueryOutputDTO
     */
    totalProductCount: number;
}
/**
 * 
 * @export
 * @interface SalesOrderOfCustomerQueryOutputDTODynamicQueryOutputDTO
 */
export interface SalesOrderOfCustomerQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<SalesOrderOfCustomerQueryOutputDTO>}
     * @memberof SalesOrderOfCustomerQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<SalesOrderOfCustomerQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderOfCustomerQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface SalesOrderOfProductQueryOutputDTO
 */
export interface SalesOrderOfProductQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof SalesOrderOfProductQueryOutputDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderOfProductQueryOutputDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {SalesOrderState}
     * @memberof SalesOrderOfProductQueryOutputDTO
     */
    state: SalesOrderState;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderOfProductQueryOutputDTO
     */
    customerNo: number;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderOfProductQueryOutputDTO
     */
    shippingAddressFullName: string;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderOfProductQueryOutputDTO
     */
    orderCreatedAt: Date;
    /**
     * 
     * @type {SalesOrderState}
     * @memberof SalesOrderOfProductQueryOutputDTO
     */
    lastCompletedState: SalesOrderState;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderOfProductQueryOutputDTO
     */
    amountOfProduct: number;
}
/**
 * 
 * @export
 * @interface SalesOrderOfProductQueryOutputDTODynamicQueryOutputDTO
 */
export interface SalesOrderOfProductQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<SalesOrderOfProductQueryOutputDTO>}
     * @memberof SalesOrderOfProductQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<SalesOrderOfProductQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderOfProductQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface SalesOrderPackageResponseDTO
 */
export interface SalesOrderPackageResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof SalesOrderPackageResponseDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {PackageState}
     * @memberof SalesOrderPackageResponseDTO
     */
    state: PackageState;
    /**
     * 
     * @type {Array<LineItemOfPackageResponseDTO>}
     * @memberof SalesOrderPackageResponseDTO
     */
    lineItems?: Array<LineItemOfPackageResponseDTO>;
    /**
     * 
     * @type {ShippingInfoOfSalesOrderResponseDTO}
     * @memberof SalesOrderPackageResponseDTO
     */
    shippingInfo?: ShippingInfoOfSalesOrderResponseDTO;
}
/**
 * 
 * @export
 * @interface SalesOrderPayment
 */
export interface SalesOrderPayment {
    /**
     * 
     * @type {PaymentOption}
     * @memberof SalesOrderPayment
     */
    paymentOption: PaymentOption;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderPayment
     */
    totalPaymentAmount?: number;
    /**
     * 
     * @type {Currency}
     * @memberof SalesOrderPayment
     */
    currency?: Currency;
}
/**
 * 
 * @export
 * @interface SalesOrderPaymentOutputDTO
 */
export interface SalesOrderPaymentOutputDTO {
    /**
     * 
     * @type {Currency}
     * @memberof SalesOrderPaymentOutputDTO
     */
    currency?: Currency;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderPaymentOutputDTO
     */
    totalPaymentAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderPaymentOutputDTO
     */
    paymentGatewayNames: string;
    /**
     * 
     * @type {PaymentOption}
     * @memberof SalesOrderPaymentOutputDTO
     */
    paymentOption: PaymentOption;
    /**
     * 
     * @type {boolean}
     * @memberof SalesOrderPaymentOutputDTO
     */
    isPaid?: boolean;
}
/**
 * 
 * @export
 * @interface SalesOrderPaymentResponseDTO
 */
export interface SalesOrderPaymentResponseDTO {
    /**
     * 
     * @type {PaymentOption}
     * @memberof SalesOrderPaymentResponseDTO
     */
    paymentOption: PaymentOption;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderPaymentResponseDTO
     */
    totalPaymentAmount?: number;
    /**
     * 
     * @type {Currency}
     * @memberof SalesOrderPaymentResponseDTO
     */
    currency?: Currency;
}
/**
 * 
 * @export
 * @interface SalesOrderQueryOutputDTO
 */
export interface SalesOrderQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof SalesOrderQueryOutputDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderQueryOutputDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {IntegrationType}
     * @memberof SalesOrderQueryOutputDTO
     */
    integrationType?: IntegrationType;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderQueryOutputDTO
     */
    integrationName?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderQueryOutputDTO
     */
    salesChannel: string;
    /**
     * 
     * @type {SalesOrderState}
     * @memberof SalesOrderQueryOutputDTO
     */
    state: SalesOrderState;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderQueryOutputDTO
     */
    customerNo: number;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderQueryOutputDTO
     */
    customerEmail: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderQueryOutputDTO
     */
    customerPhone: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderQueryOutputDTO
     */
    customerFullName: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderQueryOutputDTO
     */
    shippingAddressCity: string;
    /**
     *
     * @type {string}
     * @memberof SalesOrderQueryOutputDTO
     */
    shippingAddressCountry: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderQueryOutputDTO
     */
    shippingAddressFullName: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderQueryOutputDTO
     */
    shippingAddress: string;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderQueryOutputDTO
     */
    totalProductCount: number;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderQueryOutputDTO
     */
    orderCreatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderQueryOutputDTO
     */
    cancelledAt?: Date;
    /**
     * 
     * @type {SalesOrderState}
     * @memberof SalesOrderQueryOutputDTO
     */
    lastCompletedState: SalesOrderState;
    /**
     * 
     * @type {boolean}
     * @memberof SalesOrderQueryOutputDTO
     */
    approved: boolean;
    /**
     * 
     * @type {CargoType}
     * @memberof SalesOrderQueryOutputDTO
     */
    cargoType: CargoType;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderQueryOutputDTO
     */
    warehouseLocation?: string;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderQueryOutputDTO
     */
    totalProductCountInSalesOrder: number;
    /**
     * 
     * @type {OpenApiIdentifier}
     * @memberof SalesOrderQueryOutputDTO
     */
    openApiIdentifier: OpenApiIdentifier;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderQueryOutputDTO
     */
    shippedAt?: Date;
}
/**
 * 
 * @export
 * @interface SalesOrderQueryOutputDTODynamicQueryOutputDTO
 */
export interface SalesOrderQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<SalesOrderQueryOutputDTO>}
     * @memberof SalesOrderQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<SalesOrderQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface SalesOrderReportDetailsOutputDTO
 */
export interface SalesOrderReportDetailsOutputDTO {
    /**
     * 
     * @type {number}
     * @memberof SalesOrderReportDetailsOutputDTO
     */
    totalOrderCount: number;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderReportDetailsOutputDTO
     */
    createdCount: number;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderReportDetailsOutputDTO
     */
    pickedCount: number;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderReportDetailsOutputDTO
     */
    packedCount: number;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderReportDetailsOutputDTO
     */
    readyToBeShippedCount: number;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderReportDetailsOutputDTO
     */
    shippedCount: number;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderReportDetailsOutputDTO
     */
    deliveredCount: number;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderReportDetailsOutputDTO
     */
    cancelledCount: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SalesOrderState {
    Created = 'Created',
    Picked = 'Picked',
    Packed = 'Packed',
    ReadyToBeShipped = 'ReadyToBeShipped',
    Shipped = 'Shipped',
    Delivered = 'Delivered',
    Cancelled = 'Cancelled',
}

/**
 * 
 * @export
 * @interface SalesOrderVAS
 */
export interface SalesOrderVAS {
    /**
     * 
     * @type {string}
     * @memberof SalesOrderVAS
     */
    packageNote: string;
    /**
     * 
     * @type {SalesOrderGiftVAS}
     * @memberof SalesOrderVAS
     */
    giftVAS?: SalesOrderGiftVAS;
    /**
     * 
     * @type {SalesOrderDocumentVAS}
     * @memberof SalesOrderVAS
     */
    documentVAS?: SalesOrderDocumentVAS;
}
/**
 * 
 * @export
 * @interface SalesOrderVASResponseDTO
 */
export interface SalesOrderVASResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof SalesOrderVASResponseDTO
     */
    packageNote: string;
    /**
     * 
     * @type {SalesOrderGiftVASResponseDTO}
     * @memberof SalesOrderVASResponseDTO
     */
    gift?: SalesOrderGiftVASResponseDTO;
    /**
     * 
     * @type {SalesOrderDocumentVASResponseDTO}
     * @memberof SalesOrderVASResponseDTO
     */
    document?: SalesOrderDocumentVASResponseDTO;
}
/**
 * 
 * @export
 * @interface SalesOrderVasItem
 */
export interface SalesOrderVasItem {
    /**
     * 
     * @type {string}
     * @memberof SalesOrderVasItem
     */
    salesOrderId: string;
    /**
     * 
     * @type {SalesOrder}
     * @memberof SalesOrderVasItem
     */
    salesOrder?: SalesOrder;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderVasItem
     */
    documentURL: string;
    /**
     * 
     * @type {SalesOrderVasItemType}
     * @memberof SalesOrderVasItem
     */
    type: SalesOrderVasItemType;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderVasItem
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderVasItem
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderVasItem
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderVasItem
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderVasItem
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderVasItem
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderVasItem
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderVasItem
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SalesOrderVasItemType {
    InsertToPackage = 'InsertToPackage',
    GiftPackage = 'GiftPackage',
    PackingNote = 'PackingNote'
}

/**
 * 
 * @export
 * @interface SalesOrdersFetchFromWmsInputDTO
 */
export interface SalesOrdersFetchFromWmsInputDTO {
    /**
     * 
     * @type {string}
     * @memberof SalesOrdersFetchFromWmsInputDTO
     */
    tenantName: string;
    /**
     * 
     * @type {TimeRangeInputDTO}
     * @memberof SalesOrdersFetchFromWmsInputDTO
     */
    fetchByTimeRangeMethod?: TimeRangeInputDTO;
}
/**
 * 
 * @export
 * @interface SalesOrdersOfTenantQueryOutputDTO
 */
export interface SalesOrdersOfTenantQueryOutputDTO {
    /**
     * 
     * @type {IntegrationType}
     * @memberof SalesOrdersOfTenantQueryOutputDTO
     */
    integrationType?: IntegrationType;
    /**
     * 
     * @type {string}
     * @memberof SalesOrdersOfTenantQueryOutputDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrdersOfTenantQueryOutputDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {SalesOrderState}
     * @memberof SalesOrdersOfTenantQueryOutputDTO
     */
    state: SalesOrderState;
    /**
     * 
     * @type {SalesOrderState}
     * @memberof SalesOrdersOfTenantQueryOutputDTO
     */
    lastCompletedState: SalesOrderState;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrdersOfTenantQueryOutputDTO
     */
    pickedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrdersOfTenantQueryOutputDTO
     */
    packedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrdersOfTenantQueryOutputDTO
     */
    shippedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrdersOfTenantQueryOutputDTO
     */
    deliveredAt?: Date;
    /**
     * 
     * @type {WmsSyncState}
     * @memberof SalesOrdersOfTenantQueryOutputDTO
     */
    wmsSyncState: WmsSyncState;
    /**
     * 
     * @type {number}
     * @memberof SalesOrdersOfTenantQueryOutputDTO
     */
    wmsSyncRetryCount: number;
    /**
     * 
     * @type {SalesOrderFulfillmentSyncState}
     * @memberof SalesOrdersOfTenantQueryOutputDTO
     */
    salesOrderFulfillmentSyncState: SalesOrderFulfillmentSyncState;
    /**
     * 
     * @type {number}
     * @memberof SalesOrdersOfTenantQueryOutputDTO
     */
    salesOrderFulfillmentSyncRetryCount: number;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrdersOfTenantQueryOutputDTO
     */
    orderCreatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrdersOfTenantQueryOutputDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrdersOfTenantQueryOutputDTO
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrdersOfTenantQueryOutputDTO
     */
    cancelledAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof SalesOrdersOfTenantQueryOutputDTO
     */
    pending: boolean;
}
/**
 * 
 * @export
 * @interface SalesOrdersSendToWmsInputDTO
 */
export interface SalesOrdersSendToWmsInputDTO {
    /**
     * 
     * @type {string}
     * @memberof SalesOrdersSendToWmsInputDTO
     */
    tenantName: string;
    /**
     * 
     * @type {object}
     * @memberof SalesOrdersSendToWmsInputDTO
     */
    sendAllMethod?: object;
    /**
     * 
     * @type {SendSalesOrdersByTimeRange}
     * @memberof SalesOrdersSendToWmsInputDTO
     */
    sendByTimeRangeMethod?: SendSalesOrdersByTimeRange;
    /**
     * 
     * @type {SendSalesOrdersByReferenceNumbers}
     * @memberof SalesOrdersSendToWmsInputDTO
     */
    sendByReferenceNumbersMethod?: SendSalesOrdersByReferenceNumbers;
}
/**
 * 
 * @export
 * @interface SearchCustomersQueryOutputDTO
 */
export interface SearchCustomersQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof SearchCustomersQueryOutputDTO
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof SearchCustomersQueryOutputDTO
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof SearchCustomersQueryOutputDTO
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof SearchCustomersQueryOutputDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SearchCustomersQueryOutputDTO
     */
    address: string;
    /**
     * 
     * @type {number}
     * @memberof SearchCustomersQueryOutputDTO
     */
    customerNo: number;
}
/**
 * 
 * @export
 * @interface SendProductsByBarcodes
 */
export interface SendProductsByBarcodes {
    /**
     * 
     * @type {Array<string>}
     * @memberof SendProductsByBarcodes
     */
    barcodes?: Array<string>;
}
/**
 * 
 * @export
 * @interface SendProductsBySkus
 */
export interface SendProductsBySkus {
    /**
     * 
     * @type {Array<string>}
     * @memberof SendProductsBySkus
     */
    skUs?: Array<string>;
}
/**
 * 
 * @export
 * @interface SendProductsByTimeRange
 */
export interface SendProductsByTimeRange {
    /**
     * 
     * @type {Date}
     * @memberof SendProductsByTimeRange
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof SendProductsByTimeRange
     */
    end: Date;
}
/**
 * 
 * @export
 * @interface SendProductsToWMSCommand
 */
export interface SendProductsToWMSCommand {
    /**
     * 
     * @type {string}
     * @memberof SendProductsToWMSCommand
     */
    tenantName: string;
    /**
     * 
     * @type {object}
     * @memberof SendProductsToWMSCommand
     */
    sendAllMethod?: object;
    /**
     * 
     * @type {SendProductsByTimeRange}
     * @memberof SendProductsToWMSCommand
     */
    sendByTimeRangeMethod?: SendProductsByTimeRange;
    /**
     * 
     * @type {SendProductsBySkus}
     * @memberof SendProductsToWMSCommand
     */
    sendBySkuMethod?: SendProductsBySkus;
    /**
     * 
     * @type {SendProductsByBarcodes}
     * @memberof SendProductsToWMSCommand
     */
    sendByBarcodeMethod?: SendProductsByBarcodes;
}
/**
 * 
 * @export
 * @interface SendSalesOrdersByReferenceNumbers
 */
export interface SendSalesOrdersByReferenceNumbers {
    /**
     * 
     * @type {Array<string>}
     * @memberof SendSalesOrdersByReferenceNumbers
     */
    referenceNumbers?: Array<string>;
}
/**
 * 
 * @export
 * @interface SendSalesOrdersByTimeRange
 */
export interface SendSalesOrdersByTimeRange {
    /**
     * 
     * @type {Date}
     * @memberof SendSalesOrdersByTimeRange
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof SendSalesOrdersByTimeRange
     */
    end: Date;
}
/**
 * 
 * @export
 * @interface SendUserFeedbackCommand
 */
export interface SendUserFeedbackCommand {
    /**
     * 
     * @type {string}
     * @memberof SendUserFeedbackCommand
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof SendUserFeedbackCommand
     */
    body: string;
}
/**
 * 
 * @export
 * @interface ShippingInfoOfSalesOrderResponseDTO
 */
export interface ShippingInfoOfSalesOrderResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof ShippingInfoOfSalesOrderResponseDTO
     */
    shippingCompany: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingInfoOfSalesOrderResponseDTO
     */
    shippingTrackingId: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingInfoOfSalesOrderResponseDTO
     */
    shippingState: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingInfoOfSalesOrderResponseDTO
     */
    trackingUrl: string;
}
/**
 * 
 * @export
 * @interface SnapshotPeriodItemDTO
 */
export interface SnapshotPeriodItemDTO {
    /**
     * 
     * @type {Date}
     * @memberof SnapshotPeriodItemDTO
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof SnapshotPeriodItemDTO
     */
    end: Date;
}
/**
 * 
 * @export
 * @interface SnapshotPeriodsDTO
 */
export interface SnapshotPeriodsDTO {
    /**
     * 
     * @type {Array<SnapshotPeriodItemDTO>}
     * @memberof SnapshotPeriodsDTO
     */
    items?: Array<SnapshotPeriodItemDTO>;
}
/**
 * 
 * @export
 * @interface SortOption
 */
export interface SortOption {
    /**
     * 
     * @type {string}
     * @memberof SortOption
     */
    propertyName: string;
    /**
     * 
     * @type {SortingDirection}
     * @memberof SortOption
     */
    sortingDirection: SortingDirection;
    /**
     * 
     * @type {boolean}
     * @memberof SortOption
     */
    caseSensitive: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SortingDirection {
    Asc = 'Asc',
    Desc = 'Desc'
}

/**
 * 
 * @export
 * @interface StateDetailOutputDTO
 */
export interface StateDetailOutputDTO {
    /**
     * 
     * @type {number}
     * @memberof StateDetailOutputDTO
     */
    state: number;
    /**
     * 
     * @type {Date}
     * @memberof StateDetailOutputDTO
     */
    completedAt?: Date;
    /**
     * 
     * @type {object}
     * @memberof StateDetailOutputDTO
     */
    details?: object;
    /**
     * 
     * @type {boolean}
     * @memberof StateDetailOutputDTO
     */
    isCancelled: boolean;
}
/**
 * 
 * @export
 * @interface StateDetailsOutputDTO
 */
export interface StateDetailsOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof StateDetailsOutputDTO
     */
    id: string;
    /**
     * 
     * @type {Array<StateDetailOutputDTO>}
     * @memberof StateDetailsOutputDTO
     */
    details?: Array<StateDetailOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof StateDetailsOutputDTO
     */
    currentStateIndex: number;
    /**
     * 
     * @type {number}
     * @memberof StateDetailsOutputDTO
     */
    lastCompletedStateIndex: number;
}
/**
 * 
 * @export
 * @interface StringBulkOperationResultDTO
 */
export interface StringBulkOperationResultDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof StringBulkOperationResultDTO
     */
    successfulItems?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StringBulkOperationResultDTO
     */
    faultyItems?: Array<string>;
}
/**
 * 
 * @export
 * @interface StringInt32Tuple
 */
export interface StringInt32Tuple {
    /**
     * 
     * @type {string}
     * @memberof StringInt32Tuple
     */
    item1: string;
    /**
     * 
     * @type {number}
     * @memberof StringInt32Tuple
     */
    item2: number;
}
/**
 * 
 * @export
 * @interface SyncIntegrationCommand
 */
export interface SyncIntegrationCommand {
    /**
     * 
     * @type {string}
     * @memberof SyncIntegrationCommand
     */
    integrationId: string;
}
/**
 * 
 * @export
 * @interface SyncInventoryFromMaestroCommand
 */
export interface SyncInventoryFromMaestroCommand {
    /**
     * 
     * @type {string}
     * @memberof SyncInventoryFromMaestroCommand
     */
    tenantName: string;
    /**
     * 
     * @type {string}
     * @memberof SyncInventoryFromMaestroCommand
     */
    sku: string;
    /**
     * 
     * @type {number}
     * @memberof SyncInventoryFromMaestroCommand
     */
    stockAmount: number;
    /**
     * 
     * @type {number}
     * @memberof SyncInventoryFromMaestroCommand
     */
    reservedAmount: number;
    /**
     * 
     * @type {number}
     * @memberof SyncInventoryFromMaestroCommand
     */
    pickingItemAmount: number;
    /**
     * 
     * @type {number}
     * @memberof SyncInventoryFromMaestroCommand
     */
    packedItemAmount: number;
    /**
     * 
     * @type {number}
     * @memberof SyncInventoryFromMaestroCommand
     */
    receivedItemAmount: number;
    /**
     * 
     * @type {number}
     * @memberof SyncInventoryFromMaestroCommand
     */
    damagedItemAmount: number;
    /**
     * 
     * @type {number}
     * @memberof SyncInventoryFromMaestroCommand
     */
    lostItemAmount: number;
    /**
     * 
     * @type {number}
     * @memberof SyncInventoryFromMaestroCommand
     */
    expiredAmount: number;
    /**
     * 
     * @type {Date}
     * @memberof SyncInventoryFromMaestroCommand
     */
    processedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof SyncInventoryFromMaestroCommand
     */
    correlationId: string;
    /**
     * 
     * @type {Array<ExpirationDates>}
     * @memberof SyncInventoryFromMaestroCommand
     */
    expirationDates?: Array<ExpirationDates>;
}
/**
 * 
 * @export
 * @interface SyncOutputDTO
 */
export interface SyncOutputDTO {
    /**
     * 
     * @type {Array<SyncOutputItemDTO>}
     * @memberof SyncOutputDTO
     */
    items?: Array<SyncOutputItemDTO>;
}
/**
 * 
 * @export
 * @interface SyncOutputItemDTO
 */
export interface SyncOutputItemDTO {
    /**
     * 
     * @type {string}
     * @memberof SyncOutputItemDTO
     */
    referenceCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof SyncOutputItemDTO
     */
    succeed: boolean;
    /**
     * 
     * @type {string}
     * @memberof SyncOutputItemDTO
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface SyncPackagesFromMaestroCommand
 */
export interface SyncPackagesFromMaestroCommand {
    /**
     * 
     * @type {string}
     * @memberof SyncPackagesFromMaestroCommand
     */
    tenantName: string;
    /**
     * 
     * @type {string}
     * @memberof SyncPackagesFromMaestroCommand
     */
    referenceNumber: string;
    /**
     * 
     * @type {SalesOrderState}
     * @memberof SyncPackagesFromMaestroCommand
     */
    state: SalesOrderState;
    /**
     * 
     * @type {Date}
     * @memberof SyncPackagesFromMaestroCommand
     */
    pickedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SyncPackagesFromMaestroCommand
     */
    packedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SyncPackagesFromMaestroCommand
     */
    shippedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SyncPackagesFromMaestroCommand
     */
    deliveredAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof SyncPackagesFromMaestroCommand
     */
    shippingCompany: string;
    /**
     * 
     * @type {string}
     * @memberof SyncPackagesFromMaestroCommand
     */
    trackingId: string;
    /**
     * 
     * @type {string}
     * @memberof SyncPackagesFromMaestroCommand
     */
    shippingState: string;
    /**
     * 
     * @type {string}
     * @memberof SyncPackagesFromMaestroCommand
     */
    shipmentTrackingLinkUrl: string;
}
/**
 * 
 * @export
 * @interface SyncProductsByBarcodes
 */
export interface SyncProductsByBarcodes {
    /**
     * 
     * @type {Array<string>}
     * @memberof SyncProductsByBarcodes
     */
    barcodes?: Array<string>;
}
/**
 * 
 * @export
 * @interface SyncProductsBySkus
 */
export interface SyncProductsBySkus {
    /**
     * 
     * @type {Array<string>}
     * @memberof SyncProductsBySkus
     */
    skUs?: Array<string>;
}
/**
 * 
 * @export
 * @interface SyncProductsByTimeRange
 */
export interface SyncProductsByTimeRange {
    /**
     * 
     * @type {Date}
     * @memberof SyncProductsByTimeRange
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof SyncProductsByTimeRange
     */
    end: Date;
}
/**
 * 
 * @export
 * @interface SyncProductsFromIntegrationAdminCommand
 */
export interface SyncProductsFromIntegrationAdminCommand {
    /**
     * 
     * @type {string}
     * @memberof SyncProductsFromIntegrationAdminCommand
     */
    integrationId: string;
    /**
     * 
     * @type {object}
     * @memberof SyncProductsFromIntegrationAdminCommand
     */
    syncAllMethod?: object;
    /**
     * 
     * @type {SyncProductsByTimeRange}
     * @memberof SyncProductsFromIntegrationAdminCommand
     */
    syncByTimeRangeMethod?: SyncProductsByTimeRange;
    /**
     * 
     * @type {SyncProductsBySkus}
     * @memberof SyncProductsFromIntegrationAdminCommand
     */
    syncBySkuMethod?: SyncProductsBySkus;
    /**
     * 
     * @type {SyncProductsByBarcodes}
     * @memberof SyncProductsFromIntegrationAdminCommand
     */
    syncByBarcodeMethod?: SyncProductsByBarcodes;
}
/**
 * 
 * @export
 * @interface SyncReceivingOrderFromMaestroCommand
 */
export interface SyncReceivingOrderFromMaestroCommand {
    /**
     * 
     * @type {string}
     * @memberof SyncReceivingOrderFromMaestroCommand
     */
    operationName: string;
    /**
     * 
     * @type {string}
     * @memberof SyncReceivingOrderFromMaestroCommand
     */
    referenceNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof SyncReceivingOrderFromMaestroCommand
     */
    processedAt: Date;
    /**
     * 
     * @type {MaestroReceivingOrderState}
     * @memberof SyncReceivingOrderFromMaestroCommand
     */
    state: MaestroReceivingOrderState;
    /**
     * 
     * @type {Date}
     * @memberof SyncReceivingOrderFromMaestroCommand
     */
    expectedDeliveryDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SyncReceivingOrderFromMaestroCommand
     */
    arrivedToWarehouseAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SyncReceivingOrderFromMaestroCommand
     */
    completedAt?: Date;
    /**
     * 
     * @type {ReceivingOrderArrivalState}
     * @memberof SyncReceivingOrderFromMaestroCommand
     */
    arrivalState: ReceivingOrderArrivalState;
    /**
     * 
     * @type {MaestroReceivingOrderLineItemSyncDTO}
     * @memberof SyncReceivingOrderFromMaestroCommand
     */
    affectedLineItem?: MaestroReceivingOrderLineItemSyncDTO;
    /**
     * 
     * @type {MaestroReceivingOrderExtendedState}
     * @memberof SyncReceivingOrderFromMaestroCommand
     */
    extendedState: MaestroReceivingOrderExtendedState;
}
/**
 * 
 * @export
 * @interface SyncSalesOrderVASRequestDTO
 */
export interface SyncSalesOrderVASRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof SyncSalesOrderVASRequestDTO
     */
    packageNote: string;
    /**
     * 
     * @type {CreateSalesOrderGiftVASRequestDTO}
     * @memberof SyncSalesOrderVASRequestDTO
     */
    giftVAS?: CreateSalesOrderGiftVASRequestDTO;
    /**
     * 
     * @type {CreateSalesOrderDocumentVASRequestDTO}
     * @memberof SyncSalesOrderVASRequestDTO
     */
    documentVAS?: CreateSalesOrderDocumentVASRequestDTO;
}
/**
 * 
 * @export
 * @interface TSoftIntegrationConfigurations
 */
export interface TSoftIntegrationConfigurations {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TSoftIntegrationConfigurations
     */
    cargoCompanies?: { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof TSoftIntegrationConfigurations
     */
    fulfillmentWaitingOrderStatusCode?: Array<string>;
}
/**
 * 
 * @export
 * @interface TSoftIntegrationPayload
 */
export interface TSoftIntegrationPayload {
    /**
     * 
     * @type {Array<ECommerceCargoCompany>}
     * @memberof TSoftIntegrationPayload
     */
    cargoCompanies?: Array<ECommerceCargoCompany>;
    /**
     * 
     * @type {Array<ECommerceSalesOrderStatuses>}
     * @memberof TSoftIntegrationPayload
     */
    salesOrderStatuses?: Array<ECommerceSalesOrderStatuses>;
}
/**
 * 
 * @export
 * @interface Tenant
 */
export interface Tenant {
    /**
     * 
     * @type {Array<Product>}
     * @memberof Tenant
     */
    products?: Array<Product>;
    /**
     * 
     * @type {Array<ReceivingOrder>}
     * @memberof Tenant
     */
    receivingOrders?: Array<ReceivingOrder>;
    /**
     * 
     * @type {Array<Customer>}
     * @memberof Tenant
     */
    customers?: Array<Customer>;
    /**
     * 
     * @type {Array<InventoryItem>}
     * @memberof Tenant
     */
    inventory?: Array<InventoryItem>;
    /**
     * 
     * @type {Array<SalesOrder>}
     * @memberof Tenant
     */
    salesOrders?: Array<SalesOrder>;
    /**
     * 
     * @type {Array<Integration>}
     * @memberof Tenant
     */
    integrations?: Array<Integration>;
    /**
     * 
     * @type {Array<Return>}
     * @memberof Tenant
     */
    returns?: Array<Return>;
    /**
     * 
     * @type {Array<InvalidSalesOrder>}
     * @memberof Tenant
     */
    invalidSalesOrders?: Array<InvalidSalesOrder>;
    /**
     * 
     * @type {Array<TenantConfiguration>}
     * @memberof Tenant
     */
    tenantConfigurations?: Array<TenantConfiguration>;
    /**
     * 
     * @type {Array<WebHook>}
     * @memberof Tenant
     */
    webHooks?: Array<WebHook>;
    /**
     * 
     * @type {Company}
     * @memberof Tenant
     */
    company?: Company;
    /**
     * 
     * @type {Date}
     * @memberof Tenant
     */
    inventoryLastSyncedAtFromWMS?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Tenant
     */
    salesOrdersLastSyncedAtFromWMS?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Tenant
     */
    receivingOrdersLastSyncedAtFromWMS?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Tenant
     */
    returnLastSyncedAtFromWMS?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Tenant
     */
    packagesLastSyncedAtFromWMS?: Date;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    warehouseCode: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    customerCode: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    contractNo: string;
    /**
     * 
     * @type {Date}
     * @memberof Tenant
     */
    contractStartDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Tenant
     */
    contractEndDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Tenant
     */
    wmsTenant: boolean;
    /**
     * 
     * @type {WarehouseType}
     * @memberof Tenant
     */
    warehouseType: WarehouseType;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    warehouseLocation: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    staticToken: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof Tenant
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Tenant
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Tenant
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @interface TenantConfiguration
 */
export interface TenantConfiguration {
    /**
     * 
     * @type {Tenant}
     * @memberof TenantConfiguration
     */
    tenant?: Tenant;
    /**
     * 
     * @type {string}
     * @memberof TenantConfiguration
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof TenantConfiguration
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof TenantConfiguration
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof TenantConfiguration
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof TenantConfiguration
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof TenantConfiguration
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TenantConfiguration
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof TenantConfiguration
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof TenantConfiguration
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof TenantConfiguration
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @interface TenantDetailOutputDTO
 */
export interface TenantDetailOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof TenantDetailOutputDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDetailOutputDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDetailOutputDTO
     */
    warehouseCode: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDetailOutputDTO
     */
    customerCode: string;
    /**
     * 
     * @type {number}
     * @memberof TenantDetailOutputDTO
     */
    taxRatio: number;
    /**
     * 
     * @type {number}
     * @memberof TenantDetailOutputDTO
     */
    termDay: number;
    /**
     * 
     * @type {string}
     * @memberof TenantDetailOutputDTO
     */
    contractNo: string;
    /**
     * 
     * @type {Date}
     * @memberof TenantDetailOutputDTO
     */
    contractStartDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TenantDetailOutputDTO
     */
    contractEndDate?: Date;
}
/**
 * 
 * @export
 * @interface TenantDetailsDTO
 */
export interface TenantDetailsDTO {
    /**
     * 
     * @type {string}
     * @memberof TenantDetailsDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDetailsDTO
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof TenantDetailsDTO
     */
    isSameDayDeliveryActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TenantDetailsDTO
     */
    isUIBulkCancelBlockedAllowed: boolean;
    /**
     * 
     * @type {number}
     * @memberof TenantDetailsDTO
     */
    salesOrdersPackageDetailExcelExportLimit: number;
    /**
     * 
     * @type {number}
     * @memberof TenantDetailsDTO
     */
    salesOrdersBlockedPackageDetailExcelExportLimit: number;
    /**
     *
     * @type {boolean}
     * @memberof TenantDetailsDTO
     */
    isExternalCustomerIdAllowed: boolean;
    /**
     *
     * @type {boolean}
     * @memberof TenantDetailsDTO
     */
    isLostItemAmountVisible: boolean;
}
/**
 * 
 * @export
 * @interface TenantDetailsQueryOutputDTO
 */
export interface TenantDetailsQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof TenantDetailsQueryOutputDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDetailsQueryOutputDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDetailsQueryOutputDTO
     */
    warehouseCode: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDetailsQueryOutputDTO
     */
    warehouseLocation: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDetailsQueryOutputDTO
     */
    customerCode: string;
    /**
     * 
     * @type {Date}
     * @memberof TenantDetailsQueryOutputDTO
     */
    salesOrdersLastSyncedAtFromWMS?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TenantDetailsQueryOutputDTO
     */
    packagesLastSyncedAtFromWMS?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TenantDetailsQueryOutputDTO
     */
    returnLastSyncedAtFromWMS?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TenantDetailsQueryOutputDTO
     */
    receivingOrdersLastSyncedAtFromWMS?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TenantDetailsQueryOutputDTO
     */
    inventoryLastSyncedAtFromWMS?: Date;
}
/**
 * 
 * @export
 * @interface TenantDetailsQueryOutputDTODynamicQueryOutputDTO
 */
export interface TenantDetailsQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<TenantDetailsQueryOutputDTO>}
     * @memberof TenantDetailsQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<TenantDetailsQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof TenantDetailsQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface TenantLastSyncTimeOutputDTO
 */
export interface TenantLastSyncTimeOutputDTO {
    /**
     * 
     * @type {Date}
     * @memberof TenantLastSyncTimeOutputDTO
     */
    tenantLastSyncTime?: Date;
}
/**
 * 
 * @export
 * @interface TenantOutputDTO
 */
export interface TenantOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof TenantOutputDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TenantOutputDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TenantOutputDTO
     */
    warehouseCode: string;
    /**
     * 
     * @type {string}
     * @memberof TenantOutputDTO
     */
    customerCode: string;
}
/**
 * 
 * @export
 * @interface TenantQueryOutputDTO
 */
export interface TenantQueryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof TenantQueryOutputDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TenantQueryOutputDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TenantQueryOutputDTO
     */
    warehouseCode: string;
    /**
     * 
     * @type {string}
     * @memberof TenantQueryOutputDTO
     */
    customerCode: string;
    /**
     * 
     * @type {Date}
     * @memberof TenantQueryOutputDTO
     */
    inventoryLastSyncedAtFromWMS?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TenantQueryOutputDTO
     */
    salesOrdersLastSyncedAtFromWMS?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TenantQueryOutputDTO
     */
    receivingOrdersLastSyncedAtFromWMS?: Date;
}
/**
 * 
 * @export
 * @interface TenantQueryOutputDTODynamicQueryOutputDTO
 */
export interface TenantQueryOutputDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<TenantQueryOutputDTO>}
     * @memberof TenantQueryOutputDTODynamicQueryOutputDTO
     */
    data?: Array<TenantQueryOutputDTO>;
    /**
     * 
     * @type {number}
     * @memberof TenantQueryOutputDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @interface TenantSummaryOutputDTO
 */
export interface TenantSummaryOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof TenantSummaryOutputDTO
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof TenantSummaryOutputDTO
     */
    salesOrderCount: number;
    /**
     * 
     * @type {number}
     * @memberof TenantSummaryOutputDTO
     */
    productCount: number;
    /**
     * 
     * @type {number}
     * @memberof TenantSummaryOutputDTO
     */
    customerCount: number;
    /**
     * 
     * @type {number}
     * @memberof TenantSummaryOutputDTO
     */
    receivingOrderCount: number;
    /**
     * 
     * @type {number}
     * @memberof TenantSummaryOutputDTO
     */
    inventoryCount: number;
    /**
     * 
     * @type {number}
     * @memberof TenantSummaryOutputDTO
     */
    integrationCount: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TenantSyncType {
    InventoryFromWMS = 'InventoryFromWMS',
    ReceivingOrdersFromWMS = 'ReceivingOrdersFromWMS',
    SalesOrdersFromWMS = 'SalesOrdersFromWMS',
    ReturnsFromWMS = 'ReturnsFromWMS',
    PackagesFromWMS = 'PackagesFromWMS',
    ProductCatalogToWMS = 'ProductCatalogToWMS',
    ReceivingOrdersToWMS = 'ReceivingOrdersToWMS',
    ReturnsToWMS = 'ReturnsToWMS',
    PackagesToWMS = 'PackagesToWMS'
}

/**
 * 
 * @export
 * @interface TestSendDTO
 */
export interface TestSendDTO {
    /**
     * 
     * @type {string}
     * @memberof TestSendDTO
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof TestSendDTO
     */
    userId: string;
}
/**
 * 
 * @export
 * @interface TicimaxIntegrationConfigurations
 */
export interface TicimaxIntegrationConfigurations {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TicimaxIntegrationConfigurations
     */
    cargoCompanies?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof TicimaxIntegrationConfigurations
     */
    isReturnUndamagedProductsOnly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TicimaxIntegrationConfigurations
     */
    getOrdersWithCargoCodeOnly?: boolean;
}
/**
 * 
 * @export
 * @interface TicimaxIntegrationPayload
 */
export interface TicimaxIntegrationPayload {
    /**
     * 
     * @type {Array<ECommerceCargoCompany>}
     * @memberof TicimaxIntegrationPayload
     */
    cargoCompanies?: Array<ECommerceCargoCompany>;
}
/**
 * 
 * @export
 * @interface TimeRangeInputDTO
 */
export interface TimeRangeInputDTO {
    /**
     * 
     * @type {Date}
     * @memberof TimeRangeInputDTO
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof TimeRangeInputDTO
     */
    end: Date;
}
/**
 * 
 * @export
 * @interface UpdateAddressOfCustomerCommand
 */
export interface UpdateAddressOfCustomerCommand {
    /**
     * 
     * @type {number}
     * @memberof UpdateAddressOfCustomerCommand
     */
    addressNo: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAddressOfCustomerCommand
     */
    customerNo: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressOfCustomerCommand
     */
    addressFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressOfCustomerCommand
     */
    addressLastName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressOfCustomerCommand
     */
    company: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressOfCustomerCommand
     */
    addressOne: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressOfCustomerCommand
     */
    addressTwo: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressOfCustomerCommand
     */
    district: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressOfCustomerCommand
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressOfCustomerCommand
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressOfCustomerCommand
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressOfCustomerCommand
     */
    addressPhone: string;
}
/**
 * 
 * @export
 * @interface UpdateAddressRequestDTO
 */
export interface UpdateAddressRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressRequestDTO
     */
    addressFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressRequestDTO
     */
    addressLastName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressRequestDTO
     */
    company: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressRequestDTO
     */
    addressOne: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressRequestDTO
     */
    addressTwo: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressRequestDTO
     */
    district: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressRequestDTO
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressRequestDTO
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressRequestDTO
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressRequestDTO
     */
    addressPhone: string;
}
/**
 * 
 * @export
 * @interface UpdateCargoCodeAndUrlCommand
 */
export interface UpdateCargoCodeAndUrlCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateCargoCodeAndUrlCommand
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCargoCodeAndUrlCommand
     */
    tenantName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCargoCodeAndUrlCommand
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCargoCodeAndUrlCommand
     */
    cargoCode: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCargoCodeAndUrlCommand
     */
    cargoDocumentUrl: string;
}
/**
 * 
 * @export
 * @interface UpdateCompanyCommand
 */
export interface UpdateCompanyCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyCommand
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyCommand
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyCommand
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyCommand
     */
    taxAdministration: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyCommand
     */
    taxNo: string;
}
/**
 * 
 * @export
 * @interface UpdateCustomerInfoCommand
 */
export interface UpdateCustomerInfoCommand {
    /**
     * 
     * @type {number}
     * @memberof UpdateCustomerInfoCommand
     */
    customerNo: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerInfoCommand
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerInfoCommand
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerInfoCommand
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface UpdateCustomerRequestDTO
 */
export interface UpdateCustomerRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerRequestDTO
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerRequestDTO
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerRequestDTO
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface UpdateIntegrationCommand
 */
export interface UpdateIntegrationCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegrationCommand
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIntegrationCommand
     */
    isActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegrationCommand
     */
    integrationName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegrationCommand
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegrationCommand
     */
    apiUserName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegrationCommand
     */
    apiPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegrationCommand
     */
    shopLink?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegrationCommand
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegrationCommand
     */
    clientSecret?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIntegrationCommand
     */
    shouldSyncMissingData: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIntegrationCommand
     */
    shouldSyncMissingOrders: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIntegrationCommand
     */
    shouldSyncMissingProducts: boolean;
    /**
     * 
     * @type {IntegrationType}
     * @memberof UpdateIntegrationCommand
     */
    type: IntegrationType;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIntegrationCommand
     */
    isCoDActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIntegrationCommand
     */
    isSameAsBarcode?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIntegrationCommand
     */
    skuWithBarcode?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIntegrationCommand
     */
    isSubProductActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIntegrationCommand
     */
    isTestEnvironment?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegrationCommand
     */
    partialFulfillmentServiceName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIntegrationCommand
     */
    isPartialFulfillmentActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationOutputDTO
     */
    isSameDayDeliveryActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IntegrationOutputDTO
     */
    sameDayDeliveryKey?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegrationCommand
     */
    cashKey?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegrationCommand
     */
    creditCardKey?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateIntegrationCommand
     */
    locationId?: number;
    /**
     * 
     * @type {TSoftIntegrationConfigurations}
     * @memberof UpdateIntegrationCommand
     */
    tSoftIntegrationConfigurations?: TSoftIntegrationConfigurations;
    /**
     * 
     * @type {TicimaxIntegrationConfigurations}
     * @memberof UpdateIntegrationCommand
     */
    ticimaxIntegrationConfigurations?: TicimaxIntegrationConfigurations;
    /**
     * 
     * @type {WooCommerceIntegrationConfigurations}
     * @memberof UpdateIntegrationCommand
     */
    wooCommerceIntegrationConfigurations?: WooCommerceIntegrationConfigurations;
    /**
     * 
     * @type {TSoftIntegrationPayload}
     * @memberof UpdateIntegrationCommand
     */
    tSoftIntegrationPayload?: TSoftIntegrationPayload;
    /**
     * 
     * @type {TicimaxIntegrationPayload}
     * @memberof UpdateIntegrationCommand
     */
    ticimaxIntegrationPayload?: TicimaxIntegrationPayload;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegrationCommand
     */
    tenantSecret?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIntegrationCommand
     */
    isProductSyncActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIntegrationCommand
     */
    isMarketplaceSyncActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIntegrationCommand
     */
    isWebsiteSyncActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIntegrationCommand
     */
    isSendOrdersByOplogActive?: boolean;
    /**
     * 
     * @type {OpenApiIdentifier}
     * @memberof UpdateIntegrationCommand
     */
    openApiIdentifier: OpenApiIdentifier;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegrationCommand
     */
    productBarcodeKeyField?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIntegrationCommand
     */
    isActiveOrderNumber?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegrationCommand
     */
    storeFrontCode?: string;
}
/**
 * 
 * @export
 * @interface UpdateMaestroCredentialsCommand
 */
export interface UpdateMaestroCredentialsCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateMaestroCredentialsCommand
     */
    tenantId: string;
    /**
     * 
     * @type {WarehouseType}
     * @memberof UpdateMaestroCredentialsCommand
     */
    warehouseType: WarehouseType;
    /**
     * 
     * @type {string}
     * @memberof UpdateMaestroCredentialsCommand
     */
    warehouseLocation: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMaestroCredentialsCommand
     */
    maestroClientId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMaestroCredentialsCommand
     */
    maestroClientSecret: string;
}
/**
 * 
 * @export
 * @interface UpdateProductCommand
 */
export interface UpdateProductCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateProductCommand
     */
    sku: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductCommand
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductCommand
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProductCommand
     */
    barcodes?: Array<string>;
    /**
     * 
     * @type {ProductType}
     * @memberof UpdateProductCommand
     */
    type: ProductType;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductCommand
     */
    category: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductCommand
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductCommand
     */
    salePrice?: number;
    /**
     * 
     * @type {Currency}
     * @memberof UpdateProductCommand
     */
    currency?: Currency;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductCommand
     */
    taxRatio?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductCommand
     */
    salesUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProductCommand
     */
    sioc?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductCommand
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductCommand
     */
    weight?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductCommand
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductCommand
     */
    length?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductCommand
     */
    unitOfDimension?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductCommand
     */
    unitOfWeight?: string;
    /**
     * 
     * @type {PCSDimensionsDTO}
     * @memberof UpdateProductCommand
     */
    dimensions?: PCSDimensionsDTO;
}
/**
 * 
 * @export
 * @interface UpdateProductFavoriteCommand
 */
export interface UpdateProductFavoriteCommand {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProductFavoriteCommand
     */
    favorite: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductFavoriteCommand
     */
    sku: string;
}
/**
 * 
 * @export
 * @interface UpdateProductRequestDTO
 */
export interface UpdateProductRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateProductRequestDTO
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductRequestDTO
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProductRequestDTO
     */
    barcodes?: Array<string>;
    /**
     * 
     * @type {ProductType}
     * @memberof UpdateProductRequestDTO
     */
    type: ProductType;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductRequestDTO
     */
    category: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductRequestDTO
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductRequestDTO
     */
    salePrice?: number;
    /**
     * 
     * @type {Currency}
     * @memberof UpdateProductRequestDTO
     */
    currency?: Currency;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductRequestDTO
     */
    taxRatio?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductRequestDTO
     */
    salesUrl: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductRequestDTO
     */
    integrationId?: string;
}
/**
 * 
 * @export
 * @interface UpdateProductStateCommand
 */
export interface UpdateProductStateCommand {
    /**
     * 
     * @type {ProductState}
     * @memberof UpdateProductStateCommand
     */
    state: ProductState;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductStateCommand
     */
    sku: string;
}
/**
 * 
 * @export
 * @interface UpdateReceivingOrderTransferDetailCommand
 */
export interface UpdateReceivingOrderTransferDetailCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateReceivingOrderTransferDetailCommand
     */
    referenceNumber: string;
    /**
     * 
     * @type {Array<CreateReceivingOrderLineItemInputDTO>}
     * @memberof UpdateReceivingOrderTransferDetailCommand
     */
    lineItems?: Array<CreateReceivingOrderLineItemInputDTO>;
    /**
     * 
     * @type {ReceivingOrderTransferType}
     * @memberof UpdateReceivingOrderTransferDetailCommand
     */
    transferType: ReceivingOrderTransferType;
    /**
     * 
     * @type {CargoTransferDTO}
     * @memberof UpdateReceivingOrderTransferDetailCommand
     */
    cargoTransfer?: CargoTransferDTO;
    /**
     * 
     * @type {PickupTransferDTO}
     * @memberof UpdateReceivingOrderTransferDetailCommand
     */
    pickupTransfer?: PickupTransferDTO;
    /**
     * 
     * @type {CarriageTransferDTO}
     * @memberof UpdateReceivingOrderTransferDetailCommand
     */
    carriageTransfer?: CarriageTransferDTO;
}
/**
 * 
 * @export
 * @interface UpdateSalesOrderCargoInfoInputDTO
 */
export interface UpdateSalesOrderCargoInfoInputDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateSalesOrderCargoInfoInputDTO
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSalesOrderCargoInfoInputDTO
     */
    cargoCarrier: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSalesOrderCargoInfoInputDTO
     */
    cargoDocumentUrl: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSalesOrderCargoInfoInputDTO
     */
    cargoCode: string;
}
/**
 * 
 * @export
 * @interface UpdateReceivingOrderTransferDetailsRequestDTO
 */
export interface UpdateReceivingOrderTransferDetailsRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateReceivingOrderTransferDetailsRequestDTO
     */
    integrationId?: string;
    /**
     * 
     * @type {ReceivingOrderTransferType}
     * @memberof UpdateReceivingOrderTransferDetailsRequestDTO
     */
    transferType: ReceivingOrderTransferType;
    /**
     * 
     * @type {CargoTransferDTO}
     * @memberof UpdateReceivingOrderTransferDetailsRequestDTO
     */
    cargoTransfer?: CargoTransferDTO;
    /**
     * 
     * @type {PickupTransferDTO}
     * @memberof UpdateReceivingOrderTransferDetailsRequestDTO
     */
    pickupTransfer?: PickupTransferDTO;
    /**
     * 
     * @type {CarriageTransferDTO}
     * @memberof UpdateReceivingOrderTransferDetailsRequestDTO
     */
    carriageTransfer?: CarriageTransferDTO;
}
/**
 * 
 * @export
 * @interface UpdateSyncedIntegrationCommand
 */
export interface UpdateSyncedIntegrationCommand {
    /**
     * 
     * @type {IntegrationSyncType}
     * @memberof UpdateSyncedIntegrationCommand
     */
    syncType: IntegrationSyncType;
    /**
     * 
     * @type {Date}
     * @memberof UpdateSyncedIntegrationCommand
     */
    syncTime: Date;
    /**
     * 
     * @type {string}
     * @memberof UpdateSyncedIntegrationCommand
     */
    integrationId: string;
}
/**
 * 
 * @export
 * @interface UpdateTenantCommand
 */
export interface UpdateTenantCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantCommand
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantCommand
     */
    contractNo?: string;
    /**
     * 
     * @type {Date}
     * @memberof UpdateTenantCommand
     */
    contractStartDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UpdateTenantCommand
     */
    contractEndDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof UpdateTenantCommand
     */
    taxRatio: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateTenantCommand
     */
    termDay: number;
}
/**
 * 
 * @export
 * @interface UpdateTenantConfigurationCommand
 */
export interface UpdateTenantConfigurationCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantConfigurationCommand
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantConfigurationCommand
     */
    isSameDayDeliveryActive: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantConfigurationCommand
     */
    isGiftNoteActive: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantConfigurationCommand
     */
    isUIBulkCancelBlockedAllowed: string;
}
/**
 * 
 * @export
 * @interface UpdateUserCommand
 */
export interface UpdateUserCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    userId: string;
    /**
     * 
     * @type {object}
     * @memberof UpdateUserCommand
     */
    appMetadata?: object;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserCommand
     */
    emailVerified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    phoneNumber: string;
    /**
     * 
     * @type {object}
     * @memberof UpdateUserCommand
     */
    userMetadata?: object;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    connection: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    password: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserCommand
     */
    phoneVerified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserCommand
     */
    verifyEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserCommand
     */
    verifyPassword?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserCommand
     */
    verifyPhoneNumber?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    clientId: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserCommand
     */
    blocked?: boolean;
}
/**
 * 
 * @export
 * @interface UpdatedReceivingOrderTransferDetailOutputDTO
 */
export interface UpdatedReceivingOrderTransferDetailOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdatedReceivingOrderTransferDetailOutputDTO
     */
    id: string;
    /**
     * 
     * @type {ReceivingOrderTransferType}
     * @memberof UpdatedReceivingOrderTransferDetailOutputDTO
     */
    transferType: ReceivingOrderTransferType;
    /**
     * 
     * @type {ReceivingOrderTransferDTO}
     * @memberof UpdatedReceivingOrderTransferDetailOutputDTO
     */
    transferDetails?: ReceivingOrderTransferDTO;
}
/**
 * 
 * @export
 * @interface UploadFileOutputDTO
 */
export interface UploadFileOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof UploadFileOutputDTO
     */
    url: string;
}
/**
 * 
 * @export
 * @interface UpsertProductsCommandResult
 */
export interface UpsertProductsCommandResult {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpsertProductsCommandResult
     */
    createFailures?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpsertProductsCommandResult
     */
    updateFailures?: { [key: string]: string; };
    /**
     * 
     * @type {Array<Product>}
     * @memberof UpsertProductsCommandResult
     */
    successful?: Array<Product>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpsertProductsCommandResult
     */
    duplicateSKUs?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    email: string;
    /**
     * 
     * @type {Date}
     * @memberof UserDTO
     */
    createdAt?: Date;
    /**
     * 
     * @type {UserType}
     * @memberof UserDTO
     */
    type: UserType;
    /**
     * 
     * @type {UserStatus}
     * @memberof UserDTO
     */
    status: UserStatus;
}
/**
 * 
 * @export
 * @interface UserDTODynamicQueryOutputDTO
 */
export interface UserDTODynamicQueryOutputDTO {
    /**
     * 
     * @type {Array<UserDTO>}
     * @memberof UserDTODynamicQueryOutputDTO
     */
    data?: Array<UserDTO>;
    /**
     * 
     * @type {number}
     * @memberof UserDTODynamicQueryOutputDTO
     */
    count: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum UserStatus {
    None = 'None',
    Pending = 'Pending',
    Accepted = 'Accepted'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserType {
    None = 'None',
    Owner = 'Owner',
    Regular = 'Regular'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ValidationErrorCodesForProduct {
    ProductSKUAlreadyExists = 'ProductSKUAlreadyExists',
    ProductBarcodeAlreadyExists = 'ProductBarcodeAlreadyExists',
    SameBarcodeForDifferentSkusAtBarcodesSheetError = 'SameBarcodeForDifferentSkusAtBarcodesSheetError',
    ProductSKUIsEmpty = 'ProductSKUIsEmpty',
    ProductBarcodeIsEmpty = 'ProductBarcodeIsEmpty',
    ProductNameIsEmpty = 'ProductNameIsEmpty',
    Unknown = 'Unknown',
    ProductNotFound = 'ProductNotFound',
    SalesUrlIsNotValid = 'SalesUrlIsNotValid',
    CurrencyShouldExist = 'CurrencyShouldExist',
    PriceOrSalesPriceIsEmpty = 'PriceOrSalesPriceIsEmpty',
    BarcodeCantBeChanged = 'BarcodeCantBeChanged',
    MissingBarcodeInUpdate = 'MissingBarcodeInUpdate',
    IntegrationIdCantChange = 'IntegrationIdCantChange',
    BarcodeUsedByMultProductsInReq = 'BarcodeUsedByMultProductsInReq',
    DuplicationInAProductBarcodeInReq = 'DuplicationInAProductBarcodeInReq',
    MissingDimensions = 'MissingDimensions',
}

/**
 * 
 * @export
 * @interface WarehouseInventoryItem
 */
export interface WarehouseInventoryItem {
    /**
     * 
     * @type {number}
     * @memberof WarehouseInventoryItem
     */
    availableStockAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof WarehouseInventoryItem
     */
    receivedItemAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof WarehouseInventoryItem
     */
    damagedItemAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof WarehouseInventoryItem
     */
    expiredAmount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WarehouseInventoryItem
     */
    availableStockAmountChanged: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum WarehouseType {
    None = 'None',
    WMS = 'WMS',
    Maestro = 'Maestro',
    Biz = 'Biz'
}

/**
 * 
 * @export
 * @interface WayBill
 */
export interface WayBill {
    /**
     * 
     * @type {ReceivingOrder}
     * @memberof WayBill
     */
    receivingOrder?: ReceivingOrder;
    /**
     * 
     * @type {string}
     * @memberof WayBill
     */
    wayBillNo: string;
    /**
     * 
     * @type {Date}
     * @memberof WayBill
     */
    wayBillCreatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof WayBill
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof WayBill
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof WayBill
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof WayBill
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof WayBill
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof WayBill
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof WayBill
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @interface WayBillOfReceivingOrderResponseDTO
 */
export interface WayBillOfReceivingOrderResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof WayBillOfReceivingOrderResponseDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof WayBillOfReceivingOrderResponseDTO
     */
    wayBillNo: string;
    /**
     * 
     * @type {Date}
     * @memberof WayBillOfReceivingOrderResponseDTO
     */
    wayBillCreatedAt?: Date;
}
/**
 * 
 * @export
 * @interface WebHook
 */
export interface WebHook {
    /**
     * 
     * @type {Tenant}
     * @memberof WebHook
     */
    tenant?: Tenant;
    /**
     * 
     * @type {WebHookAuth}
     * @memberof WebHook
     */
    auth?: WebHookAuth;
    /**
     * 
     * @type {WebHookType}
     * @memberof WebHook
     */
    hookType: WebHookType;
    /**
     * 
     * @type {WebHookStatus}
     * @memberof WebHook
     */
    status: WebHookStatus;
    /**
     * 
     * @type {string}
     * @memberof WebHook
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof WebHook
     */
    bodyTemplate: string;
    /**
     * 
     * @type {string}
     * @memberof WebHook
     */
    headerTemplate: string;
    /**
     * 
     * @type {string}
     * @memberof WebHook
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof WebHook
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof WebHook
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof WebHook
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof WebHook
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof WebHook
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof WebHook
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof WebHook
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @interface WebHookAuth
 */
export interface WebHookAuth {
    /**
     * 
     * @type {string}
     * @memberof WebHookAuth
     */
    webHookId: string;
    /**
     * 
     * @type {WebHook}
     * @memberof WebHookAuth
     */
    webHook?: WebHook;
    /**
     * 
     * @type {WebHookAuthType}
     * @memberof WebHookAuth
     */
    authType: WebHookAuthType;
    /**
     * 
     * @type {string}
     * @memberof WebHookAuth
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof WebHookAuth
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof WebHookAuth
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof WebHookAuth
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof WebHookAuth
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof WebHookAuth
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof WebHookAuth
     */
    updatedBy: string;
    /**
     * 
     * @type {string}
     * @memberof WebHookAuth
     */
    deletedBy: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum WebHookAuthType {
    Basic = 'Basic'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum WebHookStatus {
    Enable = 'Enable',
    Disable = 'Disable'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum WebHookType {
    PackageFulfillment = 'PackageFulfillment',
    Inventory = 'Inventory',
    ReceivingOrderCompleted = 'ReceivingOrderCompleted',
    PackageFulfillmentV2 = 'PackageFulfillmentV2',
    InventoryV2 = 'InventoryV2',
    ReturnCompleted = 'ReturnCompleted'
}

/**
 * 
 * @export
 * @interface WeeklyReportOutputDTO
 */
export interface WeeklyReportOutputDTO {
    /**
     * 
     * @type {Array<DailyReportOutputDTO>}
     * @memberof WeeklyReportOutputDTO
     */
    report?: Array<DailyReportOutputDTO>;
}
/**
 * 
 * @export
 * @interface Weight
 */
export interface Weight {
    /**
     * 
     * @type {string}
     * @memberof Weight
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof Weight
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface Width
 */
export interface Width {
    /**
     * 
     * @type {string}
     * @memberof Width
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof Width
     */
    value?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum WmsSyncState {
    None = 'None',
    InterCreated = 'InterCreated',
    InterFailed = 'InterFailed'
}

/**
 * 
 * @export
 * @interface WooCommerceIntegrationConfigurations
 */
export interface WooCommerceIntegrationConfigurations {
    /**
     * 
     * @type {WooCommerceShipmentTrackingExtensions}
     * @memberof WooCommerceIntegrationConfigurations
     */
    shipmentTrackingExtension?: WooCommerceShipmentTrackingExtensions;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum WooCommerceShipmentTrackingExtensions {
    AUTHOR = 'AUTHOR'
}

/**
 * CargoCarriersApi - axios parameter creator
 * @export
 */
export const CargoCarriersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cargo carriers used by oplog.one.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCargoCarriersGet(options: any = {}): RequestArgs {
            const localVarPath = `/api/CargoCarriers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cargo carriers used by oplog.one open api.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1CargoCarriersGet(options: any = {}): RequestArgs {
            const localVarPath = `/openapi/v1/CargoCarriers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CargoCarriersApi - functional programming interface
 * @export
 */
export const CargoCarriersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cargo carriers used by oplog.one.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCargoCarriersGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CargoCarriersApiDTO>> {
            const localVarAxiosArgs = CargoCarriersApiAxiosParamCreator(configuration).apiCargoCarriersGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Cargo carriers used by oplog.one open api.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1CargoCarriersGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CargoCarriersOpenApiDTO>> {
            const localVarAxiosArgs = CargoCarriersApiAxiosParamCreator(configuration).openapiV1CargoCarriersGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CargoCarriersApi - factory interface
 * @export
 */
export const CargoCarriersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Cargo carriers used by oplog.one.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCargoCarriersGet(options?: any) {
            return CargoCarriersApiFp(configuration).apiCargoCarriersGet(options)(axios, basePath);
        },
        /**
         * 
         * @summary Cargo carriers used by oplog.one open api.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1CargoCarriersGet(options?: any) {
            return CargoCarriersApiFp(configuration).openapiV1CargoCarriersGet(options)(axios, basePath);
        },
    };
};

/**
 * CargoCarriersApi - object-oriented interface
 * @export
 * @class CargoCarriersApi
 * @extends {BaseAPI}
 */
export class CargoCarriersApi extends BaseAPI {
    /**
     * 
     * @summary Cargo carriers used by oplog.one.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CargoCarriersApi
     */
    public apiCargoCarriersGet(options?: any) {
        return CargoCarriersApiFp(this.configuration).apiCargoCarriersGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Cargo carriers used by oplog.one open api.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CargoCarriersApi
     */
    public openapiV1CargoCarriersGet(options?: any) {
        return CargoCarriersApiFp(this.configuration).openapiV1CargoCarriersGet(options)(this.axios, this.basePath);
    }

}


/**
 * CustomersApi - axios parameter creator
 * @export
 */
export const CustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddAddressToCustomerCommand} [addAddressToCustomerCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersAddAddressPost(addAddressToCustomerCommand?: AddAddressToCustomerCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/Customers.addAddress`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof addAddressToCustomerCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(addAddressToCustomerCommand !== undefined ? addAddressToCustomerCommand : {}) : (addAddressToCustomerCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [customerNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersAddressesGet(customerNo?: number, options: any = {}): RequestArgs {
            const localVarPath = `/api/Customers.addresses`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (customerNo !== undefined) {
                localVarQueryParameter['customerNo'] = customerNo;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateCustomerCommand} [createCustomerCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersCreatePost(createCustomerCommand?: CreateCustomerCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/Customers.create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createCustomerCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createCustomerCommand !== undefined ? createCustomerCommand : {}) : (createCustomerCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [customerNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersDetailGet(customerNo?: number, options: any = {}): RequestArgs {
            const localVarPath = `/api/Customers.detail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (customerNo !== undefined) {
                localVarQueryParameter['customerNo'] = customerNo;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersExportAddressesToExcelGet(customerNo: number, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'customerNo' is not null or undefined
            if (customerNo === null || customerNo === undefined) {
                throw new RequiredError('customerNo','Required parameter customerNo was null or undefined when calling apiCustomersExportAddressesToExcelGet.');
            }
            const localVarPath = `/api/Customers.exportAddressesToExcel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (customerNo !== undefined) {
                localVarQueryParameter['customerNo'] = customerNo;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersExportLineItemsToExcelGet(customerNo: number, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'customerNo' is not null or undefined
            if (customerNo === null || customerNo === undefined) {
                throw new RequiredError('customerNo','Required parameter customerNo was null or undefined when calling apiCustomersExportLineItemsToExcelGet.');
            }
            const localVarPath = `/api/Customers.exportLineItemsToExcel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (customerNo !== undefined) {
                localVarQueryParameter['customerNo'] = customerNo;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersExportSalesOrdersToExcelGet(customerNo: number, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'customerNo' is not null or undefined
            if (customerNo === null || customerNo === undefined) {
                throw new RequiredError('customerNo','Required parameter customerNo was null or undefined when calling apiCustomersExportSalesOrdersToExcelGet.');
            }
            const localVarPath = `/api/Customers.exportSalesOrdersToExcel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (customerNo !== undefined) {
                localVarQueryParameter['customerNo'] = customerNo;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersExportToExcelGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Customers.exportToExcel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [customerNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersInfoGet(customerNo?: number, options: any = {}): RequestArgs {
            const localVarPath = `/api/Customers.info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (customerNo !== undefined) {
                localVarQueryParameter['customerNo'] = customerNo;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersQueryAddressesGet(customerNo: number, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'customerNo' is not null or undefined
            if (customerNo === null || customerNo === undefined) {
                throw new RequiredError('customerNo','Required parameter customerNo was null or undefined when calling apiCustomersQueryAddressesGet.');
            }
            const localVarPath = `/api/Customers.queryAddresses`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (customerNo !== undefined) {
                localVarQueryParameter['customerNo'] = customerNo;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersQueryGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Customers.query`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersQueryLineItemsGet(customerNo: number, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'customerNo' is not null or undefined
            if (customerNo === null || customerNo === undefined) {
                throw new RequiredError('customerNo','Required parameter customerNo was null or undefined when calling apiCustomersQueryLineItemsGet.');
            }
            const localVarPath = `/api/Customers.queryLineItems`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (customerNo !== undefined) {
                localVarQueryParameter['customerNo'] = customerNo;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersQuerySalesOrdersGet(customerNo: number, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'customerNo' is not null or undefined
            if (customerNo === null || customerNo === undefined) {
                throw new RequiredError('customerNo','Required parameter customerNo was null or undefined when calling apiCustomersQuerySalesOrdersGet.');
            }
            const localVarPath = `/api/Customers.querySalesOrders`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (customerNo !== undefined) {
                localVarQueryParameter['customerNo'] = customerNo;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersSearchGet(searchString?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Customers.search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAddressOfCustomerCommand} [updateAddressOfCustomerCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersUpdateAddressPut(updateAddressOfCustomerCommand?: UpdateAddressOfCustomerCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/Customers.updateAddress`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateAddressOfCustomerCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateAddressOfCustomerCommand !== undefined ? updateAddressOfCustomerCommand : {}) : (updateAddressOfCustomerCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCustomerInfoCommand} [updateCustomerInfoCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersUpdateInfoPut(updateCustomerInfoCommand?: UpdateCustomerInfoCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/Customers.updateInfo`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateCustomerInfoCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateCustomerInfoCommand !== undefined ? updateCustomerInfoCommand : {}) : (updateCustomerInfoCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Address of Customer.
         * @param {number} customerNo 
         * @param {number} addressNo 
         * @param {UpdateAddressRequestDTO} [updateAddressRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1CustomersCustomerNoAddressAddressNoPut(customerNo: number, addressNo: number, updateAddressRequestDTO?: UpdateAddressRequestDTO, options: any = {}): RequestArgs {
            // verify required parameter 'customerNo' is not null or undefined
            if (customerNo === null || customerNo === undefined) {
                throw new RequiredError('customerNo','Required parameter customerNo was null or undefined when calling openapiV1CustomersCustomerNoAddressAddressNoPut.');
            }
            // verify required parameter 'addressNo' is not null or undefined
            if (addressNo === null || addressNo === undefined) {
                throw new RequiredError('addressNo','Required parameter addressNo was null or undefined when calling openapiV1CustomersCustomerNoAddressAddressNoPut.');
            }
            const localVarPath = `/openapi/v1/Customers/{customerNo}/address/{addressNo}`
                .replace(`{${"customerNo"}}`, encodeURIComponent(String(customerNo)))
                .replace(`{${"addressNo"}}`, encodeURIComponent(String(addressNo)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateAddressRequestDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateAddressRequestDTO !== undefined ? updateAddressRequestDTO : {}) : (updateAddressRequestDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add new address to Customer.
         * @param {number} customerNo 
         * @param {CreateAddressRequestDTO} [createAddressRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1CustomersCustomerNoAddressPost(customerNo: number, createAddressRequestDTO?: CreateAddressRequestDTO, options: any = {}): RequestArgs {
            // verify required parameter 'customerNo' is not null or undefined
            if (customerNo === null || customerNo === undefined) {
                throw new RequiredError('customerNo','Required parameter customerNo was null or undefined when calling openapiV1CustomersCustomerNoAddressPost.');
            }
            const localVarPath = `/openapi/v1/Customers/{customerNo}/address`
                .replace(`{${"customerNo"}}`, encodeURIComponent(String(customerNo)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createAddressRequestDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createAddressRequestDTO !== undefined ? createAddressRequestDTO : {}) : (createAddressRequestDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Customer by customerNo.
         * @param {number} customerNo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1CustomersCustomerNoGet(customerNo: number, options: any = {}): RequestArgs {
            // verify required parameter 'customerNo' is not null or undefined
            if (customerNo === null || customerNo === undefined) {
                throw new RequiredError('customerNo','Required parameter customerNo was null or undefined when calling openapiV1CustomersCustomerNoGet.');
            }
            const localVarPath = `/openapi/v1/Customers/{customerNo}`
                .replace(`{${"customerNo"}}`, encodeURIComponent(String(customerNo)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Customer.
         * @param {number} customerNo 
         * @param {UpdateCustomerRequestDTO} [updateCustomerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1CustomersCustomerNoPut(customerNo: number, updateCustomerRequestDTO?: UpdateCustomerRequestDTO, options: any = {}): RequestArgs {
            // verify required parameter 'customerNo' is not null or undefined
            if (customerNo === null || customerNo === undefined) {
                throw new RequiredError('customerNo','Required parameter customerNo was null or undefined when calling openapiV1CustomersCustomerNoPut.');
            }
            const localVarPath = `/openapi/v1/Customers/{customerNo}`
                .replace(`{${"customerNo"}}`, encodeURIComponent(String(customerNo)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateCustomerRequestDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateCustomerRequestDTO !== undefined ? updateCustomerRequestDTO : {}) : (updateCustomerRequestDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Query Customers.
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1CustomersGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/openapi/v1/Customers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Customer.
         * @param {CreateCustomerRequestDTO} [createCustomerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1CustomersPost(createCustomerRequestDTO?: CreateCustomerRequestDTO, options: any = {}): RequestArgs {
            const localVarPath = `/openapi/v1/Customers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createCustomerRequestDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createCustomerRequestDTO !== undefined ? createCustomerRequestDTO : {}) : (createCustomerRequestDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersApi - functional programming interface
 * @export
 */
export const CustomersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddAddressToCustomerCommand} [addAddressToCustomerCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersAddAddressPost(addAddressToCustomerCommand?: AddAddressToCustomerCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CustomersApiAxiosParamCreator(configuration).apiCustomersAddAddressPost(addAddressToCustomerCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [customerNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersAddressesGet(customerNo?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AddressOfCustomerOutputDTO>> {
            const localVarAxiosArgs = CustomersApiAxiosParamCreator(configuration).apiCustomersAddressesGet(customerNo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateCustomerCommand} [createCustomerCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersCreatePost(createCustomerCommand?: CreateCustomerCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedCustomerOutputDTO> {
            const localVarAxiosArgs = CustomersApiAxiosParamCreator(configuration).apiCustomersCreatePost(createCustomerCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [customerNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersDetailGet(customerNo?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDetailOutputDTO> {
            const localVarAxiosArgs = CustomersApiAxiosParamCreator(configuration).apiCustomersDetailGet(customerNo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} customerNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersExportAddressesToExcelGet(customerNo: number, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CustomersApiAxiosParamCreator(configuration).apiCustomersExportAddressesToExcelGet(customerNo, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} customerNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersExportLineItemsToExcelGet(customerNo: number, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CustomersApiAxiosParamCreator(configuration).apiCustomersExportLineItemsToExcelGet(customerNo, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} customerNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersExportSalesOrdersToExcelGet(customerNo: number, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CustomersApiAxiosParamCreator(configuration).apiCustomersExportSalesOrdersToExcelGet(customerNo, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersExportToExcelGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CustomersApiAxiosParamCreator(configuration).apiCustomersExportToExcelGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [customerNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersInfoGet(customerNo?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerInfoOutputDTO> {
            const localVarAxiosArgs = CustomersApiAxiosParamCreator(configuration).apiCustomersInfoGet(customerNo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} customerNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersQueryAddressesGet(customerNo: number, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressOfCustomerQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = CustomersApiAxiosParamCreator(configuration).apiCustomersQueryAddressesGet(customerNo, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersQueryGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = CustomersApiAxiosParamCreator(configuration).apiCustomersQueryGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} customerNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersQueryLineItemsGet(customerNo: number, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LineItemsPurchasedByCustomerQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = CustomersApiAxiosParamCreator(configuration).apiCustomersQueryLineItemsGet(customerNo, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} customerNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersQuerySalesOrdersGet(customerNo: number, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesOrderOfCustomerQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = CustomersApiAxiosParamCreator(configuration).apiCustomersQuerySalesOrdersGet(customerNo, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersSearchGet(searchString?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchCustomersQueryOutputDTO>> {
            const localVarAxiosArgs = CustomersApiAxiosParamCreator(configuration).apiCustomersSearchGet(searchString, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateAddressOfCustomerCommand} [updateAddressOfCustomerCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersUpdateAddressPut(updateAddressOfCustomerCommand?: UpdateAddressOfCustomerCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CustomersApiAxiosParamCreator(configuration).apiCustomersUpdateAddressPut(updateAddressOfCustomerCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateCustomerInfoCommand} [updateCustomerInfoCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersUpdateInfoPut(updateCustomerInfoCommand?: UpdateCustomerInfoCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CustomersApiAxiosParamCreator(configuration).apiCustomersUpdateInfoPut(updateCustomerInfoCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update Address of Customer.
         * @param {number} customerNo 
         * @param {number} addressNo 
         * @param {UpdateAddressRequestDTO} [updateAddressRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1CustomersCustomerNoAddressAddressNoPut(customerNo: number, addressNo: number, updateAddressRequestDTO?: UpdateAddressRequestDTO, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CustomersApiAxiosParamCreator(configuration).openapiV1CustomersCustomerNoAddressAddressNoPut(customerNo, addressNo, updateAddressRequestDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Add new address to Customer.
         * @param {number} customerNo 
         * @param {CreateAddressRequestDTO} [createAddressRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1CustomersCustomerNoAddressPost(customerNo: number, createAddressRequestDTO?: CreateAddressRequestDTO, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CustomersApiAxiosParamCreator(configuration).openapiV1CustomersCustomerNoAddressPost(customerNo, createAddressRequestDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Customer by customerNo.
         * @param {number} customerNo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1CustomersCustomerNoGet(customerNo: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDetailResponseDTO> {
            const localVarAxiosArgs = CustomersApiAxiosParamCreator(configuration).openapiV1CustomersCustomerNoGet(customerNo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update Customer.
         * @param {number} customerNo 
         * @param {UpdateCustomerRequestDTO} [updateCustomerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1CustomersCustomerNoPut(customerNo: number, updateCustomerRequestDTO?: UpdateCustomerRequestDTO, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CustomersApiAxiosParamCreator(configuration).openapiV1CustomersCustomerNoPut(customerNo, updateCustomerRequestDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Query Customers.
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1CustomersGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDetailResponseDTODynamicQueryPagedDTO> {
            const localVarAxiosArgs = CustomersApiAxiosParamCreator(configuration).openapiV1CustomersGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create Customer.
         * @param {CreateCustomerRequestDTO} [createCustomerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1CustomersPost(createCustomerRequestDTO?: CreateCustomerRequestDTO, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CustomersApiAxiosParamCreator(configuration).openapiV1CustomersPost(createCustomerRequestDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CustomersApi - factory interface
 * @export
 */
export const CustomersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {AddAddressToCustomerCommand} [addAddressToCustomerCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersAddAddressPost(addAddressToCustomerCommand?: AddAddressToCustomerCommand, options?: any) {
            return CustomersApiFp(configuration).apiCustomersAddAddressPost(addAddressToCustomerCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} [customerNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersAddressesGet(customerNo?: number, options?: any) {
            return CustomersApiFp(configuration).apiCustomersAddressesGet(customerNo, options)(axios, basePath);
        },
        /**
         * 
         * @param {CreateCustomerCommand} [createCustomerCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersCreatePost(createCustomerCommand?: CreateCustomerCommand, options?: any) {
            return CustomersApiFp(configuration).apiCustomersCreatePost(createCustomerCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} [customerNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersDetailGet(customerNo?: number, options?: any) {
            return CustomersApiFp(configuration).apiCustomersDetailGet(customerNo, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} customerNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersExportAddressesToExcelGet(customerNo: number, dqb?: string, options?: any) {
            return CustomersApiFp(configuration).apiCustomersExportAddressesToExcelGet(customerNo, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} customerNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersExportLineItemsToExcelGet(customerNo: number, dqb?: string, options?: any) {
            return CustomersApiFp(configuration).apiCustomersExportLineItemsToExcelGet(customerNo, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} customerNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersExportSalesOrdersToExcelGet(customerNo: number, dqb?: string, options?: any) {
            return CustomersApiFp(configuration).apiCustomersExportSalesOrdersToExcelGet(customerNo, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersExportToExcelGet(dqb?: string, options?: any) {
            return CustomersApiFp(configuration).apiCustomersExportToExcelGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} [customerNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersInfoGet(customerNo?: number, options?: any) {
            return CustomersApiFp(configuration).apiCustomersInfoGet(customerNo, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} customerNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersQueryAddressesGet(customerNo: number, dqb?: string, options?: any) {
            return CustomersApiFp(configuration).apiCustomersQueryAddressesGet(customerNo, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersQueryGet(dqb?: string, options?: any) {
            return CustomersApiFp(configuration).apiCustomersQueryGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} customerNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersQueryLineItemsGet(customerNo: number, dqb?: string, options?: any) {
            return CustomersApiFp(configuration).apiCustomersQueryLineItemsGet(customerNo, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} customerNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersQuerySalesOrdersGet(customerNo: number, dqb?: string, options?: any) {
            return CustomersApiFp(configuration).apiCustomersQuerySalesOrdersGet(customerNo, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersSearchGet(searchString?: string, options?: any) {
            return CustomersApiFp(configuration).apiCustomersSearchGet(searchString, options)(axios, basePath);
        },
        /**
         * 
         * @param {UpdateAddressOfCustomerCommand} [updateAddressOfCustomerCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersUpdateAddressPut(updateAddressOfCustomerCommand?: UpdateAddressOfCustomerCommand, options?: any) {
            return CustomersApiFp(configuration).apiCustomersUpdateAddressPut(updateAddressOfCustomerCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {UpdateCustomerInfoCommand} [updateCustomerInfoCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersUpdateInfoPut(updateCustomerInfoCommand?: UpdateCustomerInfoCommand, options?: any) {
            return CustomersApiFp(configuration).apiCustomersUpdateInfoPut(updateCustomerInfoCommand, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update Address of Customer.
         * @param {number} customerNo 
         * @param {number} addressNo 
         * @param {UpdateAddressRequestDTO} [updateAddressRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1CustomersCustomerNoAddressAddressNoPut(customerNo: number, addressNo: number, updateAddressRequestDTO?: UpdateAddressRequestDTO, options?: any) {
            return CustomersApiFp(configuration).openapiV1CustomersCustomerNoAddressAddressNoPut(customerNo, addressNo, updateAddressRequestDTO, options)(axios, basePath);
        },
        /**
         * 
         * @summary Add new address to Customer.
         * @param {number} customerNo 
         * @param {CreateAddressRequestDTO} [createAddressRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1CustomersCustomerNoAddressPost(customerNo: number, createAddressRequestDTO?: CreateAddressRequestDTO, options?: any) {
            return CustomersApiFp(configuration).openapiV1CustomersCustomerNoAddressPost(customerNo, createAddressRequestDTO, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get Customer by customerNo.
         * @param {number} customerNo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1CustomersCustomerNoGet(customerNo: number, options?: any) {
            return CustomersApiFp(configuration).openapiV1CustomersCustomerNoGet(customerNo, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update Customer.
         * @param {number} customerNo 
         * @param {UpdateCustomerRequestDTO} [updateCustomerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1CustomersCustomerNoPut(customerNo: number, updateCustomerRequestDTO?: UpdateCustomerRequestDTO, options?: any) {
            return CustomersApiFp(configuration).openapiV1CustomersCustomerNoPut(customerNo, updateCustomerRequestDTO, options)(axios, basePath);
        },
        /**
         * 
         * @summary Query Customers.
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1CustomersGet(dqb?: string, options?: any) {
            return CustomersApiFp(configuration).openapiV1CustomersGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @summary Create Customer.
         * @param {CreateCustomerRequestDTO} [createCustomerRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1CustomersPost(createCustomerRequestDTO?: CreateCustomerRequestDTO, options?: any) {
            return CustomersApiFp(configuration).openapiV1CustomersPost(createCustomerRequestDTO, options)(axios, basePath);
        },
    };
};

/**
 * CustomersApi - object-oriented interface
 * @export
 * @class CustomersApi
 * @extends {BaseAPI}
 */
export class CustomersApi extends BaseAPI {
    /**
     * 
     * @param {AddAddressToCustomerCommand} [addAddressToCustomerCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersAddAddressPost(addAddressToCustomerCommand?: AddAddressToCustomerCommand, options?: any) {
        return CustomersApiFp(this.configuration).apiCustomersAddAddressPost(addAddressToCustomerCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} [customerNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersAddressesGet(customerNo?: number, options?: any) {
        return CustomersApiFp(this.configuration).apiCustomersAddressesGet(customerNo, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {CreateCustomerCommand} [createCustomerCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersCreatePost(createCustomerCommand?: CreateCustomerCommand, options?: any) {
        return CustomersApiFp(this.configuration).apiCustomersCreatePost(createCustomerCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} [customerNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersDetailGet(customerNo?: number, options?: any) {
        return CustomersApiFp(this.configuration).apiCustomersDetailGet(customerNo, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} customerNo 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersExportAddressesToExcelGet(customerNo: number, dqb?: string, options?: any) {
        return CustomersApiFp(this.configuration).apiCustomersExportAddressesToExcelGet(customerNo, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} customerNo 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersExportLineItemsToExcelGet(customerNo: number, dqb?: string, options?: any) {
        return CustomersApiFp(this.configuration).apiCustomersExportLineItemsToExcelGet(customerNo, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} customerNo 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersExportSalesOrdersToExcelGet(customerNo: number, dqb?: string, options?: any) {
        return CustomersApiFp(this.configuration).apiCustomersExportSalesOrdersToExcelGet(customerNo, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersExportToExcelGet(dqb?: string, options?: any) {
        return CustomersApiFp(this.configuration).apiCustomersExportToExcelGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} [customerNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersInfoGet(customerNo?: number, options?: any) {
        return CustomersApiFp(this.configuration).apiCustomersInfoGet(customerNo, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} customerNo 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersQueryAddressesGet(customerNo: number, dqb?: string, options?: any) {
        return CustomersApiFp(this.configuration).apiCustomersQueryAddressesGet(customerNo, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersQueryGet(dqb?: string, options?: any) {
        return CustomersApiFp(this.configuration).apiCustomersQueryGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} customerNo 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersQueryLineItemsGet(customerNo: number, dqb?: string, options?: any) {
        return CustomersApiFp(this.configuration).apiCustomersQueryLineItemsGet(customerNo, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} customerNo 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersQuerySalesOrdersGet(customerNo: number, dqb?: string, options?: any) {
        return CustomersApiFp(this.configuration).apiCustomersQuerySalesOrdersGet(customerNo, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [searchString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersSearchGet(searchString?: string, options?: any) {
        return CustomersApiFp(this.configuration).apiCustomersSearchGet(searchString, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {UpdateAddressOfCustomerCommand} [updateAddressOfCustomerCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersUpdateAddressPut(updateAddressOfCustomerCommand?: UpdateAddressOfCustomerCommand, options?: any) {
        return CustomersApiFp(this.configuration).apiCustomersUpdateAddressPut(updateAddressOfCustomerCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {UpdateCustomerInfoCommand} [updateCustomerInfoCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersUpdateInfoPut(updateCustomerInfoCommand?: UpdateCustomerInfoCommand, options?: any) {
        return CustomersApiFp(this.configuration).apiCustomersUpdateInfoPut(updateCustomerInfoCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update Address of Customer.
     * @param {number} customerNo 
     * @param {number} addressNo 
     * @param {UpdateAddressRequestDTO} [updateAddressRequestDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public openapiV1CustomersCustomerNoAddressAddressNoPut(customerNo: number, addressNo: number, updateAddressRequestDTO?: UpdateAddressRequestDTO, options?: any) {
        return CustomersApiFp(this.configuration).openapiV1CustomersCustomerNoAddressAddressNoPut(customerNo, addressNo, updateAddressRequestDTO, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Add new address to Customer.
     * @param {number} customerNo 
     * @param {CreateAddressRequestDTO} [createAddressRequestDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public openapiV1CustomersCustomerNoAddressPost(customerNo: number, createAddressRequestDTO?: CreateAddressRequestDTO, options?: any) {
        return CustomersApiFp(this.configuration).openapiV1CustomersCustomerNoAddressPost(customerNo, createAddressRequestDTO, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get Customer by customerNo.
     * @param {number} customerNo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public openapiV1CustomersCustomerNoGet(customerNo: number, options?: any) {
        return CustomersApiFp(this.configuration).openapiV1CustomersCustomerNoGet(customerNo, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update Customer.
     * @param {number} customerNo 
     * @param {UpdateCustomerRequestDTO} [updateCustomerRequestDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public openapiV1CustomersCustomerNoPut(customerNo: number, updateCustomerRequestDTO?: UpdateCustomerRequestDTO, options?: any) {
        return CustomersApiFp(this.configuration).openapiV1CustomersCustomerNoPut(customerNo, updateCustomerRequestDTO, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Query Customers.
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public openapiV1CustomersGet(dqb?: string, options?: any) {
        return CustomersApiFp(this.configuration).openapiV1CustomersGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Create Customer.
     * @param {CreateCustomerRequestDTO} [createCustomerRequestDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public openapiV1CustomersPost(createCustomerRequestDTO?: CreateCustomerRequestDTO, options?: any) {
        return CustomersApiFp(this.configuration).openapiV1CustomersPost(createCustomerRequestDTO, options)(this.axios, this.basePath);
    }

}


/**
 * DBMigrationApi - axios parameter creator
 * @export
 */
export const DBMigrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [APIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devDBMigrationExecuteMigrationScriptsPost(APIKEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/dev/DBMigration/ExecuteMigrationScripts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (APIKEY !== undefined && APIKEY !== null) {
                localVarHeaderParameter['APIKEY'] = String(APIKEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [APIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devDBMigrationRecreateDbWithMigrationScriptsAndSeedPost(APIKEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/dev/DBMigration/RecreateDbWithMigrationScriptsAndSeed`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (APIKEY !== undefined && APIKEY !== null) {
                localVarHeaderParameter['APIKEY'] = String(APIKEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [APIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devDBMigrationSeedPost(APIKEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/dev/DBMigration/Seed`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (APIKEY !== undefined && APIKEY !== null) {
                localVarHeaderParameter['APIKEY'] = String(APIKEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DBMigrationApi - functional programming interface
 * @export
 */
export const DBMigrationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [APIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devDBMigrationExecuteMigrationScriptsPost(APIKEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DBMigrationApiAxiosParamCreator(configuration).devDBMigrationExecuteMigrationScriptsPost(APIKEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [APIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devDBMigrationRecreateDbWithMigrationScriptsAndSeedPost(APIKEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DBMigrationApiAxiosParamCreator(configuration).devDBMigrationRecreateDbWithMigrationScriptsAndSeedPost(APIKEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [APIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devDBMigrationSeedPost(APIKEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DBMigrationApiAxiosParamCreator(configuration).devDBMigrationSeedPost(APIKEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DBMigrationApi - factory interface
 * @export
 */
export const DBMigrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [APIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devDBMigrationExecuteMigrationScriptsPost(APIKEY?: string, options?: any) {
            return DBMigrationApiFp(configuration).devDBMigrationExecuteMigrationScriptsPost(APIKEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [APIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devDBMigrationRecreateDbWithMigrationScriptsAndSeedPost(APIKEY?: string, options?: any) {
            return DBMigrationApiFp(configuration).devDBMigrationRecreateDbWithMigrationScriptsAndSeedPost(APIKEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [APIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devDBMigrationSeedPost(APIKEY?: string, options?: any) {
            return DBMigrationApiFp(configuration).devDBMigrationSeedPost(APIKEY, options)(axios, basePath);
        },
    };
};

/**
 * DBMigrationApi - object-oriented interface
 * @export
 * @class DBMigrationApi
 * @extends {BaseAPI}
 */
export class DBMigrationApi extends BaseAPI {
    /**
     * 
     * @param {string} [APIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DBMigrationApi
     */
    public devDBMigrationExecuteMigrationScriptsPost(APIKEY?: string, options?: any) {
        return DBMigrationApiFp(this.configuration).devDBMigrationExecuteMigrationScriptsPost(APIKEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [APIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DBMigrationApi
     */
    public devDBMigrationRecreateDbWithMigrationScriptsAndSeedPost(APIKEY?: string, options?: any) {
        return DBMigrationApiFp(this.configuration).devDBMigrationRecreateDbWithMigrationScriptsAndSeedPost(APIKEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [APIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DBMigrationApi
     */
    public devDBMigrationSeedPost(APIKEY?: string, options?: any) {
        return DBMigrationApiFp(this.configuration).devDBMigrationSeedPost(APIKEY, options)(this.axios, this.basePath);
    }

}


/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesUploadPost(file?: any, options: any = {}): RequestArgs {
            const localVarPath = `/api/Files.upload`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesUploadPost(file?: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFileOutputDTO> {
            const localVarAxiosArgs = FilesApiAxiosParamCreator(configuration).apiFilesUploadPost(file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesUploadPost(file?: any, options?: any) {
            return FilesApiFp(configuration).apiFilesUploadPost(file, options)(axios, basePath);
        },
    };
};

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
    /**
     * 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public apiFilesUploadPost(file?: any, options?: any) {
        return FilesApiFp(this.configuration).apiFilesUploadPost(file, options)(this.axios, this.basePath);
    }

}

/**
 * IntegrationsApi - axios parameter creator
 * @export
 */
export const IntegrationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [tenantName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminIntegrationsAllGet(tenantName?: string, options: any = {}): RequestArgs {
            const localVarPath = `/admin/Integrations.all`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (tenantName !== undefined) {
                localVarQueryParameter['tenantName'] = tenantName;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateFtpIntegrationCommand} [createFtpIntegrationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminIntegrationsCreateFtpIntegrationPost(createFtpIntegrationCommand?: CreateFtpIntegrationCommand, options: any = {}): RequestArgs {
            const localVarPath = `/admin/Integrations.createFtpIntegration`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createFtpIntegrationCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createFtpIntegrationCommand !== undefined ? createFtpIntegrationCommand : {}) : (createFtpIntegrationCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantName 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminIntegrationsQueryGet(tenantName: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'tenantName' is not null or undefined
            if (tenantName === null || tenantName === undefined) {
                throw new RequiredError('tenantName','Required parameter tenantName was null or undefined when calling adminIntegrationsQueryGet.');
            }
            const localVarPath = `/admin/Integrations.query`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (tenantName !== undefined) {
                localVarQueryParameter['tenantName'] = tenantName;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantName 
         * @param {DeleteIntegrationCommand} [deleteIntegrationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminIntegrationsTenantNameDeleteDelete(tenantName: string, deleteIntegrationCommand?: DeleteIntegrationCommand, options: any = {}): RequestArgs {
            // verify required parameter 'tenantName' is not null or undefined
            if (tenantName === null || tenantName === undefined) {
                throw new RequiredError('tenantName','Required parameter tenantName was null or undefined when calling adminIntegrationsTenantNameDeleteDelete.');
            }
            const localVarPath = `/admin/Integrations/{tenantName}.delete`
                .replace(`{${"tenantName"}}`, encodeURIComponent(String(tenantName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof deleteIntegrationCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(deleteIntegrationCommand !== undefined ? deleteIntegrationCommand : {}) : (deleteIntegrationCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [tenantName] 
         * @param {UpdateSyncedIntegrationCommand} [updateSyncedIntegrationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminIntegrationsUpdateLastSyncTimePut(tenantName?: string, updateSyncedIntegrationCommand?: UpdateSyncedIntegrationCommand, options: any = {}): RequestArgs {
            const localVarPath = `/admin/Integrations.updateLastSyncTime`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (tenantName !== undefined) {
                localVarQueryParameter['tenantName'] = tenantName;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateSyncedIntegrationCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateSyncedIntegrationCommand !== undefined ? updateSyncedIntegrationCommand : {}) : (updateSyncedIntegrationCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntegrationsAllGet(options: any = {}): RequestArgs {
            const localVarPath = `/api/Integrations.all`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateIntegrationCommand} [createIntegrationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntegrationsCreatePost(createIntegrationCommand?: CreateIntegrationCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/Integrations.create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createIntegrationCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createIntegrationCommand !== undefined ? createIntegrationCommand : {}) : (createIntegrationCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteIntegrationCommand} [deleteIntegrationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntegrationsDeleteDelete(deleteIntegrationCommand?: DeleteIntegrationCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/Integrations.delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof deleteIntegrationCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(deleteIntegrationCommand !== undefined ? deleteIntegrationCommand : {}) : (deleteIntegrationCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [integrationId] 
         * @param {IntegrationType} [integrationType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntegrationsGetPayloadGet(integrationId?: string, integrationType?: IntegrationType, options: any = {}): RequestArgs {
            const localVarPath = `/api/Integrations.getPayload`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (integrationId !== undefined) {
                localVarQueryParameter['integrationId'] = integrationId;
            }

            if (integrationType !== undefined) {
                localVarQueryParameter['integrationType'] = integrationType;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SyncIntegrationCommand} [syncIntegrationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntegrationsSyncPut(syncIntegrationCommand?: SyncIntegrationCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/Integrations.sync`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof syncIntegrationCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(syncIntegrationCommand !== undefined ? syncIntegrationCommand : {}) : (syncIntegrationCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateIntegrationCommand} [updateIntegrationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntegrationsUpdatePut(updateIntegrationCommand?: UpdateIntegrationCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/Integrations.update`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateIntegrationCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateIntegrationCommand !== undefined ? updateIntegrationCommand : {}) : (updateIntegrationCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationsApi - functional programming interface
 * @export
 */
export const IntegrationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [tenantName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminIntegrationsAllGet(tenantName?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntegrationsOfTenantOutputDTO>> {
            const localVarAxiosArgs = IntegrationsApiAxiosParamCreator(configuration).adminIntegrationsAllGet(tenantName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateFtpIntegrationCommand} [createFtpIntegrationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminIntegrationsCreateFtpIntegrationPost(createFtpIntegrationCommand?: CreateFtpIntegrationCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = IntegrationsApiAxiosParamCreator(configuration).adminIntegrationsCreateFtpIntegrationPost(createFtpIntegrationCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tenantName 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminIntegrationsQueryGet(tenantName: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationsOfTenantQueryOutputDTO> {
            const localVarAxiosArgs = IntegrationsApiAxiosParamCreator(configuration).adminIntegrationsQueryGet(tenantName, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tenantName 
         * @param {DeleteIntegrationCommand} [deleteIntegrationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminIntegrationsTenantNameDeleteDelete(tenantName: string, deleteIntegrationCommand?: DeleteIntegrationCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = IntegrationsApiAxiosParamCreator(configuration).adminIntegrationsTenantNameDeleteDelete(tenantName, deleteIntegrationCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [tenantName] 
         * @param {UpdateSyncedIntegrationCommand} [updateSyncedIntegrationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminIntegrationsUpdateLastSyncTimePut(tenantName?: string, updateSyncedIntegrationCommand?: UpdateSyncedIntegrationCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = IntegrationsApiAxiosParamCreator(configuration).adminIntegrationsUpdateLastSyncTimePut(tenantName, updateSyncedIntegrationCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntegrationsAllGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntegrationOutputDTO>> {
            const localVarAxiosArgs = IntegrationsApiAxiosParamCreator(configuration).apiIntegrationsAllGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateIntegrationCommand} [createIntegrationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntegrationsCreatePost(createIntegrationCommand?: CreateIntegrationCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedOpenApiIntegrationOutputDTO> {
            const localVarAxiosArgs = IntegrationsApiAxiosParamCreator(configuration).apiIntegrationsCreatePost(createIntegrationCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DeleteIntegrationCommand} [deleteIntegrationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntegrationsDeleteDelete(deleteIntegrationCommand?: DeleteIntegrationCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = IntegrationsApiAxiosParamCreator(configuration).apiIntegrationsDeleteDelete(deleteIntegrationCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [integrationId] 
         * @param {IntegrationType} [integrationType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntegrationsGetPayloadGet(integrationId?: string, integrationType?: IntegrationType, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = IntegrationsApiAxiosParamCreator(configuration).apiIntegrationsGetPayloadGet(integrationId, integrationType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SyncIntegrationCommand} [syncIntegrationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntegrationsSyncPut(syncIntegrationCommand?: SyncIntegrationCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = IntegrationsApiAxiosParamCreator(configuration).apiIntegrationsSyncPut(syncIntegrationCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateIntegrationCommand} [updateIntegrationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntegrationsUpdatePut(updateIntegrationCommand?: UpdateIntegrationCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = IntegrationsApiAxiosParamCreator(configuration).apiIntegrationsUpdatePut(updateIntegrationCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * IntegrationsApi - factory interface
 * @export
 */
export const IntegrationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [tenantName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminIntegrationsAllGet(tenantName?: string, options?: any) {
            return IntegrationsApiFp(configuration).adminIntegrationsAllGet(tenantName, options)(axios, basePath);
        },
        /**
         * 
         * @param {CreateFtpIntegrationCommand} [createFtpIntegrationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminIntegrationsCreateFtpIntegrationPost(createFtpIntegrationCommand?: CreateFtpIntegrationCommand, options?: any) {
            return IntegrationsApiFp(configuration).adminIntegrationsCreateFtpIntegrationPost(createFtpIntegrationCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} tenantName 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminIntegrationsQueryGet(tenantName: string, dqb?: string, options?: any) {
            return IntegrationsApiFp(configuration).adminIntegrationsQueryGet(tenantName, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} tenantName 
         * @param {DeleteIntegrationCommand} [deleteIntegrationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminIntegrationsTenantNameDeleteDelete(tenantName: string, deleteIntegrationCommand?: DeleteIntegrationCommand, options?: any) {
            return IntegrationsApiFp(configuration).adminIntegrationsTenantNameDeleteDelete(tenantName, deleteIntegrationCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [tenantName] 
         * @param {UpdateSyncedIntegrationCommand} [updateSyncedIntegrationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminIntegrationsUpdateLastSyncTimePut(tenantName?: string, updateSyncedIntegrationCommand?: UpdateSyncedIntegrationCommand, options?: any) {
            return IntegrationsApiFp(configuration).adminIntegrationsUpdateLastSyncTimePut(tenantName, updateSyncedIntegrationCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntegrationsAllGet(options?: any) {
            return IntegrationsApiFp(configuration).apiIntegrationsAllGet(options)(axios, basePath);
        },
        /**
         * 
         * @param {CreateIntegrationCommand} [createIntegrationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntegrationsCreatePost(createIntegrationCommand?: CreateIntegrationCommand, options?: any) {
            return IntegrationsApiFp(configuration).apiIntegrationsCreatePost(createIntegrationCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {DeleteIntegrationCommand} [deleteIntegrationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntegrationsDeleteDelete(deleteIntegrationCommand?: DeleteIntegrationCommand, options?: any) {
            return IntegrationsApiFp(configuration).apiIntegrationsDeleteDelete(deleteIntegrationCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [integrationId] 
         * @param {IntegrationType} [integrationType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntegrationsGetPayloadGet(integrationId?: string, integrationType?: IntegrationType, options?: any) {
            return IntegrationsApiFp(configuration).apiIntegrationsGetPayloadGet(integrationId, integrationType, options)(axios, basePath);
        },
        /**
         * 
         * @param {SyncIntegrationCommand} [syncIntegrationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntegrationsSyncPut(syncIntegrationCommand?: SyncIntegrationCommand, options?: any) {
            return IntegrationsApiFp(configuration).apiIntegrationsSyncPut(syncIntegrationCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {UpdateIntegrationCommand} [updateIntegrationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntegrationsUpdatePut(updateIntegrationCommand?: UpdateIntegrationCommand, options?: any) {
            return IntegrationsApiFp(configuration).apiIntegrationsUpdatePut(updateIntegrationCommand, options)(axios, basePath);
        },
    };
};

/**
 * IntegrationsApi - object-oriented interface
 * @export
 * @class IntegrationsApi
 * @extends {BaseAPI}
 */
export class IntegrationsApi extends BaseAPI {
    /**
     * 
     * @param {string} [tenantName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public adminIntegrationsAllGet(tenantName?: string, options?: any) {
        return IntegrationsApiFp(this.configuration).adminIntegrationsAllGet(tenantName, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {CreateFtpIntegrationCommand} [createFtpIntegrationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public adminIntegrationsCreateFtpIntegrationPost(createFtpIntegrationCommand?: CreateFtpIntegrationCommand, options?: any) {
        return IntegrationsApiFp(this.configuration).adminIntegrationsCreateFtpIntegrationPost(createFtpIntegrationCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} tenantName 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public adminIntegrationsQueryGet(tenantName: string, dqb?: string, options?: any) {
        return IntegrationsApiFp(this.configuration).adminIntegrationsQueryGet(tenantName, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} tenantName 
     * @param {DeleteIntegrationCommand} [deleteIntegrationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public adminIntegrationsTenantNameDeleteDelete(tenantName: string, deleteIntegrationCommand?: DeleteIntegrationCommand, options?: any) {
        return IntegrationsApiFp(this.configuration).adminIntegrationsTenantNameDeleteDelete(tenantName, deleteIntegrationCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [tenantName] 
     * @param {UpdateSyncedIntegrationCommand} [updateSyncedIntegrationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public adminIntegrationsUpdateLastSyncTimePut(tenantName?: string, updateSyncedIntegrationCommand?: UpdateSyncedIntegrationCommand, options?: any) {
        return IntegrationsApiFp(this.configuration).adminIntegrationsUpdateLastSyncTimePut(tenantName, updateSyncedIntegrationCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public apiIntegrationsAllGet(options?: any) {
        return IntegrationsApiFp(this.configuration).apiIntegrationsAllGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {CreateIntegrationCommand} [createIntegrationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public apiIntegrationsCreatePost(createIntegrationCommand?: CreateIntegrationCommand, options?: any) {
        return IntegrationsApiFp(this.configuration).apiIntegrationsCreatePost(createIntegrationCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {DeleteIntegrationCommand} [deleteIntegrationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public apiIntegrationsDeleteDelete(deleteIntegrationCommand?: DeleteIntegrationCommand, options?: any) {
        return IntegrationsApiFp(this.configuration).apiIntegrationsDeleteDelete(deleteIntegrationCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [integrationId] 
     * @param {IntegrationType} [integrationType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public apiIntegrationsGetPayloadGet(integrationId?: string, integrationType?: IntegrationType, options?: any) {
        return IntegrationsApiFp(this.configuration).apiIntegrationsGetPayloadGet(integrationId, integrationType, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {SyncIntegrationCommand} [syncIntegrationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public apiIntegrationsSyncPut(syncIntegrationCommand?: SyncIntegrationCommand, options?: any) {
        return IntegrationsApiFp(this.configuration).apiIntegrationsSyncPut(syncIntegrationCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {UpdateIntegrationCommand} [updateIntegrationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public apiIntegrationsUpdatePut(updateIntegrationCommand?: UpdateIntegrationCommand, options?: any) {
        return IntegrationsApiFp(this.configuration).apiIntegrationsUpdatePut(updateIntegrationCommand, options)(this.axios, this.basePath);
    }

}


/**
 * InventoryApi - axios parameter creator
 * @export
 */
export const InventoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryExportToExcelExpirationDatesGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Inventory.exportToExcelExpirationDates`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryExportToExcelGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Inventory.exportToExcel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryExportToExcelQuarantineGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Inventory.exportToExcelQuarantine`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryExportToExcelSerialNumbersGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Inventory.exportToExcelSerialNumbers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryQueryExpirationDatesGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Inventory.queryExpirationDates`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryQueryGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Inventory.query`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryQueryMissingsGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Inventory.queryMissings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryQuerySerialNumbersGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Inventory.querySerialNumbers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update stock amount.
         * @param {string} [dqb] DynamicQuery
         * @param {SyncInventoryFromMaestroCommand} [syncInventoryFromMaestroCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maestroV1InventoryUpdateStockAmountPut(dqb?: string, syncInventoryFromMaestroCommand?: SyncInventoryFromMaestroCommand, options: any = {}): RequestArgs {
            const localVarPath = `/maestro/v1/Inventory/updateStockAmount`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof syncInventoryFromMaestroCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(syncInventoryFromMaestroCommand !== undefined ? syncInventoryFromMaestroCommand : {}) : (syncInventoryFromMaestroCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventoryApi - functional programming interface
 * @export
 */
export const InventoryApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryExportToExcelExpirationDatesGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InventoryApiAxiosParamCreator(configuration).apiInventoryExportToExcelExpirationDatesGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryExportToExcelGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InventoryApiAxiosParamCreator(configuration).apiInventoryExportToExcelGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryExportToExcelQuarantineGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InventoryApiAxiosParamCreator(configuration).apiInventoryExportToExcelQuarantineGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryExportToExcelSerialNumbersGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InventoryApiAxiosParamCreator(configuration).apiInventoryExportToExcelSerialNumbersGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryQueryExpirationDatesGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryExpirationDatesQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = InventoryApiAxiosParamCreator(configuration).apiInventoryQueryExpirationDatesGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryQueryGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryItemQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = InventoryApiAxiosParamCreator(configuration).apiInventoryQueryGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryQueryMissingsGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryMissingQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = InventoryApiAxiosParamCreator(configuration).apiInventoryQueryMissingsGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryQuerySerialNumbersGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventorySerialNumbersQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = InventoryApiAxiosParamCreator(configuration).apiInventoryQuerySerialNumbersGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update stock amount.
         * @param {string} [dqb] DynamicQuery
         * @param {SyncInventoryFromMaestroCommand} [syncInventoryFromMaestroCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maestroV1InventoryUpdateStockAmountPut(dqb?: string, syncInventoryFromMaestroCommand?: SyncInventoryFromMaestroCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InventoryApiAxiosParamCreator(configuration).maestroV1InventoryUpdateStockAmountPut(dqb, syncInventoryFromMaestroCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InventoryApi - factory interface
 * @export
 */
export const InventoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryExportToExcelExpirationDatesGet(dqb?: string, options?: any) {
            return InventoryApiFp(configuration).apiInventoryExportToExcelExpirationDatesGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryExportToExcelGet(dqb?: string, options?: any) {
            return InventoryApiFp(configuration).apiInventoryExportToExcelGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryExportToExcelQuarantineGet(dqb?: string, options?: any) {
            return InventoryApiFp(configuration).apiInventoryExportToExcelQuarantineGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryExportToExcelSerialNumbersGet(dqb?: string, options?: any) {
            return InventoryApiFp(configuration).apiInventoryExportToExcelSerialNumbersGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryQueryExpirationDatesGet(dqb?: string, options?: any) {
            return InventoryApiFp(configuration).apiInventoryQueryExpirationDatesGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryQueryGet(dqb?: string, options?: any) {
            return InventoryApiFp(configuration).apiInventoryQueryGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryQueryMissingsGet(dqb?: string, options?: any) {
            return InventoryApiFp(configuration).apiInventoryQueryMissingsGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryQuerySerialNumbersGet(dqb?: string, options?: any) {
            return InventoryApiFp(configuration).apiInventoryQuerySerialNumbersGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update stock amount.
         * @param {string} [dqb] DynamicQuery
         * @param {SyncInventoryFromMaestroCommand} [syncInventoryFromMaestroCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maestroV1InventoryUpdateStockAmountPut(dqb?: string, syncInventoryFromMaestroCommand?: SyncInventoryFromMaestroCommand, options?: any) {
            return InventoryApiFp(configuration).maestroV1InventoryUpdateStockAmountPut(dqb, syncInventoryFromMaestroCommand, options)(axios, basePath);
        },
    };
};

/**
 * InventoryApi - object-oriented interface
 * @export
 * @class InventoryApi
 * @extends {BaseAPI}
 */
export class InventoryApi extends BaseAPI {
    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public apiInventoryExportToExcelExpirationDatesGet(dqb?: string, options?: any) {
        return InventoryApiFp(this.configuration).apiInventoryExportToExcelExpirationDatesGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public apiInventoryExportToExcelGet(dqb?: string, options?: any) {
        return InventoryApiFp(this.configuration).apiInventoryExportToExcelGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public apiInventoryExportToExcelQuarantineGet(dqb?: string, options?: any) {
        return InventoryApiFp(this.configuration).apiInventoryExportToExcelQuarantineGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public apiInventoryExportToExcelSerialNumbersGet(dqb?: string, options?: any) {
        return InventoryApiFp(this.configuration).apiInventoryExportToExcelSerialNumbersGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public apiInventoryQueryExpirationDatesGet(dqb?: string, options?: any) {
        return InventoryApiFp(this.configuration).apiInventoryQueryExpirationDatesGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public apiInventoryQueryGet(dqb?: string, options?: any) {
        return InventoryApiFp(this.configuration).apiInventoryQueryGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public apiInventoryQueryMissingsGet(dqb?: string, options?: any) {
        return InventoryApiFp(this.configuration).apiInventoryQueryMissingsGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public apiInventoryQuerySerialNumbersGet(dqb?: string, options?: any) {
        return InventoryApiFp(this.configuration).apiInventoryQuerySerialNumbersGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update stock amount.
     * @param {string} [dqb] DynamicQuery
     * @param {SyncInventoryFromMaestroCommand} [syncInventoryFromMaestroCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public maestroV1InventoryUpdateStockAmountPut(dqb?: string, syncInventoryFromMaestroCommand?: SyncInventoryFromMaestroCommand, options?: any) {
        return InventoryApiFp(this.configuration).maestroV1InventoryUpdateStockAmountPut(dqb, syncInventoryFromMaestroCommand, options)(this.axios, this.basePath);
    }

}


/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TestSendDTO} [testSendDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileV1NotificationSendPost(testSendDTO?: TestSendDTO, options: any = {}): RequestArgs {
            const localVarPath = `/mobile/v1/Notification/send`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof testSendDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(testSendDTO !== undefined ? testSendDTO : {}) : (testSendDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TestSendDTO} [testSendDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileV1NotificationSendPost(testSendDTO?: TestSendDTO, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponse> {
            const localVarAxiosArgs = NotificationApiAxiosParamCreator(configuration).mobileV1NotificationSendPost(testSendDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {TestSendDTO} [testSendDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileV1NotificationSendPost(testSendDTO?: TestSendDTO, options?: any) {
            return NotificationApiFp(configuration).mobileV1NotificationSendPost(testSendDTO, options)(axios, basePath);
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @param {TestSendDTO} [testSendDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public mobileV1NotificationSendPost(testSendDTO?: TestSendDTO, options?: any) {
        return NotificationApiFp(this.configuration).mobileV1NotificationSendPost(testSendDTO, options)(this.axios, this.basePath);
    }

}


/**
 * PackagesApi - axios parameter creator
 * @export
 */
export const PackagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BulkResetPackageFulfilmentSyncDetailsCommand} [bulkResetPackageFulfilmentSyncDetailsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPackagesBulkResetFulfillmentSyncPut(bulkResetPackageFulfilmentSyncDetailsCommand?: BulkResetPackageFulfilmentSyncDetailsCommand, options: any = {}): RequestArgs {
            const localVarPath = `/admin/Packages.bulkResetFulfillmentSync`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof bulkResetPackageFulfilmentSyncDetailsCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(bulkResetPackageFulfilmentSyncDetailsCommand !== undefined ? bulkResetPackageFulfilmentSyncDetailsCommand : {}) : (bulkResetPackageFulfilmentSyncDetailsCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BulkResetPackageWmsSyncDetailsCommand} [bulkResetPackageWmsSyncDetailsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPackagesBulkResetWmsSyncPut(bulkResetPackageWmsSyncDetailsCommand?: BulkResetPackageWmsSyncDetailsCommand, options: any = {}): RequestArgs {
            const localVarPath = `/admin/Packages.bulkResetWmsSync`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof bulkResetPackageWmsSyncDetailsCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(bulkResetPackageWmsSyncDetailsCommand !== undefined ? bulkResetPackageWmsSyncDetailsCommand : {}) : (bulkResetPackageWmsSyncDetailsCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPackagesQueryPackagesOfTenantGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/admin/Packages.queryPackagesOfTenant`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PackagesSyncFromWmsInputDTO} [packagesSyncFromWmsInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPackagesSyncFromWmsPut(packagesSyncFromWmsInputDTO?: PackagesSyncFromWmsInputDTO, options: any = {}): RequestArgs {
            const localVarPath = `/admin/Packages.syncFromWms`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof packagesSyncFromWmsInputDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(packagesSyncFromWmsInputDTO !== undefined ? packagesSyncFromWmsInputDTO : {}) : (packagesSyncFromWmsInputDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PackagesApi - functional programming interface
 * @export
 */
export const PackagesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BulkResetPackageFulfilmentSyncDetailsCommand} [bulkResetPackageFulfilmentSyncDetailsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPackagesBulkResetFulfillmentSyncPut(bulkResetPackageFulfilmentSyncDetailsCommand?: BulkResetPackageFulfilmentSyncDetailsCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceNumberOutputDTOBulkOperationResultDTO> {
            const localVarAxiosArgs = PackagesApiAxiosParamCreator(configuration).adminPackagesBulkResetFulfillmentSyncPut(bulkResetPackageFulfilmentSyncDetailsCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {BulkResetPackageWmsSyncDetailsCommand} [bulkResetPackageWmsSyncDetailsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPackagesBulkResetWmsSyncPut(bulkResetPackageWmsSyncDetailsCommand?: BulkResetPackageWmsSyncDetailsCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceNumberOutputDTOBulkOperationResultDTO> {
            const localVarAxiosArgs = PackagesApiAxiosParamCreator(configuration).adminPackagesBulkResetWmsSyncPut(bulkResetPackageWmsSyncDetailsCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPackagesQueryPackagesOfTenantGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackagesOfTenantQueryOutputDTO> {
            const localVarAxiosArgs = PackagesApiAxiosParamCreator(configuration).adminPackagesQueryPackagesOfTenantGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {PackagesSyncFromWmsInputDTO} [packagesSyncFromWmsInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPackagesSyncFromWmsPut(packagesSyncFromWmsInputDTO?: PackagesSyncFromWmsInputDTO, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceNumberOutputDTOBulkOperationResultDTO> {
            const localVarAxiosArgs = PackagesApiAxiosParamCreator(configuration).adminPackagesSyncFromWmsPut(packagesSyncFromWmsInputDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PackagesApi - factory interface
 * @export
 */
export const PackagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {BulkResetPackageFulfilmentSyncDetailsCommand} [bulkResetPackageFulfilmentSyncDetailsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPackagesBulkResetFulfillmentSyncPut(bulkResetPackageFulfilmentSyncDetailsCommand?: BulkResetPackageFulfilmentSyncDetailsCommand, options?: any) {
            return PackagesApiFp(configuration).adminPackagesBulkResetFulfillmentSyncPut(bulkResetPackageFulfilmentSyncDetailsCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {BulkResetPackageWmsSyncDetailsCommand} [bulkResetPackageWmsSyncDetailsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPackagesBulkResetWmsSyncPut(bulkResetPackageWmsSyncDetailsCommand?: BulkResetPackageWmsSyncDetailsCommand, options?: any) {
            return PackagesApiFp(configuration).adminPackagesBulkResetWmsSyncPut(bulkResetPackageWmsSyncDetailsCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPackagesQueryPackagesOfTenantGet(dqb?: string, options?: any) {
            return PackagesApiFp(configuration).adminPackagesQueryPackagesOfTenantGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {PackagesSyncFromWmsInputDTO} [packagesSyncFromWmsInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPackagesSyncFromWmsPut(packagesSyncFromWmsInputDTO?: PackagesSyncFromWmsInputDTO, options?: any) {
            return PackagesApiFp(configuration).adminPackagesSyncFromWmsPut(packagesSyncFromWmsInputDTO, options)(axios, basePath);
        },
    };
};

/**
 * PackagesApi - object-oriented interface
 * @export
 * @class PackagesApi
 * @extends {BaseAPI}
 */
export class PackagesApi extends BaseAPI {
    /**
     * 
     * @param {BulkResetPackageFulfilmentSyncDetailsCommand} [bulkResetPackageFulfilmentSyncDetailsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagesApi
     */
    public adminPackagesBulkResetFulfillmentSyncPut(bulkResetPackageFulfilmentSyncDetailsCommand?: BulkResetPackageFulfilmentSyncDetailsCommand, options?: any) {
        return PackagesApiFp(this.configuration).adminPackagesBulkResetFulfillmentSyncPut(bulkResetPackageFulfilmentSyncDetailsCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {BulkResetPackageWmsSyncDetailsCommand} [bulkResetPackageWmsSyncDetailsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagesApi
     */
    public adminPackagesBulkResetWmsSyncPut(bulkResetPackageWmsSyncDetailsCommand?: BulkResetPackageWmsSyncDetailsCommand, options?: any) {
        return PackagesApiFp(this.configuration).adminPackagesBulkResetWmsSyncPut(bulkResetPackageWmsSyncDetailsCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagesApi
     */
    public adminPackagesQueryPackagesOfTenantGet(dqb?: string, options?: any) {
        return PackagesApiFp(this.configuration).adminPackagesQueryPackagesOfTenantGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {PackagesSyncFromWmsInputDTO} [packagesSyncFromWmsInputDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagesApi
     */
    public adminPackagesSyncFromWmsPut(packagesSyncFromWmsInputDTO?: PackagesSyncFromWmsInputDTO, options?: any) {
        return PackagesApiFp(this.configuration).adminPackagesSyncFromWmsPut(packagesSyncFromWmsInputDTO, options)(this.axios, this.basePath);
    }

}


/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<CreateProductsCommand>} [createProductsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsBulkCreatePost(createProductsCommand?: Array<CreateProductsCommand>, options: any = {}): RequestArgs {
            const localVarPath = `/admin/Products.bulkCreate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createProductsCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createProductsCommand !== undefined ? createProductsCommand : {}) : (createProductsCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantName 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsQueryGet(tenantName: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'tenantName' is not null or undefined
            if (tenantName === null || tenantName === undefined) {
                throw new RequiredError('tenantName','Required parameter tenantName was null or undefined when calling adminProductsQueryGet.');
            }
            const localVarPath = `/admin/Products.query`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (tenantName !== undefined) {
                localVarQueryParameter['tenantName'] = tenantName;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendProductsToWMSCommand} [sendProductsToWMSCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsSendtoWMSPut(sendProductsToWMSCommand?: SendProductsToWMSCommand, options: any = {}): RequestArgs {
            const localVarPath = `/admin/Products.sendtoWMS`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof sendProductsToWMSCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(sendProductsToWMSCommand !== undefined ? sendProductsToWMSCommand : {}) : (sendProductsToWMSCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [tenantName] 
         * @param {SyncProductsFromIntegrationAdminCommand} [syncProductsFromIntegrationAdminCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsSyncFromIntegrationPost(tenantName?: string, syncProductsFromIntegrationAdminCommand?: SyncProductsFromIntegrationAdminCommand, options: any = {}): RequestArgs {
            const localVarPath = `/admin/Products.syncFromIntegration`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (tenantName !== undefined) {
                localVarQueryParameter['tenantName'] = tenantName;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof syncProductsFromIntegrationAdminCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(syncProductsFromIntegrationAdminCommand !== undefined ? syncProductsFromIntegrationAdminCommand : {}) : (syncProductsFromIntegrationAdminCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateProductCommand} [createProductCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsCreatePost(createProductCommand?: CreateProductCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/Products.create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createProductCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createProductCommand !== undefined ? createProductCommand : {}) : (createProductCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsDetailGet(sku?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Products.detail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (sku !== undefined) {
                localVarQueryParameter['sku'] = sku;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsExportProductDetailsCustomersToExcelGet(sku: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'sku' is not null or undefined
            if (sku === null || sku === undefined) {
                throw new RequiredError('sku','Required parameter sku was null or undefined when calling apiProductsExportProductDetailsCustomersToExcelGet.');
            }
            const localVarPath = `/api/Products.exportProductDetailsCustomersToExcel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (sku !== undefined) {
                localVarQueryParameter['sku'] = sku;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsExportProductDetailsReceivingOrdersToExcelGet(sku: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'sku' is not null or undefined
            if (sku === null || sku === undefined) {
                throw new RequiredError('sku','Required parameter sku was null or undefined when calling apiProductsExportProductDetailsReceivingOrdersToExcelGet.');
            }
            const localVarPath = `/api/Products.exportProductDetailsReceivingOrdersToExcel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (sku !== undefined) {
                localVarQueryParameter['sku'] = sku;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsExportProductDetailsSalesOrdersToExcelGet(sku: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'sku' is not null or undefined
            if (sku === null || sku === undefined) {
                throw new RequiredError('sku','Required parameter sku was null or undefined when calling apiProductsExportProductDetailsSalesOrdersToExcelGet.');
            }
            const localVarPath = `/api/Products.exportProductDetailsSalesOrdersToExcel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (sku !== undefined) {
                localVarQueryParameter['sku'] = sku;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsExportProductExpirationDatesToExcelGet(sku: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'sku' is not null or undefined
            if (sku === null || sku === undefined) {
                throw new RequiredError('sku','Required parameter sku was null or undefined when calling apiProductsExportProductExpirationDatesToExcelGet.');
            }
            const localVarPath = `/api/Products.exportProductExpirationDatesToExcel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (sku !== undefined) {
                localVarQueryParameter['sku'] = sku;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsExportProductSerialNumbersToExcelGet(sku: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'sku' is not null or undefined
            if (sku === null || sku === undefined) {
                throw new RequiredError('sku','Required parameter sku was null or undefined when calling apiProductsExportProductSerialNumbersToExcelGet.');
            }
            const localVarPath = `/api/Products.exportProductSerialNumbersToExcel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (sku !== undefined) {
                localVarQueryParameter['sku'] = sku;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsExportToExcelGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Products.exportToExcel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsExportToExcelquarantineGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Products.exportToExcelquarantine`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsFavoriteGet(sku?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Products.favorite`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (sku !== undefined) {
                localVarQueryParameter['sku'] = sku;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsImportFromExcelPost(file?: any, options: any = {}): RequestArgs {
            const localVarPath = `/api/Products.importFromExcel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsQueryCustomersGet(sku: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'sku' is not null or undefined
            if (sku === null || sku === undefined) {
                throw new RequiredError('sku','Required parameter sku was null or undefined when calling apiProductsQueryCustomersGet.');
            }
            const localVarPath = `/api/Products.queryCustomers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (sku !== undefined) {
                localVarQueryParameter['sku'] = sku;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsQueryGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Products.query`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsQueryProductExpirationDatesGet(sku: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'sku' is not null or undefined
            if (sku === null || sku === undefined) {
                throw new RequiredError('sku','Required parameter sku was null or undefined when calling apiProductsQueryProductExpirationDatesGet.');
            }
            const localVarPath = `/api/Products.queryProductExpirationDates`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (sku !== undefined) {
                localVarQueryParameter['sku'] = sku;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsQueryProductSerialNumbersGet(sku: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'sku' is not null or undefined
            if (sku === null || sku === undefined) {
                throw new RequiredError('sku','Required parameter sku was null or undefined when calling apiProductsQueryProductSerialNumbersGet.');
            }
            const localVarPath = `/api/Products.queryProductSerialNumbers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (sku !== undefined) {
                localVarQueryParameter['sku'] = sku;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsQueryQuarantineGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Products.queryQuarantine`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsQueryReceivingOrdersGet(sku: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'sku' is not null or undefined
            if (sku === null || sku === undefined) {
                throw new RequiredError('sku','Required parameter sku was null or undefined when calling apiProductsQueryReceivingOrdersGet.');
            }
            const localVarPath = `/api/Products.queryReceivingOrders`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (sku !== undefined) {
                localVarQueryParameter['sku'] = sku;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsQuerySalesOrdersGet(sku: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'sku' is not null or undefined
            if (sku === null || sku === undefined) {
                throw new RequiredError('sku','Required parameter sku was null or undefined when calling apiProductsQuerySalesOrdersGet.');
            }
            const localVarPath = `/api/Products.querySalesOrders`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (sku !== undefined) {
                localVarQueryParameter['sku'] = sku;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProductFavoriteCommand} [updateProductFavoriteCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsUpdateFavoritePut(updateProductFavoriteCommand?: UpdateProductFavoriteCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/Products.updateFavorite`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateProductFavoriteCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateProductFavoriteCommand !== undefined ? updateProductFavoriteCommand : {}) : (updateProductFavoriteCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProductCommand} [updateProductCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsUpdatePost(updateProductCommand?: UpdateProductCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/Products.update`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateProductCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateProductCommand !== undefined ? updateProductCommand : {}) : (updateProductCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProductStateCommand} [updateProductStateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsUpdateStatePut(updateProductStateCommand?: UpdateProductStateCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/Products.updateState`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateProductStateCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateProductStateCommand !== undefined ? updateProductStateCommand : {}) : (updateProductStateCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Activate Product (Set Product\'s state to Active).
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsActivatePut(sku?: string, options: any = {}): RequestArgs {
            const localVarPath = `/openapi/v1/Products/activate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (sku !== undefined) {
                localVarQueryParameter['sku'] = sku;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Bulk Add or Update Product.
         * @param {Array<CreateProductRequestDTO>} [createProductRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsBulkUpsertPost(createProductRequestDTO?: Array<CreateProductRequestDTO>, options: any = {}): RequestArgs {
            const localVarPath = `/openapi/v1/Products/BulkUpsert`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createProductRequestDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createProductRequestDTO !== undefined ? createProductRequestDTO : {}) : (createProductRequestDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivate Product (Set Product\'s state to Disabled).
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsDeactivatePut(sku?: string, options: any = {}): RequestArgs {
            const localVarPath = `/openapi/v1/Products/deactivate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (sku !== undefined) {
                localVarQueryParameter['sku'] = sku;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Query Products.
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/openapi/v1/Products`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Product by SKU.
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsGetBySKUGet(sku?: string, options: any = {}): RequestArgs {
            const localVarPath = `/openapi/v1/Products/GetBySKU`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (sku !== undefined) {
                localVarQueryParameter['sku'] = sku;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Products which are get in to stock at least once (0 and more. Not NULL).
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsInventoryGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/openapi/v1/Products/inventory`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Product.
         * @param {CreateProductRequestDTO} [createProductRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsPost(createProductRequestDTO?: CreateProductRequestDTO, options: any = {}): RequestArgs {
            const localVarPath = `/openapi/v1/Products`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createProductRequestDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createProductRequestDTO !== undefined ? createProductRequestDTO : {}) : (createProductRequestDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Product by SKU.
         * @param {string} sku 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsSkuGet(sku: string, options: any = {}): RequestArgs {
            // verify required parameter 'sku' is not null or undefined
            if (sku === null || sku === undefined) {
                throw new RequiredError('sku','Required parameter sku was null or undefined when calling openapiV1ProductsSkuGet.');
            }
            const localVarPath = `/openapi/v1/Products/{sku}`
                .replace(`{${"sku"}}`, encodeURIComponent(String(sku)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Product.
         * @param {string} sku 
         * @param {UpdateProductRequestDTO} [updateProductRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsSkuPut(sku: string, updateProductRequestDTO?: UpdateProductRequestDTO, options: any = {}): RequestArgs {
            // verify required parameter 'sku' is not null or undefined
            if (sku === null || sku === undefined) {
                throw new RequiredError('sku','Required parameter sku was null or undefined when calling openapiV1ProductsSkuPut.');
            }
            const localVarPath = `/openapi/v1/Products/{sku}`
                .replace(`{${"sku"}}`, encodeURIComponent(String(sku)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateProductRequestDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateProductRequestDTO !== undefined ? updateProductRequestDTO : {}) : (updateProductRequestDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Activate Product (Set Product\'s state to Active).
         * @param {string} sku 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsSkuactivatePut(sku: string, options: any = {}): RequestArgs {
            // verify required parameter 'sku' is not null or undefined
            if (sku === null || sku === undefined) {
                throw new RequiredError('sku','Required parameter sku was null or undefined when calling openapiV1ProductsSkuactivatePut.');
            }
            const localVarPath = `/openapi/v1/Products/{sku}:activate`
                .replace(`{${"sku"}}`, encodeURIComponent(String(sku)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivate Product (Set Product\'s state to Disabled).
         * @param {string} sku 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsSkudeactivatePut(sku: string, options: any = {}): RequestArgs {
            // verify required parameter 'sku' is not null or undefined
            if (sku === null || sku === undefined) {
                throw new RequiredError('sku','Required parameter sku was null or undefined when calling openapiV1ProductsSkudeactivatePut.');
            }
            const localVarPath = `/openapi/v1/Products/{sku}:deactivate`
                .replace(`{${"sku"}}`, encodeURIComponent(String(sku)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Star Product (Set Product\'s favorite state to true).
         * @param {string} sku 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsSkustarPut(sku: string, options: any = {}): RequestArgs {
            // verify required parameter 'sku' is not null or undefined
            if (sku === null || sku === undefined) {
                throw new RequiredError('sku','Required parameter sku was null or undefined when calling openapiV1ProductsSkustarPut.');
            }
            const localVarPath = `/openapi/v1/Products/{sku}:star`
                .replace(`{${"sku"}}`, encodeURIComponent(String(sku)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unstar Product (Set Product\'s favorite state to false)..
         * @param {string} sku 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsSkuunstarPut(sku: string, options: any = {}): RequestArgs {
            // verify required parameter 'sku' is not null or undefined
            if (sku === null || sku === undefined) {
                throw new RequiredError('sku','Required parameter sku was null or undefined when calling openapiV1ProductsSkuunstarPut.');
            }
            const localVarPath = `/openapi/v1/Products/{sku}:unstar`
                .replace(`{${"sku"}}`, encodeURIComponent(String(sku)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Star Product (Set Product\'s favorite state to true).
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsStarPut(sku?: string, options: any = {}): RequestArgs {
            const localVarPath = `/openapi/v1/Products/star`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (sku !== undefined) {
                localVarQueryParameter['sku'] = sku;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unstar Product (Set Product\'s favorite state to false)..
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsUnstarPut(sku?: string, options: any = {}): RequestArgs {
            const localVarPath = `/openapi/v1/Products/unstar`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (sku !== undefined) {
                localVarQueryParameter['sku'] = sku;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Product.
         * @param {string} [sku] 
         * @param {UpdateProductRequestDTO} [updateProductRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsUpdatePut(sku?: string, updateProductRequestDTO?: UpdateProductRequestDTO, options: any = {}): RequestArgs {
            const localVarPath = `/openapi/v1/Products/Update`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (sku !== undefined) {
                localVarQueryParameter['sku'] = sku;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateProductRequestDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateProductRequestDTO !== undefined ? updateProductRequestDTO : {}) : (updateProductRequestDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<CreateProductsCommand>} [createProductsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsBulkCreatePost(createProductsCommand?: Array<CreateProductsCommand>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).adminProductsBulkCreatePost(createProductsCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tenantName 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsQueryGet(tenantName: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductsOfTenantQueryOutputDTO> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).adminProductsQueryGet(tenantName, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SendProductsToWMSCommand} [sendProductsToWMSCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsSendtoWMSPut(sendProductsToWMSCommand?: SendProductsToWMSCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).adminProductsSendtoWMSPut(sendProductsToWMSCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [tenantName] 
         * @param {SyncProductsFromIntegrationAdminCommand} [syncProductsFromIntegrationAdminCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsSyncFromIntegrationPost(tenantName?: string, syncProductsFromIntegrationAdminCommand?: SyncProductsFromIntegrationAdminCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpsertProductsCommandResult> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).adminProductsSyncFromIntegrationPost(tenantName, syncProductsFromIntegrationAdminCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateProductCommand} [createProductCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsCreatePost(createProductCommand?: CreateProductCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).apiProductsCreatePost(createProductCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsDetailGet(sku?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailOutputDTO> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).apiProductsDetailGet(sku, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsExportProductDetailsCustomersToExcelGet(sku: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).apiProductsExportProductDetailsCustomersToExcelGet(sku, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsExportProductDetailsReceivingOrdersToExcelGet(sku: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).apiProductsExportProductDetailsReceivingOrdersToExcelGet(sku, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsExportProductDetailsSalesOrdersToExcelGet(sku: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).apiProductsExportProductDetailsSalesOrdersToExcelGet(sku, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsExportProductExpirationDatesToExcelGet(sku: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).apiProductsExportProductExpirationDatesToExcelGet(sku, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsExportProductSerialNumbersToExcelGet(sku: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).apiProductsExportProductSerialNumbersToExcelGet(sku, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsExportToExcelGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).apiProductsExportToExcelGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsExportToExcelquarantineGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).apiProductsExportToExcelquarantineGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsFavoriteGet(sku?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductFavoriteOutputDTO> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).apiProductsFavoriteGet(sku, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsImportFromExcelPost(file?: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).apiProductsImportFromExcelPost(file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsQueryCustomersGet(sku: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerOfProductQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).apiProductsQueryCustomersGet(sku, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsQueryGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).apiProductsQueryGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsQueryProductExpirationDatesGet(sku: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryExpirationDatesQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).apiProductsQueryProductExpirationDatesGet(sku, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsQueryProductSerialNumbersGet(sku: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventorySerialNumbersQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).apiProductsQueryProductSerialNumbersGet(sku, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsQueryQuarantineGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvalidProductQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).apiProductsQueryQuarantineGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsQueryReceivingOrdersGet(sku: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceivingOrderOfProductQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).apiProductsQueryReceivingOrdersGet(sku, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsQuerySalesOrdersGet(sku: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesOrderOfProductQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).apiProductsQuerySalesOrdersGet(sku, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateProductFavoriteCommand} [updateProductFavoriteCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsUpdateFavoritePut(updateProductFavoriteCommand?: UpdateProductFavoriteCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).apiProductsUpdateFavoritePut(updateProductFavoriteCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateProductCommand} [updateProductCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsUpdatePost(updateProductCommand?: UpdateProductCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).apiProductsUpdatePost(updateProductCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateProductStateCommand} [updateProductStateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsUpdateStatePut(updateProductStateCommand?: UpdateProductStateCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).apiProductsUpdateStatePut(updateProductStateCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Activate Product (Set Product\'s state to Active).
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsActivatePut(sku?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).openapiV1ProductsActivatePut(sku, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Bulk Add or Update Product.
         * @param {Array<CreateProductRequestDTO>} [createProductRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsBulkUpsertPost(createProductRequestDTO?: Array<CreateProductRequestDTO>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).openapiV1ProductsBulkUpsertPost(createProductRequestDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Deactivate Product (Set Product\'s state to Disabled).
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsDeactivatePut(sku?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).openapiV1ProductsDeactivatePut(sku, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Query Products.
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailResponseDTODynamicQueryPagedDTO> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).openapiV1ProductsGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Product by SKU.
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsGetBySKUGet(sku?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailResponseDTO> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).openapiV1ProductsGetBySKUGet(sku, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Products which are get in to stock at least once (0 and more. Not NULL).
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsInventoryGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailResponseDTODynamicQueryPagedDTO> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).openapiV1ProductsInventoryGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create Product.
         * @param {CreateProductRequestDTO} [createProductRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsPost(createProductRequestDTO?: CreateProductRequestDTO, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).openapiV1ProductsPost(createProductRequestDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Product by SKU.
         * @param {string} sku 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsSkuGet(sku: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailResponseDTO> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).openapiV1ProductsSkuGet(sku, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update Product.
         * @param {string} sku 
         * @param {UpdateProductRequestDTO} [updateProductRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsSkuPut(sku: string, updateProductRequestDTO?: UpdateProductRequestDTO, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).openapiV1ProductsSkuPut(sku, updateProductRequestDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Activate Product (Set Product\'s state to Active).
         * @param {string} sku 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsSkuactivatePut(sku: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).openapiV1ProductsSkuactivatePut(sku, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Deactivate Product (Set Product\'s state to Disabled).
         * @param {string} sku 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsSkudeactivatePut(sku: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).openapiV1ProductsSkudeactivatePut(sku, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Star Product (Set Product\'s favorite state to true).
         * @param {string} sku 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsSkustarPut(sku: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).openapiV1ProductsSkustarPut(sku, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Unstar Product (Set Product\'s favorite state to false)..
         * @param {string} sku 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsSkuunstarPut(sku: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).openapiV1ProductsSkuunstarPut(sku, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Star Product (Set Product\'s favorite state to true).
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsStarPut(sku?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).openapiV1ProductsStarPut(sku, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Unstar Product (Set Product\'s favorite state to false)..
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsUnstarPut(sku?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).openapiV1ProductsUnstarPut(sku, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update Product.
         * @param {string} [sku] 
         * @param {UpdateProductRequestDTO} [updateProductRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsUpdatePut(sku?: string, updateProductRequestDTO?: UpdateProductRequestDTO, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).openapiV1ProductsUpdatePut(sku, updateProductRequestDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {Array<CreateProductsCommand>} [createProductsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsBulkCreatePost(createProductsCommand?: Array<CreateProductsCommand>, options?: any) {
            return ProductsApiFp(configuration).adminProductsBulkCreatePost(createProductsCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} tenantName 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsQueryGet(tenantName: string, dqb?: string, options?: any) {
            return ProductsApiFp(configuration).adminProductsQueryGet(tenantName, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {SendProductsToWMSCommand} [sendProductsToWMSCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsSendtoWMSPut(sendProductsToWMSCommand?: SendProductsToWMSCommand, options?: any) {
            return ProductsApiFp(configuration).adminProductsSendtoWMSPut(sendProductsToWMSCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [tenantName] 
         * @param {SyncProductsFromIntegrationAdminCommand} [syncProductsFromIntegrationAdminCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsSyncFromIntegrationPost(tenantName?: string, syncProductsFromIntegrationAdminCommand?: SyncProductsFromIntegrationAdminCommand, options?: any) {
            return ProductsApiFp(configuration).adminProductsSyncFromIntegrationPost(tenantName, syncProductsFromIntegrationAdminCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {CreateProductCommand} [createProductCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsCreatePost(createProductCommand?: CreateProductCommand, options?: any) {
            return ProductsApiFp(configuration).apiProductsCreatePost(createProductCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsDetailGet(sku?: string, options?: any) {
            return ProductsApiFp(configuration).apiProductsDetailGet(sku, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsExportProductDetailsCustomersToExcelGet(sku: string, dqb?: string, options?: any) {
            return ProductsApiFp(configuration).apiProductsExportProductDetailsCustomersToExcelGet(sku, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsExportProductDetailsReceivingOrdersToExcelGet(sku: string, dqb?: string, options?: any) {
            return ProductsApiFp(configuration).apiProductsExportProductDetailsReceivingOrdersToExcelGet(sku, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsExportProductDetailsSalesOrdersToExcelGet(sku: string, dqb?: string, options?: any) {
            return ProductsApiFp(configuration).apiProductsExportProductDetailsSalesOrdersToExcelGet(sku, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsExportProductExpirationDatesToExcelGet(sku: string, dqb?: string, options?: any) {
            return ProductsApiFp(configuration).apiProductsExportProductExpirationDatesToExcelGet(sku, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsExportProductSerialNumbersToExcelGet(sku: string, dqb?: string, options?: any) {
            return ProductsApiFp(configuration).apiProductsExportProductSerialNumbersToExcelGet(sku, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsExportToExcelGet(dqb?: string, options?: any) {
            return ProductsApiFp(configuration).apiProductsExportToExcelGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsExportToExcelquarantineGet(dqb?: string, options?: any) {
            return ProductsApiFp(configuration).apiProductsExportToExcelquarantineGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsFavoriteGet(sku?: string, options?: any) {
            return ProductsApiFp(configuration).apiProductsFavoriteGet(sku, options)(axios, basePath);
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsImportFromExcelPost(file?: any, options?: any) {
            return ProductsApiFp(configuration).apiProductsImportFromExcelPost(file, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsQueryCustomersGet(sku: string, dqb?: string, options?: any) {
            return ProductsApiFp(configuration).apiProductsQueryCustomersGet(sku, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsQueryGet(dqb?: string, options?: any) {
            return ProductsApiFp(configuration).apiProductsQueryGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsQueryProductExpirationDatesGet(sku: string, dqb?: string, options?: any) {
            return ProductsApiFp(configuration).apiProductsQueryProductExpirationDatesGet(sku, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsQueryProductSerialNumbersGet(sku: string, dqb?: string, options?: any) {
            return ProductsApiFp(configuration).apiProductsQueryProductSerialNumbersGet(sku, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsQueryQuarantineGet(dqb?: string, options?: any) {
            return ProductsApiFp(configuration).apiProductsQueryQuarantineGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsQueryReceivingOrdersGet(sku: string, dqb?: string, options?: any) {
            return ProductsApiFp(configuration).apiProductsQueryReceivingOrdersGet(sku, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} sku 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsQuerySalesOrdersGet(sku: string, dqb?: string, options?: any) {
            return ProductsApiFp(configuration).apiProductsQuerySalesOrdersGet(sku, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {UpdateProductFavoriteCommand} [updateProductFavoriteCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsUpdateFavoritePut(updateProductFavoriteCommand?: UpdateProductFavoriteCommand, options?: any) {
            return ProductsApiFp(configuration).apiProductsUpdateFavoritePut(updateProductFavoriteCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {UpdateProductCommand} [updateProductCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsUpdatePost(updateProductCommand?: UpdateProductCommand, options?: any) {
            return ProductsApiFp(configuration).apiProductsUpdatePost(updateProductCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {UpdateProductStateCommand} [updateProductStateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsUpdateStatePut(updateProductStateCommand?: UpdateProductStateCommand, options?: any) {
            return ProductsApiFp(configuration).apiProductsUpdateStatePut(updateProductStateCommand, options)(axios, basePath);
        },
        /**
         * 
         * @summary Activate Product (Set Product\'s state to Active).
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsActivatePut(sku?: string, options?: any) {
            return ProductsApiFp(configuration).openapiV1ProductsActivatePut(sku, options)(axios, basePath);
        },
        /**
         * 
         * @summary Bulk Add or Update Product.
         * @param {Array<CreateProductRequestDTO>} [createProductRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsBulkUpsertPost(createProductRequestDTO?: Array<CreateProductRequestDTO>, options?: any) {
            return ProductsApiFp(configuration).openapiV1ProductsBulkUpsertPost(createProductRequestDTO, options)(axios, basePath);
        },
        /**
         * 
         * @summary Deactivate Product (Set Product\'s state to Disabled).
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsDeactivatePut(sku?: string, options?: any) {
            return ProductsApiFp(configuration).openapiV1ProductsDeactivatePut(sku, options)(axios, basePath);
        },
        /**
         * 
         * @summary Query Products.
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsGet(dqb?: string, options?: any) {
            return ProductsApiFp(configuration).openapiV1ProductsGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get Product by SKU.
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsGetBySKUGet(sku?: string, options?: any) {
            return ProductsApiFp(configuration).openapiV1ProductsGetBySKUGet(sku, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get Products which are get in to stock at least once (0 and more. Not NULL).
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsInventoryGet(dqb?: string, options?: any) {
            return ProductsApiFp(configuration).openapiV1ProductsInventoryGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @summary Create Product.
         * @param {CreateProductRequestDTO} [createProductRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsPost(createProductRequestDTO?: CreateProductRequestDTO, options?: any) {
            return ProductsApiFp(configuration).openapiV1ProductsPost(createProductRequestDTO, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get Product by SKU.
         * @param {string} sku 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsSkuGet(sku: string, options?: any) {
            return ProductsApiFp(configuration).openapiV1ProductsSkuGet(sku, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update Product.
         * @param {string} sku 
         * @param {UpdateProductRequestDTO} [updateProductRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsSkuPut(sku: string, updateProductRequestDTO?: UpdateProductRequestDTO, options?: any) {
            return ProductsApiFp(configuration).openapiV1ProductsSkuPut(sku, updateProductRequestDTO, options)(axios, basePath);
        },
        /**
         * 
         * @summary Activate Product (Set Product\'s state to Active).
         * @param {string} sku 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsSkuactivatePut(sku: string, options?: any) {
            return ProductsApiFp(configuration).openapiV1ProductsSkuactivatePut(sku, options)(axios, basePath);
        },
        /**
         * 
         * @summary Deactivate Product (Set Product\'s state to Disabled).
         * @param {string} sku 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsSkudeactivatePut(sku: string, options?: any) {
            return ProductsApiFp(configuration).openapiV1ProductsSkudeactivatePut(sku, options)(axios, basePath);
        },
        /**
         * 
         * @summary Star Product (Set Product\'s favorite state to true).
         * @param {string} sku 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsSkustarPut(sku: string, options?: any) {
            return ProductsApiFp(configuration).openapiV1ProductsSkustarPut(sku, options)(axios, basePath);
        },
        /**
         * 
         * @summary Unstar Product (Set Product\'s favorite state to false)..
         * @param {string} sku 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsSkuunstarPut(sku: string, options?: any) {
            return ProductsApiFp(configuration).openapiV1ProductsSkuunstarPut(sku, options)(axios, basePath);
        },
        /**
         * 
         * @summary Star Product (Set Product\'s favorite state to true).
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsStarPut(sku?: string, options?: any) {
            return ProductsApiFp(configuration).openapiV1ProductsStarPut(sku, options)(axios, basePath);
        },
        /**
         * 
         * @summary Unstar Product (Set Product\'s favorite state to false)..
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsUnstarPut(sku?: string, options?: any) {
            return ProductsApiFp(configuration).openapiV1ProductsUnstarPut(sku, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update Product.
         * @param {string} [sku] 
         * @param {UpdateProductRequestDTO} [updateProductRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ProductsUpdatePut(sku?: string, updateProductRequestDTO?: UpdateProductRequestDTO, options?: any) {
            return ProductsApiFp(configuration).openapiV1ProductsUpdatePut(sku, updateProductRequestDTO, options)(axios, basePath);
        },
    };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * 
     * @param {Array<CreateProductsCommand>} [createProductsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public adminProductsBulkCreatePost(createProductsCommand?: Array<CreateProductsCommand>, options?: any) {
        return ProductsApiFp(this.configuration).adminProductsBulkCreatePost(createProductsCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} tenantName 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public adminProductsQueryGet(tenantName: string, dqb?: string, options?: any) {
        return ProductsApiFp(this.configuration).adminProductsQueryGet(tenantName, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {SendProductsToWMSCommand} [sendProductsToWMSCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public adminProductsSendtoWMSPut(sendProductsToWMSCommand?: SendProductsToWMSCommand, options?: any) {
        return ProductsApiFp(this.configuration).adminProductsSendtoWMSPut(sendProductsToWMSCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [tenantName] 
     * @param {SyncProductsFromIntegrationAdminCommand} [syncProductsFromIntegrationAdminCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public adminProductsSyncFromIntegrationPost(tenantName?: string, syncProductsFromIntegrationAdminCommand?: SyncProductsFromIntegrationAdminCommand, options?: any) {
        return ProductsApiFp(this.configuration).adminProductsSyncFromIntegrationPost(tenantName, syncProductsFromIntegrationAdminCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {CreateProductCommand} [createProductCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsCreatePost(createProductCommand?: CreateProductCommand, options?: any) {
        return ProductsApiFp(this.configuration).apiProductsCreatePost(createProductCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [sku] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsDetailGet(sku?: string, options?: any) {
        return ProductsApiFp(this.configuration).apiProductsDetailGet(sku, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} sku 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsExportProductDetailsCustomersToExcelGet(sku: string, dqb?: string, options?: any) {
        return ProductsApiFp(this.configuration).apiProductsExportProductDetailsCustomersToExcelGet(sku, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} sku 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsExportProductDetailsReceivingOrdersToExcelGet(sku: string, dqb?: string, options?: any) {
        return ProductsApiFp(this.configuration).apiProductsExportProductDetailsReceivingOrdersToExcelGet(sku, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} sku 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsExportProductDetailsSalesOrdersToExcelGet(sku: string, dqb?: string, options?: any) {
        return ProductsApiFp(this.configuration).apiProductsExportProductDetailsSalesOrdersToExcelGet(sku, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} sku 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsExportProductExpirationDatesToExcelGet(sku: string, dqb?: string, options?: any) {
        return ProductsApiFp(this.configuration).apiProductsExportProductExpirationDatesToExcelGet(sku, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} sku 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsExportProductSerialNumbersToExcelGet(sku: string, dqb?: string, options?: any) {
        return ProductsApiFp(this.configuration).apiProductsExportProductSerialNumbersToExcelGet(sku, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsExportToExcelGet(dqb?: string, options?: any) {
        return ProductsApiFp(this.configuration).apiProductsExportToExcelGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsExportToExcelquarantineGet(dqb?: string, options?: any) {
        return ProductsApiFp(this.configuration).apiProductsExportToExcelquarantineGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [sku] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsFavoriteGet(sku?: string, options?: any) {
        return ProductsApiFp(this.configuration).apiProductsFavoriteGet(sku, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsImportFromExcelPost(file?: any, options?: any) {
        return ProductsApiFp(this.configuration).apiProductsImportFromExcelPost(file, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} sku 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsQueryCustomersGet(sku: string, dqb?: string, options?: any) {
        return ProductsApiFp(this.configuration).apiProductsQueryCustomersGet(sku, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsQueryGet(dqb?: string, options?: any) {
        return ProductsApiFp(this.configuration).apiProductsQueryGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} sku 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsQueryProductExpirationDatesGet(sku: string, dqb?: string, options?: any) {
        return ProductsApiFp(this.configuration).apiProductsQueryProductExpirationDatesGet(sku, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} sku 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsQueryProductSerialNumbersGet(sku: string, dqb?: string, options?: any) {
        return ProductsApiFp(this.configuration).apiProductsQueryProductSerialNumbersGet(sku, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsQueryQuarantineGet(dqb?: string, options?: any) {
        return ProductsApiFp(this.configuration).apiProductsQueryQuarantineGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} sku 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsQueryReceivingOrdersGet(sku: string, dqb?: string, options?: any) {
        return ProductsApiFp(this.configuration).apiProductsQueryReceivingOrdersGet(sku, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} sku 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsQuerySalesOrdersGet(sku: string, dqb?: string, options?: any) {
        return ProductsApiFp(this.configuration).apiProductsQuerySalesOrdersGet(sku, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {UpdateProductFavoriteCommand} [updateProductFavoriteCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsUpdateFavoritePut(updateProductFavoriteCommand?: UpdateProductFavoriteCommand, options?: any) {
        return ProductsApiFp(this.configuration).apiProductsUpdateFavoritePut(updateProductFavoriteCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {UpdateProductCommand} [updateProductCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsUpdatePost(updateProductCommand?: UpdateProductCommand, options?: any) {
        return ProductsApiFp(this.configuration).apiProductsUpdatePost(updateProductCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {UpdateProductStateCommand} [updateProductStateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsUpdateStatePut(updateProductStateCommand?: UpdateProductStateCommand, options?: any) {
        return ProductsApiFp(this.configuration).apiProductsUpdateStatePut(updateProductStateCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Activate Product (Set Product\'s state to Active).
     * @param {string} [sku] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public openapiV1ProductsActivatePut(sku?: string, options?: any) {
        return ProductsApiFp(this.configuration).openapiV1ProductsActivatePut(sku, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Bulk Add or Update Product.
     * @param {Array<CreateProductRequestDTO>} [createProductRequestDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public openapiV1ProductsBulkUpsertPost(createProductRequestDTO?: Array<CreateProductRequestDTO>, options?: any) {
        return ProductsApiFp(this.configuration).openapiV1ProductsBulkUpsertPost(createProductRequestDTO, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Deactivate Product (Set Product\'s state to Disabled).
     * @param {string} [sku] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public openapiV1ProductsDeactivatePut(sku?: string, options?: any) {
        return ProductsApiFp(this.configuration).openapiV1ProductsDeactivatePut(sku, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Query Products.
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public openapiV1ProductsGet(dqb?: string, options?: any) {
        return ProductsApiFp(this.configuration).openapiV1ProductsGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get Product by SKU.
     * @param {string} [sku] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public openapiV1ProductsGetBySKUGet(sku?: string, options?: any) {
        return ProductsApiFp(this.configuration).openapiV1ProductsGetBySKUGet(sku, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get Products which are get in to stock at least once (0 and more. Not NULL).
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public openapiV1ProductsInventoryGet(dqb?: string, options?: any) {
        return ProductsApiFp(this.configuration).openapiV1ProductsInventoryGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Create Product.
     * @param {CreateProductRequestDTO} [createProductRequestDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public openapiV1ProductsPost(createProductRequestDTO?: CreateProductRequestDTO, options?: any) {
        return ProductsApiFp(this.configuration).openapiV1ProductsPost(createProductRequestDTO, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get Product by SKU.
     * @param {string} sku 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public openapiV1ProductsSkuGet(sku: string, options?: any) {
        return ProductsApiFp(this.configuration).openapiV1ProductsSkuGet(sku, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update Product.
     * @param {string} sku 
     * @param {UpdateProductRequestDTO} [updateProductRequestDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public openapiV1ProductsSkuPut(sku: string, updateProductRequestDTO?: UpdateProductRequestDTO, options?: any) {
        return ProductsApiFp(this.configuration).openapiV1ProductsSkuPut(sku, updateProductRequestDTO, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Activate Product (Set Product\'s state to Active).
     * @param {string} sku 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public openapiV1ProductsSkuactivatePut(sku: string, options?: any) {
        return ProductsApiFp(this.configuration).openapiV1ProductsSkuactivatePut(sku, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Deactivate Product (Set Product\'s state to Disabled).
     * @param {string} sku 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public openapiV1ProductsSkudeactivatePut(sku: string, options?: any) {
        return ProductsApiFp(this.configuration).openapiV1ProductsSkudeactivatePut(sku, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Star Product (Set Product\'s favorite state to true).
     * @param {string} sku 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public openapiV1ProductsSkustarPut(sku: string, options?: any) {
        return ProductsApiFp(this.configuration).openapiV1ProductsSkustarPut(sku, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Unstar Product (Set Product\'s favorite state to false)..
     * @param {string} sku 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public openapiV1ProductsSkuunstarPut(sku: string, options?: any) {
        return ProductsApiFp(this.configuration).openapiV1ProductsSkuunstarPut(sku, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Star Product (Set Product\'s favorite state to true).
     * @param {string} [sku] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public openapiV1ProductsStarPut(sku?: string, options?: any) {
        return ProductsApiFp(this.configuration).openapiV1ProductsStarPut(sku, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Unstar Product (Set Product\'s favorite state to false)..
     * @param {string} [sku] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public openapiV1ProductsUnstarPut(sku?: string, options?: any) {
        return ProductsApiFp(this.configuration).openapiV1ProductsUnstarPut(sku, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update Product.
     * @param {string} [sku] 
     * @param {UpdateProductRequestDTO} [updateProductRequestDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public openapiV1ProductsUpdatePut(sku?: string, updateProductRequestDTO?: UpdateProductRequestDTO, options?: any) {
        return ProductsApiFp(this.configuration).openapiV1ProductsUpdatePut(sku, updateProductRequestDTO, options)(this.axios, this.basePath);
    }

}


/**
 * ProvinceApi - axios parameter creator
 * @export
 */
export const ProvinceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProvinceAllGet(options: any = {}): RequestArgs {
            const localVarPath = `/api/Province.all`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProvinceCountriesGet(language?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Province.countries`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} province 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProvinceDistrictsGet(province: string, options: any = {}): RequestArgs {
            // verify required parameter 'province' is not null or undefined
            if (province === null || province === undefined) {
                throw new RequiredError('province','Required parameter province was null or undefined when calling apiProvinceDistrictsGet.');
            }
            const localVarPath = `/api/Province.districts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (province !== undefined) {
                localVarQueryParameter['province'] = province;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProvinceApi - functional programming interface
 * @export
 */
export const ProvinceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProvinceAllGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = ProvinceApiAxiosParamCreator(configuration).apiProvinceAllGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProvinceCountriesGet(language?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = ProvinceApiAxiosParamCreator(configuration).apiProvinceCountriesGet(language, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} province 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProvinceDistrictsGet(province: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = ProvinceApiAxiosParamCreator(configuration).apiProvinceDistrictsGet(province, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProvinceApi - factory interface
 * @export
 */
export const ProvinceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProvinceAllGet(options?: any) {
            return ProvinceApiFp(configuration).apiProvinceAllGet(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProvinceCountriesGet(language?: string, options?: any) {
            return ProvinceApiFp(configuration).apiProvinceCountriesGet(language, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} province 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProvinceDistrictsGet(province: string, options?: any) {
            return ProvinceApiFp(configuration).apiProvinceDistrictsGet(province, options)(axios, basePath);
        },
    };
};

/**
 * ProvinceApi - object-oriented interface
 * @export
 * @class ProvinceApi
 * @extends {BaseAPI}
 */
export class ProvinceApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvinceApi
     */
    public apiProvinceAllGet(options?: any) {
        return ProvinceApiFp(this.configuration).apiProvinceAllGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [language] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvinceApi
     */
    public apiProvinceCountriesGet(language?: string, options?: any) {
        return ProvinceApiFp(this.configuration).apiProvinceCountriesGet(language, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} province 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvinceApi
     */
    public apiProvinceDistrictsGet(province: string, options?: any) {
        return ProvinceApiFp(this.configuration).apiProvinceDistrictsGet(province, options)(this.axios, this.basePath);
    }

}


/**
 * ReceivingOrdersApi - axios parameter creator
 * @export
 */
export const ReceivingOrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BulkSoftDeleteReceivingOrdersCommand} [bulkSoftDeleteReceivingOrdersCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReceivingOrdersBulkSoftDeletePut(bulkSoftDeleteReceivingOrdersCommand?: BulkSoftDeleteReceivingOrdersCommand, options: any = {}): RequestArgs {
            const localVarPath = `/admin/ReceivingOrders.bulkSoftDelete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof bulkSoftDeleteReceivingOrdersCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(bulkSoftDeleteReceivingOrdersCommand !== undefined ? bulkSoftDeleteReceivingOrdersCommand : {}) : (bulkSoftDeleteReceivingOrdersCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReceivingOrdersQueryOrdersOfTenantGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/admin/ReceivingOrders.queryOrdersOfTenant`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetReceivingOrdersWmsSyncCommand} [resetReceivingOrdersWmsSyncCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReceivingOrdersResetWmsSyncPut(resetReceivingOrdersWmsSyncCommand?: ResetReceivingOrdersWmsSyncCommand, options: any = {}): RequestArgs {
            const localVarPath = `/admin/ReceivingOrders.resetWmsSync`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof resetReceivingOrdersWmsSyncCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(resetReceivingOrdersWmsSyncCommand !== undefined ? resetReceivingOrdersWmsSyncCommand : {}) : (resetReceivingOrdersWmsSyncCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateReceivingOrderCommand} [createReceivingOrderCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersCreatePost(createReceivingOrderCommand?: CreateReceivingOrderCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/ReceivingOrders.create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createReceivingOrderCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createReceivingOrderCommand !== undefined ? createReceivingOrderCommand : {}) : (createReceivingOrderCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [referenceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersDetailGet(referenceNumber?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/ReceivingOrders.detail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (referenceNumber !== undefined) {
                localVarQueryParameter['referenceNumber'] = referenceNumber;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersExportToExcelGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/ReceivingOrders.exportToExcel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} receivingOrderNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersExportToExcelLineItemExpirationDatesGet(receivingOrderNo: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'receivingOrderNo' is not null or undefined
            if (receivingOrderNo === null || receivingOrderNo === undefined) {
                throw new RequiredError('receivingOrderNo','Required parameter receivingOrderNo was null or undefined when calling apiReceivingOrdersExportToExcelLineItemExpirationDatesGet.');
            }
            const localVarPath = `/api/ReceivingOrders.exportToExcelLineItemExpirationDates`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (receivingOrderNo !== undefined) {
                localVarQueryParameter['receivingOrderNo'] = receivingOrderNo;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} receivingOrderNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersExportToExcelLineItemSerialNumbersGet(receivingOrderNo: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'receivingOrderNo' is not null or undefined
            if (receivingOrderNo === null || receivingOrderNo === undefined) {
                throw new RequiredError('receivingOrderNo','Required parameter receivingOrderNo was null or undefined when calling apiReceivingOrdersExportToExcelLineItemSerialNumbersGet.');
            }
            const localVarPath = `/api/ReceivingOrders.exportToExcelLineItemSerialNumbers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (receivingOrderNo !== undefined) {
                localVarQueryParameter['receivingOrderNo'] = receivingOrderNo;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} receivingOrderNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersExportToExcelLineItemsGet(receivingOrderNo: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'receivingOrderNo' is not null or undefined
            if (receivingOrderNo === null || receivingOrderNo === undefined) {
                throw new RequiredError('receivingOrderNo','Required parameter receivingOrderNo was null or undefined when calling apiReceivingOrdersExportToExcelLineItemsGet.');
            }
            const localVarPath = `/api/ReceivingOrders.exportToExcelLineItems`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (receivingOrderNo !== undefined) {
                localVarQueryParameter['receivingOrderNo'] = receivingOrderNo;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersExportToExcelWithDetailGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/ReceivingOrders.exportToExcelWithDetail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersImportFromExcelPost(file?: any, options: any = {}): RequestArgs {
            const localVarPath = `/api/ReceivingOrders.importFromExcel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersQueryGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/ReceivingOrders.query`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersQueryLineItemExpirtionDatesGet(referenceNumber: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'referenceNumber' is not null or undefined
            if (referenceNumber === null || referenceNumber === undefined) {
                throw new RequiredError('referenceNumber','Required parameter referenceNumber was null or undefined when calling apiReceivingOrdersQueryLineItemExpirtionDatesGet.');
            }
            const localVarPath = `/api/ReceivingOrders.queryLineItemExpirtionDates`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (referenceNumber !== undefined) {
                localVarQueryParameter['referenceNumber'] = referenceNumber;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersQueryLineItemSerialNumbersGet(referenceNumber: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'referenceNumber' is not null or undefined
            if (referenceNumber === null || referenceNumber === undefined) {
                throw new RequiredError('referenceNumber','Required parameter referenceNumber was null or undefined when calling apiReceivingOrdersQueryLineItemSerialNumbersGet.');
            }
            const localVarPath = `/api/ReceivingOrders.queryLineItemSerialNumbers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (referenceNumber !== undefined) {
                localVarQueryParameter['referenceNumber'] = referenceNumber;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersQueryLineItemsGet(referenceNumber: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'referenceNumber' is not null or undefined
            if (referenceNumber === null || referenceNumber === undefined) {
                throw new RequiredError('referenceNumber','Required parameter referenceNumber was null or undefined when calling apiReceivingOrdersQueryLineItemsGet.');
            }
            const localVarPath = `/api/ReceivingOrders.queryLineItems`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (referenceNumber !== undefined) {
                localVarQueryParameter['referenceNumber'] = referenceNumber;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [referenceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersStateDetailGet(referenceNumber?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/ReceivingOrders.stateDetail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (referenceNumber !== undefined) {
                localVarQueryParameter['referenceNumber'] = referenceNumber;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateReceivingOrderTransferDetailCommand} [updateReceivingOrderTransferDetailCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersUpdateTransferDetailPut(updateReceivingOrderTransferDetailCommand?: UpdateReceivingOrderTransferDetailCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/ReceivingOrders.updateTransferDetail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateReceivingOrderTransferDetailCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateReceivingOrderTransferDetailCommand !== undefined ? updateReceivingOrderTransferDetailCommand : {}) : (updateReceivingOrderTransferDetailCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates receiving order.
         * @param {SyncReceivingOrderFromMaestroCommand} [syncReceivingOrderFromMaestroCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maestroV1ReceivingOrdersUpsertReceivingOrderPut(syncReceivingOrderFromMaestroCommand?: SyncReceivingOrderFromMaestroCommand, options: any = {}): RequestArgs {
            const localVarPath = `/maestro/v1/ReceivingOrders/upsertReceivingOrder`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof syncReceivingOrderFromMaestroCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(syncReceivingOrderFromMaestroCommand !== undefined ? syncReceivingOrderFromMaestroCommand : {}) : (syncReceivingOrderFromMaestroCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Query ReceivingOrders.
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ReceivingOrdersGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/openapi/v1/ReceivingOrders`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create ReceivingOrder.
         * @param {CreateReceivingOrderRequestDTO} [createReceivingOrderRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ReceivingOrdersPost(createReceivingOrderRequestDTO?: CreateReceivingOrderRequestDTO, options: any = {}): RequestArgs {
            const localVarPath = `/openapi/v1/ReceivingOrders`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createReceivingOrderRequestDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createReceivingOrderRequestDTO !== undefined ? createReceivingOrderRequestDTO : {}) : (createReceivingOrderRequestDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get ReceivingOrder by referenceNumber.
         * @param {string} referenceNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ReceivingOrdersReferenceNumberGet(referenceNumber: string, options: any = {}): RequestArgs {
            // verify required parameter 'referenceNumber' is not null or undefined
            if (referenceNumber === null || referenceNumber === undefined) {
                throw new RequiredError('referenceNumber','Required parameter referenceNumber was null or undefined when calling openapiV1ReceivingOrdersReferenceNumberGet.');
            }
            const localVarPath = `/openapi/v1/ReceivingOrders/{referenceNumber}`
                .replace(`{${"referenceNumber"}}`, encodeURIComponent(String(referenceNumber)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update TransferDetail of ReceivingOrder  unless any action has been taken on the ReceivingOrder  (State of ReceivingOrder must be \'Created\').
         * @param {string} referenceNumber 
         * @param {UpdateReceivingOrderTransferDetailsRequestDTO} [updateReceivingOrderTransferDetailsRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ReceivingOrdersReferenceNumberTransferDetailPut(referenceNumber: string, updateReceivingOrderTransferDetailsRequestDTO?: UpdateReceivingOrderTransferDetailsRequestDTO, options: any = {}): RequestArgs {
            // verify required parameter 'referenceNumber' is not null or undefined
            if (referenceNumber === null || referenceNumber === undefined) {
                throw new RequiredError('referenceNumber','Required parameter referenceNumber was null or undefined when calling openapiV1ReceivingOrdersReferenceNumberTransferDetailPut.');
            }
            const localVarPath = `/openapi/v1/ReceivingOrders/{referenceNumber}/transferDetail`
                .replace(`{${"referenceNumber"}}`, encodeURIComponent(String(referenceNumber)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateReceivingOrderTransferDetailsRequestDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateReceivingOrderTransferDetailsRequestDTO !== undefined ? updateReceivingOrderTransferDetailsRequestDTO : {}) : (updateReceivingOrderTransferDetailsRequestDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceivingOrdersApi - functional programming interface
 * @export
 */
export const ReceivingOrdersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BulkSoftDeleteReceivingOrdersCommand} [bulkSoftDeleteReceivingOrdersCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReceivingOrdersBulkSoftDeletePut(bulkSoftDeleteReceivingOrdersCommand?: BulkSoftDeleteReceivingOrdersCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceNumberOutputDTOBulkOperationResultDTO> {
            const localVarAxiosArgs = ReceivingOrdersApiAxiosParamCreator(configuration).adminReceivingOrdersBulkSoftDeletePut(bulkSoftDeleteReceivingOrdersCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReceivingOrdersQueryOrdersOfTenantGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceivingOrdersOfTenantQueryOutputDTO> {
            const localVarAxiosArgs = ReceivingOrdersApiAxiosParamCreator(configuration).adminReceivingOrdersQueryOrdersOfTenantGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ResetReceivingOrdersWmsSyncCommand} [resetReceivingOrdersWmsSyncCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReceivingOrdersResetWmsSyncPut(resetReceivingOrdersWmsSyncCommand?: ResetReceivingOrdersWmsSyncCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceNumberOutputDTOBulkOperationResultDTO> {
            const localVarAxiosArgs = ReceivingOrdersApiAxiosParamCreator(configuration).adminReceivingOrdersResetWmsSyncPut(resetReceivingOrdersWmsSyncCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateReceivingOrderCommand} [createReceivingOrderCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersCreatePost(createReceivingOrderCommand?: CreateReceivingOrderCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedReceivingOrderOutputDTO> {
            const localVarAxiosArgs = ReceivingOrdersApiAxiosParamCreator(configuration).apiReceivingOrdersCreatePost(createReceivingOrderCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [referenceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersDetailGet(referenceNumber?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceivingOrderDetailOutputDTO> {
            const localVarAxiosArgs = ReceivingOrdersApiAxiosParamCreator(configuration).apiReceivingOrdersDetailGet(referenceNumber, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersExportToExcelGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ReceivingOrdersApiAxiosParamCreator(configuration).apiReceivingOrdersExportToExcelGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} receivingOrderNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersExportToExcelLineItemExpirationDatesGet(receivingOrderNo: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ReceivingOrdersApiAxiosParamCreator(configuration).apiReceivingOrdersExportToExcelLineItemExpirationDatesGet(receivingOrderNo, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} receivingOrderNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersExportToExcelLineItemSerialNumbersGet(receivingOrderNo: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ReceivingOrdersApiAxiosParamCreator(configuration).apiReceivingOrdersExportToExcelLineItemSerialNumbersGet(receivingOrderNo, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} receivingOrderNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersExportToExcelLineItemsGet(receivingOrderNo: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ReceivingOrdersApiAxiosParamCreator(configuration).apiReceivingOrdersExportToExcelLineItemsGet(receivingOrderNo, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersExportToExcelWithDetailGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ReceivingOrdersApiAxiosParamCreator(configuration).apiReceivingOrdersExportToExcelWithDetailGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersImportFromExcelPost(file?: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedReceivingOrderOutputDTO> {
            const localVarAxiosArgs = ReceivingOrdersApiAxiosParamCreator(configuration).apiReceivingOrdersImportFromExcelPost(file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersQueryGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceivingOrderQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = ReceivingOrdersApiAxiosParamCreator(configuration).apiReceivingOrdersQueryGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersQueryLineItemExpirtionDatesGet(referenceNumber: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LineItemExpirationDateOfReceivingOrderQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = ReceivingOrdersApiAxiosParamCreator(configuration).apiReceivingOrdersQueryLineItemExpirtionDatesGet(referenceNumber, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersQueryLineItemSerialNumbersGet(referenceNumber: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceivingOrderLineItemSerialNumbersQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = ReceivingOrdersApiAxiosParamCreator(configuration).apiReceivingOrdersQueryLineItemSerialNumbersGet(referenceNumber, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersQueryLineItemsGet(referenceNumber: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LineItemOfReceivingOrderQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = ReceivingOrdersApiAxiosParamCreator(configuration).apiReceivingOrdersQueryLineItemsGet(referenceNumber, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [referenceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersStateDetailGet(referenceNumber?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateDetailsOutputDTO> {
            const localVarAxiosArgs = ReceivingOrdersApiAxiosParamCreator(configuration).apiReceivingOrdersStateDetailGet(referenceNumber, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateReceivingOrderTransferDetailCommand} [updateReceivingOrderTransferDetailCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersUpdateTransferDetailPut(updateReceivingOrderTransferDetailCommand?: UpdateReceivingOrderTransferDetailCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdatedReceivingOrderTransferDetailOutputDTO> {
            const localVarAxiosArgs = ReceivingOrdersApiAxiosParamCreator(configuration).apiReceivingOrdersUpdateTransferDetailPut(updateReceivingOrderTransferDetailCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates receiving order.
         * @param {SyncReceivingOrderFromMaestroCommand} [syncReceivingOrderFromMaestroCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maestroV1ReceivingOrdersUpsertReceivingOrderPut(syncReceivingOrderFromMaestroCommand?: SyncReceivingOrderFromMaestroCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ReceivingOrdersApiAxiosParamCreator(configuration).maestroV1ReceivingOrdersUpsertReceivingOrderPut(syncReceivingOrderFromMaestroCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Query ReceivingOrders.
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ReceivingOrdersGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceivingOrderQueryResponseDTODynamicQueryPagedDTO> {
            const localVarAxiosArgs = ReceivingOrdersApiAxiosParamCreator(configuration).openapiV1ReceivingOrdersGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create ReceivingOrder.
         * @param {CreateReceivingOrderRequestDTO} [createReceivingOrderRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ReceivingOrdersPost(createReceivingOrderRequestDTO?: CreateReceivingOrderRequestDTO, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceivingOrderDetailResponseDTO> {
            const localVarAxiosArgs = ReceivingOrdersApiAxiosParamCreator(configuration).openapiV1ReceivingOrdersPost(createReceivingOrderRequestDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get ReceivingOrder by referenceNumber.
         * @param {string} referenceNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ReceivingOrdersReferenceNumberGet(referenceNumber: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceivingOrderDetailResponseDTO> {
            const localVarAxiosArgs = ReceivingOrdersApiAxiosParamCreator(configuration).openapiV1ReceivingOrdersReferenceNumberGet(referenceNumber, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update TransferDetail of ReceivingOrder  unless any action has been taken on the ReceivingOrder  (State of ReceivingOrder must be \'Created\').
         * @param {string} referenceNumber 
         * @param {UpdateReceivingOrderTransferDetailsRequestDTO} [updateReceivingOrderTransferDetailsRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ReceivingOrdersReferenceNumberTransferDetailPut(referenceNumber: string, updateReceivingOrderTransferDetailsRequestDTO?: UpdateReceivingOrderTransferDetailsRequestDTO, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ReceivingOrdersApiAxiosParamCreator(configuration).openapiV1ReceivingOrdersReferenceNumberTransferDetailPut(referenceNumber, updateReceivingOrderTransferDetailsRequestDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ReceivingOrdersApi - factory interface
 * @export
 */
export const ReceivingOrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {BulkSoftDeleteReceivingOrdersCommand} [bulkSoftDeleteReceivingOrdersCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReceivingOrdersBulkSoftDeletePut(bulkSoftDeleteReceivingOrdersCommand?: BulkSoftDeleteReceivingOrdersCommand, options?: any) {
            return ReceivingOrdersApiFp(configuration).adminReceivingOrdersBulkSoftDeletePut(bulkSoftDeleteReceivingOrdersCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReceivingOrdersQueryOrdersOfTenantGet(dqb?: string, options?: any) {
            return ReceivingOrdersApiFp(configuration).adminReceivingOrdersQueryOrdersOfTenantGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {ResetReceivingOrdersWmsSyncCommand} [resetReceivingOrdersWmsSyncCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReceivingOrdersResetWmsSyncPut(resetReceivingOrdersWmsSyncCommand?: ResetReceivingOrdersWmsSyncCommand, options?: any) {
            return ReceivingOrdersApiFp(configuration).adminReceivingOrdersResetWmsSyncPut(resetReceivingOrdersWmsSyncCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {CreateReceivingOrderCommand} [createReceivingOrderCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersCreatePost(createReceivingOrderCommand?: CreateReceivingOrderCommand, options?: any) {
            return ReceivingOrdersApiFp(configuration).apiReceivingOrdersCreatePost(createReceivingOrderCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [referenceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersDetailGet(referenceNumber?: string, options?: any) {
            return ReceivingOrdersApiFp(configuration).apiReceivingOrdersDetailGet(referenceNumber, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersExportToExcelGet(dqb?: string, options?: any) {
            return ReceivingOrdersApiFp(configuration).apiReceivingOrdersExportToExcelGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} receivingOrderNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersExportToExcelLineItemExpirationDatesGet(receivingOrderNo: string, dqb?: string, options?: any) {
            return ReceivingOrdersApiFp(configuration).apiReceivingOrdersExportToExcelLineItemExpirationDatesGet(receivingOrderNo, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} receivingOrderNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersExportToExcelLineItemSerialNumbersGet(receivingOrderNo: string, dqb?: string, options?: any) {
            return ReceivingOrdersApiFp(configuration).apiReceivingOrdersExportToExcelLineItemSerialNumbersGet(receivingOrderNo, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} receivingOrderNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersExportToExcelLineItemsGet(receivingOrderNo: string, dqb?: string, options?: any) {
            return ReceivingOrdersApiFp(configuration).apiReceivingOrdersExportToExcelLineItemsGet(receivingOrderNo, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersExportToExcelWithDetailGet(dqb?: string, options?: any) {
            return ReceivingOrdersApiFp(configuration).apiReceivingOrdersExportToExcelWithDetailGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersImportFromExcelPost(file?: any, options?: any) {
            return ReceivingOrdersApiFp(configuration).apiReceivingOrdersImportFromExcelPost(file, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersQueryGet(dqb?: string, options?: any) {
            return ReceivingOrdersApiFp(configuration).apiReceivingOrdersQueryGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersQueryLineItemExpirtionDatesGet(referenceNumber: string, dqb?: string, options?: any) {
            return ReceivingOrdersApiFp(configuration).apiReceivingOrdersQueryLineItemExpirtionDatesGet(referenceNumber, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersQueryLineItemSerialNumbersGet(referenceNumber: string, dqb?: string, options?: any) {
            return ReceivingOrdersApiFp(configuration).apiReceivingOrdersQueryLineItemSerialNumbersGet(referenceNumber, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersQueryLineItemsGet(referenceNumber: string, dqb?: string, options?: any) {
            return ReceivingOrdersApiFp(configuration).apiReceivingOrdersQueryLineItemsGet(referenceNumber, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [referenceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersStateDetailGet(referenceNumber?: string, options?: any) {
            return ReceivingOrdersApiFp(configuration).apiReceivingOrdersStateDetailGet(referenceNumber, options)(axios, basePath);
        },
        /**
         * 
         * @param {UpdateReceivingOrderTransferDetailCommand} [updateReceivingOrderTransferDetailCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReceivingOrdersUpdateTransferDetailPut(updateReceivingOrderTransferDetailCommand?: UpdateReceivingOrderTransferDetailCommand, options?: any) {
            return ReceivingOrdersApiFp(configuration).apiReceivingOrdersUpdateTransferDetailPut(updateReceivingOrderTransferDetailCommand, options)(axios, basePath);
        },
        /**
         * 
         * @summary Updates receiving order.
         * @param {SyncReceivingOrderFromMaestroCommand} [syncReceivingOrderFromMaestroCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maestroV1ReceivingOrdersUpsertReceivingOrderPut(syncReceivingOrderFromMaestroCommand?: SyncReceivingOrderFromMaestroCommand, options?: any) {
            return ReceivingOrdersApiFp(configuration).maestroV1ReceivingOrdersUpsertReceivingOrderPut(syncReceivingOrderFromMaestroCommand, options)(axios, basePath);
        },
        /**
         * 
         * @summary Query ReceivingOrders.
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ReceivingOrdersGet(dqb?: string, options?: any) {
            return ReceivingOrdersApiFp(configuration).openapiV1ReceivingOrdersGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @summary Create ReceivingOrder.
         * @param {CreateReceivingOrderRequestDTO} [createReceivingOrderRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ReceivingOrdersPost(createReceivingOrderRequestDTO?: CreateReceivingOrderRequestDTO, options?: any) {
            return ReceivingOrdersApiFp(configuration).openapiV1ReceivingOrdersPost(createReceivingOrderRequestDTO, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get ReceivingOrder by referenceNumber.
         * @param {string} referenceNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ReceivingOrdersReferenceNumberGet(referenceNumber: string, options?: any) {
            return ReceivingOrdersApiFp(configuration).openapiV1ReceivingOrdersReferenceNumberGet(referenceNumber, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update TransferDetail of ReceivingOrder  unless any action has been taken on the ReceivingOrder  (State of ReceivingOrder must be \'Created\').
         * @param {string} referenceNumber 
         * @param {UpdateReceivingOrderTransferDetailsRequestDTO} [updateReceivingOrderTransferDetailsRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ReceivingOrdersReferenceNumberTransferDetailPut(referenceNumber: string, updateReceivingOrderTransferDetailsRequestDTO?: UpdateReceivingOrderTransferDetailsRequestDTO, options?: any) {
            return ReceivingOrdersApiFp(configuration).openapiV1ReceivingOrdersReferenceNumberTransferDetailPut(referenceNumber, updateReceivingOrderTransferDetailsRequestDTO, options)(axios, basePath);
        },
    };
};

/**
 * ReceivingOrdersApi - object-oriented interface
 * @export
 * @class ReceivingOrdersApi
 * @extends {BaseAPI}
 */
export class ReceivingOrdersApi extends BaseAPI {
    /**
     * 
     * @param {BulkSoftDeleteReceivingOrdersCommand} [bulkSoftDeleteReceivingOrdersCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingOrdersApi
     */
    public adminReceivingOrdersBulkSoftDeletePut(bulkSoftDeleteReceivingOrdersCommand?: BulkSoftDeleteReceivingOrdersCommand, options?: any) {
        return ReceivingOrdersApiFp(this.configuration).adminReceivingOrdersBulkSoftDeletePut(bulkSoftDeleteReceivingOrdersCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingOrdersApi
     */
    public adminReceivingOrdersQueryOrdersOfTenantGet(dqb?: string, options?: any) {
        return ReceivingOrdersApiFp(this.configuration).adminReceivingOrdersQueryOrdersOfTenantGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {ResetReceivingOrdersWmsSyncCommand} [resetReceivingOrdersWmsSyncCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingOrdersApi
     */
    public adminReceivingOrdersResetWmsSyncPut(resetReceivingOrdersWmsSyncCommand?: ResetReceivingOrdersWmsSyncCommand, options?: any) {
        return ReceivingOrdersApiFp(this.configuration).adminReceivingOrdersResetWmsSyncPut(resetReceivingOrdersWmsSyncCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {CreateReceivingOrderCommand} [createReceivingOrderCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingOrdersApi
     */
    public apiReceivingOrdersCreatePost(createReceivingOrderCommand?: CreateReceivingOrderCommand, options?: any) {
        return ReceivingOrdersApiFp(this.configuration).apiReceivingOrdersCreatePost(createReceivingOrderCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [referenceNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingOrdersApi
     */
    public apiReceivingOrdersDetailGet(referenceNumber?: string, options?: any) {
        return ReceivingOrdersApiFp(this.configuration).apiReceivingOrdersDetailGet(referenceNumber, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingOrdersApi
     */
    public apiReceivingOrdersExportToExcelGet(dqb?: string, options?: any) {
        return ReceivingOrdersApiFp(this.configuration).apiReceivingOrdersExportToExcelGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} receivingOrderNo 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingOrdersApi
     */
    public apiReceivingOrdersExportToExcelLineItemExpirationDatesGet(receivingOrderNo: string, dqb?: string, options?: any) {
        return ReceivingOrdersApiFp(this.configuration).apiReceivingOrdersExportToExcelLineItemExpirationDatesGet(receivingOrderNo, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} receivingOrderNo 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingOrdersApi
     */
    public apiReceivingOrdersExportToExcelLineItemSerialNumbersGet(receivingOrderNo: string, dqb?: string, options?: any) {
        return ReceivingOrdersApiFp(this.configuration).apiReceivingOrdersExportToExcelLineItemSerialNumbersGet(receivingOrderNo, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} receivingOrderNo 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingOrdersApi
     */
    public apiReceivingOrdersExportToExcelLineItemsGet(receivingOrderNo: string, dqb?: string, options?: any) {
        return ReceivingOrdersApiFp(this.configuration).apiReceivingOrdersExportToExcelLineItemsGet(receivingOrderNo, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingOrdersApi
     */
    public apiReceivingOrdersExportToExcelWithDetailGet(dqb?: string, options?: any) {
        return ReceivingOrdersApiFp(this.configuration).apiReceivingOrdersExportToExcelWithDetailGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingOrdersApi
     */
    public apiReceivingOrdersImportFromExcelPost(file?: any, options?: any) {
        return ReceivingOrdersApiFp(this.configuration).apiReceivingOrdersImportFromExcelPost(file, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingOrdersApi
     */
    public apiReceivingOrdersQueryGet(dqb?: string, options?: any) {
        return ReceivingOrdersApiFp(this.configuration).apiReceivingOrdersQueryGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} referenceNumber 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingOrdersApi
     */
    public apiReceivingOrdersQueryLineItemExpirtionDatesGet(referenceNumber: string, dqb?: string, options?: any) {
        return ReceivingOrdersApiFp(this.configuration).apiReceivingOrdersQueryLineItemExpirtionDatesGet(referenceNumber, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} referenceNumber 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingOrdersApi
     */
    public apiReceivingOrdersQueryLineItemSerialNumbersGet(referenceNumber: string, dqb?: string, options?: any) {
        return ReceivingOrdersApiFp(this.configuration).apiReceivingOrdersQueryLineItemSerialNumbersGet(referenceNumber, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} referenceNumber 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingOrdersApi
     */
    public apiReceivingOrdersQueryLineItemsGet(referenceNumber: string, dqb?: string, options?: any) {
        return ReceivingOrdersApiFp(this.configuration).apiReceivingOrdersQueryLineItemsGet(referenceNumber, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [referenceNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingOrdersApi
     */
    public apiReceivingOrdersStateDetailGet(referenceNumber?: string, options?: any) {
        return ReceivingOrdersApiFp(this.configuration).apiReceivingOrdersStateDetailGet(referenceNumber, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {UpdateReceivingOrderTransferDetailCommand} [updateReceivingOrderTransferDetailCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingOrdersApi
     */
    public apiReceivingOrdersUpdateTransferDetailPut(updateReceivingOrderTransferDetailCommand?: UpdateReceivingOrderTransferDetailCommand, options?: any) {
        return ReceivingOrdersApiFp(this.configuration).apiReceivingOrdersUpdateTransferDetailPut(updateReceivingOrderTransferDetailCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Updates receiving order.
     * @param {SyncReceivingOrderFromMaestroCommand} [syncReceivingOrderFromMaestroCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingOrdersApi
     */
    public maestroV1ReceivingOrdersUpsertReceivingOrderPut(syncReceivingOrderFromMaestroCommand?: SyncReceivingOrderFromMaestroCommand, options?: any) {
        return ReceivingOrdersApiFp(this.configuration).maestroV1ReceivingOrdersUpsertReceivingOrderPut(syncReceivingOrderFromMaestroCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Query ReceivingOrders.
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingOrdersApi
     */
    public openapiV1ReceivingOrdersGet(dqb?: string, options?: any) {
        return ReceivingOrdersApiFp(this.configuration).openapiV1ReceivingOrdersGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Create ReceivingOrder.
     * @param {CreateReceivingOrderRequestDTO} [createReceivingOrderRequestDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingOrdersApi
     */
    public openapiV1ReceivingOrdersPost(createReceivingOrderRequestDTO?: CreateReceivingOrderRequestDTO, options?: any) {
        return ReceivingOrdersApiFp(this.configuration).openapiV1ReceivingOrdersPost(createReceivingOrderRequestDTO, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get ReceivingOrder by referenceNumber.
     * @param {string} referenceNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingOrdersApi
     */
    public openapiV1ReceivingOrdersReferenceNumberGet(referenceNumber: string, options?: any) {
        return ReceivingOrdersApiFp(this.configuration).openapiV1ReceivingOrdersReferenceNumberGet(referenceNumber, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update TransferDetail of ReceivingOrder  unless any action has been taken on the ReceivingOrder  (State of ReceivingOrder must be \'Created\').
     * @param {string} referenceNumber 
     * @param {UpdateReceivingOrderTransferDetailsRequestDTO} [updateReceivingOrderTransferDetailsRequestDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceivingOrdersApi
     */
    public openapiV1ReceivingOrdersReferenceNumberTransferDetailPut(referenceNumber: string, updateReceivingOrderTransferDetailsRequestDTO?: UpdateReceivingOrderTransferDetailsRequestDTO, options?: any) {
        return ReceivingOrdersApiFp(this.configuration).openapiV1ReceivingOrdersReferenceNumberTransferDetailPut(referenceNumber, updateReceivingOrderTransferDetailsRequestDTO, options)(this.axios, this.basePath);
    }

}


/**
 * ReturnsApi - axios parameter creator
 * @export
 */
export const ReturnsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [salesOrderId] 
         * @param {Date} [startTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsCountBySalesOrderGet(salesOrderId?: string, startTime?: Date, options: any = {}): RequestArgs {
            const localVarPath = `/api/Returns.countBySalesOrder`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (salesOrderId !== undefined) {
                localVarQueryParameter['salesOrderId'] = salesOrderId;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any).toISOString();
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOmnitroReturnCommand} [createOmnitroReturnCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsCreatePost(createOmnitroReturnCommand?: CreateOmnitroReturnCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/Returns.create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createOmnitroReturnCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createOmnitroReturnCommand !== undefined ? createOmnitroReturnCommand : {}) : (createOmnitroReturnCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [returnNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsDetailGet(returnNo?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Returns.detail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (returnNo !== undefined) {
                localVarQueryParameter['returnNo'] = returnNo;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsExportDamagedProductsToExcelGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Returns.exportDamagedProductsToExcel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsExportToExcelGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Returns.exportToExcel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsExportToExcelWithDetailGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Returns.exportToExcelWithDetail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Date} [startTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsLongTermReportGet(startTime?: Date, options: any = {}): RequestArgs {
            const localVarPath = `/api/Returns.longTermReport`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any).toISOString();
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsQueryDamagedProductsGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Returns.queryDamagedProducts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsQueryGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Returns.query`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} returnNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsQueryLineItemsGet(returnNo: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'returnNo' is not null or undefined
            if (returnNo === null || returnNo === undefined) {
                throw new RequiredError('returnNo','Required parameter returnNo was null or undefined when calling apiReturnsQueryLineItemsGet.');
            }
            const localVarPath = `/api/Returns.queryLineItems`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) { 
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (returnNo !== undefined) {
                localVarQueryParameter['returnNo'] = returnNo;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [returnNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsSalesOrderGet(returnNo?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Returns.salesOrder`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (returnNo !== undefined) {
                localVarQueryParameter['returnNo'] = returnNo;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [returnNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsStateDetailGet(returnNo?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Returns.stateDetail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (returnNo !== undefined) {
                localVarQueryParameter['returnNo'] = returnNo;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Date} [startTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsWeeklyReportGet(startTime?: Date, options: any = {}): RequestArgs {
            const localVarPath = `/api/Returns.weeklyReport`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any).toISOString();
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create or update return.
         * @param {FetchReturnsFromMaestroCommand} [fetchReturnsFromMaestroCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maestroV1ReturnsCreateOrUpdateReturnPost(fetchReturnsFromMaestroCommand?: FetchReturnsFromMaestroCommand, options: any = {}): RequestArgs {
            const localVarPath = `/maestro/v1/Returns/createOrUpdateReturn`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof fetchReturnsFromMaestroCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(fetchReturnsFromMaestroCommand !== undefined ? fetchReturnsFromMaestroCommand : {}) : (fetchReturnsFromMaestroCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Query Returns.
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ReturnsGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/openapi/v1/Returns`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Return.
         * @param {CreateReturnRequestDTO} [createReturnRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ReturnsPost(createReturnRequestDTO?: CreateReturnRequestDTO, options: any = {}): RequestArgs {
            const localVarPath = `/openapi/v1/Returns`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createReturnRequestDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createReturnRequestDTO !== undefined ? createReturnRequestDTO : {}) : (createReturnRequestDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Return by returnNo.
         * @param {string} returnNo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ReturnsReturnNoGet(returnNo: string, options: any = {}): RequestArgs {
            // verify required parameter 'returnNo' is not null or undefined
            if (returnNo === null || returnNo === undefined) {
                throw new RequiredError('returnNo','Required parameter returnNo was null or undefined when calling openapiV1ReturnsReturnNoGet.');
            }
            const localVarPath = `/openapi/v1/Returns/{returnNo}`
                .replace(`{${"returnNo"}}`, encodeURIComponent(String(returnNo)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Query Returns including LineItems.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ReturnsReturnsWithLineItemsGet(options: any = {}): RequestArgs {
            const localVarPath = `/openapi/v1/Returns/returnsWithLineItems`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReturnsApi - functional programming interface
 * @export
 */
export const ReturnsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [salesOrderId] 
         * @param {Date} [startTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsCountBySalesOrderGet(salesOrderId?: string, startTime?: Date, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountOutputDTO> {
            const localVarAxiosArgs = ReturnsApiAxiosParamCreator(configuration).apiReturnsCountBySalesOrderGet(salesOrderId, startTime, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateOmnitroReturnCommand} [createOmnitroReturnCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsCreatePost(createOmnitroReturnCommand?: CreateOmnitroReturnCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedReturnOutputDTO> {
            const localVarAxiosArgs = ReturnsApiAxiosParamCreator(configuration).apiReturnsCreatePost(createOmnitroReturnCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [returnNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsDetailGet(returnNo?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnDetailOutputDTO> {
            const localVarAxiosArgs = ReturnsApiAxiosParamCreator(configuration).apiReturnsDetailGet(returnNo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsExportDamagedProductsToExcelGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ReturnsApiAxiosParamCreator(configuration).apiReturnsExportDamagedProductsToExcelGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsExportToExcelGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ReturnsApiAxiosParamCreator(configuration).apiReturnsExportToExcelGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsExportToExcelWithDetailGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ReturnsApiAxiosParamCreator(configuration).apiReturnsExportToExcelWithDetailGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Date} [startTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsLongTermReportGet(startTime?: Date, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnLongTermReportOutputDTO> {
            const localVarAxiosArgs = ReturnsApiAxiosParamCreator(configuration).apiReturnsLongTermReportGet(startTime, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsQueryDamagedProductsGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnDamagedProductsQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = ReturnsApiAxiosParamCreator(configuration).apiReturnsQueryDamagedProductsGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsQueryGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = ReturnsApiAxiosParamCreator(configuration).apiReturnsQueryGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} returnNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsQueryLineItemsGet(returnNo: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LineItemOfReturnQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = ReturnsApiAxiosParamCreator(configuration).apiReturnsQueryLineItemsGet(returnNo, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [returnNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsSalesOrderGet(returnNo?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesOrderDetailOfReturnOutputDTO> {
            const localVarAxiosArgs = ReturnsApiAxiosParamCreator(configuration).apiReturnsSalesOrderGet(returnNo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [returnNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsStateDetailGet(returnNo?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateDetailsOutputDTO> {
            const localVarAxiosArgs = ReturnsApiAxiosParamCreator(configuration).apiReturnsStateDetailGet(returnNo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Date} [startTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsWeeklyReportGet(startTime?: Date, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WeeklyReportOutputDTO> {
            const localVarAxiosArgs = ReturnsApiAxiosParamCreator(configuration).apiReturnsWeeklyReportGet(startTime, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create or update return.
         * @param {FetchReturnsFromMaestroCommand} [fetchReturnsFromMaestroCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maestroV1ReturnsCreateOrUpdateReturnPost(fetchReturnsFromMaestroCommand?: FetchReturnsFromMaestroCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ReturnsApiAxiosParamCreator(configuration).maestroV1ReturnsCreateOrUpdateReturnPost(fetchReturnsFromMaestroCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Query Returns.
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ReturnsGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnSummaryResponseDTODynamicQueryPagedDTO> {
            const localVarAxiosArgs = ReturnsApiAxiosParamCreator(configuration).openapiV1ReturnsGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create Return.
         * @param {CreateReturnRequestDTO} [createReturnRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ReturnsPost(createReturnRequestDTO?: CreateReturnRequestDTO, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedReturnOutputDTO> {
            const localVarAxiosArgs = ReturnsApiAxiosParamCreator(configuration).openapiV1ReturnsPost(createReturnRequestDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Return by returnNo.
         * @param {string} returnNo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ReturnsReturnNoGet(returnNo: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnDetailResponseDTO> {
            const localVarAxiosArgs = ReturnsApiAxiosParamCreator(configuration).openapiV1ReturnsReturnNoGet(returnNo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Query Returns including LineItems.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ReturnsReturnsWithLineItemsGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnDetailResponseDTO> {
            const localVarAxiosArgs = ReturnsApiAxiosParamCreator(configuration).openapiV1ReturnsReturnsWithLineItemsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ReturnsApi - factory interface
 * @export
 */
export const ReturnsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [salesOrderId] 
         * @param {Date} [startTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsCountBySalesOrderGet(salesOrderId?: string, startTime?: Date, options?: any) {
            return ReturnsApiFp(configuration).apiReturnsCountBySalesOrderGet(salesOrderId, startTime, options)(axios, basePath);
        },
        /**
         * 
         * @param {CreateOmnitroReturnCommand} [createOmnitroReturnCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsCreatePost(createOmnitroReturnCommand?: CreateOmnitroReturnCommand, options?: any) {
            return ReturnsApiFp(configuration).apiReturnsCreatePost(createOmnitroReturnCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [returnNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsDetailGet(returnNo?: string, options?: any) {
            return ReturnsApiFp(configuration).apiReturnsDetailGet(returnNo, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsExportDamagedProductsToExcelGet(dqb?: string, options?: any) {
            return ReturnsApiFp(configuration).apiReturnsExportDamagedProductsToExcelGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsExportToExcelGet(dqb?: string, options?: any) {
            return ReturnsApiFp(configuration).apiReturnsExportToExcelGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsExportToExcelWithDetailGet(dqb?: string, options?: any) {
            return ReturnsApiFp(configuration).apiReturnsExportToExcelWithDetailGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {Date} [startTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsLongTermReportGet(startTime?: Date, options?: any) {
            return ReturnsApiFp(configuration).apiReturnsLongTermReportGet(startTime, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsQueryDamagedProductsGet(dqb?: string, options?: any) {
            return ReturnsApiFp(configuration).apiReturnsQueryDamagedProductsGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsQueryGet(dqb?: string, options?: any) {
            return ReturnsApiFp(configuration).apiReturnsQueryGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} returnNo 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsQueryLineItemsGet(returnNo: string, dqb?: string, options?: any) {
            return ReturnsApiFp(configuration).apiReturnsQueryLineItemsGet(returnNo, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [returnNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsSalesOrderGet(returnNo?: string, options?: any) {
            return ReturnsApiFp(configuration).apiReturnsSalesOrderGet(returnNo, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [returnNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsStateDetailGet(returnNo?: string, options?: any) {
            return ReturnsApiFp(configuration).apiReturnsStateDetailGet(returnNo, options)(axios, basePath);
        },
        /**
         * 
         * @param {Date} [startTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReturnsWeeklyReportGet(startTime?: Date, options?: any) {
            return ReturnsApiFp(configuration).apiReturnsWeeklyReportGet(startTime, options)(axios, basePath);
        },
        /**
         * 
         * @summary Create or update return.
         * @param {FetchReturnsFromMaestroCommand} [fetchReturnsFromMaestroCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maestroV1ReturnsCreateOrUpdateReturnPost(fetchReturnsFromMaestroCommand?: FetchReturnsFromMaestroCommand, options?: any) {
            return ReturnsApiFp(configuration).maestroV1ReturnsCreateOrUpdateReturnPost(fetchReturnsFromMaestroCommand, options)(axios, basePath);
        },
        /**
         * 
         * @summary Query Returns.
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ReturnsGet(dqb?: string, options?: any) {
            return ReturnsApiFp(configuration).openapiV1ReturnsGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @summary Create Return.
         * @param {CreateReturnRequestDTO} [createReturnRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ReturnsPost(createReturnRequestDTO?: CreateReturnRequestDTO, options?: any) {
            return ReturnsApiFp(configuration).openapiV1ReturnsPost(createReturnRequestDTO, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get Return by returnNo.
         * @param {string} returnNo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ReturnsReturnNoGet(returnNo: string, options?: any) {
            return ReturnsApiFp(configuration).openapiV1ReturnsReturnNoGet(returnNo, options)(axios, basePath);
        },
        /**
         * 
         * @summary Query Returns including LineItems.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1ReturnsReturnsWithLineItemsGet(options?: any) {
            return ReturnsApiFp(configuration).openapiV1ReturnsReturnsWithLineItemsGet(options)(axios, basePath);
        },
    };
};

/**
 * ReturnsApi - object-oriented interface
 * @export
 * @class ReturnsApi
 * @extends {BaseAPI}
 */
export class ReturnsApi extends BaseAPI {
    /**
     * 
     * @param {string} [salesOrderId] 
     * @param {Date} [startTime] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReturnsApi
     */
    public apiReturnsCountBySalesOrderGet(salesOrderId?: string, startTime?: Date, options?: any) {
        return ReturnsApiFp(this.configuration).apiReturnsCountBySalesOrderGet(salesOrderId, startTime, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {CreateOmnitroReturnCommand} [createOmnitroReturnCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReturnsApi
     */
    public apiReturnsCreatePost(createOmnitroReturnCommand?: CreateOmnitroReturnCommand, options?: any) {
        return ReturnsApiFp(this.configuration).apiReturnsCreatePost(createOmnitroReturnCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [returnNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReturnsApi
     */
    public apiReturnsDetailGet(returnNo?: string, options?: any) {
        return ReturnsApiFp(this.configuration).apiReturnsDetailGet(returnNo, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReturnsApi
     */
    public apiReturnsExportDamagedProductsToExcelGet(dqb?: string, options?: any) {
        return ReturnsApiFp(this.configuration).apiReturnsExportDamagedProductsToExcelGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReturnsApi
     */
    public apiReturnsExportToExcelGet(dqb?: string, options?: any) {
        return ReturnsApiFp(this.configuration).apiReturnsExportToExcelGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReturnsApi
     */
    public apiReturnsExportToExcelWithDetailGet(dqb?: string, options?: any) {
        return ReturnsApiFp(this.configuration).apiReturnsExportToExcelWithDetailGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {Date} [startTime] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReturnsApi
     */
    public apiReturnsLongTermReportGet(startTime?: Date, options?: any) {
        return ReturnsApiFp(this.configuration).apiReturnsLongTermReportGet(startTime, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReturnsApi
     */
    public apiReturnsQueryDamagedProductsGet(dqb?: string, options?: any) {
        return ReturnsApiFp(this.configuration).apiReturnsQueryDamagedProductsGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReturnsApi
     */
    public apiReturnsQueryGet(dqb?: string, options?: any) {
        return ReturnsApiFp(this.configuration).apiReturnsQueryGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} returnNo 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReturnsApi
     */
    public apiReturnsQueryLineItemsGet(returnNo: string, dqb?: string, options?: any) {
        return ReturnsApiFp(this.configuration).apiReturnsQueryLineItemsGet(returnNo, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [returnNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReturnsApi
     */
    public apiReturnsSalesOrderGet(returnNo?: string, options?: any) {
        return ReturnsApiFp(this.configuration).apiReturnsSalesOrderGet(returnNo, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [returnNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReturnsApi
     */
    public apiReturnsStateDetailGet(returnNo?: string, options?: any) {
        return ReturnsApiFp(this.configuration).apiReturnsStateDetailGet(returnNo, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {Date} [startTime] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReturnsApi
     */
    public apiReturnsWeeklyReportGet(startTime?: Date, options?: any) {
        return ReturnsApiFp(this.configuration).apiReturnsWeeklyReportGet(startTime, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Create or update return.
     * @param {FetchReturnsFromMaestroCommand} [fetchReturnsFromMaestroCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReturnsApi
     */
    public maestroV1ReturnsCreateOrUpdateReturnPost(fetchReturnsFromMaestroCommand?: FetchReturnsFromMaestroCommand, options?: any) {
        return ReturnsApiFp(this.configuration).maestroV1ReturnsCreateOrUpdateReturnPost(fetchReturnsFromMaestroCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Query Returns.
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReturnsApi
     */
    public openapiV1ReturnsGet(dqb?: string, options?: any) {
        return ReturnsApiFp(this.configuration).openapiV1ReturnsGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Create Return.
     * @param {CreateReturnRequestDTO} [createReturnRequestDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReturnsApi
     */
    public openapiV1ReturnsPost(createReturnRequestDTO?: CreateReturnRequestDTO, options?: any) {
        return ReturnsApiFp(this.configuration).openapiV1ReturnsPost(createReturnRequestDTO, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get Return by returnNo.
     * @param {string} returnNo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReturnsApi
     */
    public openapiV1ReturnsReturnNoGet(returnNo: string, options?: any) {
        return ReturnsApiFp(this.configuration).openapiV1ReturnsReturnNoGet(returnNo, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Query Returns including LineItems.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReturnsApi
     */
    public openapiV1ReturnsReturnsWithLineItemsGet(options?: any) {
        return ReturnsApiFp(this.configuration).openapiV1ReturnsReturnsWithLineItemsGet(options)(this.axios, this.basePath);
    }

}


/**
 * SalesChannelsApi - axios parameter creator
 * @export
 */
export const SalesChannelsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of Sales Channels. Like Hepsiburada or Gittigidiyor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesChannelsGet(options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesChannels`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SalesChannelsApi - functional programming interface
 * @export
 */
export const SalesChannelsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of Sales Channels. Like Hepsiburada or Gittigidiyor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesChannelsGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SalesChannelsDTO>> {
            const localVarAxiosArgs = SalesChannelsApiAxiosParamCreator(configuration).apiSalesChannelsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SalesChannelsApi - factory interface
 * @export
 */
export const SalesChannelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary List of Sales Channels. Like Hepsiburada or Gittigidiyor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesChannelsGet(options?: any) {
            return SalesChannelsApiFp(configuration).apiSalesChannelsGet(options)(axios, basePath);
        },
    };
};

/**
 * SalesChannelsApi - object-oriented interface
 * @export
 * @class SalesChannelsApi
 * @extends {BaseAPI}
 */
export class SalesChannelsApi extends BaseAPI {
    /**
     * 
     * @summary List of Sales Channels. Like Hepsiburada or Gittigidiyor.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesChannelsApi
     */
    public apiSalesChannelsGet(options?: any) {
        return SalesChannelsApiFp(this.configuration).apiSalesChannelsGet(options)(this.axios, this.basePath);
    }

}


/**
 * SalesOrdersApi - axios parameter creator
 * @export
 */
export const SalesOrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BulkForceCancelSalesOrdersCommand} [bulkForceCancelSalesOrdersCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersBulkForceCancelPut(bulkForceCancelSalesOrdersCommand?: BulkForceCancelSalesOrdersCommand, options: any = {}): RequestArgs {
            const localVarPath = `/admin/SalesOrders.bulkForceCancel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof bulkForceCancelSalesOrdersCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(bulkForceCancelSalesOrdersCommand !== undefined ? bulkForceCancelSalesOrdersCommand : {}) : (bulkForceCancelSalesOrdersCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BulkSoftDeleteSalesOrdersCommand} [bulkSoftDeleteSalesOrdersCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersBulkSoftDeletePut(bulkSoftDeleteSalesOrdersCommand?: BulkSoftDeleteSalesOrdersCommand, options: any = {}): RequestArgs {
            const localVarPath = `/admin/SalesOrders.bulkSoftDelete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof bulkSoftDeleteSalesOrdersCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(bulkSoftDeleteSalesOrdersCommand !== undefined ? bulkSoftDeleteSalesOrdersCommand : {}) : (bulkSoftDeleteSalesOrdersCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BulkUndoCancelSalesOrdersCommand} [bulkUndoCancelSalesOrdersCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersBulkUndoCancelPut(bulkUndoCancelSalesOrdersCommand?: BulkUndoCancelSalesOrdersCommand, options: any = {}): RequestArgs {
            const localVarPath = `/admin/SalesOrders.bulkUndoCancel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof bulkUndoCancelSalesOrdersCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(bulkUndoCancelSalesOrdersCommand !== undefined ? bulkUndoCancelSalesOrdersCommand : {}) : (bulkUndoCancelSalesOrdersCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [integrationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersCleanMonthlyInvalidSalesOrdersQueueMessageAddGet(integrationId?: string, options: any = {}): RequestArgs {
            const localVarPath = `/admin/SalesOrders.CleanMonthlyInvalidSalesOrdersQueueMessageAdd`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (integrationId !== undefined) {
                localVarQueryParameter['integrationId'] = integrationId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersCorrectInvalidSalesOrdersLineItemNotFoundQueueMessageAddGet(options: any = {}): RequestArgs {
            const localVarPath = `/admin/SalesOrders.CorrectInvalidSalesOrdersLineItemNotFoundQueueMessageAdd`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SalesOrdersFetchFromWmsInputDTO} [salesOrdersFetchFromWmsInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersFetchfromWMSPut(salesOrdersFetchFromWmsInputDTO?: SalesOrdersFetchFromWmsInputDTO, options: any = {}): RequestArgs {
            const localVarPath = `/admin/SalesOrders.fetchfromWMS`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof salesOrdersFetchFromWmsInputDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(salesOrdersFetchFromWmsInputDTO !== undefined ? salesOrdersFetchFromWmsInputDTO : {}) : (salesOrdersFetchFromWmsInputDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NotifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO} [notifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersFulfillIntegrationPut(notifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO?: NotifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO, options: any = {}): RequestArgs {
            const localVarPath = `/admin/SalesOrders.fulfillIntegration`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof notifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(notifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO !== undefined ? notifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO : {}) : (notifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantName 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersQueryGet(tenantName: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'tenantName' is not null or undefined
            if (tenantName === null || tenantName === undefined) {
                throw new RequiredError('tenantName','Required parameter tenantName was null or undefined when calling adminSalesOrdersQueryGet.');
            }
            const localVarPath = `/admin/SalesOrders.query`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (tenantName !== undefined) {
                localVarQueryParameter['tenantName'] = tenantName;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersQueryOrdersOfTenantGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/admin/SalesOrders.queryOrdersOfTenant`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SalesOrdersSendToWmsInputDTO} [salesOrdersSendToWmsInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersSendtoWMSPut(salesOrdersSendToWmsInputDTO?: SalesOrdersSendToWmsInputDTO, options: any = {}): RequestArgs {
            const localVarPath = `/admin/SalesOrders.sendtoWMS`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof salesOrdersSendToWmsInputDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(salesOrdersSendToWmsInputDTO !== undefined ? salesOrdersSendToWmsInputDTO : {}) : (salesOrdersSendToWmsInputDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FetchFromIntegrationInputDTO} [fetchFromIntegrationInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersSyncFromIntegrationCancelledPut(fetchFromIntegrationInputDTO?: FetchFromIntegrationInputDTO, options: any = {}): RequestArgs {
            const localVarPath = `/admin/SalesOrders.syncFromIntegrationCancelled`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof fetchFromIntegrationInputDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(fetchFromIntegrationInputDTO !== undefined ? fetchFromIntegrationInputDTO : {}) : (fetchFromIntegrationInputDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FetchFromIntegrationInputDTO} [fetchFromIntegrationInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersSyncFromIntegrationUnfulfilledPut(fetchFromIntegrationInputDTO?: FetchFromIntegrationInputDTO, options: any = {}): RequestArgs {
            const localVarPath = `/admin/SalesOrders.syncFromIntegrationUnfulfilled`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof fetchFromIntegrationInputDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(fetchFromIntegrationInputDTO !== undefined ? fetchFromIntegrationInputDTO : {}) : (fetchFromIntegrationInputDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BulkCancelLineItemsInputDTO} [bulkCancelLineItemsInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersBulkCancelLineItemsPut(bulkCancelLineItemsInputDTO?: BulkCancelLineItemsInputDTO, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.bulkCancelLineItems`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof bulkCancelLineItemsInputDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(bulkCancelLineItemsInputDTO !== undefined ? bulkCancelLineItemsInputDTO : {}) : (bulkCancelLineItemsInputDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BulkCreatePackageForBlockedSalesOrdersCommand} [bulkCreatePackageForBlockedSalesOrdersCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersBulkCreatePackagePost(bulkCreatePackageForBlockedSalesOrdersCommand?: BulkCreatePackageForBlockedSalesOrdersCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.bulkCreatePackage`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof bulkCreatePackageForBlockedSalesOrdersCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(bulkCreatePackageForBlockedSalesOrdersCommand !== undefined ? bulkCreatePackageForBlockedSalesOrdersCommand : {}) : (bulkCreatePackageForBlockedSalesOrdersCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BulkForceCancelSalesOrdersCommand} [bulkForceCancelSalesOrdersCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersBulkForceCancelPut(bulkForceCancelSalesOrdersCommand?: BulkForceCancelSalesOrdersCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.bulkForceCancel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof bulkForceCancelSalesOrdersCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(bulkForceCancelSalesOrdersCommand !== undefined ? bulkForceCancelSalesOrdersCommand : {}) : (bulkForceCancelSalesOrdersCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CancelLineItemsInputDTO} [cancelLineItemsInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersCancelLineItemsPut(cancelLineItemsInputDTO?: CancelLineItemsInputDTO, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.cancelLineItems`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof cancelLineItemsInputDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(cancelLineItemsInputDTO !== undefined ? cancelLineItemsInputDTO : {}) : (cancelLineItemsInputDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CancelPackageCommand} [cancelPackageCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersCancelPackagePut(cancelPackageCommand?: CancelPackageCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.cancelPackage`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof cancelPackageCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(cancelPackageCommand !== undefined ? cancelPackageCommand : {}) : (cancelPackageCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update SalesOrder Cargo Info.
         * @param {UpdateSalesOrderCargoInfoInputDTO} [updateSalesOrderCargoInfoInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersUpdateCargoInfoPatch(updateSalesOrderCargoInfoInputDTO?: UpdateSalesOrderCargoInfoInputDTO, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.updateCargoInfo`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateSalesOrderCargoInfoInputDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateSalesOrderCargoInfoInputDTO !== undefined ? updateSalesOrderCargoInfoInputDTO : {}) : (updateSalesOrderCargoInfoInputDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CancelSalesOrderCommand} [cancelSalesOrderCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersCancelPut(cancelSalesOrderCommand?: CancelSalesOrderCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.cancel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof cancelSalesOrderCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(cancelSalesOrderCommand !== undefined ? cancelSalesOrderCommand : {}) : (cancelSalesOrderCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [referenceNumber] 
         * @param {CorrectInvalidSalesOrderCommand} [correctInvalidSalesOrderCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersCorrectInvalidSalesOrderPost(referenceNumber?: string, correctInvalidSalesOrderCommand?: CorrectInvalidSalesOrderCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.correctInvalidSalesOrder`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (referenceNumber !== undefined) {
                localVarQueryParameter['referenceNumber'] = referenceNumber;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof correctInvalidSalesOrderCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(correctInvalidSalesOrderCommand !== undefined ? correctInvalidSalesOrderCommand : {}) : (correctInvalidSalesOrderCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreatePackageCommand} [createPackageCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersCreatePackagePost(createPackageCommand?: CreatePackageCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.createPackage`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createPackageCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createPackageCommand !== undefined ? createPackageCommand : {}) : (createPackageCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSalesOrderInputDTO} [createSalesOrderInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersCreatePost(createSalesOrderInputDTO?: CreateSalesOrderInputDTO, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createSalesOrderInputDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createSalesOrderInputDTO !== undefined ? createSalesOrderInputDTO : {}) : (createSalesOrderInputDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [referenceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersDetailGet(referenceNumber?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.detail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (referenceNumber !== undefined) {
                localVarQueryParameter['referenceNumber'] = referenceNumber;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersExportInvalidSalesOrderToExcelGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.exportInvalidSalesOrderToExcel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersExportPendingdSalesOrderToExcelGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.exportPendingdSalesOrderToExcel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersExportPendingdSalesOrderWithDetailToExcelGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.exportPendingdSalesOrderWithDetailToExcel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersExportSalesOrderLineItemsToExcelGet(referenceNumber: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'referenceNumber' is not null or undefined
            if (referenceNumber === null || referenceNumber === undefined) {
                throw new RequiredError('referenceNumber','Required parameter referenceNumber was null or undefined when calling apiSalesOrdersExportSalesOrderLineItemsToExcelGet.');
            }
            const localVarPath = `/api/SalesOrders.exportSalesOrderLineItemsToExcel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (referenceNumber !== undefined) {
                localVarQueryParameter['referenceNumber'] = referenceNumber;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersExportSalesOrderPackageLineItemsToExcelGet(referenceNumber: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'referenceNumber' is not null or undefined
            if (referenceNumber === null || referenceNumber === undefined) {
                throw new RequiredError('referenceNumber','Required parameter referenceNumber was null or undefined when calling apiSalesOrdersExportSalesOrderPackageLineItemsToExcelGet.');
            }
            const localVarPath = `/api/SalesOrders.exportSalesOrderPackageLineItemsToExcel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (referenceNumber !== undefined) {
                localVarQueryParameter['referenceNumber'] = referenceNumber;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersExportSalesOrderSerialNumbersToExcelGet(referenceNumber: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'referenceNumber' is not null or undefined
            if (referenceNumber === null || referenceNumber === undefined) {
                throw new RequiredError('referenceNumber','Required parameter referenceNumber was null or undefined when calling apiSalesOrdersExportSalesOrderSerialNumbersToExcelGet.');
            }
            const localVarPath = `/api/SalesOrders.exportSalesOrderSerialNumbersToExcel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (referenceNumber !== undefined) {
                localVarQueryParameter['referenceNumber'] = referenceNumber;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersExportToExcelGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.exportToExcel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersExportToExcelWithDetailGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.exportToExcelWithDetail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersImportFromExcelPost(file?: any, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.importFromExcel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [referenceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersInvalidSalesOrderDetailGet(referenceNumber?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.invalidSalesOrderDetail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (referenceNumber !== undefined) {
                localVarQueryParameter['referenceNumber'] = referenceNumber;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Date} [startTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersLongTermReportGet(startTime?: Date, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.longTermReport`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any).toISOString();
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.query`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryInvalidSalesOrderLineItemsGet(referenceNumber: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'referenceNumber' is not null or undefined
            if (referenceNumber === null || referenceNumber === undefined) {
                throw new RequiredError('referenceNumber','Required parameter referenceNumber was null or undefined when calling apiSalesOrdersQueryInvalidSalesOrderLineItemsGet.');
            }
            const localVarPath = `/api/SalesOrders.queryInvalidSalesOrderLineItems`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (referenceNumber !== undefined) {
                localVarQueryParameter['referenceNumber'] = referenceNumber;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {boolean} onlyPackageless 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryLineItemsGet(referenceNumber: string, onlyPackageless: boolean, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'referenceNumber' is not null or undefined
            if (referenceNumber === null || referenceNumber === undefined) {
                throw new RequiredError('referenceNumber','Required parameter referenceNumber was null or undefined when calling apiSalesOrdersQueryLineItemsGet.');
            }
            // verify required parameter 'onlyPackageless' is not null or undefined
            if (onlyPackageless === null || onlyPackageless === undefined) {
                throw new RequiredError('onlyPackageless','Required parameter onlyPackageless was null or undefined when calling apiSalesOrdersQueryLineItemsGet.');
            }
            const localVarPath = `/api/SalesOrders.queryLineItems`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (referenceNumber !== undefined) {
                localVarQueryParameter['referenceNumber'] = referenceNumber;
            }

            if (onlyPackageless !== undefined) {
                localVarQueryParameter['onlyPackageless'] = onlyPackageless;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryPackageLineItemsGet(referenceNumber: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'referenceNumber' is not null or undefined
            if (referenceNumber === null || referenceNumber === undefined) {
                throw new RequiredError('referenceNumber','Required parameter referenceNumber was null or undefined when calling apiSalesOrdersQueryPackageLineItemsGet.');
            }
            const localVarPath = `/api/SalesOrders.queryPackageLineItems`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (referenceNumber !== undefined) {
                localVarQueryParameter['referenceNumber'] = referenceNumber;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryPackagesGet(referenceNumber: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'referenceNumber' is not null or undefined
            if (referenceNumber === null || referenceNumber === undefined) {
                throw new RequiredError('referenceNumber','Required parameter referenceNumber was null or undefined when calling apiSalesOrdersQueryPackagesGet.');
            }
            const localVarPath = `/api/SalesOrders.queryPackages`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (referenceNumber !== undefined) {
                localVarQueryParameter['referenceNumber'] = referenceNumber;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryPendingGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.queryPending`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryQuarantineGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.queryQuarantine`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryRecentLineItemsGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.queryRecentLineItems`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryReturnableGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.queryReturnable`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQuerySalesOrderSerialNumbersGet(referenceNumber: string, dqb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'referenceNumber' is not null or undefined
            if (referenceNumber === null || referenceNumber === undefined) {
                throw new RequiredError('referenceNumber','Required parameter referenceNumber was null or undefined when calling apiSalesOrdersQuerySalesOrderSerialNumbersGet.');
            }
            const localVarPath = `/api/SalesOrders.querySalesOrderSerialNumbers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }

            if (referenceNumber !== undefined) {
                localVarQueryParameter['referenceNumber'] = referenceNumber;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [referenceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersStateDetailGet(referenceNumber?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.stateDetail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (referenceNumber !== undefined) {
                localVarQueryParameter['referenceNumber'] = referenceNumber;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Date} [startTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersWeeklyReportGet(startTime?: Date, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.weeklyReport`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any).toISOString();
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetStockQuantityDetailsUsedInBlockedSalesOrdersCommand} [getStockQuantityDetailsUsedInBlockedSalesOrdersCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersGetStockQuantityDetailsUsedInBlockedSalesOrdersPost(getStockQuantityDetailsUsedInBlockedSalesOrdersCommand?: GetStockQuantityDetailsUsedInBlockedSalesOrdersCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/SalesOrders.getStockQuantityDetailsUsedInBlockedSalesOrders`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof getStockQuantityDetailsUsedInBlockedSalesOrdersCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(getStockQuantityDetailsUsedInBlockedSalesOrdersCommand !== undefined ? getStockQuantityDetailsUsedInBlockedSalesOrdersCommand : {}) : (getStockQuantityDetailsUsedInBlockedSalesOrdersCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Query Packages
         * @param {Date} [start] 
         * @param {Date} [end] 
         * @param {Array<string>} [referenceNumbers] 
         * @param {string} [APIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devSalesOrdersPackagesGet(start?: Date, end?: Date, referenceNumbers?: Array<string>, APIKEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/dev/SalesOrders/packages`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any).toISOString();
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any).toISOString();
            }

            if (referenceNumbers) {
                localVarQueryParameter['referenceNumbers'] = referenceNumbers;
            }

            if (APIKEY !== undefined && APIKEY !== null) {
                localVarHeaderParameter['APIKEY'] = String(APIKEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CancelLineItemFromMaestroCommand} [cancelLineItemFromMaestroCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maestroV1SalesOrdersCancelLineItemsPut(cancelLineItemFromMaestroCommand?: CancelLineItemFromMaestroCommand, options: any = {}): RequestArgs {
            const localVarPath = `/maestro/v1/SalesOrders/cancelLineItems`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof cancelLineItemFromMaestroCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(cancelLineItemFromMaestroCommand !== undefined ? cancelLineItemFromMaestroCommand : {}) : (cancelLineItemFromMaestroCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Cargo Code and Document Url.
         * @param {UpdateCargoCodeAndUrlCommand} [updateCargoCodeAndUrlCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maestroV1SalesOrdersUpdateCargoCodeAndUrlPut(updateCargoCodeAndUrlCommand?: UpdateCargoCodeAndUrlCommand, options: any = {}): RequestArgs {
            const localVarPath = `/maestro/v1/SalesOrders/updateCargoCodeAndUrl`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateCargoCodeAndUrlCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateCargoCodeAndUrlCommand !== undefined ? updateCargoCodeAndUrlCommand : {}) : (updateCargoCodeAndUrlCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update package.
         * @param {SyncPackagesFromMaestroCommand} [syncPackagesFromMaestroCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maestroV1SalesOrdersUpdatePackagePut(syncPackagesFromMaestroCommand?: SyncPackagesFromMaestroCommand, options: any = {}): RequestArgs {
            const localVarPath = `/maestro/v1/SalesOrders/updatePackage`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof syncPackagesFromMaestroCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(syncPackagesFromMaestroCommand !== undefined ? syncPackagesFromMaestroCommand : {}) : (syncPackagesFromMaestroCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Bulk Add or Update SalesOrder.
         * @param {Array<CreateSalesOrderRequestDTO>} [createSalesOrderRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1SalesOrdersBulkUpsertPost(createSalesOrderRequestDTO?: Array<CreateSalesOrderRequestDTO>, options: any = {}): RequestArgs {
            const localVarPath = `/openapi/v1/SalesOrders/BulkUpsert`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createSalesOrderRequestDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createSalesOrderRequestDTO !== undefined ? createSalesOrderRequestDTO : {}) : (createSalesOrderRequestDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Query SalesOrders.
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1SalesOrdersGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/openapi/v1/SalesOrders`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create SalesOrder.
         * @param {CreateSalesOrderRequestDTO} [createSalesOrderRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1SalesOrdersPost(createSalesOrderRequestDTO?: CreateSalesOrderRequestDTO, options: any = {}): RequestArgs {
            const localVarPath = `/openapi/v1/SalesOrders`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createSalesOrderRequestDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createSalesOrderRequestDTO !== undefined ? createSalesOrderRequestDTO : {}) : (createSalesOrderRequestDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get SalesOrder by referenceNumber.
         * @param {string} referenceNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1SalesOrdersReferenceNumberGet(referenceNumber: string, options: any = {}): RequestArgs {
            // verify required parameter 'referenceNumber' is not null or undefined
            if (referenceNumber === null || referenceNumber === undefined) {
                throw new RequiredError('referenceNumber','Required parameter referenceNumber was null or undefined when calling openapiV1SalesOrdersReferenceNumberGet.');
            }
            const localVarPath = `/openapi/v1/SalesOrders/{referenceNumber}`
                .replace(`{${"referenceNumber"}}`, encodeURIComponent(String(referenceNumber)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {CancelLineItemsRequestDTO} [cancelLineItemsRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1SalesOrdersReferenceNumbercancelLineItemsPut(referenceNumber: string, cancelLineItemsRequestDTO?: CancelLineItemsRequestDTO, options: any = {}): RequestArgs {
            // verify required parameter 'referenceNumber' is not null or undefined
            if (referenceNumber === null || referenceNumber === undefined) {
                throw new RequiredError('referenceNumber','Required parameter referenceNumber was null or undefined when calling openapiV1SalesOrdersReferenceNumbercancelLineItemsPut.');
            }
            const localVarPath = `/openapi/v1/SalesOrders/{referenceNumber}:cancelLineItems`
                .replace(`{${"referenceNumber"}}`, encodeURIComponent(String(referenceNumber)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof cancelLineItemsRequestDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(cancelLineItemsRequestDTO !== undefined ? cancelLineItemsRequestDTO : {}) : (cancelLineItemsRequestDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel SalesOrder  unless it has been already shipped  (State of the SalesOrder must not be \'Shipped\' or \'Delivered\').
         * @param {string} referenceNumber 
         * @param {string} [integrationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1SalesOrdersReferenceNumbercancelPut(referenceNumber: string, integrationId?: string, options: any = {}): RequestArgs {
            // verify required parameter 'referenceNumber' is not null or undefined
            if (referenceNumber === null || referenceNumber === undefined) {
                throw new RequiredError('referenceNumber','Required parameter referenceNumber was null or undefined when calling openapiV1SalesOrdersReferenceNumbercancelPut.');
            }
            const localVarPath = `/openapi/v1/SalesOrders/{referenceNumber}:cancel`
                .replace(`{${"referenceNumber"}}`, encodeURIComponent(String(referenceNumber)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (integrationId !== undefined) {
                localVarQueryParameter['integrationId'] = integrationId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1SalesOrdersSalesOrdersQueryInvalidSalesOrdersGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/openapi/v1/SalesOrders/SalesOrders.queryInvalidSalesOrders`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SalesOrdersApi - functional programming interface
 * @export
 */
export const SalesOrdersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BulkForceCancelSalesOrdersCommand} [bulkForceCancelSalesOrdersCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersBulkForceCancelPut(bulkForceCancelSalesOrdersCommand?: BulkForceCancelSalesOrdersCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceNumberOutputDTOBulkOperationResultDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).adminSalesOrdersBulkForceCancelPut(bulkForceCancelSalesOrdersCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {BulkSoftDeleteSalesOrdersCommand} [bulkSoftDeleteSalesOrdersCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersBulkSoftDeletePut(bulkSoftDeleteSalesOrdersCommand?: BulkSoftDeleteSalesOrdersCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringBulkOperationResultDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).adminSalesOrdersBulkSoftDeletePut(bulkSoftDeleteSalesOrdersCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {BulkUndoCancelSalesOrdersCommand} [bulkUndoCancelSalesOrdersCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersBulkUndoCancelPut(bulkUndoCancelSalesOrdersCommand?: BulkUndoCancelSalesOrdersCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceNumberOutputDTOBulkOperationResultDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).adminSalesOrdersBulkUndoCancelPut(bulkUndoCancelSalesOrdersCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [integrationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersCleanMonthlyInvalidSalesOrdersQueueMessageAddGet(integrationId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).adminSalesOrdersCleanMonthlyInvalidSalesOrdersQueueMessageAddGet(integrationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersCorrectInvalidSalesOrdersLineItemNotFoundQueueMessageAddGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).adminSalesOrdersCorrectInvalidSalesOrdersLineItemNotFoundQueueMessageAddGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SalesOrdersFetchFromWmsInputDTO} [salesOrdersFetchFromWmsInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersFetchfromWMSPut(salesOrdersFetchFromWmsInputDTO?: SalesOrdersFetchFromWmsInputDTO, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncOutputDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).adminSalesOrdersFetchfromWMSPut(salesOrdersFetchFromWmsInputDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {NotifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO} [notifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersFulfillIntegrationPut(notifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO?: NotifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).adminSalesOrdersFulfillIntegrationPut(notifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tenantName 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersQueryGet(tenantName: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesOrdersOfTenantQueryOutputDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).adminSalesOrdersQueryGet(tenantName, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersQueryOrdersOfTenantGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesOrdersOfTenantQueryOutputDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).adminSalesOrdersQueryOrdersOfTenantGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SalesOrdersSendToWmsInputDTO} [salesOrdersSendToWmsInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersSendtoWMSPut(salesOrdersSendToWmsInputDTO?: SalesOrdersSendToWmsInputDTO, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncOutputDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).adminSalesOrdersSendtoWMSPut(salesOrdersSendToWmsInputDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {FetchFromIntegrationInputDTO} [fetchFromIntegrationInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersSyncFromIntegrationCancelledPut(fetchFromIntegrationInputDTO?: FetchFromIntegrationInputDTO, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceNumberOutputDTOBulkOperationResultDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).adminSalesOrdersSyncFromIntegrationCancelledPut(fetchFromIntegrationInputDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {FetchFromIntegrationInputDTO} [fetchFromIntegrationInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersSyncFromIntegrationUnfulfilledPut(fetchFromIntegrationInputDTO?: FetchFromIntegrationInputDTO, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceNumberOutputDTOBulkOperationResultDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).adminSalesOrdersSyncFromIntegrationUnfulfilledPut(fetchFromIntegrationInputDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {BulkCancelLineItemsInputDTO} [bulkCancelLineItemsInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersBulkCancelLineItemsPut(bulkCancelLineItemsInputDTO?: BulkCancelLineItemsInputDTO, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersBulkCancelLineItemsPut(bulkCancelLineItemsInputDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {BulkCreatePackageForBlockedSalesOrdersCommand} [bulkCreatePackageForBlockedSalesOrdersCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersBulkCreatePackagePost(bulkCreatePackageForBlockedSalesOrdersCommand?: BulkCreatePackageForBlockedSalesOrdersCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersBulkCreatePackagePost(bulkCreatePackageForBlockedSalesOrdersCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {BulkForceCancelSalesOrdersCommand} [bulkForceCancelSalesOrdersCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersBulkForceCancelPut(bulkForceCancelSalesOrdersCommand?: BulkForceCancelSalesOrdersCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceNumberOutputDTOBulkOperationResultDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersBulkForceCancelPut(bulkForceCancelSalesOrdersCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CancelLineItemsInputDTO} [cancelLineItemsInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersCancelLineItemsPut(cancelLineItemsInputDTO?: CancelLineItemsInputDTO, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersCancelLineItemsPut(cancelLineItemsInputDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CancelPackageCommand} [cancelPackageCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersCancelPackagePut(cancelPackageCommand?: CancelPackageCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersCancelPackagePut(cancelPackageCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update SalesOrder Cargo Info.
         * @param {UpdateSalesOrderCargoInfoInputDTO} [updateSalesOrderCargoInfoInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersUpdateCargoInfoPatch(updateSalesOrderCargoInfoInputDTO?: UpdateSalesOrderCargoInfoInputDTO, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersUpdateCargoInfoPatch(updateSalesOrderCargoInfoInputDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CancelSalesOrderCommand} [cancelSalesOrderCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersCancelPut(cancelSalesOrderCommand?: CancelSalesOrderCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersCancelPut(cancelSalesOrderCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [referenceNumber] 
         * @param {CorrectInvalidSalesOrderCommand} [correctInvalidSalesOrderCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersCorrectInvalidSalesOrderPost(referenceNumber?: string, correctInvalidSalesOrderCommand?: CorrectInvalidSalesOrderCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersCorrectInvalidSalesOrderPost(referenceNumber, correctInvalidSalesOrderCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreatePackageCommand} [createPackageCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersCreatePackagePost(createPackageCommand?: CreatePackageCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersCreatePackagePost(createPackageCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateSalesOrderInputDTO} [createSalesOrderInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersCreatePost(createSalesOrderInputDTO?: CreateSalesOrderInputDTO, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedSalesOrderOutputDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersCreatePost(createSalesOrderInputDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [referenceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersDetailGet(referenceNumber?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesOrderDetailOutputDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersDetailGet(referenceNumber, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersExportInvalidSalesOrderToExcelGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersExportInvalidSalesOrderToExcelGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersExportPendingdSalesOrderToExcelGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersExportPendingdSalesOrderToExcelGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersExportPendingdSalesOrderWithDetailToExcelGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersExportPendingdSalesOrderWithDetailToExcelGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersExportSalesOrderLineItemsToExcelGet(referenceNumber: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersExportSalesOrderLineItemsToExcelGet(referenceNumber, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersExportSalesOrderPackageLineItemsToExcelGet(referenceNumber: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersExportSalesOrderPackageLineItemsToExcelGet(referenceNumber, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersExportSalesOrderSerialNumbersToExcelGet(referenceNumber: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersExportSalesOrderSerialNumbersToExcelGet(referenceNumber, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersExportToExcelGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersExportToExcelGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersExportToExcelWithDetailGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersExportToExcelWithDetailGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersImportFromExcelPost(file?: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersImportFromExcelPost(file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [referenceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersInvalidSalesOrderDetailGet(referenceNumber?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvalidSalesOrderDetailOutputDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersInvalidSalesOrderDetailGet(referenceNumber, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Date} [startTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersLongTermReportGet(startTime?: Date, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesOrderLongTermReportOutputDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersLongTermReportGet(startTime, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesOrderQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersQueryGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryInvalidSalesOrderLineItemsGet(referenceNumber: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvalidSalesOrderLineItemQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersQueryInvalidSalesOrderLineItemsGet(referenceNumber, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {boolean} onlyPackageless 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryLineItemsGet(referenceNumber: string, onlyPackageless: boolean, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LineItemOfSalesOrderQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersQueryLineItemsGet(referenceNumber, onlyPackageless, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryPackageLineItemsGet(referenceNumber: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LineItemOfPackageQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersQueryPackageLineItemsGet(referenceNumber, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryPackagesGet(referenceNumber: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackageOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersQueryPackagesGet(referenceNumber, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryPendingGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesOrderQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersQueryPendingGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryQuarantineGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvalidSalesOrderQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersQueryQuarantineGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryRecentLineItemsGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LineItemOfSalesOrderQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersQueryRecentLineItemsGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryReturnableGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesOrderQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersQueryReturnableGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQuerySalesOrderSerialNumbersGet(referenceNumber: string, dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LineItemOfSalesOrderQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersQuerySalesOrderSerialNumbersGet(referenceNumber, dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [referenceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersStateDetailGet(referenceNumber?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateDetailsOutputDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersStateDetailGet(referenceNumber, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Date} [startTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersWeeklyReportGet(startTime?: Date, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WeeklyReportOutputDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersWeeklyReportGet(startTime, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {GetStockQuantityDetailsUsedInBlockedSalesOrdersCommand} [getStockQuantityDetailsUsedInBlockedSalesOrdersCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersGetStockQuantityDetailsUsedInBlockedSalesOrdersPost(getStockQuantityDetailsUsedInBlockedSalesOrdersCommand?: GetStockQuantityDetailsUsedInBlockedSalesOrdersCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStockQuantityDetailsUsedInBlockedSalesOrdersCommandResultDynamicQueryOutputDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).apiSalesOrdersGetStockQuantityDetailsUsedInBlockedSalesOrdersPost(getStockQuantityDetailsUsedInBlockedSalesOrdersCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Query Packages
         * @param {Date} [start] 
         * @param {Date} [end] 
         * @param {Array<string>} [referenceNumbers] 
         * @param {string} [APIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devSalesOrdersPackagesGet(start?: Date, end?: Date, referenceNumbers?: Array<string>, APIKEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PackageReportResponseDTO>> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).devSalesOrdersPackagesGet(start, end, referenceNumbers, APIKEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CancelLineItemFromMaestroCommand} [cancelLineItemFromMaestroCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maestroV1SalesOrdersCancelLineItemsPut(cancelLineItemFromMaestroCommand?: CancelLineItemFromMaestroCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).maestroV1SalesOrdersCancelLineItemsPut(cancelLineItemFromMaestroCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update Cargo Code and Document Url.
         * @param {UpdateCargoCodeAndUrlCommand} [updateCargoCodeAndUrlCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maestroV1SalesOrdersUpdateCargoCodeAndUrlPut(updateCargoCodeAndUrlCommand?: UpdateCargoCodeAndUrlCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).maestroV1SalesOrdersUpdateCargoCodeAndUrlPut(updateCargoCodeAndUrlCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update package.
         * @param {SyncPackagesFromMaestroCommand} [syncPackagesFromMaestroCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maestroV1SalesOrdersUpdatePackagePut(syncPackagesFromMaestroCommand?: SyncPackagesFromMaestroCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).maestroV1SalesOrdersUpdatePackagePut(syncPackagesFromMaestroCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Bulk Add or Update SalesOrder.
         * @param {Array<CreateSalesOrderRequestDTO>} [createSalesOrderRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1SalesOrdersBulkUpsertPost(createSalesOrderRequestDTO?: Array<CreateSalesOrderRequestDTO>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).openapiV1SalesOrdersBulkUpsertPost(createSalesOrderRequestDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Query SalesOrders.
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1SalesOrdersGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesOrderDetailResponseDTODynamicQueryPagedDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).openapiV1SalesOrdersGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create SalesOrder.
         * @param {CreateSalesOrderRequestDTO} [createSalesOrderRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1SalesOrdersPost(createSalesOrderRequestDTO?: CreateSalesOrderRequestDTO, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).openapiV1SalesOrdersPost(createSalesOrderRequestDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get SalesOrder by referenceNumber.
         * @param {string} referenceNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1SalesOrdersReferenceNumberGet(referenceNumber: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesOrderDetailResponseDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).openapiV1SalesOrdersReferenceNumberGet(referenceNumber, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {CancelLineItemsRequestDTO} [cancelLineItemsRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1SalesOrdersReferenceNumbercancelLineItemsPut(referenceNumber: string, cancelLineItemsRequestDTO?: CancelLineItemsRequestDTO, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).openapiV1SalesOrdersReferenceNumbercancelLineItemsPut(referenceNumber, cancelLineItemsRequestDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Cancel SalesOrder  unless it has been already shipped  (State of the SalesOrder must not be \'Shipped\' or \'Delivered\').
         * @param {string} referenceNumber 
         * @param {string} [integrationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1SalesOrdersReferenceNumbercancelPut(referenceNumber: string, integrationId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).openapiV1SalesOrdersReferenceNumbercancelPut(referenceNumber, integrationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1SalesOrdersSalesOrdersQueryInvalidSalesOrdersGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvalidSalesOrderQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = SalesOrdersApiAxiosParamCreator(configuration).openapiV1SalesOrdersSalesOrdersQueryInvalidSalesOrdersGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SalesOrdersApi - factory interface
 * @export
 */
export const SalesOrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {BulkForceCancelSalesOrdersCommand} [bulkForceCancelSalesOrdersCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersBulkForceCancelPut(bulkForceCancelSalesOrdersCommand?: BulkForceCancelSalesOrdersCommand, options?: any) {
            return SalesOrdersApiFp(configuration).adminSalesOrdersBulkForceCancelPut(bulkForceCancelSalesOrdersCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {BulkSoftDeleteSalesOrdersCommand} [bulkSoftDeleteSalesOrdersCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersBulkSoftDeletePut(bulkSoftDeleteSalesOrdersCommand?: BulkSoftDeleteSalesOrdersCommand, options?: any) {
            return SalesOrdersApiFp(configuration).adminSalesOrdersBulkSoftDeletePut(bulkSoftDeleteSalesOrdersCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {BulkUndoCancelSalesOrdersCommand} [bulkUndoCancelSalesOrdersCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersBulkUndoCancelPut(bulkUndoCancelSalesOrdersCommand?: BulkUndoCancelSalesOrdersCommand, options?: any) {
            return SalesOrdersApiFp(configuration).adminSalesOrdersBulkUndoCancelPut(bulkUndoCancelSalesOrdersCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [integrationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersCleanMonthlyInvalidSalesOrdersQueueMessageAddGet(integrationId?: string, options?: any) {
            return SalesOrdersApiFp(configuration).adminSalesOrdersCleanMonthlyInvalidSalesOrdersQueueMessageAddGet(integrationId, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersCorrectInvalidSalesOrdersLineItemNotFoundQueueMessageAddGet(options?: any) {
            return SalesOrdersApiFp(configuration).adminSalesOrdersCorrectInvalidSalesOrdersLineItemNotFoundQueueMessageAddGet(options)(axios, basePath);
        },
        /**
         * 
         * @param {SalesOrdersFetchFromWmsInputDTO} [salesOrdersFetchFromWmsInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersFetchfromWMSPut(salesOrdersFetchFromWmsInputDTO?: SalesOrdersFetchFromWmsInputDTO, options?: any) {
            return SalesOrdersApiFp(configuration).adminSalesOrdersFetchfromWMSPut(salesOrdersFetchFromWmsInputDTO, options)(axios, basePath);
        },
        /**
         * 
         * @param {NotifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO} [notifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersFulfillIntegrationPut(notifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO?: NotifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO, options?: any) {
            return SalesOrdersApiFp(configuration).adminSalesOrdersFulfillIntegrationPut(notifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} tenantName 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersQueryGet(tenantName: string, dqb?: string, options?: any) {
            return SalesOrdersApiFp(configuration).adminSalesOrdersQueryGet(tenantName, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersQueryOrdersOfTenantGet(dqb?: string, options?: any) {
            return SalesOrdersApiFp(configuration).adminSalesOrdersQueryOrdersOfTenantGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {SalesOrdersSendToWmsInputDTO} [salesOrdersSendToWmsInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersSendtoWMSPut(salesOrdersSendToWmsInputDTO?: SalesOrdersSendToWmsInputDTO, options?: any) {
            return SalesOrdersApiFp(configuration).adminSalesOrdersSendtoWMSPut(salesOrdersSendToWmsInputDTO, options)(axios, basePath);
        },
        /**
         * 
         * @param {FetchFromIntegrationInputDTO} [fetchFromIntegrationInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersSyncFromIntegrationCancelledPut(fetchFromIntegrationInputDTO?: FetchFromIntegrationInputDTO, options?: any) {
            return SalesOrdersApiFp(configuration).adminSalesOrdersSyncFromIntegrationCancelledPut(fetchFromIntegrationInputDTO, options)(axios, basePath);
        },
        /**
         * 
         * @param {FetchFromIntegrationInputDTO} [fetchFromIntegrationInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesOrdersSyncFromIntegrationUnfulfilledPut(fetchFromIntegrationInputDTO?: FetchFromIntegrationInputDTO, options?: any) {
            return SalesOrdersApiFp(configuration).adminSalesOrdersSyncFromIntegrationUnfulfilledPut(fetchFromIntegrationInputDTO, options)(axios, basePath);
        },
        /**
         * 
         * @param {BulkCancelLineItemsInputDTO} [bulkCancelLineItemsInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersBulkCancelLineItemsPut(bulkCancelLineItemsInputDTO?: BulkCancelLineItemsInputDTO, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersBulkCancelLineItemsPut(bulkCancelLineItemsInputDTO, options)(axios, basePath);
        },
        /**
         * 
         * @param {BulkCreatePackageForBlockedSalesOrdersCommand} [bulkCreatePackageForBlockedSalesOrdersCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersBulkCreatePackagePost(bulkCreatePackageForBlockedSalesOrdersCommand?: BulkCreatePackageForBlockedSalesOrdersCommand, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersBulkCreatePackagePost(bulkCreatePackageForBlockedSalesOrdersCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {BulkForceCancelSalesOrdersCommand} [bulkForceCancelSalesOrdersCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersBulkForceCancelPut(bulkForceCancelSalesOrdersCommand?: BulkForceCancelSalesOrdersCommand, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersBulkForceCancelPut(bulkForceCancelSalesOrdersCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {CancelLineItemsInputDTO} [cancelLineItemsInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersCancelLineItemsPut(cancelLineItemsInputDTO?: CancelLineItemsInputDTO, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersCancelLineItemsPut(cancelLineItemsInputDTO, options)(axios, basePath);
        },
        /**
         * 
         * @param {CancelPackageCommand} [cancelPackageCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersCancelPackagePut(cancelPackageCommand?: CancelPackageCommand, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersCancelPackagePut(cancelPackageCommand, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update SalesOrder Cargo Info.
         * @param {UpdateSalesOrderCargoInfoInputDTO} [updateSalesOrderCargoInfoInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersUpdateCargoInfoPatch(updateSalesOrderCargoInfoInputDTO?: UpdateSalesOrderCargoInfoInputDTO, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersUpdateCargoInfoPatch(updateSalesOrderCargoInfoInputDTO, options)(axios, basePath);
        },
        /**
         * 
         * @param {CancelSalesOrderCommand} [cancelSalesOrderCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersCancelPut(cancelSalesOrderCommand?: CancelSalesOrderCommand, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersCancelPut(cancelSalesOrderCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [referenceNumber] 
         * @param {CorrectInvalidSalesOrderCommand} [correctInvalidSalesOrderCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersCorrectInvalidSalesOrderPost(referenceNumber?: string, correctInvalidSalesOrderCommand?: CorrectInvalidSalesOrderCommand, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersCorrectInvalidSalesOrderPost(referenceNumber, correctInvalidSalesOrderCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {CreatePackageCommand} [createPackageCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersCreatePackagePost(createPackageCommand?: CreatePackageCommand, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersCreatePackagePost(createPackageCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {CreateSalesOrderInputDTO} [createSalesOrderInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersCreatePost(createSalesOrderInputDTO?: CreateSalesOrderInputDTO, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersCreatePost(createSalesOrderInputDTO, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [referenceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersDetailGet(referenceNumber?: string, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersDetailGet(referenceNumber, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersExportInvalidSalesOrderToExcelGet(dqb?: string, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersExportInvalidSalesOrderToExcelGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersExportPendingdSalesOrderToExcelGet(dqb?: string, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersExportPendingdSalesOrderToExcelGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersExportPendingdSalesOrderWithDetailToExcelGet(dqb?: string, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersExportPendingdSalesOrderWithDetailToExcelGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersExportSalesOrderLineItemsToExcelGet(referenceNumber: string, dqb?: string, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersExportSalesOrderLineItemsToExcelGet(referenceNumber, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersExportSalesOrderPackageLineItemsToExcelGet(referenceNumber: string, dqb?: string, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersExportSalesOrderPackageLineItemsToExcelGet(referenceNumber, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersExportSalesOrderSerialNumbersToExcelGet(referenceNumber: string, dqb?: string, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersExportSalesOrderSerialNumbersToExcelGet(referenceNumber, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersExportToExcelGet(dqb?: string, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersExportToExcelGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersExportToExcelWithDetailGet(dqb?: string, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersExportToExcelWithDetailGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersImportFromExcelPost(file?: any, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersImportFromExcelPost(file, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [referenceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersInvalidSalesOrderDetailGet(referenceNumber?: string, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersInvalidSalesOrderDetailGet(referenceNumber, options)(axios, basePath);
        },
        /**
         * 
         * @param {Date} [startTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersLongTermReportGet(startTime?: Date, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersLongTermReportGet(startTime, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryGet(dqb?: string, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersQueryGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryInvalidSalesOrderLineItemsGet(referenceNumber: string, dqb?: string, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersQueryInvalidSalesOrderLineItemsGet(referenceNumber, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {boolean} onlyPackageless 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryLineItemsGet(referenceNumber: string, onlyPackageless: boolean, dqb?: string, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersQueryLineItemsGet(referenceNumber, onlyPackageless, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryPackageLineItemsGet(referenceNumber: string, dqb?: string, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersQueryPackageLineItemsGet(referenceNumber, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryPackagesGet(referenceNumber: string, dqb?: string, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersQueryPackagesGet(referenceNumber, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryPendingGet(dqb?: string, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersQueryPendingGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryQuarantineGet(dqb?: string, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersQueryQuarantineGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryRecentLineItemsGet(dqb?: string, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersQueryRecentLineItemsGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQueryReturnableGet(dqb?: string, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersQueryReturnableGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersQuerySalesOrderSerialNumbersGet(referenceNumber: string, dqb?: string, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersQuerySalesOrderSerialNumbersGet(referenceNumber, dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [referenceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersStateDetailGet(referenceNumber?: string, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersStateDetailGet(referenceNumber, options)(axios, basePath);
        },
        /**
         * 
         * @param {Date} [startTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersWeeklyReportGet(startTime?: Date, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersWeeklyReportGet(startTime, options)(axios, basePath);
        },
        /**
         * 
         * @param {GetStockQuantityDetailsUsedInBlockedSalesOrdersCommand} [getStockQuantityDetailsUsedInBlockedSalesOrdersCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSalesOrdersGetStockQuantityDetailsUsedInBlockedSalesOrdersPost(getStockQuantityDetailsUsedInBlockedSalesOrdersCommand?: GetStockQuantityDetailsUsedInBlockedSalesOrdersCommand, options?: any) {
            return SalesOrdersApiFp(configuration).apiSalesOrdersGetStockQuantityDetailsUsedInBlockedSalesOrdersPost(getStockQuantityDetailsUsedInBlockedSalesOrdersCommand, options)(axios, basePath);
        },
        /**
         * 
         * @summary Query Packages
         * @param {Date} [start] 
         * @param {Date} [end] 
         * @param {Array<string>} [referenceNumbers] 
         * @param {string} [APIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devSalesOrdersPackagesGet(start?: Date, end?: Date, referenceNumbers?: Array<string>, APIKEY?: string, options?: any) {
            return SalesOrdersApiFp(configuration).devSalesOrdersPackagesGet(start, end, referenceNumbers, APIKEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {CancelLineItemFromMaestroCommand} [cancelLineItemFromMaestroCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maestroV1SalesOrdersCancelLineItemsPut(cancelLineItemFromMaestroCommand?: CancelLineItemFromMaestroCommand, options?: any) {
            return SalesOrdersApiFp(configuration).maestroV1SalesOrdersCancelLineItemsPut(cancelLineItemFromMaestroCommand, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update Cargo Code and Document Url.
         * @param {UpdateCargoCodeAndUrlCommand} [updateCargoCodeAndUrlCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maestroV1SalesOrdersUpdateCargoCodeAndUrlPut(updateCargoCodeAndUrlCommand?: UpdateCargoCodeAndUrlCommand, options?: any) {
            return SalesOrdersApiFp(configuration).maestroV1SalesOrdersUpdateCargoCodeAndUrlPut(updateCargoCodeAndUrlCommand, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update package.
         * @param {SyncPackagesFromMaestroCommand} [syncPackagesFromMaestroCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maestroV1SalesOrdersUpdatePackagePut(syncPackagesFromMaestroCommand?: SyncPackagesFromMaestroCommand, options?: any) {
            return SalesOrdersApiFp(configuration).maestroV1SalesOrdersUpdatePackagePut(syncPackagesFromMaestroCommand, options)(axios, basePath);
        },
        /**
         * 
         * @summary Bulk Add or Update SalesOrder.
         * @param {Array<CreateSalesOrderRequestDTO>} [createSalesOrderRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1SalesOrdersBulkUpsertPost(createSalesOrderRequestDTO?: Array<CreateSalesOrderRequestDTO>, options?: any) {
            return SalesOrdersApiFp(configuration).openapiV1SalesOrdersBulkUpsertPost(createSalesOrderRequestDTO, options)(axios, basePath);
        },
        /**
         * 
         * @summary Query SalesOrders.
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1SalesOrdersGet(dqb?: string, options?: any) {
            return SalesOrdersApiFp(configuration).openapiV1SalesOrdersGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @summary Create SalesOrder.
         * @param {CreateSalesOrderRequestDTO} [createSalesOrderRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1SalesOrdersPost(createSalesOrderRequestDTO?: CreateSalesOrderRequestDTO, options?: any) {
            return SalesOrdersApiFp(configuration).openapiV1SalesOrdersPost(createSalesOrderRequestDTO, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get SalesOrder by referenceNumber.
         * @param {string} referenceNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1SalesOrdersReferenceNumberGet(referenceNumber: string, options?: any) {
            return SalesOrdersApiFp(configuration).openapiV1SalesOrdersReferenceNumberGet(referenceNumber, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {CancelLineItemsRequestDTO} [cancelLineItemsRequestDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1SalesOrdersReferenceNumbercancelLineItemsPut(referenceNumber: string, cancelLineItemsRequestDTO?: CancelLineItemsRequestDTO, options?: any) {
            return SalesOrdersApiFp(configuration).openapiV1SalesOrdersReferenceNumbercancelLineItemsPut(referenceNumber, cancelLineItemsRequestDTO, options)(axios, basePath);
        },
        /**
         * 
         * @summary Cancel SalesOrder  unless it has been already shipped  (State of the SalesOrder must not be \'Shipped\' or \'Delivered\').
         * @param {string} referenceNumber 
         * @param {string} [integrationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1SalesOrdersReferenceNumbercancelPut(referenceNumber: string, integrationId?: string, options?: any) {
            return SalesOrdersApiFp(configuration).openapiV1SalesOrdersReferenceNumbercancelPut(referenceNumber, integrationId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openapiV1SalesOrdersSalesOrdersQueryInvalidSalesOrdersGet(dqb?: string, options?: any) {
            return SalesOrdersApiFp(configuration).openapiV1SalesOrdersSalesOrdersQueryInvalidSalesOrdersGet(dqb, options)(axios, basePath);
        },
    };
};

/**
 * SalesOrdersApi - object-oriented interface
 * @export
 * @class SalesOrdersApi
 * @extends {BaseAPI}
 */
export class SalesOrdersApi extends BaseAPI {
    /**
     * 
     * @param {BulkForceCancelSalesOrdersCommand} [bulkForceCancelSalesOrdersCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public adminSalesOrdersBulkForceCancelPut(bulkForceCancelSalesOrdersCommand?: BulkForceCancelSalesOrdersCommand, options?: any) {
        return SalesOrdersApiFp(this.configuration).adminSalesOrdersBulkForceCancelPut(bulkForceCancelSalesOrdersCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {BulkSoftDeleteSalesOrdersCommand} [bulkSoftDeleteSalesOrdersCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public adminSalesOrdersBulkSoftDeletePut(bulkSoftDeleteSalesOrdersCommand?: BulkSoftDeleteSalesOrdersCommand, options?: any) {
        return SalesOrdersApiFp(this.configuration).adminSalesOrdersBulkSoftDeletePut(bulkSoftDeleteSalesOrdersCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {BulkUndoCancelSalesOrdersCommand} [bulkUndoCancelSalesOrdersCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public adminSalesOrdersBulkUndoCancelPut(bulkUndoCancelSalesOrdersCommand?: BulkUndoCancelSalesOrdersCommand, options?: any) {
        return SalesOrdersApiFp(this.configuration).adminSalesOrdersBulkUndoCancelPut(bulkUndoCancelSalesOrdersCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [integrationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public adminSalesOrdersCleanMonthlyInvalidSalesOrdersQueueMessageAddGet(integrationId?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).adminSalesOrdersCleanMonthlyInvalidSalesOrdersQueueMessageAddGet(integrationId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public adminSalesOrdersCorrectInvalidSalesOrdersLineItemNotFoundQueueMessageAddGet(options?: any) {
        return SalesOrdersApiFp(this.configuration).adminSalesOrdersCorrectInvalidSalesOrdersLineItemNotFoundQueueMessageAddGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {SalesOrdersFetchFromWmsInputDTO} [salesOrdersFetchFromWmsInputDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public adminSalesOrdersFetchfromWMSPut(salesOrdersFetchFromWmsInputDTO?: SalesOrdersFetchFromWmsInputDTO, options?: any) {
        return SalesOrdersApiFp(this.configuration).adminSalesOrdersFetchfromWMSPut(salesOrdersFetchFromWmsInputDTO, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {NotifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO} [notifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public adminSalesOrdersFulfillIntegrationPut(notifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO?: NotifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO, options?: any) {
        return SalesOrdersApiFp(this.configuration).adminSalesOrdersFulfillIntegrationPut(notifyFulfilledSalesOrdersFromOSToIntegrationsInputDTO, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} tenantName 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public adminSalesOrdersQueryGet(tenantName: string, dqb?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).adminSalesOrdersQueryGet(tenantName, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public adminSalesOrdersQueryOrdersOfTenantGet(dqb?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).adminSalesOrdersQueryOrdersOfTenantGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {SalesOrdersSendToWmsInputDTO} [salesOrdersSendToWmsInputDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public adminSalesOrdersSendtoWMSPut(salesOrdersSendToWmsInputDTO?: SalesOrdersSendToWmsInputDTO, options?: any) {
        return SalesOrdersApiFp(this.configuration).adminSalesOrdersSendtoWMSPut(salesOrdersSendToWmsInputDTO, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {FetchFromIntegrationInputDTO} [fetchFromIntegrationInputDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public adminSalesOrdersSyncFromIntegrationCancelledPut(fetchFromIntegrationInputDTO?: FetchFromIntegrationInputDTO, options?: any) {
        return SalesOrdersApiFp(this.configuration).adminSalesOrdersSyncFromIntegrationCancelledPut(fetchFromIntegrationInputDTO, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {FetchFromIntegrationInputDTO} [fetchFromIntegrationInputDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public adminSalesOrdersSyncFromIntegrationUnfulfilledPut(fetchFromIntegrationInputDTO?: FetchFromIntegrationInputDTO, options?: any) {
        return SalesOrdersApiFp(this.configuration).adminSalesOrdersSyncFromIntegrationUnfulfilledPut(fetchFromIntegrationInputDTO, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {BulkCancelLineItemsInputDTO} [bulkCancelLineItemsInputDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersBulkCancelLineItemsPut(bulkCancelLineItemsInputDTO?: BulkCancelLineItemsInputDTO, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersBulkCancelLineItemsPut(bulkCancelLineItemsInputDTO, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {BulkCreatePackageForBlockedSalesOrdersCommand} [bulkCreatePackageForBlockedSalesOrdersCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersBulkCreatePackagePost(bulkCreatePackageForBlockedSalesOrdersCommand?: BulkCreatePackageForBlockedSalesOrdersCommand, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersBulkCreatePackagePost(bulkCreatePackageForBlockedSalesOrdersCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {BulkForceCancelSalesOrdersCommand} [bulkForceCancelSalesOrdersCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersBulkForceCancelPut(bulkForceCancelSalesOrdersCommand?: BulkForceCancelSalesOrdersCommand, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersBulkForceCancelPut(bulkForceCancelSalesOrdersCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {CancelLineItemsInputDTO} [cancelLineItemsInputDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersCancelLineItemsPut(cancelLineItemsInputDTO?: CancelLineItemsInputDTO, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersCancelLineItemsPut(cancelLineItemsInputDTO, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {CancelPackageCommand} [cancelPackageCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersCancelPackagePut(cancelPackageCommand?: CancelPackageCommand, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersCancelPackagePut(cancelPackageCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update SalesOrder Cargo Info.
     * @param {UpdateSalesOrderCargoInfoInputDTO} [updateSalesOrderCargoInfoInputDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersUpdateCargoInfoPatch(updateSalesOrderCargoInfoInputDTO?: UpdateSalesOrderCargoInfoInputDTO, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersUpdateCargoInfoPatch(updateSalesOrderCargoInfoInputDTO, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {CancelSalesOrderCommand} [cancelSalesOrderCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersCancelPut(cancelSalesOrderCommand?: CancelSalesOrderCommand, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersCancelPut(cancelSalesOrderCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [referenceNumber] 
     * @param {CorrectInvalidSalesOrderCommand} [correctInvalidSalesOrderCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersCorrectInvalidSalesOrderPost(referenceNumber?: string, correctInvalidSalesOrderCommand?: CorrectInvalidSalesOrderCommand, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersCorrectInvalidSalesOrderPost(referenceNumber, correctInvalidSalesOrderCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {CreatePackageCommand} [createPackageCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersCreatePackagePost(createPackageCommand?: CreatePackageCommand, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersCreatePackagePost(createPackageCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {CreateSalesOrderInputDTO} [createSalesOrderInputDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersCreatePost(createSalesOrderInputDTO?: CreateSalesOrderInputDTO, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersCreatePost(createSalesOrderInputDTO, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [referenceNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersDetailGet(referenceNumber?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersDetailGet(referenceNumber, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersExportInvalidSalesOrderToExcelGet(dqb?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersExportInvalidSalesOrderToExcelGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersExportPendingdSalesOrderToExcelGet(dqb?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersExportPendingdSalesOrderToExcelGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersExportPendingdSalesOrderWithDetailToExcelGet(dqb?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersExportPendingdSalesOrderWithDetailToExcelGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} referenceNumber 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersExportSalesOrderLineItemsToExcelGet(referenceNumber: string, dqb?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersExportSalesOrderLineItemsToExcelGet(referenceNumber, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} referenceNumber 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersExportSalesOrderPackageLineItemsToExcelGet(referenceNumber: string, dqb?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersExportSalesOrderPackageLineItemsToExcelGet(referenceNumber, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} referenceNumber 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersExportSalesOrderSerialNumbersToExcelGet(referenceNumber: string, dqb?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersExportSalesOrderSerialNumbersToExcelGet(referenceNumber, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersExportToExcelGet(dqb?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersExportToExcelGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersExportToExcelWithDetailGet(dqb?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersExportToExcelWithDetailGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersImportFromExcelPost(file?: any, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersImportFromExcelPost(file, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [referenceNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersInvalidSalesOrderDetailGet(referenceNumber?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersInvalidSalesOrderDetailGet(referenceNumber, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {Date} [startTime] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersLongTermReportGet(startTime?: Date, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersLongTermReportGet(startTime, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersQueryGet(dqb?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersQueryGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} referenceNumber 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersQueryInvalidSalesOrderLineItemsGet(referenceNumber: string, dqb?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersQueryInvalidSalesOrderLineItemsGet(referenceNumber, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} referenceNumber 
     * @param {boolean} onlyPackageless 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersQueryLineItemsGet(referenceNumber: string, onlyPackageless: boolean, dqb?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersQueryLineItemsGet(referenceNumber, onlyPackageless, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} referenceNumber 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersQueryPackageLineItemsGet(referenceNumber: string, dqb?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersQueryPackageLineItemsGet(referenceNumber, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} referenceNumber 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersQueryPackagesGet(referenceNumber: string, dqb?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersQueryPackagesGet(referenceNumber, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersQueryPendingGet(dqb?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersQueryPendingGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersQueryQuarantineGet(dqb?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersQueryQuarantineGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersQueryRecentLineItemsGet(dqb?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersQueryRecentLineItemsGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersQueryReturnableGet(dqb?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersQueryReturnableGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} referenceNumber 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersQuerySalesOrderSerialNumbersGet(referenceNumber: string, dqb?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersQuerySalesOrderSerialNumbersGet(referenceNumber, dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [referenceNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersStateDetailGet(referenceNumber?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersStateDetailGet(referenceNumber, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {Date} [startTime] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersWeeklyReportGet(startTime?: Date, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersWeeklyReportGet(startTime, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {GetStockQuantityDetailsUsedInBlockedSalesOrdersCommand} [getStockQuantityDetailsUsedInBlockedSalesOrdersCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public apiSalesOrdersGetStockQuantityDetailsUsedInBlockedSalesOrdersPost(getStockQuantityDetailsUsedInBlockedSalesOrdersCommand?: GetStockQuantityDetailsUsedInBlockedSalesOrdersCommand, options?: any) {
        return SalesOrdersApiFp(this.configuration).apiSalesOrdersGetStockQuantityDetailsUsedInBlockedSalesOrdersPost(getStockQuantityDetailsUsedInBlockedSalesOrdersCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Query Packages
     * @param {Date} [start] 
     * @param {Date} [end] 
     * @param {Array<string>} [referenceNumbers] 
     * @param {string} [APIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public devSalesOrdersPackagesGet(start?: Date, end?: Date, referenceNumbers?: Array<string>, APIKEY?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).devSalesOrdersPackagesGet(start, end, referenceNumbers, APIKEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {CancelLineItemFromMaestroCommand} [cancelLineItemFromMaestroCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public maestroV1SalesOrdersCancelLineItemsPut(cancelLineItemFromMaestroCommand?: CancelLineItemFromMaestroCommand, options?: any) {
        return SalesOrdersApiFp(this.configuration).maestroV1SalesOrdersCancelLineItemsPut(cancelLineItemFromMaestroCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update Cargo Code and Document Url.
     * @param {UpdateCargoCodeAndUrlCommand} [updateCargoCodeAndUrlCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public maestroV1SalesOrdersUpdateCargoCodeAndUrlPut(updateCargoCodeAndUrlCommand?: UpdateCargoCodeAndUrlCommand, options?: any) {
        return SalesOrdersApiFp(this.configuration).maestroV1SalesOrdersUpdateCargoCodeAndUrlPut(updateCargoCodeAndUrlCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update package.
     * @param {SyncPackagesFromMaestroCommand} [syncPackagesFromMaestroCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public maestroV1SalesOrdersUpdatePackagePut(syncPackagesFromMaestroCommand?: SyncPackagesFromMaestroCommand, options?: any) {
        return SalesOrdersApiFp(this.configuration).maestroV1SalesOrdersUpdatePackagePut(syncPackagesFromMaestroCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Bulk Add or Update SalesOrder.
     * @param {Array<CreateSalesOrderRequestDTO>} [createSalesOrderRequestDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public openapiV1SalesOrdersBulkUpsertPost(createSalesOrderRequestDTO?: Array<CreateSalesOrderRequestDTO>, options?: any) {
        return SalesOrdersApiFp(this.configuration).openapiV1SalesOrdersBulkUpsertPost(createSalesOrderRequestDTO, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Query SalesOrders.
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public openapiV1SalesOrdersGet(dqb?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).openapiV1SalesOrdersGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Create SalesOrder.
     * @param {CreateSalesOrderRequestDTO} [createSalesOrderRequestDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public openapiV1SalesOrdersPost(createSalesOrderRequestDTO?: CreateSalesOrderRequestDTO, options?: any) {
        return SalesOrdersApiFp(this.configuration).openapiV1SalesOrdersPost(createSalesOrderRequestDTO, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get SalesOrder by referenceNumber.
     * @param {string} referenceNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public openapiV1SalesOrdersReferenceNumberGet(referenceNumber: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).openapiV1SalesOrdersReferenceNumberGet(referenceNumber, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} referenceNumber 
     * @param {CancelLineItemsRequestDTO} [cancelLineItemsRequestDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public openapiV1SalesOrdersReferenceNumbercancelLineItemsPut(referenceNumber: string, cancelLineItemsRequestDTO?: CancelLineItemsRequestDTO, options?: any) {
        return SalesOrdersApiFp(this.configuration).openapiV1SalesOrdersReferenceNumbercancelLineItemsPut(referenceNumber, cancelLineItemsRequestDTO, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Cancel SalesOrder  unless it has been already shipped  (State of the SalesOrder must not be \'Shipped\' or \'Delivered\').
     * @param {string} referenceNumber 
     * @param {string} [integrationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public openapiV1SalesOrdersReferenceNumbercancelPut(referenceNumber: string, integrationId?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).openapiV1SalesOrdersReferenceNumbercancelPut(referenceNumber, integrationId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesOrdersApi
     */
    public openapiV1SalesOrdersSalesOrdersQueryInvalidSalesOrdersGet(dqb?: string, options?: any) {
        return SalesOrdersApiFp(this.configuration).openapiV1SalesOrdersSalesOrdersQueryInvalidSalesOrdersGet(dqb, options)(this.axios, this.basePath);
    }

}

/**
 * TenantsApi - axios parameter creator
 * @export
 */
export const TenantsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddTenantConfigurationCommand} [addTenantConfigurationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsAddTenantConfigPost(addTenantConfigurationCommand?: AddTenantConfigurationCommand, options: any = {}): RequestArgs {
            const localVarPath = `/admin/Tenants.addTenantConfig`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof addTenantConfigurationCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(addTenantConfigurationCommand !== undefined ? addTenantConfigurationCommand : {}) : (addTenantConfigurationCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateTenantCommand} [createTenantCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsCreatePost(createTenantCommand?: CreateTenantCommand, options: any = {}): RequestArgs {
            const localVarPath = `/admin/Tenants.create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createTenantCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createTenantCommand !== undefined ? createTenantCommand : {}) : (createTenantCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsDetailGet(id?: string, options: any = {}): RequestArgs {
            const localVarPath = `/admin/Tenants.detail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsQueryGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/admin/Tenants.query`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsQueryTenantDetailsGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/admin/Tenants.queryTenantDetails`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [tenantName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsSummaryGet(tenantName?: string, options: any = {}): RequestArgs {
            const localVarPath = `/admin/Tenants.summary`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (tenantName !== undefined) {
                localVarQueryParameter['tenantName'] = tenantName;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateMaestroCredentialsCommand} [updateMaestroCredentialsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsUpdateMaestroCredentialsPut(updateMaestroCredentialsCommand?: UpdateMaestroCredentialsCommand, options: any = {}): RequestArgs {
            const localVarPath = `/admin/Tenants.updateMaestroCredentials`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateMaestroCredentialsCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateMaestroCredentialsCommand !== undefined ? updateMaestroCredentialsCommand : {}) : (updateMaestroCredentialsCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateTenantCommand} [updateTenantCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsUpdatePut(updateTenantCommand?: UpdateTenantCommand, options: any = {}): RequestArgs {
            const localVarPath = `/admin/Tenants.update`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateTenantCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateTenantCommand !== undefined ? updateTenantCommand : {}) : (updateTenantCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateTenantConfigurationCommand} [updateTenantConfigurationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsUpdateTenantConfigPut(updateTenantConfigurationCommand?: UpdateTenantConfigurationCommand, options: any = {}): RequestArgs {
            const localVarPath = `/admin/Tenants.updateTenantConfig`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateTenantConfigurationCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateTenantConfigurationCommand !== undefined ? updateTenantConfigurationCommand : {}) : (updateTenantConfigurationCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantsAllGet(options: any = {}): RequestArgs {
            const localVarPath = `/api/Tenants.all`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantsCompanyGet(options: any = {}): RequestArgs {
            const localVarPath = `/api/Tenants.company`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantsDetailsGet(options: any = {}): RequestArgs {
            const localVarPath = `/api/Tenants.details`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TenantSyncType} [syncType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantsLastSyncTimeGet(syncType?: TenantSyncType, options: any = {}): RequestArgs {
            const localVarPath = `/api/Tenants.lastSyncTime`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (syncType !== undefined) {
                localVarQueryParameter['syncType'] = syncType;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCompanyCommand} [updateCompanyCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantsUpdateCompanyPut(updateCompanyCommand?: UpdateCompanyCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/Tenants.updateCompany`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateCompanyCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateCompanyCommand !== undefined ? updateCompanyCommand : {}) : (updateCompanyCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantName 
         * @param {number} [fingerPrint] 
         * @param {string} [APIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devTenantsTenantNameApplicationsDelete(tenantName: string, fingerPrint?: number, APIKEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'tenantName' is not null or undefined
            if (tenantName === null || tenantName === undefined) {
                throw new RequiredError('tenantName','Required parameter tenantName was null or undefined when calling devTenantsTenantNameApplicationsDelete.');
            }
            const localVarPath = `/dev/Tenants/{tenantName}/applications`
                .replace(`{${"tenantName"}}`, encodeURIComponent(String(tenantName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (fingerPrint !== undefined) {
                localVarQueryParameter['fingerPrint'] = fingerPrint;
            }

            if (APIKEY !== undefined && APIKEY !== null) {
                localVarHeaderParameter['APIKEY'] = String(APIKEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantName 
         * @param {number} [fingerPrint] 
         * @param {string} [APIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devTenantsTenantNameDataDelete(tenantName: string, fingerPrint?: number, APIKEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'tenantName' is not null or undefined
            if (tenantName === null || tenantName === undefined) {
                throw new RequiredError('tenantName','Required parameter tenantName was null or undefined when calling devTenantsTenantNameDataDelete.');
            }
            const localVarPath = `/dev/Tenants/{tenantName}/data`
                .replace(`{${"tenantName"}}`, encodeURIComponent(String(tenantName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (fingerPrint !== undefined) {
                localVarQueryParameter['fingerPrint'] = fingerPrint;
            }

            if (APIKEY !== undefined && APIKEY !== null) {
                localVarHeaderParameter['APIKEY'] = String(APIKEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantName 
         * @param {number} [fingerPrint] 
         * @param {string} [APIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devTenantsTenantNameUsersDelete(tenantName: string, fingerPrint?: number, APIKEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'tenantName' is not null or undefined
            if (tenantName === null || tenantName === undefined) {
                throw new RequiredError('tenantName','Required parameter tenantName was null or undefined when calling devTenantsTenantNameUsersDelete.');
            }
            const localVarPath = `/dev/Tenants/{tenantName}/users`
                .replace(`{${"tenantName"}}`, encodeURIComponent(String(tenantName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (fingerPrint !== undefined) {
                localVarQueryParameter['fingerPrint'] = fingerPrint;
            }

            if (APIKEY !== undefined && APIKEY !== null) {
                localVarHeaderParameter['APIKEY'] = String(APIKEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantsApi - functional programming interface
 * @export
 */
export const TenantsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddTenantConfigurationCommand} [addTenantConfigurationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsAddTenantConfigPost(addTenantConfigurationCommand?: AddTenantConfigurationCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TenantsApiAxiosParamCreator(configuration).adminTenantsAddTenantConfigPost(addTenantConfigurationCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateTenantCommand} [createTenantCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsCreatePost(createTenantCommand?: CreateTenantCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TenantsApiAxiosParamCreator(configuration).adminTenantsCreatePost(createTenantCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsDetailGet(id?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantDetailOutputDTO> {
            const localVarAxiosArgs = TenantsApiAxiosParamCreator(configuration).adminTenantsDetailGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsQueryGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = TenantsApiAxiosParamCreator(configuration).adminTenantsQueryGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsQueryTenantDetailsGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantDetailsQueryOutputDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = TenantsApiAxiosParamCreator(configuration).adminTenantsQueryTenantDetailsGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [tenantName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsSummaryGet(tenantName?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantSummaryOutputDTO> {
            const localVarAxiosArgs = TenantsApiAxiosParamCreator(configuration).adminTenantsSummaryGet(tenantName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateMaestroCredentialsCommand} [updateMaestroCredentialsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsUpdateMaestroCredentialsPut(updateMaestroCredentialsCommand?: UpdateMaestroCredentialsCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TenantsApiAxiosParamCreator(configuration).adminTenantsUpdateMaestroCredentialsPut(updateMaestroCredentialsCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateTenantCommand} [updateTenantCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsUpdatePut(updateTenantCommand?: UpdateTenantCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TenantsApiAxiosParamCreator(configuration).adminTenantsUpdatePut(updateTenantCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateTenantConfigurationCommand} [updateTenantConfigurationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsUpdateTenantConfigPut(updateTenantConfigurationCommand?: UpdateTenantConfigurationCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TenantsApiAxiosParamCreator(configuration).adminTenantsUpdateTenantConfigPut(updateTenantConfigurationCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantsAllGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TenantOutputDTO>> {
            const localVarAxiosArgs = TenantsApiAxiosParamCreator(configuration).apiTenantsAllGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantsCompanyGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDTO> {
            const localVarAxiosArgs = TenantsApiAxiosParamCreator(configuration).apiTenantsCompanyGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantsDetailsGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantDetailsDTO> {
            const localVarAxiosArgs = TenantsApiAxiosParamCreator(configuration).apiTenantsDetailsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {TenantSyncType} [syncType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantsLastSyncTimeGet(syncType?: TenantSyncType, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTenantLastSyncTimeQuery> {
            const localVarAxiosArgs = TenantsApiAxiosParamCreator(configuration).apiTenantsLastSyncTimeGet(syncType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateCompanyCommand} [updateCompanyCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantsUpdateCompanyPut(updateCompanyCommand?: UpdateCompanyCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TenantsApiAxiosParamCreator(configuration).apiTenantsUpdateCompanyPut(updateCompanyCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tenantName 
         * @param {number} [fingerPrint] 
         * @param {string} [APIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devTenantsTenantNameApplicationsDelete(tenantName: string, fingerPrint?: number, APIKEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TenantsApiAxiosParamCreator(configuration).devTenantsTenantNameApplicationsDelete(tenantName, fingerPrint, APIKEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tenantName 
         * @param {number} [fingerPrint] 
         * @param {string} [APIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devTenantsTenantNameDataDelete(tenantName: string, fingerPrint?: number, APIKEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TenantsApiAxiosParamCreator(configuration).devTenantsTenantNameDataDelete(tenantName, fingerPrint, APIKEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tenantName 
         * @param {number} [fingerPrint] 
         * @param {string} [APIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devTenantsTenantNameUsersDelete(tenantName: string, fingerPrint?: number, APIKEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TenantsApiAxiosParamCreator(configuration).devTenantsTenantNameUsersDelete(tenantName, fingerPrint, APIKEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TenantsApi - factory interface
 * @export
 */
export const TenantsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {AddTenantConfigurationCommand} [addTenantConfigurationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsAddTenantConfigPost(addTenantConfigurationCommand?: AddTenantConfigurationCommand, options?: any) {
            return TenantsApiFp(configuration).adminTenantsAddTenantConfigPost(addTenantConfigurationCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {CreateTenantCommand} [createTenantCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsCreatePost(createTenantCommand?: CreateTenantCommand, options?: any) {
            return TenantsApiFp(configuration).adminTenantsCreatePost(createTenantCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsDetailGet(id?: string, options?: any) {
            return TenantsApiFp(configuration).adminTenantsDetailGet(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsQueryGet(dqb?: string, options?: any) {
            return TenantsApiFp(configuration).adminTenantsQueryGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsQueryTenantDetailsGet(dqb?: string, options?: any) {
            return TenantsApiFp(configuration).adminTenantsQueryTenantDetailsGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [tenantName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsSummaryGet(tenantName?: string, options?: any) {
            return TenantsApiFp(configuration).adminTenantsSummaryGet(tenantName, options)(axios, basePath);
        },
        /**
         * 
         * @param {UpdateMaestroCredentialsCommand} [updateMaestroCredentialsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsUpdateMaestroCredentialsPut(updateMaestroCredentialsCommand?: UpdateMaestroCredentialsCommand, options?: any) {
            return TenantsApiFp(configuration).adminTenantsUpdateMaestroCredentialsPut(updateMaestroCredentialsCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {UpdateTenantCommand} [updateTenantCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsUpdatePut(updateTenantCommand?: UpdateTenantCommand, options?: any) {
            return TenantsApiFp(configuration).adminTenantsUpdatePut(updateTenantCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {UpdateTenantConfigurationCommand} [updateTenantConfigurationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTenantsUpdateTenantConfigPut(updateTenantConfigurationCommand?: UpdateTenantConfigurationCommand, options?: any) {
            return TenantsApiFp(configuration).adminTenantsUpdateTenantConfigPut(updateTenantConfigurationCommand, options)(axios, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantsAllGet(options?: any) {
            return TenantsApiFp(configuration).apiTenantsAllGet(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantsCompanyGet(options?: any) {
            return TenantsApiFp(configuration).apiTenantsCompanyGet(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantsDetailsGet(options?: any) {
            return TenantsApiFp(configuration).apiTenantsDetailsGet(options)(axios, basePath);
        },
        /**
         * 
         * @param {TenantSyncType} [syncType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantsLastSyncTimeGet(syncType?: TenantSyncType, options?: any) {
            return TenantsApiFp(configuration).apiTenantsLastSyncTimeGet(syncType, options)(axios, basePath);
        },
        /**
         * 
         * @param {UpdateCompanyCommand} [updateCompanyCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantsUpdateCompanyPut(updateCompanyCommand?: UpdateCompanyCommand, options?: any) {
            return TenantsApiFp(configuration).apiTenantsUpdateCompanyPut(updateCompanyCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} tenantName 
         * @param {number} [fingerPrint] 
         * @param {string} [APIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devTenantsTenantNameApplicationsDelete(tenantName: string, fingerPrint?: number, APIKEY?: string, options?: any) {
            return TenantsApiFp(configuration).devTenantsTenantNameApplicationsDelete(tenantName, fingerPrint, APIKEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} tenantName 
         * @param {number} [fingerPrint] 
         * @param {string} [APIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devTenantsTenantNameDataDelete(tenantName: string, fingerPrint?: number, APIKEY?: string, options?: any) {
            return TenantsApiFp(configuration).devTenantsTenantNameDataDelete(tenantName, fingerPrint, APIKEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} tenantName 
         * @param {number} [fingerPrint] 
         * @param {string} [APIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devTenantsTenantNameUsersDelete(tenantName: string, fingerPrint?: number, APIKEY?: string, options?: any) {
            return TenantsApiFp(configuration).devTenantsTenantNameUsersDelete(tenantName, fingerPrint, APIKEY, options)(axios, basePath);
        },
    };
};

/**
 * TenantsApi - object-oriented interface
 * @export
 * @class TenantsApi
 * @extends {BaseAPI}
 */
export class TenantsApi extends BaseAPI {
    /**
     * 
     * @param {AddTenantConfigurationCommand} [addTenantConfigurationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public adminTenantsAddTenantConfigPost(addTenantConfigurationCommand?: AddTenantConfigurationCommand, options?: any) {
        return TenantsApiFp(this.configuration).adminTenantsAddTenantConfigPost(addTenantConfigurationCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {CreateTenantCommand} [createTenantCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public adminTenantsCreatePost(createTenantCommand?: CreateTenantCommand, options?: any) {
        return TenantsApiFp(this.configuration).adminTenantsCreatePost(createTenantCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public adminTenantsDetailGet(id?: string, options?: any) {
        return TenantsApiFp(this.configuration).adminTenantsDetailGet(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public adminTenantsQueryGet(dqb?: string, options?: any) {
        return TenantsApiFp(this.configuration).adminTenantsQueryGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public adminTenantsQueryTenantDetailsGet(dqb?: string, options?: any) {
        return TenantsApiFp(this.configuration).adminTenantsQueryTenantDetailsGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [tenantName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public adminTenantsSummaryGet(tenantName?: string, options?: any) {
        return TenantsApiFp(this.configuration).adminTenantsSummaryGet(tenantName, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {UpdateMaestroCredentialsCommand} [updateMaestroCredentialsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public adminTenantsUpdateMaestroCredentialsPut(updateMaestroCredentialsCommand?: UpdateMaestroCredentialsCommand, options?: any) {
        return TenantsApiFp(this.configuration).adminTenantsUpdateMaestroCredentialsPut(updateMaestroCredentialsCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {UpdateTenantCommand} [updateTenantCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public adminTenantsUpdatePut(updateTenantCommand?: UpdateTenantCommand, options?: any) {
        return TenantsApiFp(this.configuration).adminTenantsUpdatePut(updateTenantCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {UpdateTenantConfigurationCommand} [updateTenantConfigurationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public adminTenantsUpdateTenantConfigPut(updateTenantConfigurationCommand?: UpdateTenantConfigurationCommand, options?: any) {
        return TenantsApiFp(this.configuration).adminTenantsUpdateTenantConfigPut(updateTenantConfigurationCommand, options)(this.axios, this.basePath);
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public apiTenantsAllGet(options?: any) {
        return TenantsApiFp(this.configuration).apiTenantsAllGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public apiTenantsCompanyGet(options?: any) {
        return TenantsApiFp(this.configuration).apiTenantsCompanyGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public apiTenantsDetailsGet(options?: any) {
        return TenantsApiFp(this.configuration).apiTenantsDetailsGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {TenantSyncType} [syncType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public apiTenantsLastSyncTimeGet(syncType?: TenantSyncType, options?: any) {
        return TenantsApiFp(this.configuration).apiTenantsLastSyncTimeGet(syncType, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {UpdateCompanyCommand} [updateCompanyCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public apiTenantsUpdateCompanyPut(updateCompanyCommand?: UpdateCompanyCommand, options?: any) {
        return TenantsApiFp(this.configuration).apiTenantsUpdateCompanyPut(updateCompanyCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} tenantName 
     * @param {number} [fingerPrint] 
     * @param {string} [APIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public devTenantsTenantNameApplicationsDelete(tenantName: string, fingerPrint?: number, APIKEY?: string, options?: any) {
        return TenantsApiFp(this.configuration).devTenantsTenantNameApplicationsDelete(tenantName, fingerPrint, APIKEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} tenantName 
     * @param {number} [fingerPrint] 
     * @param {string} [APIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public devTenantsTenantNameDataDelete(tenantName: string, fingerPrint?: number, APIKEY?: string, options?: any) {
        return TenantsApiFp(this.configuration).devTenantsTenantNameDataDelete(tenantName, fingerPrint, APIKEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} tenantName 
     * @param {number} [fingerPrint] 
     * @param {string} [APIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public devTenantsTenantNameUsersDelete(tenantName: string, fingerPrint?: number, APIKEY?: string, options?: any) {
        return TenantsApiFp(this.configuration).devTenantsTenantNameUsersDelete(tenantName, fingerPrint, APIKEY, options)(this.axios, this.basePath);
    }

}

/**
 * UserFeedbackApi - axios parameter creator
 * @export
 */
export const UserFeedbackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SendUserFeedbackCommand} [sendUserFeedbackCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserFeedbackPost(sendUserFeedbackCommand?: SendUserFeedbackCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/UserFeedback`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof sendUserFeedbackCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(sendUserFeedbackCommand !== undefined ? sendUserFeedbackCommand : {}) : (sendUserFeedbackCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserFeedbackApi - functional programming interface
 * @export
 */
export const UserFeedbackApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SendUserFeedbackCommand} [sendUserFeedbackCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserFeedbackPost(sendUserFeedbackCommand?: SendUserFeedbackCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = UserFeedbackApiAxiosParamCreator(configuration).apiUserFeedbackPost(sendUserFeedbackCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserFeedbackApi - factory interface
 * @export
 */
export const UserFeedbackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {SendUserFeedbackCommand} [sendUserFeedbackCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserFeedbackPost(sendUserFeedbackCommand?: SendUserFeedbackCommand, options?: any) {
            return UserFeedbackApiFp(configuration).apiUserFeedbackPost(sendUserFeedbackCommand, options)(axios, basePath);
        },
    };
};

/**
 * UserFeedbackApi - object-oriented interface
 * @export
 * @class UserFeedbackApi
 * @extends {BaseAPI}
 */
export class UserFeedbackApi extends BaseAPI {
    /**
     * 
     * @param {SendUserFeedbackCommand} [sendUserFeedbackCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserFeedbackApi
     */
    public apiUserFeedbackPost(sendUserFeedbackCommand?: SendUserFeedbackCommand, options?: any) {
        return UserFeedbackApiFp(this.configuration).apiUserFeedbackPost(sendUserFeedbackCommand, options)(this.axios, this.basePath);
    }

}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangeUserTypeCommand} [changeUserTypeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersChangeRolePost(changeUserTypeCommand?: ChangeUserTypeCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/Users.changeRole`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof changeUserTypeCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(changeUserTypeCommand !== undefined ? changeUserTypeCommand : {}) : (changeUserTypeCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserCommand} [createUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersCreatePost(createUserCommand?: CreateUserCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/Users.create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createUserCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createUserCommand !== undefined ? createUserCommand : {}) : (createUserCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteUserCommand} [deleteUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersDeleteDelete(deleteUserCommand?: DeleteUserCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/Users.delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof deleteUserCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(deleteUserCommand !== undefined ? deleteUserCommand : {}) : (deleteUserCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersDetailGet(userId?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Users.detail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersQueryGet(dqb?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/Users.query`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dqb !== undefined) {
                localVarQueryParameter['dqb'] = dqb;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserCommand} [updateUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersUpdatePut(updateUserCommand?: UpdateUserCommand, options: any = {}): RequestArgs {
            const localVarPath = `/api/Users.update`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateUserCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateUserCommand !== undefined ? updateUserCommand : {}) : (updateUserCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangeUserTypeCommand} [changeUserTypeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersChangeRolePost(changeUserTypeCommand?: ChangeUserTypeCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).apiUsersChangeRolePost(changeUserTypeCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateUserCommand} [createUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersCreatePost(createUserCommand?: CreateUserCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).apiUsersCreatePost(createUserCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DeleteUserCommand} [deleteUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersDeleteDelete(deleteUserCommand?: DeleteUserCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).apiUsersDeleteDelete(deleteUserCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersDetailGet(userId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDTO> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).apiUsersDetailGet(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersQueryGet(dqb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDTODynamicQueryOutputDTO> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).apiUsersQueryGet(dqb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateUserCommand} [updateUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersUpdatePut(updateUserCommand?: UpdateUserCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).apiUsersUpdatePut(updateUserCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {ChangeUserTypeCommand} [changeUserTypeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersChangeRolePost(changeUserTypeCommand?: ChangeUserTypeCommand, options?: any) {
            return UsersApiFp(configuration).apiUsersChangeRolePost(changeUserTypeCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {CreateUserCommand} [createUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersCreatePost(createUserCommand?: CreateUserCommand, options?: any) {
            return UsersApiFp(configuration).apiUsersCreatePost(createUserCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {DeleteUserCommand} [deleteUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersDeleteDelete(deleteUserCommand?: DeleteUserCommand, options?: any) {
            return UsersApiFp(configuration).apiUsersDeleteDelete(deleteUserCommand, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersDetailGet(userId?: string, options?: any) {
            return UsersApiFp(configuration).apiUsersDetailGet(userId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [dqb] DynamicQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersQueryGet(dqb?: string, options?: any) {
            return UsersApiFp(configuration).apiUsersQueryGet(dqb, options)(axios, basePath);
        },
        /**
         * 
         * @param {UpdateUserCommand} [updateUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersUpdatePut(updateUserCommand?: UpdateUserCommand, options?: any) {
            return UsersApiFp(configuration).apiUsersUpdatePut(updateUserCommand, options)(axios, basePath);
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {ChangeUserTypeCommand} [changeUserTypeCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersChangeRolePost(changeUserTypeCommand?: ChangeUserTypeCommand, options?: any) {
        return UsersApiFp(this.configuration).apiUsersChangeRolePost(changeUserTypeCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {CreateUserCommand} [createUserCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersCreatePost(createUserCommand?: CreateUserCommand, options?: any) {
        return UsersApiFp(this.configuration).apiUsersCreatePost(createUserCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {DeleteUserCommand} [deleteUserCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersDeleteDelete(deleteUserCommand?: DeleteUserCommand, options?: any) {
        return UsersApiFp(this.configuration).apiUsersDeleteDelete(deleteUserCommand, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersDetailGet(userId?: string, options?: any) {
        return UsersApiFp(this.configuration).apiUsersDetailGet(userId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [dqb] DynamicQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersQueryGet(dqb?: string, options?: any) {
        return UsersApiFp(this.configuration).apiUsersQueryGet(dqb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {UpdateUserCommand} [updateUserCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersUpdatePut(updateUserCommand?: UpdateUserCommand, options?: any) {
        return UsersApiFp(this.configuration).apiUsersUpdatePut(updateUserCommand, options)(this.axios, this.basePath);
    }

}


/**
 * WebHookApi - axios parameter creator
 * @export
 */
export const WebHookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateWebHookCommand} [createWebHookCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminWebHookCreatePost(createWebHookCommand?: CreateWebHookCommand, options: any = {}): RequestArgs {
            const localVarPath = `/admin/WebHook.create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Bearer", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createWebHookCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createWebHookCommand !== undefined ? createWebHookCommand : {}) : (createWebHookCommand || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebHookApi - functional programming interface
 * @export
 */
export const WebHookApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateWebHookCommand} [createWebHookCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminWebHookCreatePost(createWebHookCommand?: CreateWebHookCommand, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = WebHookApiAxiosParamCreator(configuration).adminWebHookCreatePost(createWebHookCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WebHookApi - factory interface
 * @export
 */
export const WebHookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateWebHookCommand} [createWebHookCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminWebHookCreatePost(createWebHookCommand?: CreateWebHookCommand, options?: any) {
            return WebHookApiFp(configuration).adminWebHookCreatePost(createWebHookCommand, options)(axios, basePath);
        },
    };
};

/**
 * WebHookApi - object-oriented interface
 * @export
 * @class WebHookApi
 * @extends {BaseAPI}
 */
export class WebHookApi extends BaseAPI {
    /**
     * 
     * @param {CreateWebHookCommand} [createWebHookCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebHookApi
     */
    public adminWebHookCreatePost(createWebHookCommand?: CreateWebHookCommand, options?: any) {
        return WebHookApiFp(this.configuration).adminWebHookCreatePost(createWebHookCommand, options)(this.axios, this.basePath);
    }

}


