import { InventoryGrid } from '@components';
import { InventoryExpirationDatesGrid } from '@components/organisms/InventoryExpirationDatesGrid';
import { InventoryQuarantineGrid } from '@components/organisms/InventoryQuarantineGrid';
import { InventorySerialNumbersGrid } from '@components/organisms/InventorySerialNumbersGrid';
import { MemberPageContainer } from '@containers/MemberPageContainer/MemberPageContainer';
import { ActionBar, Box, LayoutContent, Panel, Popover, Tab, Text } from '@oplog/express';
import { TenantDetailsDTO } from '@services/swagger';
import useCommonDataStore from '@store/commonData/commonDataState';
import * as React from 'react';
import { useState } from 'react';
import { Props } from '../../atoms/Component/Component';

const COMPONENT_INTL_KEY = 'StockStatus';

enum ProductCatalogTabs {
  Inventory = 'Inventory',
  InventoryQuarantine = 'InventoryQuarantine',
  InventoryExpirationDates = 'InventoryExpirationDates',
  InventorySerialNumbers = 'InventorySerialNumbers',
}
export interface InventoryProps extends Props {
  goTab: (tab: string) => void;
  getTenantDetails: () => void;
  tenantDetails: TenantDetailsDTO;
}

// eslint-disable-next-line react/prefer-stateless-function
export const Inventory: React.FC<InventoryProps> = ({ intl, goTab, getTenantDetails, tenantDetails }) => {
  const breadcrumb = [{ title: intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`] }];
  const [currentTab, setCurrentTab] = useState(0);

  const [{ integrations }, { setIntegrations }] = useCommonDataStore();

  return (
    <MemberPageContainer documentTitle={intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`]}>
      <ActionBar top="66px" title={intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`]} breadcrumb={breadcrumb} />
      <LayoutContent>
        <Panel>
          <Box>
            <Tab
              onTabChange={data => {
                setCurrentTab(data);
                goTab(Object.values(ProductCatalogTabs)[data]);
              }}
              activeIndex={currentTab}
              tabs={[
                {
                  id: ProductCatalogTabs.Inventory,
                  title: (
                    <Popover
                      content={
                        <Text fontFamily="Lato" fontSize="16">
                          {intl.messages[`${COMPONENT_INTL_KEY}.Grid.TitleMessage`]}
                        </Text>
                      }
                      isDark
                      withPortal
                      placement="top"
                      action={['hover']}
                    >
                      <Text>{intl.messages[`${COMPONENT_INTL_KEY}.Grid.Title`]}</Text>
                    </Popover>
                  ),
                  component: (
                    <InventoryGrid
                      intl={intl}
                      integrations={integrations}
                      getTenantDetails={getTenantDetails}
                      tenantDetails={tenantDetails}
                    />
                  ),
                },
                {
                  id: ProductCatalogTabs.InventoryQuarantine,
                  title: (
                    <Popover
                      content={
                        <Text fontFamily="Lato" fontSize="16">
                          {intl.messages[`${COMPONENT_INTL_KEY}.Grid.QuarantineMessage`]}
                        </Text>
                      }
                      isDark
                      withPortal
                      placement="top"
                      action={['hover']}
                    >
                      <Text>{intl.messages[`${COMPONENT_INTL_KEY}.Grid.Quarantine`]}</Text>
                    </Popover>
                  ),
                  component: <InventoryQuarantineGrid intl={intl} />,
                },
                {
                  id: ProductCatalogTabs.InventoryExpirationDates,
                  title: (
                    <Popover
                      content={
                        <Text fontFamily="Lato" fontSize="16">
                          {intl.messages[`${COMPONENT_INTL_KEY}.Grid.ExpirationDatesMessage`]}
                        </Text>
                      }
                      isDark
                      withPortal
                      placement="top"
                      action={['hover']}
                    >
                      <Text>{intl.messages[`${COMPONENT_INTL_KEY}.Grid.ExpirationDates`]}</Text>
                    </Popover>
                  ),
                  component: <InventoryExpirationDatesGrid intl={intl} />,
                },
                {
                  id: ProductCatalogTabs.InventorySerialNumbers,
                  title: (
                    <Popover
                      content={
                        <Text fontFamily="Lato" fontSize="16">
                          {intl.messages[`${COMPONENT_INTL_KEY}.Grid.SerialNumbersMessage`]}
                        </Text>
                      }
                      isDark
                      withPortal
                      placement="top"
                      action={['hover']}
                    >
                      <Text>{intl.messages[`${COMPONENT_INTL_KEY}.Grid.SerialNumbers`]}</Text>
                    </Popover>
                  ),
                  component: <InventorySerialNumbersGrid intl={intl} />,
                },
              ]}
            />
          </Box>
        </Panel>
      </LayoutContent>
    </MemberPageContainer>
  );
};
